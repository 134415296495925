import * as React from "react";
import "./RequestTheRights.css";
import { Paper, Grid } from "@mui/material";
import Iframe from "react-iframe";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import getLanguage from '../../Translation/Translation';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";
import { Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs, { Dayjs } from "dayjs";
import DatePicker from '@mui/lab/DatePicker';
import { Formik, FormikProps } from "formik";
import { ContactUs } from "../../../types/contactus.type";
import { useAppDispatch } from "../../..";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import { useNavigate } from "react-router-dom";

type ContactUsPageProps = {
  //
};

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "gray",
  marginLeft: "auto",
  marginRight: "auto",
  paddingRight: 1,
  paddingBottom: 1
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center"
}));

const RequestTheRights: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "left" },
    buttons: { marginTop: 2 },
  };

  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
    // i18next.changeLanguage(code);
  };
  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'ไทย'
    }
  ];
  // function onLanguageClick(code){
  //   i18next.changeLanguage(code);
  // }


  const FormContactUs = ({ handleSubmit, handleChange, isSubmitting, values }: FormikProps<ContactUs>) => {
    return (
      <form onSubmit={handleSubmit} >
        <Typography sx={{marginBottom:'-10px'}} variant="inherit"  component="h6">
            {t('Name')}
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          onChange={handleChange}
          value={values.name}
          autoComplete="email"
          autoFocus
          size="small"
        />
        
        <Typography sx={{marginBottom:'-10px',marginTop:'10px'}} variant="inherit"  component="h6">
            {t('Email Address')}
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          onChange={handleChange}
          value={values.email_address}
          size="small"
          
          helperText={t('ContactUs Email helperText')}
          
          sx={{ '.MuiFormHelperText-root': {
            marginLeft: "0px",
          },}}
        />

         <Typography sx={{marginBottom:'-10px',marginTop:'10px'}} variant="inherit"  component="h6">
            {t('ContactUs Contact Number')}
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="contact_number"
          onChange={handleChange}
          value={values.contact_number}
          size="small"
        />

        <Typography sx={{marginBottom:'-10px',marginTop:'10px'}} variant="inherit"  component="h6">
        {t('Message')}
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="message"
          onChange={handleChange}
          value={values.message}
           multiline
           rows={3}
        />
        <br />

     
  
        <Stack direction="row" spacing={1} sx={classes.buttons}>
          <Button
            type="submit" 
            variant="contained" 
            sx={{ backgroundColor: '#EC994B', color:'#ffffff', margin: 'auto', width: '20%' , '&:hover': {backgroundColor: '#E8630A'} }}
          >
            {t('Send')}
          </Button>
        </Stack>
        {/* <a onClick={() => navigate("/register")}>
          Register Now
          </a> */}
      </form>
    );
  };


  const initialValues: ContactUs = { name: "", email_address: "", contact_number: "", message: "" };
  return (
    <Container fixed sx={{ mt: 3, padding: "2rem 0" }} >

              
                <Grid container spacing={2} columns={12}   sx={{alignItems: "center"}} >
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={async(values, {}) => {
                                dispatch(verifyEmailActions.sendMailRequest(values, navigate));
                            }}
                            
                        >
                        {(props) => FormContactUs(props)}
                        </Formik>
                    </Grid>
                </Grid>
  
      </Container>
  

  
  );
};

export default RequestTheRights;
