import * as React from "react";
import { Formik, FormikProps } from "formik";
import { Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { Reset } from "../../../types/reset.type";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import * as resetActions from "../../../actions/reset.action";
import { useAppDispatch } from "../../..";
import { Paper, Grid } from "@mui/material";
import { Link, useMatch, useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from "react-i18next";
import * as loginActions from "../../../actions/login.action";
import {
  OK,
  LOGIN_FAILED,
  LOGIN_FETCHING,
  LOGIN_SUCCESS,
  server,
  TOKEN,
  LOGOUT,
} from "../../../../src/services/Constants";
const Swal = require('sweetalert2');

type LoginPageProps = {
  
};



let user_id = 0;
const ResetPage: React.FC<any> = () => {


  const [showCurrentPassword, setShowCurrentPassword] = React.useState(true);
  const handleClickCurrentShowPassword = () => setShowCurrentPassword((show) => !show);

  const [showPassword, setShowPassword] = React.useState(true);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [ComfirmshowPassword, setComfirmshowPassword] = React.useState(true);
  const handleClickConfirmShowPassword = () => setComfirmshowPassword((show) => !show);
  const handleMouseConfirmDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const resetReducer = useSelector((state: RootReducers) => state.resetReducer);



  const navigate = useNavigate();
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "center" },
    buttons: { marginTop: 2 ,justifyContent: "center"},
  };
  
  React.useEffect(() => {
    if (localStorage.getItem(TOKEN)) {
        const token = JSON.stringify(localStorage.getItem(TOKEN));
          let base64Url = token.split(".")[1];
          let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          let tokenpayload;
          try{
           tokenpayload = decodeURIComponent(atob(base64).split("").map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
        user_id = JSON.parse(tokenpayload).user_id;
    }catch (e) {
      tokenpayload = unescape(atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      }).join("")
     
  );
  dispatch(loginActions.logouttologinpage(navigate));
    }
  }
  }, [localStorage.getItem(TOKEN)]);
  
  const showFormV2 = ({ handleSubmit, handleChange, isSubmitting, values }: FormikProps<any>) => {
    return (
      
      <form onSubmit={handleSubmit} >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="current_password"
          label={t("Current Password")}
          onChange={handleChange}
          value={values.current_password}
          type={showCurrentPassword ? 'password' : 'text'}
          InputProps={{
            endAdornment: <InputAdornment position="end">
            <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickCurrentShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            
            </InputAdornment>,
          }}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="new_password"
          label={t('New Password')}
          onChange={handleChange}
          value={values.new_password}
          type={showPassword ? 'password' : 'text'}
          InputProps={{
            endAdornment: <InputAdornment position="end">
            <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            
            </InputAdornment>,
          }}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="confirm_password"
          label={t('Confirm New Password')}
          onChange={handleChange}
          value={values.confirm_password}
          type={ComfirmshowPassword ? 'password' : 'text'}
          InputProps={{
            endAdornment: <InputAdornment position="end">
            <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickConfirmShowPassword}
                  // onMouseDown={handleMouseConfirmDownPassword}
                  edge="end"
                >
                  {ComfirmshowPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            
            </InputAdornment>,
          }}
        />
        <br />

        {resetReducer.isErrorCheckPasswordMatch && <Alert severity="error" >{t(`Password Not Match`)}</Alert>}
        {resetReducer.isErrorUpdate && <Alert severity="error">{t(`Change New Password Fialed`)}</Alert>}
        <Stack direction="row" spacing={1} sx={classes.buttons}>
          <Button   
            type="submit" 
            variant="contained"   
            sx={{backgroundColor: '#EC994B', color:'#ffffff', margin: 'auto', width: '40%' , '&:hover': {backgroundColor: '#E8630A'} }}
          >
            {t(" Reset ")} 
          </Button>
        </Stack>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center"
    justifyContent="center" direction="column" container>
      </Grid>
      </form>
    );
  };

  const initialValues: any = { current_password: "",new_password: "", confirm_password: "" };
  return (
    <>
      <Box sx={classes.root}>
        <Card sx={{ maxWidth: '40rem',width: '100%', marginTop: '6rem' }}>
          <CardContent sx={{border: '#ABA9A6 solid 1px', borderRadius: 0, padding: '2rem 2rem'}}>
            <Typography gutterBottom variant="h5" component="h2" sx={{color:'#4F4A41',textAlign:'center'}}>
              {t('Change Password')}
            </Typography>
            <Formik
              onSubmit={(values, {}) => {
                if(values.new_password !== values.confirm_password){
                  Swal.fire({
                    title: `${i18n.t(`Edit Data`)}`,
                    text: `${i18n.t(`Password does not match`)}`,
                    icon: 'error',
                  });
                  return;
                }
                let dataAll = {id:user_id,your_pass:values.current_password,password:values.new_password}
                dispatch(resetActions.Resetpassword(dataAll,navigate));
              }}
              initialValues={initialValues}
            >
              {(props) => showFormV2(props)}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default ResetPage;
