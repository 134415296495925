import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

import { createStore, applyMiddleware, Middleware, AnyAction } from "redux";
import thunk, { ThunkDispatch } from "redux-thunk";
import { Provider, useDispatch } from "react-redux";
import reducers from "./reducers";
import logger from "redux-logger";
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// import HttpApi from 'i18next-http-backend';
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationThai from './locales/th/translation.json';
import translationEnglish from './locales/en/translation.json';
const resources = {
  th:{
    translation:translationThai,
  },
  en:{
    translation:translationEnglish,
  },
}

if (!localStorage.getItem('i18nextLng')) {
  localStorage.setItem('i18nextLng', 'en');
   }

i18next
.use(LanguageDetector)
.use(initReactI18next) 
.init({
  resources,
    // lng:'en',
    fallbackLng: "en",
    debug: false, // show debug information(log)
      detection: {
      order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
   
});


// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .use(LanguageDetector)
//   .use(HttpApi)
//   .init({
//     supportedLngs: ['en','th'],
//     fallbackLng: "en",
//     detection: {
//       order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
//       caches: ['localStorage']
//     },
//     keySeparator: false, 

//     interpolation: {
//         escapeValue: false 
//     },
//     backend: {
//       loadPath: '../../assets/locales/{{lng}}/translation.json',
//     }
//   });


let middlewares: Middleware[] = [thunk];

if (process.env.REACT_APP_IS_PRODUCTION != "1") {
  // middlewares.push(logger); // log for action file
}
// reference
// https://redux.js.org/usage/usage-with-typescript
// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch = () => useDispatch<AppDispatch>();

// reference
//https://lightrun.com/answers/reduxjs-redux-thunk-thunkaction-is-not-assignable-to-parameter-of-type-anyaction
export type AppState = ReturnType<typeof reducers>;
export type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;
export const useAppDispatch = () => useDispatch<TypedDispatch<AppState>>();

export const store = createStore(reducers, applyMiddleware(...middlewares));
// export default i18n;
// export default  i18next;
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <BrowserRouter>
    <Provider store={store}>
    <App />
    </Provider>
    </BrowserRouter>
);

reportWebVitals();

// ReactDOM.render(
//   <BrowserRouter>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </BrowserRouter>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
