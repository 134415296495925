import * as React from "react";
import * as DepartmentActions from "../../../../actions/departmentmanagement.action";
import { useNavigate,useMatch } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  Divider,
  Container,
  Paper,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { User } from "../../../../types/user.type";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../../reducers";
import * as loginActions from "../../../../actions/login.action";
import { useAppDispatch } from "../../../..";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CachedIcon from "@mui/icons-material/Cached";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from '@mui/material/Autocomplete';
import * as rolesActions from "../../../../actions/roles.action";
import * as userActions from "../../../../actions/user.action";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from "dayjs";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Swal from "sweetalert2";
import { MEMBER_ID, server, TOKEN } from "../../../../services/Constants";
import { Roles } from "../../../../types/roles.type"
import { DepartmentManagement } from "../../../../types/departmentmanagement.type";

const UsersManagementAdd: React.FC<any> = () => {
  const match = useMatch("general-users-edit/:id");
  const main_id = match?.params.id;
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      // navigate("/login");
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
    const callawaitfunction = async () =>{
      await dispatch(userActions.loadUserById(Number(main_id)));
      await dispatch(DepartmentActions.loadDepartment_Management());
      await dispatch(rolesActions.loadROLES());;
  }
  callawaitfunction();
 
  }, [0]);
  const DepartmentReducer = useSelector((state: RootReducers) => state.DepartmentManagementStateReducer);
  const loginReducer = useSelector((state: RootReducers) => state.loginReducer);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "center" },
    buttons: { marginTop: 2 },
  };

  const [showPassword, setShowPassword] = React.useState(true);
  const [showPasswordUser, setShowPasswordUser] = React.useState(true);
  const [showConfirmPasswordUser, setShowConfirmPasswordUser] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordUser = () => setShowPasswordUser((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPasswordUser((show) => !show);

  const [SelectRoloes, setSelectRoloes] =  React.useState<any>({}); 
  const [dataAddUser, setdataAddUser] = React.useState({
    user_name: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    status: "Active",
    expiration_date: dayjs(),
    role_id: 0,
    user_create: 0,
    role:"",
    your_pass: "",
    department_id: 0,
    assign_permission: 0,
  });
  const RolesStateReducer = useSelector((state: RootReducers) => state.rolesStateReducer);
  const UserStateReducer = useSelector((state: RootReducers) => state.userStateReducer);
  React.useEffect(() => {
    UserStateReducer.result.forEach((item) =>{
      setdataAddUser({
        ...dataAddUser,
        user_name: item.user_name,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        password: item.password,
        status: item.status,
        expiration_date: dayjs(new Date(item.expiration_date)),
        role_id: item.role_id,
        role: item.role,
        department_id: item.department_id,
        assign_permission: item.assign_permission
        
      });
      setConfirmPassword(item.password)
      // setSelectRoloes({
      //   id: item.role_id,
      //   role: item.role,
      //   status: item.status_role, 
      //   created_at: item.created_at_role,
      //   updated_at: item.updated_at_role
      // })
    })
}, [UserStateReducer.result]);


  const [ConfirmPassword, setConfirmPassword] = React.useState("");
  const [UserRolelist, setUserRolelist] = React.useState<any[]>([]);
  const [Departmentlist, setDepartmentlist] = React.useState<any[]>([]);
  // const Departmentlist = DepartmentReducer.result;
  React.useEffect(() => {
    let  dataList:any[] = [];
    let index = 0;
    DepartmentReducer.result.forEach((item) =>{
        dataList[index] = item;
        index += 1;
    })
    setDepartmentlist(dataList)
}, [DepartmentReducer.result]);
  const Assignlist = [
      { assign_name: "Yes" , assign_id: 1},
      { assign_name: "No" , assign_id: 0}
    ];
  React.useEffect(() => {
      let  dataList:any[] = [];
      let index = 0;
      RolesStateReducer.result.forEach((item) =>{
          dataList[index] = item;
          index += 1;
      })
      setUserRolelist(dataList)
  }, [RolesStateReducer.result]);

  const Statsulist = [{status: "Active"},{status: "Inactive"}];


  const handleadCheckPassword = (condi:string) => {
    // alert(dataAddUser.password)
    if(condi == "p"){
        if(ConfirmPassword != ""){
          if(dataAddUser.password != ConfirmPassword){
            Swal.fire({
              title: `${t("Check Data")}`,
              text: `รหัสผ่านไม่ตรงกัน กรุณาเลือกรหัสผ่านใหม่`,
              icon: "error",
            });
            return;
          }else{
            return;
          }
        }else{
          return;
        }
    }else{
      if(dataAddUser.password != ""){
        if(dataAddUser.password != ConfirmPassword){
          Swal.fire({
            title: `${t("Check Data")}`,
            text: `รหัสผ่านไม่ตรงกัน กรุณาเลือกรหัสผ่านใหม่`,
            icon: "error",
          });
          return;
        }else{
          return;
        }
      }else{
        if(ConfirmPassword != ""){
          Swal.fire({
            title: `${t("Check Data")}`,
            text: `กรุณากรอกข้อมูลรหัสผ่าน`,
            icon: "error",
          });
          return;
        }else{
          return;
        }
      }

    }
  }
  const handleaddUserSubmit = (event: any) => {
    event.preventDefault();
    console.log(dataAddUser)
    // if(dataAddUser.password != ""){
    //   if(ConfirmPassword != ""){
    //     if(dataAddUser.password != ConfirmPassword){
    //       Swal.fire({
    //         title: `${t("Check Data")}`,
    //         text: `รหัสผ่านไม่ตรงกัน กรุณาเลือกรหัสผ่านใหม่`,
    //         icon: "error",
    //       });
    //       return;
    //     }
    //   }else{
    //     Swal.fire({
    //       title: `${t("Check Data")}`,
    //       text: `รหัสผ่านไม่ตรงกัน กรุณาเลือกรหัสผ่านใหม่`,
    //       icon: "error",
    //     });
    //     return;
    //   }
    // }else{
    //   if(ConfirmPassword != ""){
    //     Swal.fire({
    //       title: `${t("Check Data")}`,
    //       text: `กรุณากรอกข้อมูลรหัสผ่าน`,
    //       icon: "error",
    //     });
    //     return;
    //   }
    // }
    // if(dataAddUser.role_id == 0){
    //   Swal.fire({
    //     title: `${t("Check Data")}`,
    //     text: `กรุณากรอกข้อมูลกลุ่มผู้ใช้งาน`,
    //     icon: "error",
    //   });
    //   return;
    // }
    let dataEdit = {
      // user_name: "",
      // your_pass: "",
      // first_name: "",
      // last_name: "",
      // email: "",
      // status: "",
      // expiration_date: "",
      role_id: 0,
      // department_id: 0,
      // assign_permission: 0,
    };
    // dataEdit.user_name = dataAddUser.user_name;
    // dataEdit.first_name = dataAddUser.first_name;
    // dataEdit.last_name = dataAddUser.last_name;
    // dataEdit.email = dataAddUser.email;
    // dataEdit.status = dataAddUser.status;
    // dataEdit.expiration_date = dataAddUser.expiration_date?.toISOString().substring(0, 10);
    dataEdit.role_id = dataAddUser.role_id;
    // dataEdit.department_id = dataAddUser.department_id;
    // dataEdit.assign_permission = dataAddUser.assign_permission;
    // dataEdit.your_pass = dataAddUser.your_pass;
    dispatch(userActions.editUsers(dataEdit,Number(main_id), navigate));
  };
  
  const [selectedDepartment, setSelectedDepartment] = React.useState(() => {
    const foundRole = Departmentlist.find(option => Number(option.id) === Number(dataAddUser.department_id));
    return foundRole || null;
  });

  React.useEffect(() => {
    const foundRole = Departmentlist.find(option => Number(option.id) === Number(dataAddUser.department_id));
    setSelectedDepartment(foundRole || null);
  }, [Departmentlist, dataAddUser.department_id]);

  const [selectedAssign, setSelectedAssign] = React.useState<{ assign_name: string; assign_id: number; } | undefined>(() => {
    const foundRole = Assignlist.find(option => Number(option.assign_id) === Number(dataAddUser.assign_permission));
    return foundRole || undefined;
  });

  React.useEffect(() => {
    const foundRole = Assignlist.find(option => Number(option.assign_id) === Number(dataAddUser.assign_permission));
    setSelectedAssign(foundRole || undefined);
  }, [Assignlist, dataAddUser.assign_permission]);

  const [selectedRole, setSelectedRole] = React.useState(() => {
    const foundRole = UserRolelist.find(option => Number(option.id) === Number(dataAddUser.role_id));
    return foundRole || null;
  });

  React.useEffect(() => {
    const foundRole = UserRolelist.find(option => Number(option.id) === Number(dataAddUser.role_id));
    setSelectedRole(foundRole || null);
  }, [UserRolelist, dataAddUser.role_id]);

  const initialValues: User = { username: "", password: "" };
  return (
    <Container fixed sx={{ mt: 3 }}>
      <form onSubmit={handleaddUserSubmit}>
        <Box>
          <Grid
            container
            item
            // sx={{ ...gridStyles }}
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              alignItems="center"
              justifyContent="center"
              direction="column"
              item
              container
            >
              <Typography  
                sx={{ fontWeight: "bold", fontSize: "22px" }}
                textAlign="left"
              >
                  {t(`Edit New User`)}
              </Typography>
            </Grid>
          </Grid>

          <Grid sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                textAlign="left"
              >
                {t(`Account Information`)}
                {/* <Typography
                  sx={{ color: "red", display: "inline", fontSize: "22px" }}
                >
                  {" "}
                  *
                </Typography> */}
              </Typography>
              <Divider
                sx={{ borderBottomWidth: "initial", borderColor: "#00000061" }}
              ></Divider>
            </Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >   <Grid container >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11}
                    lg={11}
                    xl={11}
                    textAlign="start"
                    sx={{ alignSelf: "center" }}
                  > 
                    <Typography sx={{}}>
                      {t(`User Name`)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    textAlign="start"
                    sx={{ alignSelf: "center", mt: 1}}
                  > 
                    <Typography
                      sx={{ color: "red", display: "inline", fontSize: "22px" }}
                    >
                      {" "}
                      *
                    </Typography> 
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                disabled
                value={dataAddUser.user_name}
                required
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{}}
                sx={{
                  //  width:500,
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
                onChange={(e) => {
                  setdataAddUser({
                    ...dataAddUser,
                    user_name: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Grid container >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11}
                    lg={11}
                    xl={11}
                    textAlign="start"
                    sx={{ alignSelf: "center" }}
                  > 
                    <Typography sx={{}}>
                      {t(`First Name`)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    textAlign="start"
                    sx={{ alignSelf: "center", mt: 1}}
                  > 
                    <Typography
                      sx={{ color: "red", display: "inline", fontSize: "22px" }}
                    >
                      {" "}
                      *
                    </Typography> 
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                disabled
                value={dataAddUser.first_name}
                required
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{}}
                sx={{
                  //  width:500,
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
                onChange={(e) => {
                  setdataAddUser({
                    ...dataAddUser,
                    first_name: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Grid container >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11}
                    lg={11}
                    xl={11}
                    textAlign="start"
                    sx={{ alignSelf: "center" }}
                  > 
                    <Typography sx={{}}>
                      {t(`Last Name`)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    textAlign="start"
                    sx={{ alignSelf: "center", mt: 1}}
                  > 
                    <Typography
                      sx={{ color: "red", display: "inline", fontSize: "22px" }}
                    >
                      {" "}
                      *
                    </Typography> 
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                disabled
                value={dataAddUser.last_name}
                required
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{}}
                sx={{
                  //  width:500,
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
                onChange={(e) => {
                  setdataAddUser({
                    ...dataAddUser,
                    last_name: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Grid container >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11}
                    lg={11}
                    xl={11}
                    textAlign="start"
                    sx={{ alignSelf: "center" }}
                  > 
                    <Typography sx={{}}>
                      {t(`Email`)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    textAlign="start"
                    sx={{ alignSelf: "center", mt: 1}}
                  > 
                    <Typography
                      sx={{ color: "red", display: "inline", fontSize: "22px" }}
                    >
                      {" "}
                      *
                    </Typography> 
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
              disabled
              required
              type="email"
                value={dataAddUser.email}
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{}}
                sx={{
                  //  width:500,
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
                onChange={(e) => {
                  setdataAddUser({
                    ...dataAddUser,
                    email: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Typography sx={{}}>{t(`Password`)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                disabled
                value={dataAddUser.password}
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  //  width:500,
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
                type={showPasswordUser ? "password" : "text"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordUser}
                          // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordUser ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {     
                  setdataAddUser({
                    ...dataAddUser,
                    password: e.target.value,
                  });
                  
                }}
                onBlur={(e) =>{
                  handleadCheckPassword("p")
                }} 
                
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Typography sx={{}}>{t(`Confirm Password`)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                disabled
                value={ConfirmPassword}
                onChange={(e) => {     
                  setConfirmPassword(e.target.value)
                }}
                onBlur={(e) =>{
                  handleadCheckPassword("c")
                }} 
                fullWidth
                variant="outlined"
                size="small"
                type={showConfirmPasswordUser ? "password" : "text"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton 
                        disabled
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                          // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPasswordUser ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  //  width:500,
                  "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                  },
                }}
                
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Typography sx={{}}>{t(`This Account is`)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <Autocomplete
                disabled
                value={{ status: `${dataAddUser.status}` }}
                onChange={(e,value: any) => {
                  setdataAddUser({
                    ...dataAddUser,
                    status: value?.status,
                  });
                }}
                disableClearable={true}
                size="small"
                disablePortal
                options={Statsulist}
                getOptionLabel={(options) => options.status}
                isOptionEqualToValue={(option, value) => option.status === value.status}
                sx={{ minWidth: '100%' }}
                renderInput={(params) => <TextField {...params}     variant="outlined"  />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}></Grid>
          </Grid>
          <Grid container item sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Typography sx={{}}>{t(`Expiration Date`)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <LocalizationProvider 
                dateAdapter={AdapterDayjs} 
                adapterLocale={'en-gb'}
              >
                <DemoContainer components={['DatePicker']}>
                  <DatePicker   
                  disabled
                    value={dataAddUser.expiration_date}
                    defaultValue={dayjs(Date.now())} 
                    onChange={(newValue:any) => {
                      setdataAddUser({
                        ...dataAddUser,
                        expiration_date: newValue
                      });
                    }}     
                    slotProps={{ textField: { size: 'small' } }}      
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid sx={{ mt: 4 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                textAlign="left"
              >
                {t(`Department`)}
              </Typography>
              <Divider
                sx={{ borderBottomWidth: "initial", borderColor: "#00000061" }}
              ></Divider>
            </Grid>
          </Grid>
          <Grid container item sx={{ mt: 4 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
        <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Grid container >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11}
                    lg={11}
                    xl={11}
                    textAlign="start"
                    sx={{ alignSelf: "center" }}
                  > 
                    <Typography sx={{}}>
                      {t(`Department Name`)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    textAlign="start"
                    sx={{ alignSelf: "center", mt: 1}}
                  > 
                    <Typography
                      sx={{ color: "red", display: "inline", fontSize: "22px" }}
                    >
                      {" "}
                      *
                    </Typography> 
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Autocomplete
              disabled
                disableClearable={true}
                value={selectedDepartment}
                // onChange={(event, value: any) => setCompanySelected(value?.id)} 
                size="small"
                clearOnEscape
                disablePortal
                options={Departmentlist}
                getOptionLabel={(options) => options.name}
                sx={{ minWidth: '100%' }}
                onChange={(e,value: any) => {
                  console.log(value)
                  setdataAddUser({
                    ...dataAddUser,
                    department_id: Number(value?.id),
                  });
                }}
                renderInput={(params) => <TextField {...params}  variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>
          {/* <Grid sx={{ mt: 4 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                textAlign="left"
              >
                Assign Permission
              </Typography>
              <Divider
                sx={{ borderBottomWidth: "initial", borderColor: "#00000061" }}
              ></Divider>
            </Grid>
          </Grid>
          <Grid container item sx={{ mt: 4 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
        <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Grid container >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11}
                    lg={11}
                    xl={11}
                    textAlign="start"
                    sx={{ alignSelf: "center" }}
                  > 
                    <Typography sx={{}}>
                      Assign Permission
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    textAlign="start"
                    sx={{ alignSelf: "center", mt: 1}}
                  > 
                    <Typography
                      sx={{ color: "red", display: "inline", fontSize: "22px" }}
                    >
                      {" "}
                      *
                    </Typography> 
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <Autocomplete
                disableClearable={true}
                value={selectedAssign}
                // onChange={(event, value: any) => setCompanySelected(value?.id)} 
                size="small"
                clearOnEscape
                disablePortal
                options={Assignlist}
                getOptionLabel={(options) => options.assign_name}
                isOptionEqualToValue={(option, value) => option.assign_id === value.assign_id}
                sx={{ minWidth: '100%' }}
                onChange={(e,value: any) => {
                  setdataAddUser({
                    ...dataAddUser,
                    assign_permission: value?.assign_id,
                  });
                }}
                renderInput={(params) => <TextField {...params}  variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid> */}
          <Grid sx={{ mt: 4 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                textAlign="left"
              >
                {t(`Account Role`)}
              </Typography>
              <Divider
                sx={{ borderBottomWidth: "initial", borderColor: "#00000061" }}
              ></Divider>
            </Grid>
          </Grid>
          <Grid container item sx={{ mt: 4 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              textAlign="center"
            ></Grid>
          <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Grid container >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11}
                    lg={11}
                    xl={11}
                    textAlign="start"
                    sx={{ alignSelf: "center" }}
                  > 
                    <Typography sx={{}}>
                      {t(`Role Name`)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    textAlign="start"
                    sx={{ alignSelf: "center", mt: 1}}
                  > 
                    <Typography
                      sx={{ color: "red", display: "inline", fontSize: "22px" }}
                    >
                      {" "}
                      *
                    </Typography> 
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Autocomplete
                value={selectedRole}
                options={UserRolelist}
                getOptionLabel={(option) => option.role}
                filterSelectedOptions
                disableClearable={true}
                size="small"
                clearOnEscape
                disablePortal
                sx={{ minWidth: '100%' }}
                onChange={(e,value: any) => {
                  setdataAddUser({
                    ...dataAddUser,
                    role_id: value?.id,
                  });
                }}
                renderInput={(params) => <TextField {...params}  variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid>

          {/* <Grid sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "18px" }}
                textAlign="left"
              >
                {t(`Current User Identity Verification`)}
              </Typography>
              <Divider
                sx={{ borderBottomWidth: "initial", borderColor: "#00000061" }}
              ></Divider>
            </Grid>
          </Grid>

          <Grid container item sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              textAlign="start"
              sx={{ alignSelf: "center" }}
            >
              <Grid container >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11}
                    lg={11}
                    xl={11}
                    textAlign="start"
                    sx={{ alignSelf: "center" }}
                  > 
                    <Typography sx={{}}>
                       {t(`Your Password`)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    textAlign="start"
                    sx={{ alignSelf: "center", mt: 1}}
                  > 
                    <Typography
                      sx={{ color: "red", display: "inline", fontSize: "22px" }}
                    >
                      {" "}
                      *
                    </Typography> 
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                fullWidth
                id="password"
                // onChange={handleChange}
                // value={values.password}
                onChange={(e: any) => {
                  setdataAddUser({
                    ...dataAddUser,
                    your_pass: e.target.value,
                  });
                }}
                type={showPassword ? "password" : "text"}
                autoComplete='new-password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
          </Grid> */}


          <Grid container item justifyContent="center" xs={12} sx={{ mt: 4 }}>
            <Button
              startIcon={<ArrowBackIcon />}
              variant="contained"
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                m: 2,
                width: 90,
                backgroundColor: "#73777B",
                "&:hover": { backgroundColor: "#2C394B" },
              }}
              className="requestButton"
            >
              {t(`Back`)}
            </Button>

            <Button
              startIcon={<CachedIcon />}
              variant="contained"
              onClick={(e)=>{window.location.reload();}}
              sx={{
                m: 2,
                width: 90,
                backgroundColor: "#FFFFFF",
                color: "#F36D0A",
                "&:hover": { backgroundColor: "#FFFFF4" },
              }}
              className="requestButton"
            >
              {t(`Reset`)}
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                m: 2,
                width: 90,
                backgroundColor: "#c79059",
                "&:hover": { backgroundColor: "#c18346" },
              }}
              className="requestButton"
            >
              {t(`Save`)}
            </Button>
          </Grid>
        </Box>
      </form>
    </Container>
  );
};
export default UsersManagementAdd;
