import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../..";
import { useTranslation } from "react-i18next";
import { server, TOKEN,MEMBER_ID } from "../../../services/Constants";
import { Grid, Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as companyListActions from "../../../actions/company.list.action";
import * as requestTypeListActions from "../../../actions/requesttype.list.action";
import * as requestSubTypeListActions from "../../../actions/requestsubtype.list.action";
import * as requestCreatetActions from "../../../actions/request.create.actions";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from "dayjs";
import "./RequestCreatePage.css";
import * as loginActions from "../../../actions/login.action";
import { Tune } from "@mui/icons-material";
import i18next from 'i18next';
import * as registerActions from "../../../actions/register.action";
const Swal = require('sweetalert2')



export default function RequestCreatePage() {
    const CompanyListReducer = useSelector((state: RootReducers) => state.companyListReducer);
    const requesTypeListReducer = useSelector((state: RootReducers) => state.requesTypeListReducer);
    const requestSubTypeListReducer = useSelector((state: RootReducers) => state.requestSubTypeListReducer);
    const registerReducer = useSelector((state: RootReducers) => state.registerReducer);
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [request_type_list, setRequesttypelist] = React.useState<any[]>([]);
    const [first_name, setfirst_name] = React.useState<string>("");
    const [last_name, setlast_name] = React.useState<string>("");
    React.useEffect(() => {
        if (!localStorage.getItem(TOKEN)) {
        //   navigate("/login");
        dispatch(loginActions.logouttologinpage(navigate));
        }
        dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
    }, []);
    //load conpany list
    React.useEffect(() => {
        dispatch(companyListActions.loadCompanyLiest(localStorage.getItem(MEMBER_ID)));
    }, []);

    //load request type list
    React.useEffect(() => {
        dispatch(requestTypeListActions.loadRequestTypeList());
    }, []);

    //load sub request  type list
    React.useEffect(() => {
        dispatch(requestSubTypeListActions.loadRequestSubTypeList());
    }, []);

    React.useEffect(() => {
        dispatch(registerActions.loadMemberByID("Add",Number(localStorage.getItem(MEMBER_ID))));
    }, []);

    React.useEffect(() => {
        let  dataList:any[] = [];
        let index = 0;
        requestSubTypeListReducer.result.forEach((item) =>{
            if(i18next.language == 'en'){
                item.request_sub_show = item.request_sub_name_en;
            }else if(i18next.language == 'th'){
                item.request_sub_show = item.request_sub_name;
            }else{
                return;
            }
            dataList[index] = item;
            index += 1;
        })
        console.log("dataList request Sub")
        console.log(dataList)
        setRequestSubTypeList(dataList)
        setRequestSubTypeListAuto(dataList)
        onLoadRequestSubType(req_type_select)
    }, [requestSubTypeListReducer.result,i18next.language]);

    React.useEffect(() => {
        if(registerReducer.result){
            console.log("dataList userName")
            console.log(registerReducer.result[0])
            setfirst_name(registerReducer.result[0].first_name)
            setlast_name(registerReducer.result[0].last_name)
        }
    }, [registerReducer.result]);
    
    // const company_list = CompanyListReducer.result;
    const [company_list, setcompany_list] = React.useState<any[]>([ {
            id: 0, 
            th_company_name: "No business"
        }
    ]);
    
    React.useEffect(() => {
        let  dataList:any[] = [];
        let index = 0;
        CompanyListReducer.result.forEach((item) =>{
            // console.log("item")
            // console.log(item)
            dataList[index] = item;
            index += 1;
        })
        if(i18next.language == 'en'){
            if(CompanyListReducer.result.length > 0){
                dataList[dataList.length - 1] = {
                    id: 0, 
                    th_company_name: "No business"
                }
            }else{
                dataList[0] = {
                    id: 0, 
                    th_company_name: "No business"
                }
            }
          }else if(i18next.language == 'th'){
            if(CompanyListReducer.result.length > 0){
                dataList[dataList.length - 1] = {
                    id: 0, 
                    th_company_name: "ไม่มีข้อมูลธุรกิจ"
                }
            }else{
                dataList[0] = {
                    id: 0, 
                    th_company_name: "ไม่มีข้อมูลธุรกิจ"
                }
            }
          }else{
            return;
          }

        setcompany_list(dataList);
    }, [CompanyListReducer.result,company_list,i18next.language]);
    // data test
    
    React.useEffect(() => {
        if(requesTypeListReducer.result.length >0){
          let  dataList:any[] = [];
          let index = 0;
          requesTypeListReducer.result.forEach((item) =>{
            if(i18next.language == 'en'){
              item.request_name_show = item.request_type_name_en;
            }else if(i18next.language == 'th'){
              item.request_name_show = item.request_type_name;
            }else{
              return;
            }
              dataList[index] = item;
              index += 1;
          })
          console.log("dataList request")
          console.log(dataList)
          setRequesttypelist(dataList)
        }
      }, [requesTypeListReducer.result,i18next.language]);
    // const request_sub_type_list = requestSubTypeListReducer.result

    //get data input
    const [datasheet_id, setCompanySelected] = React.useState(0);
    const [checkedbox, setCheckedBox] = React.useState(true);
    const [req_type_select, setRequestTypeSelect] = React.useState(0);
    const [statusD, setStatusD] = React.useState("1");
    const [statusS, setStatusS] = React.useState("2");
    const [remark, setRemark] = React.useState("");
    const [req_type, setRequestTypeSelected] = React.useState<any[]>([]);
    const [req_sub_type, setRequestSubTypeSelected] = React.useState<any[]>([]);
    const [request_sub_type_list, setRequestSubTypeList] = React.useState<any[]>([]);
    const [request_sub_type_list_auto, setRequestSubTypeListAuto] = React.useState<any[]>([]);
    const start = new Date().setDate(new Date().getDate() + 1);
    const [requestDate, setRequestDate] = React.useState<Dayjs | null>(dayjs(start));
    const [requestDateValue, setRequestValue] = React.useState<Dayjs | null>(dayjs(start));
    //date 
    const setReqestDateValue = (newValue: Dayjs | null) => {
        setRequestDate(newValue);
    };

    const onLoadRequestSubType = (request_id:number) => {
        setRequestSubTypeSelected([])
        let loadListByReqId:any = [];
        let loadListIndex = 0;
        request_sub_type_list.forEach((item) => {
            if(Number(request_id) == Number(item.requesttype_id)){
                if(i18next.language == 'en'){
                    item.request_sub_show = item.request_sub_name_en;
                }else if(i18next.language == 'th'){
                    item.request_sub_show = item.request_sub_name;
                }else{
                    return;
                }
                loadListByReqId[loadListIndex] = item;
                loadListIndex += 1;
            }
            
        })
        console.log("loadListByReqId")
        console.log(loadListByReqId)
        setRequestSubTypeListAuto(loadListByReqId)
    };
    //get requestype
    const getRequestTypeSelect = (e:any) => {
        // Destructuring
        const { value, checked } = e.target;
        
        // Case 1 : The user checks the box
        if (checked) {
            let data = req_type;
            data.push({request_type_id: value});
            setRequestTypeSelected(data)
        }
        // Case 2  : The user unchecks the box
        else {
            let filtered = req_type.filter(item=>{
                return item.request_type_id != value;
            });
            setRequestTypeSelected(filtered)
        }

    };
     //get request subtype
    const getRequestSubTypeSelect = (e:any) => {
        // Destructuring
        const { value, checked } = e.target;
        
        // Case 1 : The user checks the box
        if (checked) {
            let data = req_sub_type;
            data.push({request_sub_type_id: value});
            setRequestSubTypeSelected(data)
        }
        // Case 2  : The user unchecks the box
        else {
            let filtered = req_sub_type.filter(item=>{
                return item.request_sub_type_id != value;
            });
            setRequestSubTypeSelected(filtered)
        }   
    };

    // const initialValues: RequesCreate = { 
    //     datasheet_id: datasheet_id, 
    //     req_type: req_type,
    //     req_sub_type: req_sub_type,
    //     remark: remark,
    //     status: status
    // };
    const SaveDraftRequest = (event:any) => {
        let reqDate:any = requestDate?.toISOString().substring(0, 10);
        event.preventDefault();
        if(dayjs(requestDate).format('DD-MM-YYYY') >= dayjs(start).format('DD-MM-YYYY')){
        }
        else{
            Swal.fire({
                title: `${t("Check Data")}`,
                text: `${t("Please Select Request Due Date")}`,
                icon: "warning",
            });  
            return;
        }
        // console.log(req_type_select)
        // console.log(req_sub_type)
        if(req_type_select == 0){
            Swal.fire({
                title: `${t("Check Data")}`,
                text: `${t("Please Choose Request Type")}`,
                icon: "warning",
            });  
            return;
        }
        if(req_type_select != 38){
            if(req_sub_type.length == 0){
                Swal.fire({
                    title:  `${t("Check Data")}`,
                    text: `${t("Please Choose Sub Request Type")}`,
                    icon: "warning",
                });  
                return;
            }
        }
        dispatch(requestCreatetActions.addRequestCreate(
            datasheet_id,
            req_type_select,
            req_sub_type,
            reqDate,
            remark,
            statusD,
            navigate
        ))
    };
    const SaveSubmitRequest = (event:any) => {
        event.preventDefault();
        if(dayjs(requestDate).format('DD-MM-YYYY') >= dayjs(start).format('DD-MM-YYYY')){
        }
        else{
            Swal.fire({
                title: `${t("Check Data")}`,
                text: `${t("Please Select Request Due Date")}`,
                icon: "warning",
            });  
            return;
        }
        let reqDate:any = requestDate?.toISOString().substring(0, 10);
        // if(datasheet_id == 0){
        //     Swal.fire({
        //         title: `${t("Check Data")}`,
        //         text: `${t("Please Choose Business")}`,
        //         icon: "warning",
        //     }); 
        //     return; 
        // }
        if(req_type_select == 0){
            Swal.fire({
                title: `${t("Check Data")}`,
                text: `${t("Please Choose Request Type")}`,
                icon: "warning",
            });  
            return;
        }
        if(req_type_select != 38){
            if(req_sub_type.length == 0){
                Swal.fire({
                    title: `${t("Check Data")}`,
                    text: `${t("Please Choose Sub Request Type")}`,
                    icon: "warning",
                });  
                return;
            }
        }
        dispatch(requestCreatetActions.addRequestCreate(
            datasheet_id,
            req_type_select,
            req_sub_type,
            reqDate,
            remark,
            statusS,
            navigate
        ))
    };
    const ClearValue = () => {
        window.location.reload();
    };
    return (
        <Grid  
            xs={12} sm={12} md={10} lg={5} xl={5} 
            container
            justifyContent="center"
            sx={
                { 
                    margin: '0 auto'
                }
            }
        >
            <Card 
                sx={
                    { 
                        borderRadius:'25px',
                        marginTop:2,
                        minWidth: '40%',
                        boxShadow: 'unset',
                        width: '100%',
                    }
                }
            >
                <CardContent>
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                            sx={{
                                borderBottom: '#ABA9A6 solid 2px',
                                marginBottom: '1rem'
                            }}
                        >
                            <Typography 
                                gutterBottom 
                                variant="h5" 
                                component="h2" 
                                sx={{
                                    color:'#4F4A41',
                                    textAlign: "left",
                                    marginTop: 2,
                                }}
                            >
                                {t(`New Request`)}
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2,
                            }}
                        >
                            <Grid 
                                xs={12}  xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>{t(`Request Due Date`)}</Typography>
                            </Grid>
                            <Grid 
                                xs={12}  xl={3} 
                                alignItems="center"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                                    <MobileDatePicker   
                                        value={requestDate}
                                        onChange={setReqestDateValue}
                                        slotProps={{ textField: { size: 'small' } }}
                                        minDate={dayjs()}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid 
                                xs={12} sm={2} md={2} lg={2} xl={2} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                >
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item 
                            sx={
                                    { 
                                        backgroundColor: '#CE9461',
                                        color:'#ffffff',
                                        padding: '0.7rem'
                                    }
                            }
                        >
                            <Typography
                                sx={{
                                    marginLeft: 1, 
                                }}
                            >
                                {t(`Section 1 Persanal Information`)}
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2,
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={12} lg={3} xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>{t(`Firstname`)}</Typography>
                            </Grid>
                            <Grid 
                                xs={12} sm={12} md={12} lg={8} xl={8} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                <Typography>{first_name}</Typography>
                            </Grid>

                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2,
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={12} lg={3} xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>{t(`Lastname`)}</Typography>
                            </Grid>
                            <Grid 
                                xs={12} sm={12} md={12} lg={8} xl={8} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                <Typography>{last_name}</Typography>
                            </Grid>

                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2,
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={12} lg={3} xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>{t(`Business Name`)}</Typography>
                            </Grid>
                            <Grid 
                                xs={12} sm={12} md={12} lg={5} xl={5} 
                                alignItems="center"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                <Autocomplete
                                    onChange={
                                        (event, value: any) => {
                                            setCompanySelected(value?.id)
                                        }
                                    } 
                                    size="small"
                                    options={company_list}
                                    getOptionLabel={(options) => options.th_company_name}
                                    sx={{ minWidth: '100%' }}
                                    renderInput={(params) => <TextField {...params}   variant="outlined"  />}
                                />
                            </Grid>
                            <Grid 
                                xs={12} sm={12} md={12} lg={3} xl={3} 
                                paddingLeft={2}
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                <Button 
                                    sx={{
                                        backgroundColor: '#CE9461',
                                        '&:hover': {backgroundColor: '#CC704B'}
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                        navigate("/datasheet/create")
                                    }}
                                >
                                    {t(`Add New`)}
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item 
                            sx={
                                    { 
                                        backgroundColor: '#CE9461',
                                        color:'#ffffff',
                                        padding: '0.7rem'
                                    }
                            }
                        >
                            <Typography
                                sx={{
                                    marginLeft: 1
                                }}
                            >
                                {t(`Section 2 Choose Request Type`)}
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={6} lg={4} xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>{t(`Request Type`)}</Typography>
                            </Grid>
                            <Grid 
                                xs={12} sm={12} md={12} lg={7} xl={8} 
                                alignItems="center"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                <Autocomplete
                                    onChange={
                                        (event, value: any) => {
                                            setRequestTypeSelect(value?.id)
                                            setCheckedBox(false)
                                            onLoadRequestSubType(value?.id)
                                        }
                                    }
                                    size="small"
                                    disablePortal
                                    options={request_type_list}
                                    getOptionLabel={(options) => options.request_name_show}
                                    sx={{ minWidth: '100%' }}
                                    renderInput={
                                        (params) => <TextField {...params}   variant="outlined"/>
                                    } 
                                />
                                {/* {request_type_list.map((name) => (
                                    <div 
                                        key={name.request_type_name} 
                                       
                                    >   
                                        <FormControlLabel
                                            label={name.request_type_name}
                                            control={
                                                <Checkbox 
                                                    value={name.id} 
                                                    onChange={(e)=>getRequestTypeSelect(e)} 
                                                />
                                            }
                                        />
                                    </div>
                                ))} */}
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item 
                            sx={
                                    { 
                                        backgroundColor: '#CE9461',
                                        color:'#ffffff',
                                        padding: '0.7rem'
                                    }
                            }
                        >
                            <Typography
                                sx={{
                                    marginLeft: 1, 
                                }}
                            >
                                {t(`Section 3 Choose Sub Request`)}
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2,
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={12} lg={12} xl={12} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                                sx = {{
                                    marginLeft:2,
                                }}
                            >
                                {request_sub_type_list_auto.map((name) => (
                                    <div key={name.request_sub_name}>
                                        <FormControlLabel
                                            label={name.request_sub_show}
                                            control={
                                                <Checkbox 
                                                    value={name.id} 
                                                    onChange={(e)=>getRequestSubTypeSelect(e)} 
                                                    disabled={checkedbox}
                                                />
                                            }
                                        />
                                    </div>
                                    
                                    
                                ))}
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item 
                        >
                            <TextField 
                                id="outlined-basic" 
                                value={remark} 
                                label={t(`More Information`)} 
                                variant="outlined" 
                                onChange={(e)=>setRemark(e.target.value)}
                                sx = {{
                                    width: '90%',
                                    margin: 'auto'
                                }}
                            />
                        </Grid>
                    <br />
                    <Grid 
                    container   justifyContent="center"
                    xs={12}
                    >

                        <Button 
                            variant="contained"
                            onClick={SaveDraftRequest}
                            sx={{
                                width: 90,
                                backgroundColor: "#73777B",
                                '&:hover': {backgroundColor: '#2C394B'}
                            }}
                            className="requestButton"
                        >
                            {t(`Draft`)}
                        </Button>
                        <Button 
                            sx={{
                                marginLeft:2,
                                marginRight: 2,
                                width: 90,
                                backgroundColor: "#3E8E7E",
                                '&:hover': {backgroundColor: '#125B50'}
                            }}
                            className="requestButton"
                            variant="contained"
                            onClick={SaveSubmitRequest}
                        >
                            
                            {t(`Submit`)}
                        </Button>
                        {/* <Button 
                            sx={{
                                width: 90,
                                backgroundColor: '#A84448'
                            }}
                            className="requestButton"
                            variant="contained"
                            color="error"
                            onClick={() => {
                                ClearValue()
                            }}
                        >
                            Clear
                        </Button> */}
                        <Button 
                            sx={{
                                width: 90,
                                backgroundColor: '#CE9461',
                                '&:hover': {backgroundColor: '#CC704B'}
                            }}
                            variant="contained"
                            onClick={() => {
                                navigate("/my-request")
                            }}
                        >
                            {t(`Back`)}
                        </Button>
                        
                    </Grid>
                    <br />
                </CardContent>
            </Card>
        </Grid>

  );
}
