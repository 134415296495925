import {
  OK,
  DATASHEET_CREATE_FETCHING,
  DATASHEET_CREATE_SUCCESS,
  DATASHEET_UPDATE_SUCCESS,
  DATASHEET_CREATE_FAILED,
  DATASHEET_CREATE_SHARE_HOLDER_TH,
  DATASHEET_CREATE_SHARE_HOLDER_EN,
  DATASHEET_CREATE_DIRECTOR_TH,
  DATASHEET_CREATE_DIRECTOR_EN,
  DATASHEET_LIST,
  DATASHEET_FIND_BY_ID,
  server,
  TOKEN,
  MEMBER_ID,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import i18n from 'i18next';
import {
  DataSheetCreate,
  DataSheetShareHolderTH,
  DataSheetShareHolderEN,
  DataSheetDirectorTH,
  DataSheetDirectorEN,
} from "../types/data_sheet.type";
const Swal = require("sweetalert2");

export const setDataSheetCreateFetchingToState = () => ({
  type: DATASHEET_CREATE_FETCHING,
});

export const setDataSheetCreateSuccessToState = (payload: DataSheetShareHolderTH) => ({
  type: DATASHEET_CREATE_SUCCESS,
  payload,
});

export const setDataSheetUpdateSuccessToState = (payload: DataSheetShareHolderTH) => ({
  type: DATASHEET_UPDATE_SUCCESS,
  payload,
});

export const setDataSheetShareHolderENToState = (payload: DataSheetShareHolderEN) => ({
  type: DATASHEET_CREATE_SHARE_HOLDER_EN,
  payload,
});

export const setDataSheetShareHolderTHToState = (payload: DataSheetShareHolderTH) => ({
  type: DATASHEET_CREATE_SHARE_HOLDER_TH,
  payload,
});

export const setDataSheetDirectorENToState = (payload: DataSheetDirectorEN) => ({
  type: DATASHEET_CREATE_DIRECTOR_EN,
  payload,
});

export const setDataSheetDirectorTHToState = (payload: DataSheetDirectorTH) => ({
  type: DATASHEET_CREATE_DIRECTOR_TH,
  payload,
});

export const setDataSheetCreateFailedToState = () => ({
  type: DATASHEET_CREATE_FAILED,
});

export const setDataSheetListToState = (payload: any) => ({
  type: DATASHEET_LIST,
  payload,
});

export const setDataSheetFindByIdToState = (payload: any) => ({
  type: DATASHEET_FIND_BY_ID,
  payload,
});


export const createDataSheet = (
  ds_data: DataSheetCreate,
  dsh_th: DataSheetShareHolderTH,
  dsh_en: DataSheetShareHolderEN,
  dsd_th: DataSheetDirectorTH,
  dsd_en: DataSheetDirectorEN,
  navigate: any
) => {
  return async (dispatch: any) => {
    try {
      // begin connecting...
      dispatch(setDataSheetCreateFetchingToState());

      const doc_gen = await httpClient.post(server.DOC_RUNNING + "/getdocGenerate", { module_name: "DataSheet" });

      ds_data.datasheet_number = doc_gen.data;

      // console.log("ds_data doc_gen:", JSON.stringify(doc_gen.data));
      console.log("dsh_th body:", JSON.stringify(dsh_th));
      // connect
      const result = await httpClient.post(server.DATASHEET_URL + "/add", ds_data);
      if (result.status == 200 || result.status == 201) {
        dispatch(setDataSheetCreateSuccessToState(result.data));
        // console.log("ds_data result:", JSON.stringify(result.data));

        Object.entries(dsh_th).forEach(async ([key, value], index) => {
          // console.log(key, value, index);
          // console.log("dsh_th:", value.th_share_holder);
          let data = {
            data_sheet_id: result.data.id,
            datasheet_number: result.data.datasheet_number,
            member_id: result.data.member_id,
            th_share_holder: value.th_share_holder,
          };
          await httpClient.post(server.SHARE_HOLDER, data);
        });

        Object.entries(dsh_en).forEach(async ([key, value], index) => {
          // console.log(key, value, index);
          // console.log("dsh_en:", value.en_share_holder);
          let data = {
            data_sheet_id: result.data.id,
            datasheet_number: result.data.datasheet_number,
            member_id: result.data.member_id,
            en_share_holder: value.en_share_holder,
          };
          await httpClient.post(server.SHARE_HOLDER, data);
        });

        Object.entries(dsd_th).forEach(async ([key, value], index) => {
          // console.log(key, value, index);
          // console.log("dsd_th:", value.th_director);
          let data = {
            data_sheet_id: result.data.id,
            datasheet_number: result.data.datasheet_number,
            member_id: result.data.member_id,
            th_director: value.th_director,
          };
          await httpClient.post(server.DIRECTOR, data);
        });

        Object.entries(dsd_en).forEach(async ([key, value], index) => {
          // console.log(key, value, index);
          // console.log("dsd_en:", value.en_director);
          let data = {
            data_sheet_id: result.data.id,
            datasheet_number: result.data.datasheet_number,
            member_id: result.data.member_id,
            en_director: value.en_director,
          };
          await httpClient.post(server.DIRECTOR, data);
        });

        Swal.fire({
          position: "center",
          icon: "success",
          title: `${i18n.t('Information Saved')}`,
          text:  `${i18n.t('The information has been saved and updated successfully.')}`,
          showConfirmButton: true,
          confirmButtonText: "OK",
        }).then((result: { isConfirmed: any }) => {
          if (result.isConfirmed) {
            navigate("/business-information");
          }
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${i18n.t('Information Saved')}`,
          text:  `${i18n.t('The information has been saved and updated successfully.')}`,
        });
        dispatch(setDataSheetCreateFailedToState());
      }
    } catch (error) {
      // error
      dispatch(setDataSheetCreateFailedToState());
      Swal.fire({
        position: "center",
        icon: "error",
        title: `${i18n.t('Information Saved')}`,
        text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,

      });
    }
  };
};

export const updateDataSheet = (
  id:number,
  ds_data: DataSheetCreate,
  dsh_th: DataSheetShareHolderTH,
  dsh_en: DataSheetShareHolderEN,
  dsd_th: DataSheetDirectorTH,
  dsd_en: DataSheetDirectorEN,
  navigate: any
) => {
  return async (dispatch: any) => {
    try {
      // return
      // begin connecting...
      dispatch(setDataSheetCreateFetchingToState());

      // console.log("ds_data doc_gen:", JSON.stringify(doc_gen.data));
      // connect
      const result = await httpClient.put(server.DATASHEET_URL + `/${id}`, ds_data);
      if (result.status == 200 || result.status == 201) {
        dispatch(setDataSheetUpdateSuccessToState(result.data));
        // console.log("ds_data result:", JSON.stringify(result.data));
     await httpClient.delete(server.DIRECTOR + `/${id}`);
     await httpClient.delete(server.SHARE_HOLDER + `/${id}`);


        Object.entries(dsh_th).forEach(async ([key, value], index) => {
          // console.log(key, value, index);
          // console.log("dsh_th:", value.th_share_holder);
          let data = {
            data_sheet_id: id,
            datasheet_number: ds_data.datasheet_number,
            member_id: ds_data.member_id,
            th_share_holder: value.th_share_holder,
          };
          await httpClient.post(server.SHARE_HOLDER, data);
        });

        Object.entries(dsh_en).forEach(async ([key, value], index) => {
          // console.log(key, value, index);
          // console.log("dsh_en:", value.en_share_holder);
          let data = {
            data_sheet_id: id,
            datasheet_number: ds_data.datasheet_number,
            member_id: ds_data.member_id,
            en_share_holder: value.en_share_holder,
          };
          await httpClient.post(server.SHARE_HOLDER, data);
        });

        Object.entries(dsd_th).forEach(async ([key, value], index) => {
          // console.log(key, value, index);
          // console.log("dsd_th:", value.th_director);
          let data = {
            data_sheet_id: id,
            datasheet_number: ds_data.datasheet_number,
            member_id: ds_data.member_id,
            th_director: value.th_director,
          };
          await httpClient.post(server.DIRECTOR, data);
        });

        Object.entries(dsd_en).forEach(async ([key, value], index) => {
          // console.log(key, value, index);
          // console.log("dsd_en:", value.en_director);
          let data = {
            data_sheet_id:id,
            datasheet_number: ds_data.datasheet_number,
            member_id: ds_data.member_id,
            en_director: value.en_director,
          };
          await httpClient.post(server.DIRECTOR, data);
        });

        Swal.fire({
          position: "center",
          icon: "success",
          title: `${i18n.t('Information Saved')}`,
          text:  `${i18n.t('The information has been saved and updated successfully.')}`,
          showConfirmButton: true,
          confirmButtonText: "OK",
        }).then((result: { isConfirmed: any }) => {
          if (result.isConfirmed) {
            navigate("/business-information");
          }
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${i18n.t('Information Saved')}`,
          text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,
        });
        dispatch(setDataSheetCreateFailedToState());
      }
    } catch (error) {
      // error
      dispatch(setDataSheetCreateFailedToState());
      Swal.fire({
        position: "center",
        icon: "error",
        title: `${i18n.t('Information Saved')}`,
        text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,
      });
    }
  };
};


export const getAllDataSheets = (member_id:number) => {
  return async (dispatch: any) => {
    try {
      // begin connecting...
      dispatch(setDataSheetCreateFetchingToState());
      // connect
      const result = await httpClient.get(server.DATASHEET_URL + `/getbymemer_id/${member_id}`);
      if (result.status === 200) {
        // result.data.unshift({id: 0,code: "00",name_en: "-- select provinces --",name_th: "-- เลือกจังหวัด --",geography_id: 0,created_at: null,updatedAt:  null})
        // setTimeout(() => {
          dispatch(setDataSheetListToState(result.data));
          // alert("Provinces Successfully");
          // navigate("/login");
        // }, 1000);
      } else {
        dispatch(setDataSheetCreateFetchingToState());
      }
    } catch (error) {
      // error
      dispatch(setDataSheetCreateFetchingToState());
    }
  };
};

export const getDataSheetFindById = (id: number) => {
  return async (dispatch: any) => {
    try {
      // begin connecting...
      dispatch(setDataSheetCreateFetchingToState());
      // connect
      const result = await httpClient.get(server.DATASHEET_URL + `/${id}`);
      if (result.status === 200) {
        // result.data.unshift({id: 0,code: "00",name_en: "-- select provinces --",name_th: "-- เลือกจังหวัด --",geography_id: 0,created_at: null,updatedAt:  null})
        // setTimeout(() => {
          dispatch(setDataSheetFindByIdToState(result.data));
          // alert("Provinces Successfully");
          // navigate("/login");
        // }, 1000);
      } else {
        dispatch(setDataSheetCreateFetchingToState());
      }
    } catch (error) {
      // error
      dispatch(setDataSheetCreateFetchingToState());
    }
  };
};

export const updateStatutsDataSheet = (
  id:number,
  ds_data: any,
) => {
  return async (dispatch: any) => {
    try {
      // begin connecting...
      dispatch(setDataSheetCreateFetchingToState());
      // connect
      const result = await httpClient.put(server.DATASHEET_URL + `/${id}`, ds_data);
      console.log(result)
      if (result.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${i18n.t('Information Saved')}`,
          text:  `${i18n.t('The information has been saved and updated successfully.')}`,
          denyButtonText: `OK`,
        });
        dispatch(getAllDataSheets(result.data.member_id));
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${i18n.t('Information Saved')}`,
          text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,
          denyButtonText: `OK`,
        });
        dispatch(setDataSheetCreateFetchingToState());
      }
    } catch (error) {
      dispatch(setDataSheetCreateFetchingToState());
    }
  };

};

export const getDataBUSINESS_INFORMATION_LOGS_Search = (query: any, navigate: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(setDataSheetCreateFetchingToState());
      const result = await httpClient.get<[]>(`${server.DATASHEET_URL}/getSearchData/businesslog`,query);
      dispatch(setDataSheetListToState(
          result.data 
      ));
         
    } catch (error) {
      dispatch(setDataSheetCreateFailedToState());
    }
  };
};