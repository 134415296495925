// import { history } from "../index";
import {
    REQUEST_TRACKING_FETCHING,
    REQUEST_TRACKING_SUCCESS,
    REQUEST_TRACKING_FAILED,
    REQUEST_TRACKING_CLEAR,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { RequestTracking } from "../types/request_tracking.type";
const Swal = require('sweetalert2')

export const setRequestTrackingFetchingToState = () => ({
    type: REQUEST_TRACKING_FETCHING,
});
  
export const setRequestTrackingSuccessToState = (
        payload: RequestTracking[],
        res2: number,
        res3: number,
        res6: number,
        res4: number,
        res9: number,
    ) => (
        {
            type: REQUEST_TRACKING_SUCCESS,
            payload,
            res2,
            res3,
            res6,
            res4,
            res9
        }
);
  
export const setRequestTrackingFailedToState = () => ({
    type: REQUEST_TRACKING_FAILED,
});

export const setRequestTrackingClearToState = () => ({
    type: REQUEST_TRACKING_CLEAR,
});
  
export const loadRequestByKeyword = (keyword: string, filter: string) => {
    return async (dispatch: any) => {
        dispatch(setRequestTrackingFetchingToState());
        try {
            let result = await httpClient.get<any>(
                `
                ${server.REQUEST}/keyword/${keyword}/${filter}/${localStorage.getItem(MEMBER_ID)}
                `
            );
            // alert(JSON.stringify(result))
            let res_count = 0;
            let oper_count = 0;
            let sucess_count = 0;
            result.data.forEach((item:any)=>{
                res_count = res_count + 1;
                if(item.status == "7"){
                    sucess_count = sucess_count + 1;
                }
                if(item.status != "7" && item.status != "8"){
                    oper_count = oper_count + 1;
                }
            })
            // dispatch(setRequestTrackingSuccessToState(
            //     result.data, 
            //     res_count,
            //     oper_count,
            //     sucess_count
            // ));
        } catch (error) {
            dispatch(setRequestTrackingFailedToState());
        }
    };
};

export const loadRequestTracking = () => {
    return async(dispatch: any) => {
        dispatch(setRequestTrackingFetchingToState());
        getDataTrackingRequest(dispatch)
    };
};
export const loadRequestTrackingHistory = () => {
    return async(dispatch: any) => {
        dispatch(setRequestTrackingFetchingToState());
        getDataTrackingRequestHistory(dispatch)
    };
};

const getDataTrackingRequest = async (dispatch: any) => {
    try {
        const result = await httpClient.get<RequestTracking[]>(`${server.REQUEST}/tracking/${localStorage.getItem(MEMBER_ID)}`);
        let res2 = 0;
        let res3 = 0;
        let res6 = 0;
        let res4 = 0;
        let res9 = 0;
        result.data.forEach((item)=>{
            if(item.status == "Not Started"){
                res2 = res2 + 1;
            }
            if(item.status == "In Progress"){
                res3 = res3 + 1;
            }
            if(item.status == "Overdue"){
                res6 = res6 + 1;
            }
            if(item.status == "Done"){
                res4 = res4 + 1;
            }
            if(item.status == "Closed"){
                res9 = res9 + 1;
            }
        })
        console.log("res2:"+res2)
        console.log("res3:"+res3)
        console.log("res6:"+res6)
        console.log("res4:"+res4)
        console.log("res9:"+res9)
        dispatch(setRequestTrackingSuccessToState(
            result.data, 
            res2,
            res3,
            res6,
            res4,
            res9

        ));
    }   catch (error) {
        dispatch(setRequestTrackingFailedToState());
    }
}
  
const getDataTrackingRequestHistory = async (dispatch: any) => {
    try {
        const result = await httpClient.get<RequestTracking[]>(`${server.REQUEST}/tracking-history/${localStorage.getItem(MEMBER_ID)}`);
        let res2 = 0;
        let res3 = 0;
        let res6 = 0;
        let res4 = 0;
        let res9 = 0;
        result.data.forEach((item)=>{
            if(item.status == "Not Started"){
                res2 = res2 + 1;
            }
            if(item.status == "In Progress"){
                res3 = res3 + 1;
            }
            if(item.status == "Overdue"){
                res6 = res6 + 1;
            }
            if(item.status == "Done"){
                res4 = res4 + 1;
            }
            if(item.status == "Closed"){
                res9 = res9 + 1;
            }
        })
        console.log("res2:"+res2)
        console.log("res3:"+res3)
        console.log("res6:"+res6)
        console.log("res4:"+res4)
        console.log("res9:"+res9)
        dispatch(setRequestTrackingSuccessToState(
            result.data, 
            res2,
            res3,
            res6,
            res4,
            res9
        ));
    }   catch (error) {
        dispatch(setRequestTrackingFailedToState());
    }
}
  
  
 
  