import {
    REQUEST_LOG_FETCHING,
    REQUEST_LOG_SUCCESS,
    REQUEST_LOG_FAILED,
  } from "../services/Constants";
  import { Request } from "../types/request.type";
    
    export interface RequestLogState {
        result: any[];
        isFetching: boolean;
        isError: boolean;
        res_count: number
        oper_count: number
        sucess_count: number
    }
    
    const initialState: RequestLogState = {
        result: [],
        isFetching: false,
        isError: false,
        res_count: 0,
        oper_count: 0,
        sucess_count: 0,
    };
    
    export default (state = initialState, { type, payload, res_count, oper_count, sucess_count}: any) => {
      switch (type) {
        case REQUEST_LOG_FETCHING:
          return { ...state, result: [], isFetching: true, isError: false};
        case REQUEST_LOG_SUCCESS:
          return { 
            ...state, 
            result: payload, 
            isFetching: false, 
            isError: false,
          };
        case REQUEST_LOG_FAILED:
          return { ...state, result: [], isFetching: false, isError: true};
        default:
          return state;
      }
    };
    