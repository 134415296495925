import {
  OK,
  DATASHEET_CREATE_FETCHING,
  DATASHEET_CREATE_SUCCESS,
  DATASHEET_UPDATE_SUCCESS,
  DATASHEET_CREATE_FAILED,
  DATASHEET_CREATE_SHARE_HOLDER_TH,
  DATASHEET_CREATE_SHARE_HOLDER_EN,
  DATASHEET_CREATE_DIRECTOR_TH,
  DATASHEET_CREATE_DIRECTOR_EN,
  DATASHEET_LIST,
  DATASHEET_FIND_BY_ID,
  server,
  TOKEN,
  MEMBER_ID,
} from "../services/Constants";

export interface DataSheetCreateState {
  isFetching: boolean;
  isError: boolean;
  result: any;
  resultupdate:any,
  result_share_holder_th: any;
  result_share_holder_en: any;

  result_director_th: any;
  result_director_en: any;
  result_data_list: any;
  result_by_id: any;
}

const initialState: DataSheetCreateState = {
  isFetching: false,
  isError: false,
  result: null,
  resultupdate:null,
  result_share_holder_th: null,
  result_share_holder_en: null,
  result_director_th: null,
  result_director_en: null,
  result_data_list: null,
  result_by_id: null,
};

export default (state = initialState, { type, payload }: any): DataSheetCreateState => {
  switch (type) {
    case DATASHEET_CREATE_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null,result_data_list:null };
    case DATASHEET_CREATE_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
      case DATASHEET_UPDATE_SUCCESS:
      return { ...state, isFetching: false, isError: false, resultupdate: payload };
    case DATASHEET_CREATE_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case DATASHEET_CREATE_SHARE_HOLDER_TH:
      return { ...state, result_share_holder_th: payload };
    case DATASHEET_CREATE_SHARE_HOLDER_EN:
      return { ...state, result_share_holder_en: payload };
    case DATASHEET_CREATE_DIRECTOR_TH:
      return { ...state, result_director_th: payload };
    case DATASHEET_CREATE_DIRECTOR_EN:
      return { ...state, result_director_en: payload };
    case DATASHEET_LIST:
      return { ...state, result_data_list: payload };
    case DATASHEET_FIND_BY_ID:
        return { ...state, result_by_id: payload };
    default:
      return state;
  }
};
