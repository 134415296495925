import axios from "axios";
import join from "url-join";
import {
  server,
  TOKEN,
  apiUrl,
  NOT_CONNECT_NETWORK,
  NETWORK_CONNECTION_MESSAGE,
} from "./Constants";
import {store} from ".."
import { useNavigate } from "react-router-dom";
import * as callpaiActions from "../actions/callapi.action";
import * as loginpaiActions from "../actions/login.action";



const isAbsoluteURLRegex = /^(?:\w+:)\/\//;
// axios.defaults.headers.common['Authorization'] =`Bearer ${localStorage.getItem(TOKEN)}`;

axios.interceptors.request.use(async (config: any) => {
  const token = localStorage.getItem(TOKEN);
  config.headers.Authorization = `Bearer ${token}`;
     store.dispatch(callpaiActions.setCheckCallApiToState(true));
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.url = join(apiUrl, config.url);
  }
  config.timeout = 10000; // 10 Second
  return config;
});

axios.interceptors.response.use(
  (response) => {
    setTimeout(() => {
      store.dispatch(callpaiActions.setCheckCallApiToState(false));
    }, 100);
  
    return response;
  },
  (error) => {
  
    if(error.response.status == 401 || error.response.status == 403){
      store.dispatch(callpaiActions.setCheckCallApiToState(false));
       store.dispatch(loginpaiActions.logouttologinpage(useNavigate));
      return Promise.reject(error);
    }
    setTimeout(() => {
      store.dispatch(callpaiActions.setCheckCallApiToState(false));
    }, 100);
    // store.dispatch(loginpaiActions.logouttologinpage(useNavigate));
    console.log(JSON.stringify(error, undefined, 2));
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (!error.response) {
      return Promise.reject({
        code: NOT_CONNECT_NETWORK,
        message: NETWORK_CONNECTION_MESSAGE,
      });
    }
    return Promise.reject(error);
  }
);

export const httpClient = axios;
