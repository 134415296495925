import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../../services/Constants";
import * as rolesActions from "../../../../actions/roles.action";
import * as userActions from "../../../../actions/user.action";
import * as requestDeleteActions from "../../../../actions/request.delete.actions";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../../reducers";
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate,useMatch } from "react-router-dom";
import { useAppDispatch } from "../../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN } from "../../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./UsersManagement.css";
import { Search as SearchIcon, Tune } from "@mui/icons-material";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import * as loginActions from "../../../../actions/login.action";



interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}


type RolesManagementProps = {
  //
};

const RolesManagement: React.FC<any> = () => {
  const RolesStateReducer = useSelector((state: RootReducers) => state.rolesStateReducer);
  const UserStateReducer = useSelector((state: RootReducers) => state.userStateReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedRoles, setSelectedRoles] = React.useState<any>({});
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dataAddRoles, setdataAddRoles] = React.useState({group:"",lang:"en"});
  const [dataEditRoles, setdataEditRoles] = React.useState({id:0,group:"",lang:""});
  
  const [openaddRolesgroup, setOpenaddRolesgroup] = React.useState(false);
  const [openeditRolesgroup, setOpeneditRolesgroup] = React.useState(false);

  const Alllanguages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'Thai'
    }
  ];

  const handleAddRolesGroup = () => {
    setdataAddRoles({group:"",lang:"en"})
    console.log("add");
    setOpenaddRolesgroup(true);
};

const handleEditRolesGroup = (data:any) => {
    setdataEditRoles({id:data.id,group:data.group,lang:data.lang});
    console.log("edit");
    setOpeneditRolesgroup(true);
};


  const handleCloseaddRolesgroup = () => {
    setOpenaddRolesgroup(false);
  };

  const handleCloseeditRolesgroup = () => {
    setOpeneditRolesgroup(false);
  };

 
 

    React.useEffect(() => {
        if (UserStateReducer.isAdddata) {
            Swal.fire({
                title: `${t("Information Saved")}`,
                text: `${t("The information has been saved and updated successfully.")}`,
                icon: "success",
            });
            setOpenaddRolesgroup(false);
            dispatch(userActions.setUserFetchingToState());
            dispatch(userActions.loadUser());
        return;
        } else if(UserStateReducer.isAddError) {
            Swal.fire({
                title: `${t("Information Saved")}`,
                text: `${t("The information has been saved and updated unsuccessfully.")}`,
                icon: "error",
            });
            dispatch(userActions.setUserFetchingToState());
            dispatch(userActions.loadUser());
        
        }else if(UserStateReducer.isEditdata) {
            Swal.fire({
                title: `${t("Information Saved")}`,
                text: `${t("The information has been saved and updated successfully.")}`,
                icon: "success",
            });
            dispatch(userActions.setUserFetchingToState());
            dispatch(userActions.loadUser());
        
        }else if(UserStateReducer.isEditError) {
            Swal.fire({
                title: `${t("Information Saved")}`,
                text: `${t("The information has been saved and updated unsuccessfully.")}`,
                icon: "error",
            });
            dispatch(userActions.setUserFetchingToState());
            dispatch(userActions.loadUser());
        
        }
        
        else if(UserStateReducer.isDeletedata) {
            Swal.fire({
                title: `${t("Information Saved")}`,
                text: `${t("The information has been saved and updated successfully.")}`,
                icon: "success",
            });
            dispatch(userActions.setUserFetchingToState());
            dispatch(userActions.loadUser());
        
        }else if(UserStateReducer.isDeleteError) {
            Swal.fire({
                title: `${t("Information Saved")}`,
                text: `${t("The information has been saved and updated unsuccessfully.")}`,
                icon: "error",
            });
            dispatch(userActions.setUserFetchingToState());
            dispatch(userActions.loadUser());
        
        }else {
        }
    }, [UserStateReducer]);
    

    //load data table
    React.useEffect(() => {
        if (!localStorage.getItem(TOKEN)) {
            // navigate("/login");
            dispatch(loginActions.logouttologinpage(navigate));
        }
        dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
        dispatch(userActions.loadUser());

    }, []);
    const deleteUser = (user_id:number) =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteConfirm(user_id)
            }
        })
    }


    const tableColumns: GridColDef[] = [
        {
            headerName: "ID",
            field: "id",
            maxWidth: 50,
            minWidth: 50
        },
        {
            headerName: `${t("User Name")}`,
            field: "user_name",
            flex: 1,
            minWidth: 150
        },
        
        {
            headerName: `${t("First Name")}`,
            field: "first_name",
            flex: 1,
            minWidth: 150
        },
        
        {
            headerName: `${t("Last Name")}`,
            field: "last_name",
            flex: 1,
            minWidth: 150
        },
        
        {
            headerName: `${t("Email")}`,
            field: "email",
            flex: 2,
            minWidth: 300
        },
        {
            headerName: `${t("Roles")}`,
            field: "role",
            flex: 1,
            minWidth: 150
        },
        {
            headerName: `${t("Department")}`,
            field: "dep_name",
            flex: 2,
            minWidth: 150
        },
        {
            headerName: `${t("Status")}`,
            field: "status",
            flex: 1,
            minWidth: 100
        },
        
        {
            headerName: "",
            field: ".",
            maxWidth: 150,
            align: "center",
            renderCell: ({ row }: GridRenderCellParams<string>) => (
                <Stack direction="row">
                    <IconButton
                        aria-label="edit"
                        size="large"
                        onClick={() => {
                            navigate(`/general-users-edit/${row.id}`);
                        }}
                >
                        <EditIcon 
                            fontSize="inherit" 
                            sx={{ color: blue[700] }}  
                        />
                    </IconButton>
                    {/* <IconButton
                        aria-label="delete"
                        size="large"
                        onClick={() => {
                            // console.log(row)
                            deleteUser(row.id);
                            setOpenDialog(true);
                        }}
                    >
                        <DeleteIcon 
                            fontSize="inherit" 
                            sx={{ color: red[500] }}
                        />
                    </IconButton> */}
                </Stack>
                ),
        },
    ];

    //search
    const [searchText, setSearchText] = React.useState("");
    const [searchText1, setSearchText1] = React.useState("");
    const [tableData, setTableData] = React.useState<any[]>([]);

    React.useEffect(() => {
        let  dataList:any[] = [];
        let index = 0;
        UserStateReducer.result.forEach((item) =>{
            dataList[index] = item;
            index += 1;
        })
        setTableData(dataList)
    }, [UserStateReducer.result]);

    const requestSearch = (searchValue: string) => {
        const filteredRows = UserStateReducer.result.filter((row) => {
            return( 
                row.user_name?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                row.first_name?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                row.last_name?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                row.email?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                row.role?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                row.status?.toString().toLowerCase().includes(searchValue.toLowerCase())
            )
        });
        setTableData(filteredRows);
    };


    const handleDeleteConfirm = (user_id:number) => {
        dispatch(userActions.deleteUsers(user_id,navigate));
    };


    //confirm dailog Delete 
    
    const showDialog = () => {
        if (selectedRoles === null) {
            return "";
        }


};

    


    return (
        <Container fixed sx={{ mt: 3}} >
        <Box 
        sx={{}}
        >
            {/* Summary Icons */}
            <Grid 
            container item
            justifyContent="center"
            alignItems="center"
            sx={{
                margin: 'auto',
                width: '100%',
                maxWidth: '60rem'
            }}
            >
                <Typography 
                    variant="body1"
                    sx={{ fontWeight: "bold", fontSize: "22px" }}
                    textAlign="left"
                >
                    {t(`User Management Page`)}
                </Typography>

            </Grid>

            <Grid container justifyContent="flex-end">
                {/* <Button 
                    size="medium"
                    variant="contained" 
                    sx={{
                        marginTop: 2,
                        marginLeft:2,
                        marginRight: 2,
                        minWidth: 200,
                        backgroundColor: "#c79059"
                    }}
                    className="requestButton"
                    // onClick={() => { handleAddRolesGroup()}}
                    onClick={() => {
                        navigate("/general-users-add");
                    }}
                >
                    {t("Add New User")} 
                </Button> */}
            </Grid>
            <br />
            <Grid>
            <Grid container spacing={2}>
    
    <Grid item xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}>
    <TextField 
    fullWidth 
                    placeholder="ค้นหา..."
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (<IconButton><SearchIcon /></IconButton>)
                    }}
                    onChange={(e)=>{
                        requestSearch(e.target.value)
                        setSearchText(e.target.value)
                    }} 
                    sx={{
                        //  width:500,
                        "& .MuiInput-underline:before": {
                            borderBottom: 1,
                            borderColor: "divider",
                        },
                        marginBottom:2
                    }}
                />
    </Grid>
    <Grid item xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}>
    </Grid>
    </Grid>
                
                <DataGrid
                    sx={{ backgroundColor: "white"}}  
                    rows={tableData}
                    columns={tableColumns}
                    autoHeight
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                />
            </Grid>
            <br />
            <br />
            {showDialog()}
        </Box>
        </Container>
);
                }
export default RolesManagement;
