import "./HopOnHopOff.css";
import * as React from "react";
import {  useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

import { useMediaQuery, useTheme } from '@mui/material';
import { Container, Grid, Typography,  Card, CardContent, Box, Button } from "@mui/material";
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import about1 from "../../../components/layouts/assets/hop2.png";
import con_hop2 from "../../../components/layouts/assets/con_hop2.png";
import about2 from "../../../components/layouts/assets/her-hop1.PNG";
import hop1 from "../../../components/layouts/assets/hop-d-1.png";
import hop2 from "../../../components/layouts/assets/hop-d-2.png";
import hop3 from "../../../components/layouts/assets/hop-d-3.png";

import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';

import { Square as SquareIcon } from '@mui/icons-material';

const HopOnHopOff: React.FC<any> = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // ใช้ 'sm' สำหรับหน้าจอเล็กกว่า 600px

  const [showC, setshowC] = React.useState(false);
  const [showCD, setshowCD] = React.useState(false);
  const [showMB, setshowMB] = React.useState(false);
  // React.useEffect(() => {
  //   alert(i18next.language)
  // }, [i18next.language]);
  const handleClickShowC = () => {
    setshowC(!showC)
  }
  const handleClickShowCD = () => {
    setshowCD(!showCD)
  }
  const handleClickShowMB = () => {
    setshowMB(!showMB)
  }
  return (
    <>
      {
        i18next.language == "en" ? (
          <>
            <Grid className="hop-container-th-top-en">
              <Container fixed maxWidth="lg" className="hop-container-th-heading">
                <Grid
                    container
                    justifyContent="start"
                    className="why-container"
                >
                  <Grid className='container-text' xs={12} sm={12}>
                    <Typography className="hop-text-heading-back-en ">
                      {t(`SME Mate 12 Stops: `)}
                    </Typography>
                    <Typography className="hop-text-heading-back-en ">
                      {t(`Hop On & Hop Off`)}
                    </Typography>
                    <Typography className="hop-text-descritons-back-eng">
                      {t(`Law shapes every aspect of business operations. From formation to dissolution, legal frameworks guide and protect enterprises.`)}
                    </Typography>
                    <Button
                        onClick={() => navigate("/contact-us")}
                        size="medium"
                        sx={{   
                          paddingLeft:"10px",
                          paddingRight:"10px",
                          paddingTop:"10px",
                          paddingBottom:"10px",
                          marginBottom: "20px",
                          marginRight: "10px",
                          backgroundColor: "#B2A599",
                          "&:hover": {
                            backgroundColor: "#F2903A",
                          },
                        }}
                      >
                        <Typography sx={{fontSize: "18px",fontWeight: "700", fontFamily: "Arial",color: "black",width: "140px"}} >{t("Contact Us")}</Typography>
                      </Button>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid className="hop-container-th-top-back">
              <Container fixed maxWidth="lg" className="hop-container-th">
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading-en" >
                        {t(`Business Organization and Incorporation`)}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "30px"}}>
                      <Grid  className="col-hop" sx={{ display: 'flex'}} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="hop-home-sub" sx={{ height: '80%' }}>
                          <CardContent>
                            <Typography className="hop-text-sub-heading-low" >
                              {t("Incorporation")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("Law dictates how to legally form your business organization/enterprise.")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid  className="col-hop" sx={{ display: 'flex'}} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="hop-home-sub" sx={{ height: '80%' }}>
                          <CardContent>
                            <Typography className="hop-text-sub-heading-low" >
                              {t("Joint Venture")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("Legal terms for business model partnerships.")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid  className="col-hop" sx={{ display: 'flex'}} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card className="hop-home-sub" sx={{ height: '80%' }}>
                          <CardContent>
                            <Typography className="hop-text-sub-heading-low" >
                              {t("Location")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("Where the place of business operated.")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Container>
            </Grid>
            <Grid className="hop-container-th-top-back-bottom">
              <Container fixed maxWidth="lg" className="hop-container-th-heading">
                  <Grid
                      container
                      justifyContent="start"
                      className="why-container"
                      style={{ marginTop: "30px"}}
                  >
                    <Grid >
                      <Typography className="hop-text-heading" >
                        {t(`Business Policy and Objectives`)}
                      </Typography>

                    </Grid>
                    <Grid container sx={{marginTop: "10px"}}>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography className="hop-text-sub-heading" >
                          {t("Business Policy")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Laws ensuring ethical operations.")}
                        </Typography>

                      </Grid>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography className="hop-text-sub-heading" >
                          {t("Object")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Legal goal is to minimize risks.")}
                        </Typography>
                      </Grid>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography className="hop-text-sub-heading" >
                          {t("Target Products / Services")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Laws regulate product safeness and consumer protection.")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
            </Grid>
            <Grid className="hop-container-th-top-back">
              <Container fixed maxWidth="lg" className="hop-container-th">
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`Management and Employment`)}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "40px"}}>
                      <Timeline
                          position={isSmallScreen ? undefined: 'alternate-reverse' }
                          sx={{
                            ...(isSmallScreen && {
                              [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                              },
                            }),
                          }}
                      >

                        <TimelineItem >
                          <TimelineSeparator>
                            <TimelineDot sx={{backgroundColor: '#CA965C' }}>
                              <Typography color="white" sx={{width: "25px", textAlign: "center"}} > 1 </Typography> 
                            </TimelineDot>
                            <TimelineConnector  sx={{ backgroundColor: '#CA965C' }} />
                          </TimelineSeparator>
                          <TimelineContent>
                                  <Typography className="hop-text-sub-heading" style={{textAlign: isSmallScreen ? "left": "right"}}>
                                      {t("Management")}
                                  </Typography>
                                  <Typography className="hop-text-descritons" style={{textAlign: isSmallScreen ? "left": "right"}}>
                                    {t("Legal guidelines direct company leadership practices and digital working time clock.")}
                                  </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot sx={{backgroundColor: '#CA965C' }}> 
                              <Typography color="white" sx={{width: "25px", textAlign: "center"}}> 2 </Typography> 
                            </TimelineDot>
                            <TimelineConnector sx={{ backgroundColor: '#CA965C' }} />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography className="hop-text-sub-heading" >
                                {t("Employment")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                                {t("Labor laws govern hiring, pay, workplace and other conditions.")}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                          <TimelineDot sx={{backgroundColor: '#CA965C' }}> 
                            <Typography color="white" sx={{width: "25px", textAlign: "center"}}> 3 </Typography> 
                          </TimelineDot>
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography className="hop-text-sub-heading" style={{textAlign: isSmallScreen ? "left": "right"}}>
                                {t("Digital Connection")}
                            </Typography>
                            <Typography className="hop-text-descritons" style={{textAlign: isSmallScreen ? "left": "right"}}>
                                {t("Laws cover online networking systems, including data protection.")}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`Logistics and Suppliers`)}
                      </Typography>
                    </Grid>
                    { 
                      !isSmallScreen && (
                        <Grid container sx={{marginTop: "30px"}}>
                          <img src={about2} className="img-about" />
                        </Grid>
                      )
                    }
                    <Grid container sx={{marginTop: "30px"}}>
                      <Grid  className="col-hop"  xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography className="hop-text-sub-heading-low" >
                          {t("Products/Services")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Laws dictate quality, safety, and labeling requirements.")}
                        </Typography>
                      </Grid>
                      <Grid  className="col-hop"  xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography className="hop-text-sub-heading-low" >
                          {t("Supply and Raw Materials")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Regulations govern sourcing and sustainability.")}
                        </Typography>
                      </Grid>
                      <Grid  className="col-hop"  xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography className="hop-text-sub-heading-low" >
                          {t("Finished Products")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Legal standards must be met for sale and distribution.")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`Sales and Marketing`)}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "30px"}}>
                      <Grid  className="col-hop"  xs={12} sm={12} md={3} lg={3} xl={3}>
                        <LocalGroceryStoreOutlinedIcon  sx={{color: "#DDCFBB",fontSize:"50px"}} />
                        <Typography className="hop-text-sub-heading-low" >
                          {t("Sales")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Consumer protection laws govern transactions.")}
                        </Typography>
                      </Grid>
                      <Grid  className="col-hop"  xs={12} sm={12} md={3} lg={3} xl={3}>
                        <CampaignOutlinedIcon  sx={{color: "#DDCFBB",fontSize:"50px"}} />
                        <Typography className="hop-text-sub-heading-low" >
                          {t("Advertisement")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Regulations control claims and disclosures.")}
                        </Typography>
                      </Grid>
                      <Grid  className="col-hop"  xs={12} sm={12} md={3} lg={3} xl={3}>
                        <SportsScoreIcon  sx={{color: "#DDCFBB",fontSize:"50px"}} />
                        <Typography className="hop-text-sub-heading-low" >
                          {t("Market Segment")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Anti-discrimination laws affect targeting.")}
                        </Typography>
                      </Grid>
                      <Grid  className="col-hop"  xs={12} sm={12} md={3} lg={3} xl={3}>
                        <DevicesOutlinedIcon  sx={{color: "#DDCFBB",fontSize:"50px"}} />
                        <Typography className="hop-text-sub-heading-low" >
                          {t("Digital Marketing")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Laws like PDPA regulate data collection and use.")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Container>
            </Grid>
            <Grid className="hop-container-th-top-back-bottom">
              <Container fixed maxWidth="lg" className="hop-container-th-heading">
                  <Grid
                      container
                      justifyContent="start"
                      className="why-container"
                      style={{ marginTop: "30px"}}
                  >
                    <Grid >
                      <Typography className="hop-text-heading" >
                        {t(`Contracts and Risk Management`)}
                      </Typography>

                    </Grid>
                    <Grid container sx={{marginTop: "30px"}}>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Grid container >
                          <Grid  xs={2} sm={2} md={3} lg={3} xl={3}>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '50px',  // กำหนดขนาดของไอคอน
                                  height: '50px', // กำหนดขนาดของไอคอน
                                }}
                              >
                                <SquareIcon sx={{ fontSize: 50, color: '#F2EBDF',marginTop: "15px",borderRadius: "2%"}} />
                                <Typography
                                  className="hop-text-sub-heading-low"
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                  }}
                                >
                                  1
                                </Typography>
                              </Box>
                          </Grid>
                          <Grid  xs={10} sm={10} md={9} lg={9} xl={9}>
                            <Typography className="hop-text-sub-heading-low" >
                              {t("Contracts")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("Laws outline enforceable terms and conditions.")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Grid container >
                          <Grid  xs={2} sm={2} md={3} lg={3} xl={3}>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '50px',  // กำหนดขนาดของไอคอน
                                  height: '50px', // กำหนดขนาดของไอคอน
                                }}
                              >
                                <SquareIcon sx={{ fontSize: 50, color: '#F2EBDF',marginTop: "15px",borderRadius: "2%"}} />
                                <Typography
                                  className="hop-text-sub-heading-low"
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                  }}
                                >
                                  2
                                </Typography>
                              </Box>
                          </Grid>
                          <Grid  xs={10} sm={10} md={9} lg={9} xl={9}>
                            <Typography className="hop-text-sub-heading-low" >
                              {t("Collection")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("Legal rules govern debt recovery methods.")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Grid container >
                          <Grid  xs={2} sm={2} md={3} lg={3} xl={3}>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '50px',  // กำหนดขนาดของไอคอน
                                  height: '50px', // กำหนดขนาดของไอคอน
                                }}
                              >
                                <SquareIcon sx={{ fontSize: 50, color: '#F2EBDF',marginTop: "15px",borderRadius: "2%"}} />
                                <Typography
                                  className="hop-text-sub-heading-low"
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                  }}
                                >
                                  3
                                </Typography>
                              </Box>
                          </Grid>
                          <Grid  xs={10} sm={10} md={9} lg={9} xl={9}>
                            <Typography className="hop-text-sub-heading-low" >
                              {t("Receipt")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("Regulations stipulate record-keeping and proof of transaction.")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
            </Grid>
            <Grid className="hop-container-th-top-back">
              <Container fixed maxWidth="lg" className="hop-container-th">
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`Financial Compliance`)}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "30px"}}>
                      
                      <Grid  
                        sx={{
                          borderLeft: "2px solid #B2A599" ,
                          borderRight: "2px solid #B2A599" ,
                          borderTop: "2px solid #B2A599" ,
                          paddingTop: "10px",
                          paddingLeft: "10px",
                        }} 
                        xs={4} sm={4} md={4} lg={4} xl={4}>

                        <Typography className="hop-text-descritons" >
                          {t("Tax Filings")}
                        </Typography>
                      </Grid>
                      <Grid  

                        xs={8} sm={8} md={8} lg={8} xl={8}
                        sx={{
                          borderRight: "2px solid #B2A599" ,
                          borderTop: "2px solid #B2A599" ,
                          paddingTop: "10px",
                          paddingLeft: "10px",
                        }} 
                      >

                        <Typography className="hop-text-descritons" >
                          {t("Tax codes dictate reporting and payment obligations.")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      
                      <Grid  
  
                        xs={4} sm={4} md={4} lg={4} xl={4}
                        sx={{
                          borderLeft: "2px solid #B2A599" ,
                          borderRight: "2px solid #B2A599" ,
                          borderTop: "2px solid #B2A599" ,
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          backgroundColor: "#F2EBDF"
                        }} 
                      >

                        <Typography className="hop-text-descritons" >
                          {t("Account")}
                        </Typography>
                      </Grid>
                      <Grid  
 
                        xs={8} sm={8} md={8} lg={8} xl={8}
                        sx={{
                          borderRight: "2px solid #B2A599" ,
                          borderTop: "2px solid #B2A599" ,
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          backgroundColor: "#F2EBDF"
                        }} 
                      >

                        <Typography className="hop-text-descritons" >
                          {t("Laws govern financial record-keeping.")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid  

                        xs={4} sm={4} md={4} lg={4} xl={4}
                        sx={{
                          borderLeft: "2px solid #B2A599" ,
                          borderRight: "2px solid #B2A599" ,
                          borderBottom: "2px solid #B2A599" ,
                          borderTop: "2px solid #B2A599" ,
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          
                        }} 
                        >

                        <Typography className="hop-text-descritons" >
                          {t("Banks")}
                        </Typography>
                      </Grid>
                      <Grid  
                        xs={8} sm={8} md={8} lg={8} xl={8}
                        sx={{
                          borderRight: "2px solid #B2A599" ,
                          borderTop: "2px solid #B2A599" ,
                          borderBottom: "2px solid #B2A599" ,
                          paddingTop: "10px",
                          paddingLeft: "10px",
                        }} 
                      >

                        <Typography className="hop-text-descritons" >
                          {t("Regulatory compliance is essential for banking transactions.")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Container>
            </Grid>
            <Grid className="hop-container-th-top-back-bottom-cd">
              <Container fixed maxWidth="lg" className="hop-container-th-heading">
                  <Grid
                      container
                      justifyContent="start"
                      className="why-container"
                      style={{ marginTop: "30px"}}
                  >
                    <Grid >
                      <Typography className="hop-text-heading" >
                        {t(`Corporate Filing`)}
                      </Typography>

                    </Grid>
                    {
                      !showC ? (
                        <Grid container className="content-contact-show">
                          <Grid  xs={12} className="content-contact-col" onClick={handleClickShowC}>
                            <Typography className="hop-text-sub-heading-low" >
                              {t(`Corporate Filing`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      ):(
                      <Grid container className="content-contact-show">
                        <Grid  xs={12} className="content-contact-col-show" onClick={handleClickShowC}>
                          <Typography className="hop-text-sub-heading-low" >
                            {t(`Corporate Filing`)}
                          </Typography>
                        </Grid>
                        <Grid  xs={12} sx={{}}>
                          <Typography className="hop-text-sub-heading-low"  sx={{paddingLeft: "7px" }}>
                            {t(`Laws require formal documentation for business activities.`)}
                          </Typography>
                        </Grid>
                      </Grid>
                      )
                    }
                    {
                      !showCD ? (
                        <Grid container className="content-contact-show">
                          <Grid  xs={12} className="content-contact-col" onClick={handleClickShowCD}>
                            <Typography className="hop-text-sub-heading-low" >
                              {t(`Compliance`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      ):(
                      <Grid container className="content-contact-show">
                        <Grid  xs={12} className="content-contact-col-show" onClick={handleClickShowCD}>
                          <Typography className="hop-text-sub-heading-low" >
                            {t(`Compliance`)}
                          </Typography>
                        </Grid>
                        <Grid  xs={12} sx={{}}>
                          <Typography className="hop-text-sub-heading-low"  sx={{paddingLeft: "7px" }}>
                            {t(`Regulatory frameworks dictate business conduct and reporting.`)}
                          </Typography>
                        </Grid>
                      </Grid>
                      )
                    }
                    {
                      !showMB ? (
                        <Grid container className="content-contact-show">
                          <Grid  xs={12} className="content-contact-col" onClick={handleClickShowMB}>
                            <Typography className="hop-text-sub-heading-low" >
                              {t(`Minutes Book and Stock Certificates`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      ):(
                      <Grid container className="content-contact-show">
                        <Grid  xs={12} className="content-contact-col-show" onClick={handleClickShowMB}>
                          <Typography className="hop-text-sub-heading-low" >
                            {t(`Minutes Book and Stock Certificates`)}
                          </Typography>
                        </Grid>
                        <Grid  xs={12} sx={{}}>
                          <Typography className="hop-text-sub-heading-low"  sx={{paddingLeft: "7px" }}>
                            {t(`Legally mandated for documenting corporate decisions and ownership.`)}
                          </Typography>
                        </Grid>
                      </Grid>
                      )
                    }
                  </Grid>
                </Container>
            </Grid>
            <Grid className="hop-container-th-top-back-bottom">
              <Container fixed maxWidth="lg" className="hop-container-th-heading">
                  <Grid
                      container
                      justifyContent="start"
                      className="why-container"
                      style={{ marginTop: "30px"}}
                  >
                    <Grid >
                      <Typography className="hop-text-heading" >
                        {t(`Intellectual Property Protection`)}
                      </Typography>

                    </Grid>
                    <Grid container sx={{marginTop: "10px"}}>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4} sx={{ paddingTop: "30px"}}>
                        <img src={hop1} className="img-sub" />
                        <Typography className="hop-text-sub-heading-low" >
                        Trademarks
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Legal guidelines govern brand identity protection.")}
                        </Typography>

                      </Grid>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4} sx={{ paddingTop: "30px"}}>
                        <img src={hop2} className="img-sub" />
                        <Typography className="hop-text-sub-heading-low" >
                          Copyright
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Laws protect original creative works.")}
                        </Typography>
                      </Grid>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4} sx={{ paddingTop: "30px"}}>
                        <img src={hop3} className="img-sub" />
                        <Typography className="hop-text-sub-heading-low" >
                          Patent
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Enhancing business opportunity on R&D industrial / commercial application.")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                      container
                      justifyContent="start"
                      className="why-container"
                  >
                    
                    <Grid className='container-text' xs={12} sm={12} md={7} lg={7} xl={7} sx={{marginBottom: "50px"}}>
                      <Grid>
                        <Typography className="hop-text-heading">
                          {t(`Mergers and Acquisitions`)}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Timeline 
                            sx={{
                              [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                              },
                            }}
              
                        >

                          <TimelineItem >
                            <TimelineSeparator>
                              <TimelineDot sx={{backgroundColor: '#CA965C', marginTop: "15px" }}>
                                <Typography color="white" sx={{width: "25px", textAlign: "center"}} > 1 </Typography> 
                              </TimelineDot>
                              <TimelineConnector  sx={{ backgroundColor: '#CA965C' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                    <Typography className="hop-text-sub-heading" style={{textAlign: "left"}}>
                                        {t("Merger")}
                                    </Typography>
                                    <Typography className="hop-text-descritons" style={{textAlign: "left"}}>
                                      {t("Legal protocols govern the fusion of two companies.")}
                                    </Typography>
                            </TimelineContent>
                          </TimelineItem>
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineDot sx={{backgroundColor: '#CA965C', marginTop: "15px" }}> 
                                <Typography color="white" sx={{width: "25px", textAlign: "center"}}> 2 </Typography> 
                              </TimelineDot>
                              <TimelineConnector sx={{ backgroundColor: '#CA965C' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                              <Typography className="hop-text-sub-heading" >
                                  {t("Acquisition")}
                              </Typography>
                              <Typography className="hop-text-descritons" >
                                  {t("Laws dictate how one company can purchase another.")}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                          <TimelineItem>
                            <TimelineSeparator>
                            <TimelineDot sx={{backgroundColor: '#CA965C', marginTop: "15px" }}> 
                              <Typography color="white" sx={{width: "25px", textAlign: "center"}}> 3 </Typography> 
                            </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent>
                              <Typography className="hop-text-sub-heading" style={{textAlign: "left"}}>
                                  {t("Due Diligence")}
                              </Typography>
                              <Typography className="hop-text-descritons" style={{textAlign: "left"}}>
                                  {t("Legal standards for evaluating risks in mergers, acquisitions, and Material Legal Agreements (MLA).")}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        </Timeline>
                      </Grid>

                    </Grid>
                    <Grid className='container-img' xs={12} sm={12} md={5} lg={5} xl={5}>
                      <img src={con_hop2} className="img-about" />
                    </Grid>
                  </Grid>
                </Container>
            </Grid>
            <Grid className="hop-container-th-top-back">
              <Container fixed maxWidth="lg" className="hop-container-th">
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`Dispute Resolution`)}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "30px"}}>
                      <Grid  className="col-hop"  xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className="hop-text-sub-heading-low" >
                          {t("Commercial Disputes")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Laws outline resolution mechanisms for business conflicts.")}
                        </Typography>
                      </Grid>
                      <Grid  className="col-hop"  xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className="hop-text-sub-heading-low" >
                          {t("Litigation")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("Legal procedures govern court-based dispute resolution.")}
                        </Typography>
                      </Grid>
                      
                    </Grid>
                  </CardContent>
                </Card>
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`Business Evolution`)}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "30px"}}>
                      <Grid  className="col-hop" sx={{ display: 'flex'}} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="hop-home-sub" sx={{ height: '80%' }}>
                          <CardContent>
                            <Typography className="hop-text-sub-heading-low" >
                              {t("Moving Forward")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("Legal guidelines facilitate business growth and expansion.")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid  className="col-hop" sx={{ display: 'flex'}} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="hop-home-sub" sx={{ height: '80%' }}>
                          <CardContent>
                            <Typography className="hop-text-sub-heading-low" >
                              {t("Restructuring")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("Laws govern how to modify corporate form or operations to surpass multiple debts.")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Container>
            </Grid>
            
          </>
        ):(
          <>
            <Grid className="hop-container-th-top">
              <Container fixed maxWidth="lg" className="hop-container-th-heading">
                <Grid
                    container
                    justifyContent="start"
                    className="why-container"
                >
                  <Grid className='container-text' xs={12} sm={12}>
                    <Typography className="hop-text-heading-back">
                      {t(`การจดทะเบียนธุรกิจ: ก้าวสำคัญสู่ความสำเร็จทางธุรกิจ`)}
                    </Typography>
                    <Typography className="hop-text-descritons-back">
                      {t(`การจดทะเบียนธุรกิจ หรือ "การจัดตั้งบริษัท" เป็นกระบวนการที่ผู้ประกอบการต้องดำเนินการเพื่อให้ธุรกิจของตนเป็นนิติบุคคล ซึ่งจะช่วยให้ธุรกิจมีความมั่นคงและสามารถเติบโตได้อย่างยั่งยืน นอกจากนี้ การจดทะเบียนธุรกิจยังนำมาซึ่งประโยชน์ทั้งในด้านกฎหมายและภาษี ทำให้ผู้ประกอบการสามารถบริหารจัดการธุรกิจได้อย่างมีประสิทธิภาพ`)}
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid className="hop-container-th-top-back">
              <Container fixed maxWidth="lg" className="hop-container-th">
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`ประเภทของการจดทะเบียนธุรกิจ`)}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography className="hop-text-descritons" >
                        {t("มีหลายรูปแบบในการจดทะเบียนธุรกิจ ขึ้นอยู่กับลักษณะและขนาดของธุรกิจ ได้แก่ ห้างหุ้นส่วนจำกัด, บริษัทจำกัด, บริษัทมหาชน และห้างหุ้นส่วนสามัญนิติบุคคล เป็นต้น แต่ละรูปแบบมีข้อแตกต่างกันในเรื่องของจำนวนผู้ถือหุ้น การลงทุน การบริหารจัดการ และความรับผิดชอบต่อหนี้สิน ดังนั้น ผู้ประกอบการจึงต้องพิจารณาให้ดีว่ารูปแบบใดที่เหมาะสมกับธุรกิจของตน")}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "40px"}}>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography className="hop-text-sub-heading" >
                          {t("ห้างหุ้นส่วนจำกัด")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("เป็นรูปแบบธุรกิจที่เหมาะสำหรับธุรกิจขนาดเล็กถึงขนาดกลาง มีจำนวนหุ้นส่วนตั้งแต่ 2 คนขึ้นไป แต่ไม่เกิน 20 คน มีการจำกัดความรับผิดชอบของหุ้นส่วนต่อหนี้สินของกิจการ")}
                        </Typography>
                      </Grid>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography className="hop-text-sub-heading" >
                          {t("บริษัทจำกัด")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("เป็นรูปแบบธุรกิจที่เหมาะสำหรับธุรกิจขนาดกลางถึงขนาดใหญ่ มีจำนวนผู้ถือหุ้นตั้งแต่ 3 คนขึ้นไป มีการจำกัดความรับผิดชอบของผู้ถือหุ้นต่อหนี้สินของบริษัท")}
                        </Typography>
                      </Grid>
                      <Grid  className="col-hop" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography className="hop-text-sub-heading" >
                          {t("บริษัทมหาชน")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("เป็นรูปแบบธุรกิจที่เหมาะสำหรับธุรกิจขนาดใหญ่ที่ต้องการระดมทุนจากสาธารณะ มีจำนวนผู้ถือหุ้นไม่จำกัด และสามารถนำหุ้นเข้าจดทะเบียนในตลาดหลักทรัพย์ได้")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`ขั้นตอนการจดทะเบียนธุรกิจ`)}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "40px"}}>
                      <Timeline
                          position={isSmallScreen ? undefined: 'alternate-reverse' }
                          sx={{
                            ...(isSmallScreen && {
                              [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                              },
                            }),
                          }}
                      >

                        <TimelineItem >
                          <TimelineSeparator>
                            <TimelineDot sx={{backgroundColor: '#CA965C' }}>
                              <Typography color="white" sx={{width: "25px", textAlign: "center"}} > 1 </Typography> 
                            </TimelineDot>
                            <TimelineConnector  sx={{ backgroundColor: '#CA965C' }} />
                          </TimelineSeparator>
                          <TimelineContent>
                                  <Typography className="hop-text-sub-heading" style={{textAlign: isSmallScreen ? "left": "right"}}>
                                      {t("การเลือกรูปแบบธุรกิจ")}
                                  </Typography>
                                  <Typography className="hop-text-descritons" style={{textAlign: isSmallScreen ? "left": "right"}}>
                                    {t("เป็นขั้นตอนแรกที่ผู้ประกอบการต้องพิจารณาเพื่อเลือกรูปแบบธุรกิจที่เหมาะสมกับธุรกิจของตน ทั้งในเรื่องของจำนวนผู้ถือหุ้น การลงทุน การบริหารจัดการ และความรับผิดชอบต่อหนี้สิน")}
                                  </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot sx={{backgroundColor: '#CA965C' }}> 
                              <Typography color="white" sx={{width: "25px", textAlign: "center"}}> 2 </Typography> 
                            </TimelineDot>
                            <TimelineConnector sx={{ backgroundColor: '#CA965C' }} />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography className="hop-text-sub-heading" >
                                {t("การจัดเตรียมเอกสาร")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                                {t("ผู้ประกอบการต้องจัดเตรียมเอกสารต่างๆ เช่น หนังสือบริคณห์สนธิ ข้อบังคับบริษัท และงบการเงินเริ่มต้น เพื่อยื่นขอจดทะเบียนธุรกิจ")}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                          <TimelineDot sx={{backgroundColor: '#CA965C' }}> 
                            <Typography color="white" sx={{width: "25px", textAlign: "center"}}> 3 </Typography> 
                          </TimelineDot>
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography className="hop-text-sub-heading" style={{textAlign: isSmallScreen ? "left": "right"}}>
                                {t("การจดทะเบียนธุรกิจ")}
                            </Typography>
                            <Typography className="hop-text-descritons" style={{textAlign: isSmallScreen ? "left": "right"}}>
                                {t("เมื่อเตรียมเอกสารครบถ้วน ผู้ประกอบการสามารถยื่นขอจดทะเบียนธุรกิจกับหน่วยงานที่เกี่ยวข้อง เช่น กรมพัฒนาธุรกิจการค้า และอาจต้องชำระค่าธรรมเนียมในการจดทะเบียน")}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`ประโยชน์ของการจดทะเบียนธุรกิจ`)}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "30px"}}>
                      <Grid  className="col-hop" sx={{ display: 'flex'}} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="hop-home-sub" sx={{ height: '90%' }}>
                          <CardContent>
                            <Typography className="hop-text-sub-heading" >
                              {t("ความมั่นคงทางกฎหมาย")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("การจดทะเบียนธุรกิจจะช่วยให้ธุรกิจของผู้ประกอบการมีสถานะเป็นนิติบุคคล ซึ่งจะทำให้ธุรกิจมีความมั่นคงและสามารถดำเนินการได้อย่างถูกต้องตามกฎหมาย")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid  className="col-hop" sx={{ display: 'flex'}}xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="hop-home-sub" sx={{ height: '90%' }}>
                          <CardContent>
                            <Typography className="hop-text-sub-heading" >
                              {t("การจำกัดความรับผิดชอบ")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("ในรูปแบบของบริษัทจำกัด ผู้ถือหุ้นจะมีความรับผิดชอบจำกัดเพียงเท่าที่ลงทุนในบริษัท ช่วยปกป้องสินทรัพย์ส่วนตัวของผู้ถือหุ้น")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid  className="col-hop" sx={{ display: 'flex'}} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="hop-home-sub" sx={{ height: '90%' }}>
                          <CardContent>
                            <Typography className="hop-text-sub-heading" >
                              {t("ประโยชน์ทางภาษี")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("การจดทะเบียนธุรกิจจะช่วยให้ผู้ประกอบการสามารถใช้สิทธิประโยชน์ทางภาษีต่างๆ ที่กฎหมายกำหนด เช่น ภาษีเงินได้นิติบุคคล ภาษีมูลค่าเพิ่ม เป็นต้น")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid  className="col-hop" sx={{ display: 'flex'}} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card className="hop-home-sub" sx={{ height: '90%' }}>
                          <CardContent>
                            <Typography className="hop-text-sub-heading" >
                              {t("การระดมทุนและการขยายธุรกิจ")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("การจดทะเบียนธุรกิจในรูปแบบบริษัทมหาชน จะช่วยให้ผู้ประกอบการสามารถระดมทุนจากสาธารณะและขยายธุรกิจได้อย่างกว้างขวางขึ้น ")}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`ต้นทุนและข้อกำหนดในการจดทะเบียนธุรกิจ`)}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "30px"}}>
                      <Grid  className="col-hop" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container >
                          <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '50px',  // กำหนดขนาดของไอคอน
                                  height: '50px', // กำหนดขนาดของไอคอน
                                }}
                              >
                                <SquareIcon sx={{ fontSize: 50, color: '#F2EBDF',marginTop: "15px",borderRadius: "2%"}} />
                                <Typography
                                  className="hop-text-sub-heading"
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                  }}
                                >
                                  1
                                </Typography>
                              </Box>
                          </Grid>
                          <Grid  xs={10} sm={10} md={10} lg={10} xl={10}>
                            <Typography className="hop-text-sub-heading" >
                              {t("ต้นทุนในการจดทะเบียน")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("โดยทั่วไปค่าใช้จ่ายในการจดทะเบียนธุรกิจจะอยู่ในช่วง 5,000 - 20,000 บาท ขึ้นอยู่กับรูปแบบของธุรกิจและหน่วยงานที่รับจดทะเบียน")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid  className="col-hop" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container >
                          <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '50px',  // กำหนดขนาดของไอคอน
                                  height: '50px', // กำหนดขนาดของไอคอน
                                }}
                              >
                                <SquareIcon sx={{ fontSize: 50, color: '#F2EBDF',marginTop: "15px",borderRadius: "2%"}} />
                                <Typography
                                  className="hop-text-sub-heading"
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                  }}
                                >
                                  2
                                </Typography>
                              </Box>
                          </Grid>
                          <Grid  xs={10} sm={10} md={10} lg={10} xl={10}>
                            <Typography className="hop-text-sub-heading" >
                              {t("ข้อกำหนดต่อเนื่อง")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("ธุรกิจที่จดทะเบียนแล้วจะมีข้อกำหนดต่อเนื่องที่ต้องปฏิบัติ เช่น การยื่นงบการเงินประจำปี การเสียภาษี และการปฏิบัติตามกฎระเบียบอื่นๆ ตามที่กฎหมายกำหนด")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                    <Grid  className="col-hop" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container >
                          <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '50px',  // กำหนดขนาดของไอคอน
                                  height: '50px', // กำหนดขนาดของไอคอน
                                }}
                              >
                                <SquareIcon sx={{ fontSize: 50, color: '#F2EBDF',marginTop: "15px",borderRadius: "2%"}} />
                                <Typography
                                  className="hop-text-sub-heading"
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                  }}
                                >
                                  3
                                </Typography>
                              </Box>
                          </Grid>
                          <Grid  xs={10} sm={10} md={10} lg={10} xl={10}>
                            <Typography className="hop-text-sub-heading" >
                              {t("ข้อจำกัดของการจดทะเบียน")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("ธุรกิจที่จดทะเบียนแล้วต้องปฏิบัติตามกฎระเบียบที่เข้มงวดกว่า รวมถึงอาจมีข้อจำกัดในการเปลี่ยนแปลงโครงสร้างของธุรกิจ")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid  className="col-hop" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container >
                          <Grid  xs={2} sm={2} md={2} lg={2} xl={2}>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '50px',  // กำหนดขนาดของไอคอน
                                  height: '50px', // กำหนดขนาดของไอคอน
                                }}
                              >
                                <SquareIcon sx={{ fontSize: 50, color: '#F2EBDF',marginTop: "15px",borderRadius: "2%"}} />
                                <Typography
                                  className="hop-text-sub-heading"
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                  }}
                                >
                                  4
                                </Typography>
                              </Box>
                          </Grid>
                          <Grid  xs={10} sm={10} md={10} lg={10} xl={10}>
                            <Typography className="hop-text-sub-heading" >
                              {t("พิจารณาความจำเป็น")}
                            </Typography>
                            <Typography className="hop-text-descritons" >
                              {t("ผู้ประกอบการต้องพิจารณาให้ดีว่าการจดทะเบียนธุรกิจจะเป็นประโยชน์ต่อธุรกิจของตนหรือไม่ และมีความจำเป็นหรือไม่")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className="hop-home">
                  <CardContent>
                    <Grid sx={{marginBottom: "30px"}}>
                      <Typography className="hop-text-heading" >
                        {t(`โครงสร้างบริษัทมหาชนกับบริษัทจำกัด`)}
                      </Typography>
                    </Grid>
                    <Grid container sx={{marginTop: "40px"}}>
                      <Grid  className="col-hop" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className="hop-text-sub-heading" >
                          {t("บริษัทมหาชน (C Corporation)")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("เป็นบริษัทที่ถือหุ้นโดยสาธารณชน มักมีจำนวนผู้ถือหุ้นมาก และสามารถระดมทุนได้มากกว่าบริษัทจำกัด โครงสร้างการบริหารจัดการจะมีคณะกรรมการบริษัทที่ทำหน้าที่กำหนดนโยบายและกำกับดูแล")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("บริษัทมหาชนต้องเสียภาษีเงินได้นิติบุคคลในอัตราที่กำหนด และผู้ถือหุ้นอาจต้องเสียภาษีเงินปันผล")}
                        </Typography>
                      </Grid>

                      <Grid  className="col-hop" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className="hop-text-sub-heading" >
                          {t("บริษัทจำกัด (S Corporation)")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("เป็นบริษัทที่มีผู้ถือหุ้นจำนวนน้อย โดยทั่วไปไม่เกิน 100 คน โครงสร้างการบริหารจัดการจะมีความยืดหยุ่นมากกว่า แต่ก็มีข้อจำกัดในการระดมทุน")}
                        </Typography>
                        <Typography className="hop-text-descritons" >
                          {t("บริษัทจำกัดสามารถเลือกเสียภาษีแบบธุรกิจขนาดเล็ก (S Corporation) ทำให้ไม่ต้องเสียภาษีระดับบริษัท แต่ผู้ถือหุ้นต้องเสียภาษีจากรายได้ของบริษัทแทน")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Container>
            </Grid>
            <Grid className="hop-container-th-top-back-bottom">
              <Container fixed maxWidth="lg" className="hop-container-th-heading">
                  <Grid
                      container
                      justifyContent="start"
                      className="why-container"
                      style={{ marginTop: "30px"}}
                  >
                    <Grid className='container-text' xs={12} sm={12} md={7} lg={7} xl={7} sx={{marginBottom: "50px"}}>
                      <Grid>
                        <Typography className="hop-text-heading">
                          {t(`ติดต่อเรา`)}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography className="hop-text-descritons">
                          {t(`คุณกำลังวางแผนเริ่มต้นธุรกิจอยู่หรือไม่? เราพร้อมช่วยคุณจดทะเบียนธุรกิจได้อย่างง่ายดาย รวดเร็ว และปลอดภัย`)}
                        </Typography>
                        <Typography className="hop-text-descritons">
                          {t(`ทีมงานของเรามีความรู้ความเชี่ยวชาญในด้านกฎหมายและข้อบังคับการจดทะเบียนธุรกิจ`)}
                        </Typography>
                        <Typography className="hop-text-descritons">
                          {t(`เราให้คำปรึกษาแนะนำและช่วยเหลือในการดำเนินการทุกขั้นตอน พร้อมให้บริการหลังการขายอย่างมืออาชีพ`)}
                        </Typography>
                      </Grid>
                      <Grid>
                      <Button
                        onClick={() => navigate("/contact-us")}
                        size="medium"
                        sx={{   
                          paddingLeft:"10px",
                          paddingRight:"10px",
                          paddingTop:"10px",
                          paddingBottom:"10px",
                          marginBottom: "20px",
                          marginRight: "10px",
                          backgroundColor: "#B2A599",
                          "&:hover": {
                            backgroundColor: "#F2903A",
                          },
                        }}
                      >
                        <Typography sx={{fontSize: "18px",fontWeight: "700", fontFamily: "Arial",color: "white",width: "100px"}} >{t("ติดต่อเรา")}</Typography>
                      </Button>
                      </Grid>
                    </Grid>
                    <Grid className='container-img' xs={12} sm={12} md={5} lg={5} xl={5}>
                      <img src={about1} className="img-about" />
                    </Grid>
                  </Grid>
                </Container>
            </Grid>
          </>
        )
    }
    </>
  );
};
export default HopOnHopOff;

  // const gridStyles = {
  //   backgroundColor: "blanchedalmond",
  //   width: 1,
  //   paddingBottom: 1,
  //   marginBottom: 2
  // };

  // const stops : { [key: string]: any } = {
   //   'Business Organization, Incorporation, Joint Venture, Location, etc​.': {
  //     description: [
  //       'Incorporation: Law dictates how to legally form your business organization/enterprise.',
  //       'Joint Venture: Legal terms for business model partnerships.',
  //       'Location: Where the place of business operated.'
  //     ]
  //   },
  //     'Business Privacy Policy, Objects & Target Products/Services': {
  //       description: [
  //         'Business Policy: Laws ensuring ethical operations.',
  //         'Object: Legal goal is to minimize risks.',
  //         'Target Products/Services: Laws regulate product safeness and consumer protection.'
  //       ]
  //     },
  //     'Management, Employment & Digital Management': {
  //       description: [
  //         'Management: Legal guidelines direct company leadership practices and digital working time clock.',
  //         'Employment: Labor laws govern hiring, pay, workplace and other conditions.',
  //         'Digital Connection: Laws cover online networking systems, including data protection.'
  //       ]
  //     },
  //     'Logistics & Suppliers': {
  //       description: [
  //         'Products/Services: Laws dictate quality, safety, and labeling requirements.',
  //         'Supply and Raw Materials: Regulations govern sourcing and sustainability.',
  //         'Finished Products: Legal standards must be met for sale and distribution.'
  //       ]
  //     },
  //     'Sales, Market Segment, Advertisement & Digital Marketing​': {
  //       description: [
  //         'Sales: Consumer protection laws govern transactions.',
  //         'Advertisement: Regulations control claims and disclosures.',
  //         'Market Segment: Anti-discrimination laws affect targeting.',
  //         'Digital Marketing: Laws like PDPA regulate data collection and use.'
  //       ]
  //     },
  //     'Contracts, Risk Management & Collection and Receipts, etc.​': {
  //       description: [
  //         'Contracts: Laws outline enforceable terms and conditions.',
  //         'Collection: Legal rules govern debt recovery methods.',
  //         'Receipt: Regulations stipulate record-keeping and proof of transaction.'
  //       ]
  //     },
  //     'Tax filings, Account & Banks​': {
  //       description: [
  //         'Tax Filings: Tax codes dictate reporting and payment obligations.',
  //         'Account: Laws govern financial record-keeping.',
  //         'Banks: Regulatory compliance is essential for banking transactions.'
  //       ]
  //     },
  //     'Corporate filings, Compliance, Minutes Book Stock/Share Certificates​': {
  //       description: [
  //         'Corporate Filing: Laws require formal documentation for business activities.',
  //         'Compliance: Regulatory frameworks dictate business conduct and reporting.',
  //         'Minutes Book: Legally mandated for documenting corporate decisions.',
  //         'Stock/Share Certificate: Laws regulate issuance and tracking of ownership.'
  //       ]
  //     },
  //     'Intellectual Property (IPs), Trademarks, Patents, Copyrights & Trade Secrets​': {
  //       description: [
  //         'Trademarks: Legal guidelines govern brand identity protection.',
  //         'Copyright: Laws protect original creative works.',
  //         'Patent: enhancing business opportunity on R&D industrial / commercial application',
  //         'Trade Secrets: Laws secure confidential business information.'
  //       ]
  //     },
  //     'Merger, Acquisition and Due Diligence MLA​': {
  //       description: [
  //         'Merger: Legal protocols govern the fusion of two companies.',
  //         'Acquisition: Laws dictate how one company can purchase another.',
  //         'Due Diligence: Legal standards for evaluating risks in mergers, acquisitions, and Material Legal Agreements (MLA).',
  //       ]
  //     },
  //     'Commercial disputes & Litigation​': {
  //       description: [
  //         'Commercial Disputes: Laws outline resolution mechanisms for business conflicts.',
  //         'Litigation: Legal procedures govern court-based dispute resolution.',
  //       ]
  //     },
  //     'Moving Forward and Restructuring​': {
  //       description: [
  //         'Moving Forward: Legal guidelines facilitate business growth and expansion.',
  //         'Restructuring: Laws govern how to modify corporate form or operations to surpass multiple debts.',
  //       ]
  //     },
  // };
{/* <Grid>
<Grid
  container
  sx={{ ...gridStyles }} 
  justifyContent="center"
  className="service-box"
>
  <Grid>
    <Typography className="hop-title" sx={{color:'#4F4A41',marginBottom: '1rem',marginTop: '4rem',textAlign:'center'}} variant="h4"  component="h4">
      {t('Hop On and Hop Off')}
    </Typography>
    <Typography  sx={{color:'#4F4A41',textAlign:'center', marginBottom: '1rem'}} variant="h6">
        {t(`A look at the importance of law`)}
      </Typography>
  </Grid>
</Grid>
  <div style={{margin: 'auto', maxWidth: '80%'}}>
    {
    Object.keys(stops).map(stop_name => {
      return (
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {t(stop_name)}
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {
              stops[stop_name].description.map((desc : any) => {
                return(<li>{desc}</li>)
              })
            }
          </ul>
        </AccordionDetails>
      </Accordion>
      )
    })
    }
  </div>
</Grid> */}