// import { history } from "../index";
import {
    DIRECTOR_FETCHING,
    DIRECTOR_LIST,
    server,
  } from "../services/Constants";
  import { httpClient } from "../services/httpclient";

  export const setDirectorFetchingToState = () => ({
    type: DIRECTOR_FETCHING,
  });
  
  export const setGetDirectorListToState = (payload: Boolean) => ({
    type: DIRECTOR_LIST,
    payload
  });

  export const getDirectorList = (id:number) => {
    return async (dispatch: any) => {
        try {
            // begin connecting...
            dispatch(setDirectorFetchingToState());
            // connect
            const result = await httpClient.get(server.DIRECTOR+`/getby_data_sheet_id/${id}`);
            console.log(result);
            if (result.status === 200 && result.data.length > 0) {
                dispatch(setGetDirectorListToState(result.data));
            } else {
              dispatch(setDirectorFetchingToState());
            }
          } catch (error) {
            // error
            dispatch(setDirectorFetchingToState());
          }
    };
  };
  