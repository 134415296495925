import React, { useState } from "react";
import { Card, CardContent, Typography, CardActions, Button, Box } from "@mui/material";
import { Paper, Grid } from "@mui/material";
import "./DirectorTH.css";
import { useAppDispatch } from "../../..";
import * as dataSheetCreateActions from "../../../actions/datasheet.create.action";
import { useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";

function DirectorTH() {
  const dispatch = useAppDispatch();
  // const dataSheetCreateReducer = useSelector((state: RootReducers) => state.dataSheetCreateReducer);

  const directorStateReducer = useSelector((state) => state.directorStateReducer);

  
  const [inputList, setInputList] = useState([{ th_director: "" }]);

  React.useEffect(() => {
  if(directorStateReducer.result){
    let setdata = []
    directorStateReducer.result.forEach((x) =>{
      if(x.th_director){
        setdata.push({th_director:x.th_director})
      }else{

      }
      
    })
    setInputList(setdata)
    dispatch(dataSheetCreateActions.setDataSheetDirectorTHToState(setdata));
  }
  }, [directorStateReducer.result]);
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    dispatch(dataSheetCreateActions.setDataSheetDirectorTHToState(inputList));
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    dispatch(dataSheetCreateActions.setDataSheetDirectorTHToState(list));
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    for(let i=0;i < inputList.length;i++) {
      if(inputList[i].th_director == "" || !inputList[i].th_director){
      return;
      }
  }
    setInputList([...inputList, { th_director: "" }]);
    dispatch(dataSheetCreateActions.setDataSheetDirectorTHToState(inputList));
  };

  return (
    <Grid 
      container 
      direction="row" 
      sx={{
        marginTop:2,
        marginBottom: 2
      }}
    >
      <Grid item xs={5}>
        <Typography gutterBottom variant="body1">
          7. กรรมการ:<span style={{ color: "red" }}>* </span>
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {inputList.map((x, i) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <input
                  required
                  name="th_director"
                  placeholder="Enter Name"
                  value={x.th_director}
                  onChange={(e) => handleInputChange(e, i)}
                  className="share-input"
                />
              </Grid>
              <Grid item xs={4}>
                <div className="btn-box">
                  {inputList.length !== 1 && (
                    <button className="button remove" onClick={() => handleRemoveClick(i)}>
                      Remove
                    </button>
                  )}
                  {inputList.length - 1 === i && (
                    <button className="button add" onClick={handleAddClick}>
                      Add
                    </button>
                  )}
                </div>
              </Grid>
            </Grid>
          );
        })}
        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
      </Grid>
    </Grid>
  );
}

export default DirectorTH;
