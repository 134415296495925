import * as React from "react";
import "./StartupQuiz.css";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../..";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

import Container from '@mui/material/Container';
import { 
    SxProps, 
    Theme, 
    Typography, 
    Grid,
    Card, 
    CardContent,
    LinearProgress,
    Button
} from "@mui/material";
import Swal from "sweetalert2";

const StartupQuiz: React.FC<any> = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);

    const classes: SxProps<Theme> | any = {
        root: { display: "flex", justifyContent: "left" },
        buttons: { marginTop: 2 },
    };


    const languages = [
        {
        code: 'en',
        name: 'English'
        },
        {
        code: 'th',
        name: 'ไทย'
        }
    ];
    
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = 6; // จำนวนขั้นตอนที่คงที่
    
    const [answer, setAnswer] = React.useState<any>([]);
    const [answerCalculate, setAnswerCalculate] = React.useState<any>([]);
    const [answerCalculateShow, setAnswerCalculateShow] = React.useState<any>([]);
    const [statusFinish, setStatusFinish] = React.useState<boolean>(false);

    const questions = [
        {
            questionsShow: `${i18n.t('What type of business are you planning to start?')}`,
            questionsChoice: [
                `${i18n.t("Retail (e.g., shop, online store)")}`,
                `${i18n.t("Service (e.g., consulting, repairs)")}`,
                `${i18n.t("Food & Beverage (e.g., restaurant, café)")}`,
                `${i18n.t("E-commerce (e.g., dropshipping, digital products)")}`,
            ],
        },
        {
            questionsShow: `${i18n.t('What is your estimated initial investment?')}`,
            questionsChoice: [
                `${i18n.t("Less than 30,000 THB")}`,
                `${i18n.t("30,000 - 100,000 THB")}`,
                `${i18n.t("100,001 - 500,000 THB")}`,
                `${i18n.t("Over 500,000 THB")}`,
            ],
        },
        {
            questionsShow: `${i18n.t('What are your expected monthly expenses?')}`,
            questionsChoice: [
                `${i18n.t("Less than 10,000 THB")}`,
                `${i18n.t("10,000 - 30,000 THB")}`,
                `${i18n.t("30,001 - 100,000 THB")}`,
                `${i18n.t("Over 100,000 THB")}`,
            ],
        },
        {
            questionsShow: `${i18n.t('What is the average price of your product / service?')}`,
            questionsChoice: [
                `${i18n.t("Less than 50 THB")}`,
                `${i18n.t("50 - 200 THB")}`,
                `${i18n.t("201 - 500 THB")}`,
                `${i18n.t("Over 500 THB")}`,
            ],
        },
        {
            questionsShow: `${i18n.t('How many sales do you expect to make each month?')}`,
            questionsChoice: [
                `${i18n.t("Less than 50")}`,
                `${i18n.t("50 - 200")}`,
                `${i18n.t("201 - 500")}`,
                `${i18n.t("Over 500")}`,
            ],
        },
        {
            questionsShow: `${i18n.t('What is your estimated monthly budget for marketing?')}`,
            questionsChoice: [
                `${i18n.t("None")}`,
                `${i18n.t("1 - 5,000 THB")}`,
                `${i18n.t("5,001 - 20,000 THB")}`,
                `${i18n.t("Over 20,000 THB")}`,
            ],
        },
    ];
    const summary  = [
        `${i18n.t("Business Type:")}`,
        `${i18n.t("Total Initial Investment:")}`,
        `${i18n.t("Total Monthly Expenses:")}`,
        `${i18n.t("Monthly Revenue:")}`,
        `${i18n.t("Monthly Profit:")}`,
        `${i18n.t("Break-Even Point:")}`,
    ]
    const handleNext = () => {
        if (answer[activeStep] === undefined) {
            Swal.fire({
                title: `${t("Failed")}`,
                text: `${t("Please enter all the details")}`,
                icon: "warning",
            });
            return; // ไม่ให้ไปยังขั้นตอนถัดไป
        }
        if (activeStep < steps - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
  
    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };
 
    const handleClick = (index: number) => {

        setAnswer((prevAnswer:any) => {
            const updatedAnswer = [...prevAnswer];
            updatedAnswer[activeStep] = index; 
            return updatedAnswer;
        });

    
    };
    const handleFinish = () => {
        if (answer[activeStep] === undefined) {
            Swal.fire({
                title: `${t("Failed")}`,
                text: `${t("Please enter all the details")}`,
                icon: "warning",
            });
            return; 
        }
        

        const averageQuestions = [
            [
                "Retail (e.g., shop, online store)", 
                "Service (e.g., consulting, repairs)", 
                "Food & Beverage (e.g., restaurant, café)", 
                "E-commerce (e.g., dropshipping, digital products)"
            ],
            [30000, 65000, 300000, 750000],
            [10000, 20000, 65000, 150000],
            [50, 125, 350, 750],
            [25, 100, 350, 600],
            [0, 3000, 10000, 30000],
        ];
        
        // สร้าง array ชั่วคราวเพื่อเก็บค่าใหม่
        const updatedAnswerCalculate = [...answerCalculate]; 
        
        for (let i = 0; i < 6; i++) {
            const idx_averageQuestions = Number(answer[i]);
            updatedAnswerCalculate[i] = averageQuestions[i][idx_averageQuestions]; 
        }
        
        // อัปเดต answerCalculate ทีเดียวหลังจากจบลูป
        setAnswerCalculate(updatedAnswerCalculate);

        var answer1 = "";
        answer1 = updatedAnswerCalculate[0];

        var answer2 = 0;
        answer2 = updatedAnswerCalculate[1];

        var answer3 = 0;
        answer3 = updatedAnswerCalculate[2] + updatedAnswerCalculate[5];

        var answer4 = 0;
        answer4 = updatedAnswerCalculate[3] * updatedAnswerCalculate[4];

        var answer5 = 0;
        answer5 = answer4 - answer3;

        var answer6 = 0;
        answer6 = Number((updatedAnswerCalculate[1] / answer5).toFixed(2));

        const updatedAnswerCalculateShow = []; 

        updatedAnswerCalculateShow[0] = `${t(`${answer1}`)}`;
        updatedAnswerCalculateShow[1] = `${answer2.toLocaleString('en-US')} ${t("THB")}`;
        updatedAnswerCalculateShow[2] = `${answer3.toLocaleString('en-US')} ${t("THB")}`;
        updatedAnswerCalculateShow[3] = `${answer4.toLocaleString('en-US')} ${t("THB")}`;
        updatedAnswerCalculateShow[4] = `${answer5.toLocaleString('en-US')} ${t("THB")}`;
        updatedAnswerCalculateShow[5] = `${answer6.toLocaleString('en-US')} ${t("months")}`;

        setAnswerCalculateShow(updatedAnswerCalculateShow);
        console.log(updatedAnswerCalculateShow)
        
        setStatusFinish(true)
    };
    

    return (
      <Grid container className="quiz-container" justifyContent="center" alignItems="center" style={{ height: '100vh' }} >
        <Container fixed maxWidth="lg">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={10} sx={{ margin: 'auto' }}>
            <Card className="quiz-box">
              <CardContent>
                <Grid className="quiz-content">
                  <Typography className="heading-text-quiz">
                    {t(`Business Startup Cost Quiz`)}
                  </Typography>
                </Grid>
  
                <Grid  item xs={12} sm={12} md={12} lg={12} xl={10}  className="quiz-stapper">
                    <LinearProgress
                        variant="determinate"
                        value={(activeStep / (steps - 1)) * 100} // คำนวณเปอร์เซ็นต์ของแถบความคืบหน้า
                        sx={{
                            width: '100%',
                            height: 10, // ขนาดความสูงของแถบความคืบหน้า
                            borderRadius: 5, // เพิ่มมุมโค้ง
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#DDCFBB', // สีของแถบความคืบหน้า (เปลี่ยนเป็นสีที่ต้องการ)
                            },
                            backgroundColor: '#e0e0e0', // สีพื้นหลังของแถบ (กรณีที่แถบความคืบหน้ายังไม่เต็ม)
                        }}
                    />
                </Grid>
                {
                    !statusFinish ?(

                        <>
                            <Grid  item xs={12} sm={12} md={12} lg={12} xl={10} sx={{ margin: '30px auto 30px auto' }}>
                                <Typography className="content-text-quiz">
                                    {questions[activeStep].questionsShow}
                            </Typography>
                            </Grid>
                            {
                                questions[activeStep].questionsChoice.map((choice, index) => (
                                    <Grid  
                                        className="quiz-choice"
                                        item xs={12} sm={12} md={12} lg={12} xl={10} 
                                        sx={{ 
                                            border: `2px solid ${answer[activeStep] === index ? 'black' : '#D3D3D3'}`, // กำหนด border ให้กับ Grid
                                            borderRadius: '8px', // เพิ่มมุมโค้งให้กับ border
                                            padding: '10px', 
                                            cursor: 'pointer',
                                            '&:hover': {
                                                borderColor: 'black', // เมื่อ hover ให้ border เป็นสีดำ
                                            }
                                        }} 
                                        onClick={() => handleClick(index)}
                                    >
                                        <Typography className="content-sub-text-quiz">
                                            {choice}
                                        </Typography>
                                    </Grid>
                                ))
                            }
                            <Grid sx={{ marginTop: "30px"}}>
                                {
                                    steps > 1 && activeStep !== 0  && (
                                        <Button
                                            onClick={handleBack}
                                            variant="outlined"
                                            size="medium"
                                            sx={{ 
                                                textTransform: 'none',
                                                marginRight: '20px',
                                                borderColor: 'black', // กำหนดสีกรอบเป็นสีดำ
                                                color: 'black', // กำหนดสีข้อความให้เป็นสีดำ
                                                '&:hover': {
                                                borderColor: 'black', // สีกรอบในกรณีที่ hover
                                                backgroundColor: '#f5f5f5', // สีพื้นหลังเมื่อ hover (สามารถปรับได้)
                                                },
                                            }}
                                        >
                                            <Typography sx={{fontSize: "18px",fontWeight: "600", fontFamily: "Arial",color: "black",width: "120px"}}>{t(`Back`)}</Typography>
                                        </Button>
                                    )
                                }
                                {
                                    activeStep != 5  && (
                                        <Button
                                            onClick={handleNext}
                                            size="medium"
                                            sx={{   
                                                textTransform: 'none',
                                                backgroundColor: "#DDCFBB",
                                                "&:hover": {
                                                    backgroundColor: "#F2903A",
                                                },
                                            }}
                                        >
                                            <Typography sx={{fontSize: "18px",fontWeight: "600", fontFamily: "Arial",color: "black",width: "120px"}} >{t(`Next`)}</Typography>
                                        </Button>
                                    )
                                }
                                {
                                    steps > 1 && activeStep == 5  && (
                                        <Button
                                            onClick={handleFinish}
                                            size="medium"
                                            sx={{   
                                                textTransform: 'none',
                                                backgroundColor: "#DDCFBB",
                                                "&:hover": {
                                                    backgroundColor: "#F2903A",
                                                },
                                            }}
                                        >
                                            <Typography sx={{fontSize: "18px",fontWeight: "600", fontFamily: "Arial",color: "black",width: "120px"}} >{t(`Finish`)}</Typography>
                                        </Button>
                                    )
                                }
                            </Grid>
                        </>
                    ):(
                        <>
                            <Grid  item xs={12} sm={12} md={12} lg={10} xl={10} sx={{ margin: '30px auto 30px auto' }}>
                                <Typography className="content-text-quiz">
                                    {t(`Summary of Results`)}
                                </Typography>
                            </Grid>
                            <Grid 
                                className="quiz-box-sumary"
                                sx={{ 
                                    border: `2px solid #e0e0e0`,
                                    borderRadius: '10px'
                                }}
                            >
                                 {
                                    summary.map((item, index) => (
                                        <Grid container  className="d-flex">
                                            <Grid item xs={6} sm={6} md={5} lg={5} xl={5} sx={{textAlign: "start"}} className="quiz-sumary-detail"> 
                                                <Typography className="content-sub-text-quiz">
                                                    {item}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={7} lg={7} xl={7} sx={{textAlign: "start", paddingLeft: "10px"}} className="quiz-sumary-detail">
                                                <Typography 
                                                    className="content-sub-text-quiz"
                                                    sx={{ 
                                                        textDecoration: index == 5 ? 'underline' : 'none' 
                                                    }}
                                                >
                                                    {answerCalculateShow[index]}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            <Grid  item xs={12} sm={10} md={10} lg={10} xl={10} sx={{ margin: '50px auto 20px auto' }}>
                                <Button
                                    onClick={() => window.location.reload()}
                                    size="medium"
                                    sx={{   
                                        textTransform: 'none',
                                        backgroundColor: "#DDCFBB",
                                        "&:hover": {
                                            backgroundColor: "#F2903A",
                                        },
                                    }}
                                >
                                    <Typography sx={{fontSize: "18px",fontWeight: "600", fontFamily: "Arial",color: "black",width: "150px"}} >{t(`Start Again`)}</Typography>
                                </Button>
                            </Grid>
                            
                        </>
                    )
                }
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </Grid>
    );
};

export default StartupQuiz;
