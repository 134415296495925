// import { history } from "../index";
import {
    GENERALMENU_FETCHING,
    GENERALMENU_SUCCESS,
    GENERALMENU_ADD_SUCCESS,
    GENERALMENU_ADD_FAILED,
    GENERALMENU_EDIT_SUCCESS,
    GENERALMENU_EDIT_FAILED,
    GENERALMENU_DELETE_SUCCESS,
    GENERALMENU_DELETE_FAILED,
    GENERALMENU_FAILED,
    GENERALMENU_CLEAR,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { GeneralMenu } from "../types/generalmenu.type";
const Swal = require('sweetalert2')

export const setGENERAL_MENUFetchingToState = () => ({
    type: GENERALMENU_FETCHING,
});
  
export const setGENERAL_MENUSuccessToState = (
    payload: GeneralMenu[],) => (
        {
            type: GENERALMENU_SUCCESS,
            payload,
        }
);

export const setGENERAL_MENUAddSuccessToState = (payload: any) => ({
            type: GENERALMENU_ADD_SUCCESS,
            payload,
});

export const setGENERAL_MENUAddFailedToState = () => ({
    type: GENERALMENU_ADD_FAILED,
});

export const setGENERAL_MENUEditSuccessToState = (payload: any) => ({
    type: GENERALMENU_EDIT_SUCCESS,
    payload,
});

export const setGENERAL_MENUEditFailedToState = () => ({
    type: GENERALMENU_EDIT_FAILED,
});

export const setGENERAL_MENUDeleteSuccessToState = (payload: any) => ({
    type: GENERALMENU_DELETE_SUCCESS,
    payload,
});

export const setGENERAL_MENUDeleteFailedToState = () => ({
    type: GENERALMENU_DELETE_FAILED,
});
  
export const setGENERAL_MENUFailedToState = () => ({
    type: GENERALMENU_FAILED,
});

export const setGENERAL_MENUClearToState = () => ({
    type: GENERALMENU_CLEAR,
});
  

export const loadGENERAL_MENU = () => {
    return async(dispatch: any) => {
        dispatch(setGENERAL_MENUFetchingToState());
        getDataGENERAL_MENU(dispatch)
    };
};

const getDataGENERAL_MENU = async (dispatch: any) => {
    try {
        const result = await httpClient.get<GeneralMenu[]>(`${server.GENERAL_MENU}/getAllActive`);
        dispatch(setGENERAL_MENUSuccessToState(
            result.data 
        ));
    }   catch (error) {
        dispatch(setGENERAL_MENUFailedToState());
    }
}
  
  
export const addGeneralMenu = (data: any, navigate: any) => {
    console.log(data)
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setGENERAL_MENUFetchingToState());
        // connect
        const result = await httpClient.post(server.GENERAL_MENU+"/add", data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setGENERAL_MENUAddSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setGENERAL_MENUAddFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setGENERAL_MENUAddFailedToState());
      }
    };
  };

  export const editGeneralMenu = (data: any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setGENERAL_MENUFetchingToState());
        // connect
        const result = await httpClient.put(`${server.GENERAL_MENU}/${data.id}`, data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setGENERAL_MENUEditSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setGENERAL_MENUEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setGENERAL_MENUEditFailedToState());
      }
    };
  };


  export const deleteGeneralMenu = (GENERAL_MENU_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.GENERAL_MENU + `/${GENERAL_MENU_id}`);
            console.log(result);
            if (result.status == 204) {
               dispatch(setGENERAL_MENUDeleteSuccessToState(result.data));
             } else {
               dispatch(setGENERAL_MENUDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setGENERAL_MENUDeleteFailedToState());
        }
    };
};
  