import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../..";
import { server, TOKEN } from "../../../services/Constants";
import "./Dashboard.css";
import Container from '@mui/material/Container';
import * as loginActions from "../../../actions/login.action";
import { Grid, Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import styled from '@emotion/styled';
import * as RequestLogActions from "../../../actions/request_log.action";
import * as requestTrackingActions from "../../../actions/request.tracking.action";
import * as RequestAssignActions from "../../../actions/request_assign.action";
import * as userActions from "../../../actions/user.action";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

export default function Dashboard(): JSX.Element {
    const { t } = useTranslation();
    const RequestLogStateReducer = useSelector((state: RootReducers) => state.RequestLogStateReducer);
    const requesTrackingReducer = useSelector((state: RootReducers) => state.requesTrackingReducer);
    const RequestAssignStateReducer = useSelector((state: RootReducers) => state.RequestAssignStateReducer);
    const UserStateReducer = useSelector((state: RootReducers) => state.userStateReducer);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isSelected1, setIsSelected1] = React.useState(false);
    const [borderColor1, setborderColor] = React.useState("grey.500");
    const [role_id, setRoleId] = React.useState(0);
    const [emp_all, setEmpAll] = React.useState(0);
    const [card_req_all, setCarnReqAll] = React.useState(0);
    const [card_req_all_h, setCarnReqAllH] = React.useState(0);
    const [card_in_pro, setCarnInPro] = React.useState(0);
    const [user_type, setUserType] = React.useState("");
    const [RequestActivity, setRequestActivity] = React.useState<any[]>([]);
    const [RequestActivityH, setRequestActivityH] = React.useState<any[]>([]);
    const [RequestOverview, setRequestOverview] = React.useState<any[]>([]);
    const [RequestOverviewH, setRequestOverviewH] = React.useState<any[]>([]);
    const [tableData, setTableData] = React.useState<any>([]);

    const StyledCard = styled(Card)`
      transition: border 0.3s;
      &:hover {
          border: 2px solid blue;
      }
      cursor: pointer;
    
      ${(props: { isSelected: boolean }) =>
        props.isSelected &&
        `
        border: 2px solid blue;
      `}

      `;
    const ScrollContainer = styled('div')({
        height: '243px',
        overflowY: 'scroll',
        outline: '1px solid slategrey',
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: 5,

    });
    const Content = styled('div')({
        marginRight: "5px",
        height: 'auto',
    });
    const requestColumns: GridColDef[] = [
        {
            headerName: `${t('Ticket')}`,
            field: "request_no",
            flex: 1,
            minWidth: 100  
        },
        {
            headerName: `${t("Ticket Owner")}`,
            field: "name",
            flex: 1,
            minWidth: 250 
        },
        {
            headerName: `${t("Status")}`,
            field: "status_name",
            flex: 1,
            minWidth: 100  
        },
        {
            headerName: `${t("Due Date")}`,
            field: "duedate",
            flex: 1,
            minWidth: 100  
        },

    ];
    // const handleMouseEnter = () => {
    //   setBorderColor1('secondary.main'); // เมื่อนำเมาส์ไปชี้ที่ Box
    // }
  
    // const handleMouseLeave = () => {
    //   setBorderColor1('primary.main'); // เมื่อนำเมาส์ออกจาก Box
    // }
    React.useEffect(() => {
      if (!localStorage.getItem(TOKEN)) {
      //   navigate("/login");
        dispatch(loginActions.logouttologinpage(navigate));
      }
      dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
      const callawaitfunction = async () =>{
        await dispatch(RequestLogActions.loadRequestDashboard());
        await  dispatch(requestTrackingActions.loadRequestTracking());
        await dispatch(RequestAssignActions.loadRequestDashH());
        await dispatch(userActions.loadUserDep());;
      }
      callawaitfunction();
    }, []);
    
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
    }, []);

    //set data 
    React.useEffect(() => {
      let  dataList:any[] = [];
      RequestLogStateReducer.result.forEach((item,index) =>{
        if(Number(item.status) == 1){
            item.status_name = `${t('Draft')}`
        }else if(Number(item.status) == 2){
            item.status_name = `${t(`Not Started`)}`
        }else if(Number(item.status) == 3){
            item.status_name = `${t('In Progress')}`
        }else if(Number(item.status) == 4){
            item.status_name = `${t(`Done`)}`
        }else if(Number(item.status) == 5){
            item.status_name = `${t('Cancel')}`
        }else if(Number(item.status) == 6){
            item.status_name = `${t('Overdue')}`
        }else if(Number(item.status) == 7){
            item.status_name = `${t('Pending')}`

        }else if(Number(item.status) == 8){
            item.status_name = `${t('Assign')}`
        }
        dataList[index] = `${item.name} ${t("update")} ${t("Ticket")} ${item.request_no} ${t(`to In`)} ${item.status_name}`;
      })

      setRequestActivity(dataList)
  }, [RequestLogStateReducer.result,i18next.language]);

    React.useEffect(() => {
      let  dataList:any[] = [];
      UserStateReducer.result.forEach((item,index) =>{
          dataList[index] = item;
      })
      // console.log("dataListUser")
      // console.log(dataList)
      setEmpAll(dataList.length)
  }, [UserStateReducer.result]);

  React.useEffect(() => {
      let  dataList:any[] = [];
      let index = 0;
      requesTrackingReducer.result.forEach((item) =>{
        if(Number(item.status_show) == 1){
            item.status = `${t('Draft')}`
        }else if(Number(item.status_show) == 2){
            item.status = `${t(`Not Started`)}`
        }else if(Number(item.status_show) == 3){
            item.status = `${t('In Progress')}`
        }else if(Number(item.status_show) == 4){
            item.status = `${t(`Done`)}`
        }else if(Number(item.status_show) == 5){
            item.status = `${t('Cancel')}`
        }else if(Number(item.status_show) == 6){
            item.status = `${t('Overdue')}`
        }else if(Number(item.status_show) == 7){
            item.status = `${t('Pending')}`

        }else if(Number(item.status_show) == 8){
            item.status = `${t('Assign')}`
        }else if(Number(item.status_show) == 9){
            item.status = `${t('Closed')}`
        }
          dataList[index] = item;
          index += 1;
      })
      // console.log("setRequestOverview")
      // console.log(dataList)
      setCarnReqAll(dataList.length)
      setRequestOverview(dataList)
  }, [requesTrackingReducer.result,i18next.language]);
  
  React.useEffect(() => {
      let  dataList:any[] = [];
      let index = 0;
      RequestAssignStateReducer.result.forEach((item) =>{
        if(Number(item.status) == 1){
            item.status_name = `${t('Draft')}`
        }else if(Number(item.status) == 2){
            item.status_name = `${t(`Not Started`)}`
        }else if(Number(item.status) == 3){
            item.status_name = `${t('In Progress')}`
        }else if(Number(item.status) == 4){
            item.status_name = `${t(`Done`)}`
        }else if(Number(item.status) == 5){
            item.status_name = `${t('Cancel')}`
        }else if(Number(item.status) == 6){
            item.status_name = `${t('Overdue')}`
        }else if(Number(item.status) == 7){
            item.status_name = `${t('Pending')}`

        }else if(Number(item.status) == 8){
            item.status_name = `${t('Assign')}`
        }else if(Number(item.status) == 9){
            item.status_name = `${t('Closed')}`
        }
        dataList[index] = item;
        index += 1;
      })
      // console.log("setRequestOverviewH")
      // console.log(dataList)
      setCarnReqAllH(dataList.length)
      setRequestOverviewH(dataList)
      setTableData(dataList)
  }, [RequestAssignStateReducer.result,i18next.language]);

  React.useEffect(() => {
    setCarnInPro(requesTrackingReducer.res3)
  }, [requesTrackingReducer]);

  React.useEffect(() => {
      if (localStorage.getItem(TOKEN)) {
          const token = JSON.stringify(localStorage.getItem(TOKEN));
            let base64Url = token.split(".")[1];
            let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            let tokenpayload;
            try{
            tokenpayload = decodeURIComponent(atob(base64).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(""));
          setRoleId(JSON.parse(tokenpayload).role_id)
          setUserType(JSON.parse(tokenpayload).user_type)
      }catch (e) {
        tokenpayload = unescape(atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join("")
      
    );
    dispatch(loginActions.logouttologinpage(navigate));
      }
    }
  }, [localStorage.getItem(TOKEN)]);


  const handleCardClick = (status:number) => {}



   

    return (
        <Container fixed sx={{ mt: 3}} >
          <Grid  
              xs={12} sm={12} md={12} lg={12} xl={12} 
              container
              justifyContent="center"
              sx={
                  { 
                      margin: '0 auto'
                  }
              }
          >
            <Card 
                sx={
                    { 
                        borderRadius:'25px',
                        marginTop:2,
                        minWidth: '40%',
                        boxShadow: 'unset',
                        width: '100%',
                    }
                }
            >
              <CardContent>
                  <Grid container>
                    <Grid 
                      xs={12} sm={12} md={12} lg={12} xl={12} 
                      direction="column" 
                      container item
                      sx={{
                        borderBottom: '#ABA9A6 solid 2px',
                          marginBottom: "2rem"
                      }}
                    >
                      <Typography 
                        gutterBottom 
                        variant="h5" 
                        component="h2" 
                        sx={{
                          color:'#4F4A41',
                          textAlign: "left",
                          marginTop: 2,
                        }}
                      >
                        {t(`Dashboard`)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {
                    user_type == "User" &&
                    Number(role_id) == 2 &&
                    <Grid container>
                      <Grid 
                        xs={12} sm={12} md={12} lg={12} xl={12} 
                        direction="column" 
                        container item
                        sx={{
                            marginBottom: "2rem"
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid 
                            xs={12} sm={6} md={6} lg={6} xl={6} 
                            direction="column" 
                            container item
                            sx={{
                                marginBottom: "2rem",
                            }}
                          >
                            <Grid container>
                              <Grid 
                                xs={12} sm={1} md={1} lg={1} xl={1} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              ></Grid>
                              <Grid 
                                xs={12} sm={10} md={10} lg={10} xl={10} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              >
                                <Box
                                  // borderColor={borderColor1} // ใช้ borderColor เพื่อกำหนดสีขอบ
                                  onClick={() => {
                                    navigate("/my-request-tracking")
                                  }}
                                  sx={{
                                      width: "100%",
                                      minHeight: 100,
                                      border: 1,
                                      borderRadius: 3,
                                      backgroundColor: "#D3D3D3",
                                      borderColor: 'grey.500',
                                      '&:hover': {border: "2px solid blue"}
                                  }}
                                  // onMouseEnter={handleMouseEnter}
                                  // onMouseLeave={handleMouseLeave}
                                >
                                  <Grid 
                                    container
                                    sx={{
                                      paddingTop: "0.5rem",
                                    }}
                                  >
                                    <Grid 
                                      xs={12} sm={1} md={1} lg={1} xl={1} 
                                      direction="column" 
                                      container item
                                    ></Grid>
                                    <Grid 
                                      xs={12} sm={6} md={6} lg={6} xl={6} 
                                      direction="column" 
                                      container item
                                    >
                                      <Grid 
                                        container
                                        sx={{
                                          paddingTop: "0.5rem",
                                        }}
                                      >
                                        <Grid 
                                          xs={12} sm={12} md={12} lg={12} xl={12} 
                                          direction="column" 
                                          container item
                                        >
                                          <Typography 
                                            gutterBottom 
                                            variant="h5" 
                                            component="h2" 
                                            sx={{
                                              color:'#4F4A41',
                                              marginTop: 2,
                                              paddingLeft: "10px"
                                            }}
                                          >
                                            {t(`My Tickets`)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid 
                                        container
                                        sx={{
                                          paddingTop: "0.5rem",
                                        }}
                                      >
                                        <Grid 
                                          xs={12} sm={5} md={5} lg={5} xl={5} 
                                          direction="column" 
                                          container item
                                        >
                                          <Typography 
                                            gutterBottom 
                                            variant="h3" 
                                            sx={{
                                              color:'#4F4A41',
                                              textAlign: "center",
                                            }}
                                          >
                                            {card_req_all}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid 
                                      xs={12} sm={2} md={2} lg={2} xl={2} 
                                      direction="column" 
                                      container item

                                    ></Grid>
                                  </Grid>

                                </Box>
                              </Grid>
                              <Grid 
                                xs={12} sm={1} md={1} lg={1} xl={1} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              ></Grid>
                            </Grid>
                            <Grid container>
                              <Grid 
                                xs={12} sm={1} md={1} lg={1} xl={1} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              ></Grid>
                              <Grid 
                                xs={12} sm={10} md={10} lg={10} xl={10} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem",
                                }}
                              >
                                <ScrollContainer ref={scrollContainerRef}>
                                  <Content>
                                    <Grid container>
                                      <Grid 
                                          xs={12} sm={12} md={12} lg={12} xl={12} 
                                          direction="column" 
                                          container item
                                          sx={{
                                              marginBottom: "1rem"
                                          }}
                                        >
                                          <Typography 
                                            gutterBottom 
                                            variant="h5" 
                                            component="h2" 
                                            sx={{
                                              color:'#4F4A41',
                                              textAlign: "left",
                                              marginTop: 3,
                                            }}
                                          >
                                            <b>{t(`Overview`)}</b>
                                          </Typography>
                                      </Grid>
                                    </Grid>
                                    {
                                      RequestOverview.map((item, index) => (    
                                      <>         
                                      <Grid 
                                        container
                                        sx={{
                                          marginBottom: "1rem"
                                        }}
                                      >
                                        <Grid 
                                            xs={12} sm={4} md={4} lg={4} xl={4} 
                                            direction="column" 
                                            container item

                                          >
                                            {t(`Ticket`)} {item.request_no}
                                        </Grid>
                                        <Grid 
                                            xs={12} sm={4} md={4} lg={4} xl={4} 
                                            direction="column" 
                                            container item
                                          >

                                        </Grid>
                                        <Grid 
                                            xs={12} sm={4} md={4} lg={4} xl={4} 
                                            direction="column" 
                                            container item
                                          >
                                            {item.status}
                                        </Grid>
                                      </Grid>                         
                                      </>  
                                    ))}    
                                    {
                                        RequestOverview.length == 0 &&
                                        
                                          <Typography 
                                            gutterBottom 
                                            variant="h5" 
                                            component="h2" 
                                            sx={{
                                              color:'#4F4A41',
                                              textAlign: "center",
                                              marginTop: 3,
                                            }}
                                          >
                                            <b>{t(`No Overview`)}</b>
                                          </Typography>

                                    }
                                  </Content>
                                </ScrollContainer>
                              </Grid>
                              <Grid 
                                xs={12} sm={1} md={1} lg={1} xl={1} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              ></Grid>
                            </Grid>
                          </Grid>
                          <Grid 
                            xs={12} sm={6} md={6} lg={6} xl={6} 
                            direction="column" 
                            container item
                            sx={{
                                marginBottom: "2rem"
                            }}
                          >
                            <Grid container>
                              <Grid 
                                xs={12} sm={1} md={1} lg={1} xl={1} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              ></Grid>
                              <Grid 
                                xs={12} sm={10} md={10} lg={10} xl={10} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              >
                                <Box
                                  onClick={() => {
                                    navigate("/my-request-tracking/1")
                                  }}
                                  sx={{
                                      width: "100%",
                                      minHeight: 100,
                                      border: 1,
                                      borderRadius: 3,
                                      backgroundColor: "#D3D3D3",
                                      borderColor: 'grey.500',
                                      '&:hover': {border: "2px solid blue"}
                                                                                  
                                  }}
                                >
                                  <Grid 
                                    container
                                    sx={{
                                      paddingTop: "0.5rem",
                                    }}
                                  >
                                    <Grid 
                                      xs={12} sm={1} md={1} lg={1} xl={1} 
                                      direction="column" 
                                      container item
                                    ></Grid>
                                    <Grid 
                                      xs={12} sm={6} md={6} lg={6} xl={6} 
                                      direction="column" 
                                      container item
                                    >
                                      <Grid 
                                        container
                                        sx={{
                                          paddingTop: "0.5rem",
                                        }}
                                      >
                                        <Grid 
                                          xs={12} sm={12} md={12} lg={12} xl={12} 
                                          direction="column" 
                                          container item
                                        >
                                          <Typography 
                                            gutterBottom 
                                            variant="h5" 
                                            component="h2" 
                                            sx={{
                                              color:'#4F4A41',
                                              marginTop: 2,
                                              paddingLeft: "10px"
                                            }}
                                          >
                                            {t(`In Progress Tickets`)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid 
                                        container
                                        sx={{
                                          paddingTop: "0.5rem",
                                        }}
                                      >
                                        <Grid 
                                          xs={12} sm={5} md={5} lg={5} xl={5} 
                                          direction="column" 
                                          container item
                                        >
                                          <Typography 
                                            gutterBottom 
                                            variant="h3" 
                                            sx={{
                                              color:'#4F4A41',
                                              textAlign: "center",
                                            }}
                                          >
                                            {card_in_pro}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid 
                                      xs={12} sm={2} md={2} lg={2} xl={2} 
                                      direction="column" 
                                      container item

                                    ></Grid>
                                  </Grid>

                                </Box>
                              </Grid>
                              <Grid 
                                xs={12} sm={1} md={1} lg={1} xl={1} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              ></Grid>
                            </Grid>
                            <Grid container>
                              <Grid 
                                xs={12} sm={1} md={1} lg={1} xl={1} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              ></Grid>
                              <Grid 
                                xs={12} sm={10} md={10} lg={10} xl={10} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              >
                                <ScrollContainer ref={scrollContainerRef}>
                                  <Content>
                                    <Grid container>
                                      <Grid 
                                        xs={12} sm={12} md={12} lg={12} xl={12} 
                                        direction="column" 
                                        container item
                                        sx={{
                                            marginBottom: "1rem"
                                        }}
                                      >
                                        <Typography 
                                          gutterBottom 
                                          variant="h5" 
                                          component="h2" 
                                          sx={{
                                            color:'#4F4A41',
                                            textAlign: "left",
                                            marginTop: 3,
                                          }}
                                        >
                                          <b>{t(`Activity`)}</b>
                                        </Typography>
                                      </Grid>
                                    </Grid>

                                  
                                    {
                                      RequestActivity.length > 0 &&
                                      RequestActivity.map((item, index) => (    
                                      <>         
                                        {item} <br /><br />                            
                                      </>  
                                    ))}  
                                    {
                                      RequestActivity.length == 0 &&
                                        <Typography 
                                          gutterBottom 
                                          variant="h5" 
                                          component="h2" 
                                          sx={{
                                            color:'#4F4A41',
                                            textAlign: "center",
                                            marginTop: 3,
                                          }}
                                        >
                                          <b>{t(`No Activity`)}</b>
                                        </Typography>
                                    }  
                                  </Content>
                                </ScrollContainer>
                              </Grid>
                              <Grid 
                                xs={12} sm={1} md={1} lg={1} xl={1} 
                                direction="column" 
                                container item
                                sx={{
                                    marginBottom: "2rem"
                                }}
                              ></Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {
                    user_type == "User" &&
                    Number(role_id) == 4 &&
                    <>
                      <Grid container>
                        <Grid 
                          xs={12} sm={12} md={12} lg={12} xl={12} 
                          direction="column" 
                          container item
                          sx={{
                              marginBottom: "2rem"
                          }}
                        >
                          <Grid container>
                            <Grid 
                              xs={12} sm={7} md={7} lg={7} xl={7} 
                              direction="column" 
                              container item
                              sx={{
                                  marginBottom: "2rem",
                              }}
                            >
                              <Grid 
                                container
                                sx={{
                                  border: 1,
                                  borderRadius: 1,
                                }}
                              >
                                <Grid 
                                  xs={12} sm={12} md={12} lg={12} xl={12} 
                                  direction="column" 
                                  container item
                                  sx={{
                                      marginBottom: "0.5rem",
                                      
                                  }}
                                > 
                                  <Typography 
                                    gutterBottom 
                                    sx={{
                                      color:'#4F4A41',
                                        textAlign: "left",
                                        marginTop: "0.7rem",
                                        paddingLeft: "10px"
                                    }}
                                  >
                                    <b>{t(`Employees`)}({emp_all})</b>
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid 
                                  xs={12} sm={12} md={12} lg={12} xl={12} 
                                  direction="column" 
                                  container item
                                  sx={{
                                      marginBottom: "2rem",
                                  }}
                                > 
                                  <DataGrid
                                      // components={{ Toolbar: CustomToolbar }}
                                      sx={{ 
                                        backgroundColor: "white",
                                        boxShadow: 1,
                                        border: 1,
                                      }}  
                                      rows={tableData}
                                      columns={requestColumns}
                                      // componentsProps={{
                                      //     toolbar: {
                                      //       value: searchText,
                                      //       onChange: (searchVal: string) => requestSearch(searchVal),
                                      //       onCancelSearch: () => cancelSearch()
                                      //     }
                                      // }}
                                      autoHeight
                                      pageSize={5}
                                      rowsPerPageOptions={[5]}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid 
                              xs={12} sm={1} md={1} lg={1} xl={1} 
                              direction="column" 
                              container item
                              sx={{
                                  marginBottom: "2rem",
                              }}
                            ></Grid>
                            <Grid 
                              xs={12} sm={4} md={4} lg={4} xl={4} 
                              direction="column" 
                              container item
                              sx={{
                                  marginBottom: "2rem",
                              }}
                            > 
                              <Grid container>
                                <Grid 
                                  xs={12} sm={12} md={12} lg={12} xl={12} 
                                  direction="column" 
                                  container item
                                  sx={{
                                      marginBottom: "2rem",
                                  }}
                                > 
                                  <Box
                                    onClick={() => {
                                      navigate("/my-request-tracking")
                                    }}
                                    sx={{
                                        width: "100%",
                                        minHeight: 100,
                                        border: 1,
                                        borderRadius: 3,
                                        backgroundColor: "#D3D3D3",
                                        borderColor: 'grey.500',
                                        '&:hover': {border: "2px solid blue"}
                                                                                    
                                    }}
                                  >  
                                        <Grid 
                                          container
                                          sx={{
                                            paddingTop: "0.5rem",
                                          }}
                                        >
                                          <Grid 
                                            xs={12} sm={12} md={12} lg={12} xl={12} 
                                            direction="column" 
                                            container item
                                          >
                                            <Typography 
                                              gutterBottom 
                                              variant="h5" 
                                              component="h2" 
                                              sx={{
                                                color:'#4F4A41',
                                                marginTop: 2,
                                                paddingLeft: "15px"
                                              }}
                                            >
                                              {t(`My Tickets`)}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid 
                                          container
                                          sx={{
                                            paddingTop: "0.5rem",
                                          }}
                                        >
                                          <Grid 
                                            xs={12} sm={5} md={5} lg={5} xl={5} 
                                            direction="column" 
                                            container item
                                          >
                                            <Typography 
                                              gutterBottom 
                                              variant="h3" 
                                              sx={{
                                                color:'#4F4A41',
                                                textAlign: "center",
                                              }}
                                            >
                                              {card_req_all}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid 
                                  xs={12} sm={12} md={12} lg={12} xl={12} 
                                  direction="column" 
                                  container item
                                  sx={{
                                      marginBottom: "2rem",
                                  }}
                                > 
                                  <ScrollContainer ref={scrollContainerRef}>
                                    <Content>
                                      <Grid container>
                                        <Grid 
                                            xs={12} sm={12} md={12} lg={12} xl={12} 
                                            direction="column" 
                                            container item
                                            sx={{
                                                marginBottom: "1rem"
                                            }}
                                          >
                                            <Typography 
                                              gutterBottom 
                                              variant="h5" 
                                              component="h2" 
                                              sx={{
                                                color:'#4F4A41',
                                                textAlign: "left",
                                                marginTop: 3,
                                              }}
                                            >
                                              <b>{t(`Overview`)}</b>
                                            </Typography>
                                        </Grid>
                                      </Grid>
                                      {
                                        RequestOverviewH.length > 0 &&
                                        RequestOverviewH.map((item, index) => (    
                                        <>         
                                        <Grid 
                                          container
                                          sx={{
                                            marginBottom: "1rem"
                                          }}
                                        >
                                          <Grid 
                                              xs={12} sm={5} md={5} lg={5} xl={5} 
                                              direction="column" 
                                              container item
                                            >
                                              {t(`Ticket`)} {item.request_no}
                                          </Grid>
                                          <Grid 
                                              xs={12} sm={3} md={3} lg={3} xl={3} 
                                              direction="column" 
                                              container item
                                            >

                                          </Grid>
                                          <Grid 
                                              xs={12} sm={4} md={4} lg={4} xl={4} 
                                              direction="column" 
                                              container item
                                            >
                                              {item.status_name}
                                          </Grid>
                                        </Grid>                         
                                        </>  
                                      ))}   
                                      {
                                        RequestOverviewH.length == 0 &&
                                        
                                          <Typography 
                                            gutterBottom 
                                            variant="h5" 
                                            component="h2" 
                                            sx={{
                                              color:'#4F4A41',
                                              textAlign: "center",
                                              marginTop: 3,
                                            }}
                                          >
                                            <b>{t(`No Overview`)}</b>
                                          </Typography>

                                      }
                                    </Content>
                                  </ScrollContainer>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  }
              </CardContent>
            </Card>
            
          </Grid>
        </Container>
  );
}
