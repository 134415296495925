import React, { useState } from "react";
import { Card, CardContent, Typography, CardActions, Button, Box } from "@mui/material";
import { Paper, Grid } from "@mui/material";
import "./ShareHolderEN.css";
import { useAppDispatch } from "../../..";
import * as dataSheetCreateActions from "../../../actions/datasheet.create.action";
import { useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";

function ShareHolderEN() {
  const dispatch = useAppDispatch();
  // const dataSheetCreateReducer = useSelector((state: RootReducers) => state.dataSheetCreateReducer);
  const shareholderStateReducer = useSelector((state) => state.shareholderStateReducer);

  const [inputList, setInputList] = useState([{ en_share_holder: "" }]);

  React.useEffect(() => {
    if(shareholderStateReducer.result){
      let setdata = []
      shareholderStateReducer.result.forEach((x) =>{
        if(x.en_share_holder){
          setdata.push({en_share_holder:x.en_share_holder})
        }else{
          
        }
        
      })
      setInputList(setdata)
      dispatch(dataSheetCreateActions.setDataSheetShareHolderENToState(setdata));
    }
    }, [shareholderStateReducer.result]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    dispatch(dataSheetCreateActions.setDataSheetShareHolderENToState(inputList));
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    dispatch(dataSheetCreateActions.setDataSheetShareHolderENToState(list));
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    for(let i=0;i < inputList.length;i++) {
      if(inputList[i].en_share_holder == "" || !inputList[i].en_share_holder){
      return;
      }
  }
    setInputList([...inputList, { en_share_holder: "" }]);
    dispatch(dataSheetCreateActions.setDataSheetShareHolderENToState(inputList));
  };

  return (
    <Grid 
      container 
      direction="row" 
      sx={{
        marginTop:2,
        marginBottom: 2
      }}
    >
      <Grid item xs={5}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="body1">
            Number of Shares Held : <span style={{ color: "red" }}>* </span>
          </Typography>
        </Grid>
        <span style={{ color: "red" }}>(at least 2)</span>
      </Grid>
      <Grid item xs={7}>
        {inputList.map((x, i) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <input
                  required
                  name="en_share_holder"
                  placeholder="Enter Name"
                  value={x.en_share_holder}
                  onChange={(e) => handleInputChange(e, i)}
                  className="share-input"
                />
              </Grid>
              <Grid item xs={4}>
                <div className="btn-box">
                  {inputList.length !== 1 && (
                    <button className="button remove" onClick={() => handleRemoveClick(i)}>
                      Remove
                    </button>
                  )}
                  {inputList.length - 1 === i && (
                    <button className="button add" onClick={handleAddClick}>
                      Add
                    </button>
                  )}
                </div>
              </Grid>
            </Grid>
          );
        })}
        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
      </Grid>
    </Grid>
  );
}

export default ShareHolderEN;
