
import {
    SHARE_HOLDER_FETCHING,
    SHARE_HOLDER_LIST,
  } from "../services/Constants";
  import { Provinces } from "../types/provinces.type";
  
  export interface ShareHolderState {
    isFetching: boolean;
    isError: boolean;
    result:any;
  }
  
  const initialState: ShareHolderState = {
    isFetching: false,
    isError: false,
    result: null,
  };
  
  export default (
    state = initialState,
    { type, payload }: any
  ): ShareHolderState => {
    switch (type) {
        case SHARE_HOLDER_FETCHING:
            return { ...state, isFetching: true, isError: false, result: null };
      case SHARE_HOLDER_LIST:
        return { ...state, isFetching: false, isError: false, result: payload };
      default:
        return state;
    }
  };
  