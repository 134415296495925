import {
  FORGOT_CHECK_MAIL_FETCHING,
  FORGOT_CHECK_MAIL_SUCCESS,
  FORGOT_CHECK_MAIL_FAILED,
  FORGOT_SEND_MAIL_FETCHING,
  FORGOT_SEND_MAIL_SUCCESS,
  FORGOT_SEND_MAIL_FAILED,
  server,
} from "../services/Constants";
import { MemberResult } from "../types/member.type";
import { httpClient } from "../services/httpclient";
import i18n from 'i18next';
const Swal = require('sweetalert2')

export const setForgotCheckMailFetchingToState = () => ({
  type: FORGOT_CHECK_MAIL_FETCHING,
});

export const setForgotCheckMailSuccessToState = (payload: any) => ({
  type: FORGOT_CHECK_MAIL_SUCCESS,
  payload,
});

export const setForgotCheckMailFailedToState = () => ({
  type: FORGOT_CHECK_MAIL_FAILED,
});

export const setForgotSendMailFetchingToState = () => ({
  type: FORGOT_SEND_MAIL_FETCHING,
});

export const setForgotSendMailSuccessToState = (payload: any) => ({
  type: FORGOT_SEND_MAIL_SUCCESS,
  payload,
});

export const setForgotSendMailFailedToState = () => ({
  type: FORGOT_SEND_MAIL_FAILED,
});

export const checkEmailToResetPassword = (payload: any,navigate: any) => {
  return async (dispatch: any) => {
    dispatch(setForgotCheckMailFetchingToState());
    let link = window.location.href.substring(window.location.href.length - 15 , 0);
    try {
      let result = await httpClient.get<any>(`${server.MEMBER_URL}/getUserByEmail/${payload.email}`);
      console.log("sendMailResetPass 1:", JSON.stringify(result.data));
      if (result.data) {

        dispatch(setForgotCheckMailSuccessToState(result.data));
        let sendMailResetPass = {
          // link: "https://smemate-dev.dcm.co.th",
          link:  link,
          email: payload.email,
          id: result.data[0].id,
        };
        console.log("sendMailResetPass 2:");
        console.log((sendMailResetPass));

        dispatch(setForgotSendMailFetchingToState());
        let result_send_mail = await httpClient.post(`${server.MEMBER_URL}/sendMailResetPassword`, sendMailResetPass);
        if (result_send_mail.data.link === true) {
          dispatch(setForgotSendMailSuccessToState(result_send_mail.data));
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: `${i18n.t(`Send to E-Mail Success`)}`,
            showConfirmButton: false,
            timer: 1500
          })
          navigate("/login");
        } else {
          dispatch(setForgotSendMailFailedToState());
        }
      } else {
        dispatch(setForgotCheckMailFailedToState());
      }
    } catch (error) {
      dispatch(setForgotCheckMailFailedToState());
    }
  };
};
