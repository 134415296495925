// import { history } from "../index";
import {
    REQUEST_ASSIGN_FETCHING,
    REQUEST_ASSIGN_SUCCESS,
    REQUEST_ASSIGN_LEVEL_SUCCESS,
    REQUEST_ASSIGN_FAILED,
    REQUEST_ASSIGN_LEVEL_FAILED,
    // REQUEST_ASSIGN_COUNT_DRAFT,
    // REQUEST_ASSIGN_CLEAR,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { Request } from "../types/request.type";
import i18n from 'i18next';
const Swal = require('sweetalert2')

export const setRequestFetchingToState = () => ({
    type: REQUEST_ASSIGN_FETCHING,
});
export const setRequestAssignSuccessToState = (
  payload: any[],) => (
      {
          type: REQUEST_ASSIGN_SUCCESS,
          payload,
      }
);

export const setRequestAssignFailedToState = () => ({
  type: REQUEST_ASSIGN_FAILED,
});

export const setRequestAssignLevelSuccessToState = (
  payload: any[],) => (
      {
          type: REQUEST_ASSIGN_LEVEL_SUCCESS,
          payload,
      }
);

export const setRequestAssignLevelFailedToState = () => ({
  type: REQUEST_ASSIGN_LEVEL_FAILED,
});


export const loadRequestById = (req_id:number) => {
  return async(dispatch: any) => {
      // dispatch(setRequestFetchingToState());
      getDataRequestAssignByID(dispatch,req_id)
  };
};
export const loadRequestAssignlevel = (req_id:number) => {
  return async(dispatch: any) => {
      // dispatch(setRequestFetchingToState());
      getDataRequestAssignLevel(dispatch,req_id)
  };
};
export const loadRequestDashH = () => {
  return async(dispatch: any) => {
      dispatch(setRequestFetchingToState());
      getDataRequestDashH(dispatch)
  };
};

const getDataRequestAssignByID = async (dispatch: any, req_id:number) => {
  try {
      const result = await httpClient.get<any[]>(`${server.REQUEST_ASSIGN}/get-by-id/${req_id}`);
      console.log("result.data_assign")
      console.log(result.data)
      dispatch(setRequestAssignSuccessToState(result.data));
  }   catch (error) {
      dispatch(setRequestAssignFailedToState());
  }
}
const getDataRequestAssignLevel = async (dispatch: any, req_id:number) => {
  try {
      const result = await httpClient.get<any[]>(`${server.REQUEST_ASSIGN}/get-by-id-level/${req_id}`);
      console.log("result.data_assign_level")
      console.log(result.data)
      dispatch(setRequestAssignLevelSuccessToState(result.data));
  }   catch (error) {
      dispatch(setRequestAssignLevelFailedToState());
  }
}

export const updaterequestAssign = (data: any, navigate: any) => {
    console.log(data)
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setRequestFetchingToState());
        // // connect
        let dataUpdate = {
            status: Number(data.status),
            user_assign_by: localStorage.getItem(MEMBER_ID),
            user_assign_to: data.user_assign_to,
            user_owner: Number(data.user_owner),
            status_check: Number(data.status_check),
            level: Number(data.level),
            check_owner_assign: Number(data.check_owner_assign)
        }
        const result_update = await httpClient.put<any[]>(`${server.REQUEST}/tracking-assign/${data.req_id}`,dataUpdate);
        if(Number(result_update.status) == 200 || Number(result_update.status) == 201){
          Swal.fire({
              position: 'center',
              icon: 'success',
              title: `${i18n.t('Information Saved')}`,
              text:  `${i18n.t('The information has been saved and updated successfully.')}`,
              showConfirmButton: false,
              timer: 1500
          })
          navigate("/my-request-tracking");
        }
        // console.log(result_check.data)
        
      } catch (error) {
        // error

      }
    };
};

const getDataRequestDashH = async (dispatch: any) => {
  try {
      const result_dashH = await httpClient.get<any[]>(`${server.REQUEST_ASSIGN}/get-request-dashboard-header`);
      // console.log("result_dashH")
      // console.log(result_dashH.data)
      dispatch(setRequestAssignLevelSuccessToState(result_dashH.data));
  }   catch (error) {
      dispatch(setRequestAssignLevelFailedToState());
  }
}
  


  