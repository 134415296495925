import * as React from "react";
import { Paper, Grid } from "@mui/material";
import Iframe from "react-iframe";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import getLanguage from "../../Translation/Translation";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "../../..";
import * as registerActions from "../../../actions/register.action";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import { RootReducers } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button, { ButtonProps } from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Search from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import * as datasheetActions from "../../../actions/datasheet.create.action";
import { DataSheetCreate } from "../../../types/data_sheet.type";
import { MEMBER_ID, server, TOKEN } from "../../../services/Constants";
import * as directorActions from "../../../actions/director.action";
import * as shareholderrActions from "../../../actions/shareholder.action";
import * as dataSheetCreateActions from "../../../actions/datasheet.create.action";
import * as loginActions from "../../../actions/login.action";

type Business_informationProps = {
  //
};

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "gray",
  marginLeft: "auto",
  marginRight: "auto",
  paddingRight: 1,
  paddingBottom: 1,
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}
const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center",
}));

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };
}

function createData(name: string, calories: number, fat: number) {
  return { name, calories, fat };
}

const Business_information: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const dataSheetCreateReducer = useSelector((state: RootReducers) => state.dataSheetCreateReducer);

  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      // navigate("/login");
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
    dispatch(datasheetActions.getAllDataSheets(Number(localStorage.getItem(MEMBER_ID))));
    dispatch(directorActions.setDirectorFetchingToState());
    dispatch(shareholderrActions.setShare_HolderrFetchingToState());
  }, []);

  // const rows = [
  //   {id:1,company_name_en:'DCM',calories:111,fat:11.0},
  //   {id:2,company_name_en:'ABC',calories:305,fat:3.7},
  //   {id:3,company_name_en:'XYZ',calories:452,fat:25.0},
  //   {id:4,company_name_en:'DEF',calories:262,fat:16.0},
  //   {id:5,company_name_en:'PPP',calories:159,fat:26.0},
  // ]
  // .sort((a, b) => (a.calories < b.calories ? -1 : 1));
  const [rows, setrows] = React.useState<DataSheetCreate[]>([]);
  const [searched, setSearched] = React.useState<string>("");
  const [searchrows, setsearchrows] = React.useState(rows);
  const [showcloseicon, setshowcloseicon] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);

  React.useEffect(() => {
    if (dataSheetCreateReducer.result_data_list) {
      setrows(dataSheetCreateReducer.result_data_list);
      setsearchrows(dataSheetCreateReducer.result_data_list);
    }
  }, [dataSheetCreateReducer]);

  const conFirmDelete = (id: string) => {
    dispatch(dataSheetCreateActions.updateStatutsDataSheet(Number(id), { status: "I" }));
  };

  const ButtonAddBusiness_information = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#ffffff",
    backgroundColor: "#254E58",
    borderColor: "#162E34",
    "&:hover": {
      backgroundColor: "#162E34",
      color: "#ffffff",
      borderColor: "#254E58",
    },
  }));

  const ButtonEditBusiness_information = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#ffffff",
    backgroundColor: "#4F4A41",
    borderColor: "#4F4A21",
    "&:hover": {
      backgroundColor: "#4F4A21",
      color: "#ffffff",
      borderColor: "#4F4A41",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      backgroundColor: "#cccccc",
    },
  }));

  const ButtonDeleteBusiness_information = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#ffffff",
    backgroundColor: "#c30010",
    borderColor: "#de0a26",
    "&:hover": {
      backgroundColor: "#de0a26",
      color: "#ffffff",
      borderColor: "#c30010",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      backgroundColor: "#cccccc",
    },
  }));

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const filteredRows = rows.filter((row) => {
      return row.th_company_name?.toString().toLowerCase().includes(searchedVal.toLowerCase());
    });
    setsearchrows(filteredRows);
    if (searchedVal === "") {
      setshowcloseicon(false);
    } else {
      setshowcloseicon(true);
    }
    setPage(0);
  };
  const handleClickClearSearch = () => {
    setSearched("");
    setsearchrows(rows);
    setshowcloseicon(false);
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{ marginTop: "1rem" }}
    >
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        alignItems="center"
        justifyContent="center"
        direction="row"
        item
        container
      >
        <Grid
          xs={12}
          sm={12}
          md={10}
          lg={7.5}
          xl={7.5}
          alignItems="right"
          justifyContent="end"
          direction="row"
          item
          container
        >
          <Box>
            <ButtonAddBusiness_information
              size="large"
              variant="outlined"
              onClick={() => {
                navigate("/datasheet/create");
              }}
            >
              {t('Add new Business Information')}
            </ButtonAddBusiness_information>
          </Box>
        </Grid>
      </Grid>

      {searchrows.length > 0 ?
      <>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        alignItems="center"
        justifyContent="center"
        direction="row"
        item
        container
      >
      <Grid
          xs={12}
          sm={12}
          md={8}
          lg={6}
          xl={4.6}
          alignItems="center"
          justifyContent="center"
          direction="row"
          item
          container
          sx={{ marginTop: "0.5rem" }}
        >
          <Box sx={{ width: "100%" }}>
            <TextField
              sx={{ width: "100%" }}
              value={searched}
              onChange={(e) => {
                requestSearch(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickClearSearch} edge="end">
                      {showcloseicon ? <CloseIcon /> : ""}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              id="input-with-icon-textfield"
              label={`${t("Search Business Name")}`}
              size="small"
              variant="outlined"
            />
          </Box>
        </Grid>
        </Grid>
        </>
        :""}

      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        alignItems="center"
        justifyContent="center"
        direction="row"
        item
        container
      >
        {searchrows.length > 0 ?
        <TableContainer component={Paper} sx={{ boxShadow: "none", width: "100%" }}>
          <Table aria-label="custom pagination table">
            <TableBody>
              {(rowsPerPage > 0
                ? searchrows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : searchrows
              ).map((row: any) => (
                <TableRow key={row.id}>
                  {/* {row.name} */}
                  <TableCell colSpan={6} sx={{ borderBottom: "none" }}>
                    <Grid
                      xs={12}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={4.6}
                      alignItems="center"
                      justifyContent="center"
                      direction="row"
                      item
                      container
                      sx={{ margin: "auto", width: "100%"}}
                    >
                      <Card sx={{ width: "100%"}}>
                        <CardContent sx={{ width: "100%" }}>
                        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
                          {t(`Company Name`)} {row.th_company_name}
                          </Typography>
                          <Typography variant="body2">{t(`Registration No.`)} {row.th_regis_no}</Typography>
                          <Typography variant="body2">{t(`Address `)} {row.th_ho_address}</Typography>
                          <Typography variant="body2">{t(`Issued on`)} {row.th_issued_on}</Typography>
                          <Typography variant="body2">{t(`Status`)} {row.status}</Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: "flex-end" }}>
                          <ButtonEditBusiness_information
                            disabled={row.status !== "Active"}
                            size="small"
                            onClick={() => {
                              navigate(`/datasheet/edit/${row.id}`);
                            }}
                          >
                            {t('Edit')}
                          </ButtonEditBusiness_information>
                          <ButtonDeleteBusiness_information
                            disabled={row.status !== "Active"}
                            size="small"
                            onClick={() => {
                              Swal.fire({
                                title: `${t('information Saved')}`,
                                text: `${t('Confirm Delete Data')} ?`,
                                icon: "warning",
                                // showDenyButton: true,
                                showCancelButton: true,
                                confirmButtonText: `${t('Delete')}`,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                cancelButtonText: `${t('Cancel')}`,
                                // denyButtonText: `ไม่`,
                              }).then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                  conFirmDelete(row.id);
                                  // Swal.fire("Saved!", "", "info");
                                }
                                // else if (result.isDenied) {
                                //   Swal.fire("Changes are not saved", "", "info");
                                // }
                              });
                            }}
                          >
                             {t('Delete')}
                          </ButtonDeleteBusiness_information>
                        </CardActions>
                      </Card>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter sx={{ marinTop: 10 }}>
              <TableRow>
                <Grid
                      xs={12}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={4.6}
                      alignItems="center"
                      justifyContent="center"
                      direction="row"
                      item
                      container
                      sx={{ margin: "auto", width: "100%"}}
                >
                  <TablePagination
                    sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)", width: "100%" }}
                    // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    rowsPerPageOptions={[2, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={searchrows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    labelRowsPerPage={`${t('Rows per page')}`}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    // ActionsComponent={TablePaginationActions}
                  />
                </Grid>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        :
        <Typography gutterBottom variant="h5" component="h2" sx={{color:'#4F4A41',textAlign:'center',mt:8}}>
        
        {t('You have no Business Information')}
        </Typography>
        }
      </Grid>
    </Grid>
  );
};

export default Business_information;
