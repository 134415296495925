import * as React from "react";
import "./Personal_information.css";
import { Stack,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField} from "@mui/material";
import Iframe from "react-iframe";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import getLanguage from "../../Translation/Translation";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "../../..";
import * as registerActions from "../../../actions/register.action";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import * as PersonalInformationAction from "../../../actions/personal_information.action";
import { RootReducers } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  OK,
  LOGIN_FAILED,
  LOGIN_FETCHING,
  LOGIN_SUCCESS,
  server,
  TOKEN,
  LOGOUT,
} from "../../../../src/services/Constants";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import * as loginActions from "../../../actions/login.action";
import EditIcon from "@mui/icons-material/Edit";
import { Email } from "@mui/icons-material";
import Link from '@mui/material/Link';

type HomePageProps = {
  //
};

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "gray",
  marginLeft: "auto",
  marginRight: "auto",
  paddingRight: 1,
  paddingBottom: 1,
};



const HomePage: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const registerReducer = useSelector((state: RootReducers) => state.registerReducer);
  const personal_informationStateReducer = useSelector((state: RootReducers) => state.personal_informationStateReducer);
  const navigate = useNavigate();
  const [personal_info, setpersonal_info] = React.useState({id:0,name:"",first_name:"",last_name:"",phone_number:"",address:"",member_role:"",user_type:"",email: "",user_name: ""});
  const [openeditdata, setOpeneditdata] = React.useState(false);
  const [disableedit, setdisableedit] = React.useState(false);
  const [dataname, setdataname] = React.useState("");
  const [datanameedit, setdatanameedit] = React.useState("");

  let avatarname = "";
  let member_id = 0;
  let contact_name = "";
  let contact_phone = "";
  let contact_address = "";
  let member_roll = "";
  let first_name = "";
  let last_name = "";
  if (localStorage.getItem(TOKEN)) {
    const token = JSON.stringify(localStorage.getItem(TOKEN));

    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    member_id = JSON.parse(jsonPayload).user_id ? JSON.parse(jsonPayload).user_id : 0;
    avatarname = JSON.parse(jsonPayload).contact_name ? JSON.parse(jsonPayload).contact_name.slice(0, 1) : "";
    contact_name = JSON.parse(jsonPayload).contact_name ? JSON.parse(jsonPayload).contact_name : "";
    contact_phone = JSON.parse(jsonPayload).contact_phone ? JSON.parse(jsonPayload).contact_phone : "";
    contact_address = JSON.parse(jsonPayload).contact_address ? JSON.parse(jsonPayload).contact_address : "";
    member_roll = JSON.parse(jsonPayload).member_roll ? JSON.parse(jsonPayload).member_roll : "";
    first_name = JSON.parse(jsonPayload).member_roll ? JSON.parse(jsonPayload).member_roll : "";
    last_name = JSON.parse(jsonPayload).member_roll ? JSON.parse(jsonPayload).member_roll : "";
  }

  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      // navigate("/login");
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(PersonalInformationAction.getPERONAL_INFOMATIONFindByroleId(member_id));
  }, []);

  React.useEffect(() => {
    if(personal_informationStateReducer.result){
      setpersonal_info({
        ...personal_info,
        id:personal_informationStateReducer.result.id,
        name:personal_informationStateReducer.result.name,
        phone_number:personal_informationStateReducer.result.phone_number,
        address:personal_informationStateReducer.result.address,
        member_role:personal_informationStateReducer.result.role_name,
        user_type:personal_informationStateReducer.result.user_type,
        first_name:personal_informationStateReducer.result.first_name,
        last_name:personal_informationStateReducer.result.last_name,
        email:personal_informationStateReducer.result.email,
        user_name:personal_informationStateReducer.result.user_name
      });
    }

  }, [personal_informationStateReducer.result]);


  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
    // i18next.changeLanguage(code);
  };
  const handleEditData = (data:any,name:any) => {
    setdataname(name);
    setdatanameedit(data)
    console.log("edit");
    setOpeneditdata(true);
};
const handleCloseeditData = () => {
  setdataname("");
  setdatanameedit("")
  setOpeneditdata(false);
};
const handleShowDisable = () => {
  Swal.fire({
    position: "center",
    icon: "success",
    showConfirmButton: false,
    timer: 1500
  });
  setdisableedit(!disableedit)
}
const handleEditDataSubmit = (event:any,datacheck:string) => {
  event.preventDefault();
  // console.log("SubmitEdit");
  // console.log(personal_info);
  let dataAll = {}
  if(personal_info.user_type == "Member"){
    if(datacheck == "Firstname"){
      dataAll = {id: personal_info.id,first_name: personal_info.first_name}
    }else if(datacheck == "Lastname"){
      dataAll = {id: personal_info.id,last_name: personal_info.last_name}
    }else if(datacheck == "Phone Number"){
      dataAll = {id: personal_info.id,contact_phone: personal_info.phone_number}
    }else if(datacheck == "Address"){
      dataAll = {id: personal_info.id,contact_address: personal_info.address}
    }else{

    }
  }else{
    return;
  }
    // setdisableedit(false)
    dispatch(
        PersonalInformationAction.EditPERONAL_INFOMATION(
          dataAll,
          navigate
        )
    );
    handleCloseeditData();
  };
  const languages = [
    {
      code: "en",
      name: "English",
    },
    {
      code: "th",
      name: "ไทย",
    },
  ];
  
  const showDialogEditData = () => {
   
    return (
      <Dialog
      disableScrollLock
      fullWidth
      open={openeditdata}
      onClose={handleCloseeditData}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <form >
      <DialogTitle id="alert-dialog-title">
      {t("Edit Personal Information")} 
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        </DialogContentText>
        <Grid container  >
                        <Grid 
                        sx={{alignSelf:"center"}}
                        item xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}>
        <Typography  variant="inherit"  component="h6">
    {t(`${dataname}`)} :
    </Typography>
    </Grid>
    
    <Grid item xs={12}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email_address"
              size="small"
              onChange={(e) => {setdatanameedit(e.target.value)}}
              value={datanameedit}
            />
             </Grid>
             </Grid>

             
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseeditData}> {t("Cancel")} </Button>
        <Button type="submit" autoFocus>
        {t("Save")} 
        </Button>
      </DialogActions>
      </form>
    </Dialog>
        );
    };
  // function onLanguageClick(code){
  //   i18next.changeLanguage(code);
  // }

  return (
    <Grid
      container
      // sx={{ ...gridStyles }}
      sx={{padding: "0 100px 0 100px"}}
    >
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        alignItems="center"
        justifyContent="center"
        direction="column"
        item
        container
        sx={{marginTop: "50px",marginBottom: "30px"}}
      >
        <Typography variant="h3" component="div" sx={{ color: "#F2903A"}}>
          {t("Personal Information")}
        </Typography>
      </Grid>
      <Divider 
        sx={{ 
          borderBottomWidth: 1, 
          borderColor: "black",
          width: "100%", 
        }}
      />
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        alignItems="center"
        justifyContent="center"
        direction="row"
        item
        container
        sx={{marginTop: "20px"}}
      >
        <Grid container spacing={4}>
          <Grid
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            alignItems="center" // เปลี่ยนเป็น center
            justifyContent="right"
            direction="row"
            item
            container
          >
            <Avatar alt={avatarname} sx={{ width: 150, height: 150 }} />
            { 
                  personal_info.user_type == "Member" && (
                    <IconButton aria-label="edit"  onClick={() => { handleShowDisable()}}>
                      <Box
                        sx={{
                          marginLeft: "-50px",
                          marginBottom: "-100px",
                          backgroundColor: "#F2903A", // สีพื้นหลังของวงกลม
                          borderRadius: "50%", // รูปแบบเป็นวงกลม
                          width: 40, // ขนาดความกว้าง
                          height: 40, // ขนาดความสูง
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        
                        <EditIcon fontSize="medium" sx={{ color: "#fff" }} /> 
                      </Box>
                    </IconButton>
                  ) 
            }
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            alignItems="center" // เปลี่ยนเป็น center
            justifyContent="left"
            direction="row"
            item
            container
          >
            <Grid>
              <Typography
                sx={{ textTransform: "capitalize", fontSize: "18px"}}
                variant="button"
                component="div"
                gutterBottom
              >
                {/* {t("Firstname")} {t("Lastname")} */}
              </Typography>
              { 
                  !disableedit ? (
                    <Typography
                      sx={{ textTransform: "capitalize", fontSize: "18px" }}
                      variant="button"
                      component="div"
                      gutterBottom
                    >
                      {personal_info.first_name} {personal_info.last_name}
                    </Typography>
                  ):( 
                    <>
                      <TextField 
                        value={personal_info.first_name}
                        variant="outlined"
                        sx={{marginRight: "10px"}}
                        onChange={(event) => {
                          setpersonal_info({...personal_info, first_name: event.target.value});
                        }}
                        onBlur={(event) => {
                          handleEditDataSubmit(event,"Firstname")
                        }}
                      />
                      <TextField 
                        value={personal_info.last_name}
                        variant="outlined"
                        onChange={(event) => {
                          setpersonal_info({...personal_info, last_name: event.target.value});
                        }}
                        onBlur={(event) => {
                          handleEditDataSubmit(event,"Lastname")
                        }}
                      />
                    </>
                )
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        alignItems="left"
        justifyContent="left"
        direction="row"
        item
        container
        sx={{marginTop: "20px"}}
        spacing={4}
      >
        
         <Grid
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            direction="row"
            item
            container
          >

            <Typography
                sx={{ textTransform: "capitalize", fontSize: "18px"}}
                variant="button"
                component="div"
                gutterBottom
            >{t('Username')}</Typography>
            <TextField 
              value={personal_info.user_name}
              disabled
              variant="outlined"
              fullWidth
            />
          </Grid>
         <Grid
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            direction="row"
            item
            container
          >

            <Typography
                sx={{ textTransform: "capitalize", fontSize: "18px"}}
                variant="button"
                component="div"
                gutterBottom
            >{t('Email')}</Typography>
            <TextField 
              value={personal_info.email}
              disabled
              variant="outlined"
              fullWidth
            />
          </Grid>
         <Grid
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            direction="row"
            item
            container
          >

            <Typography
                sx={{ textTransform: "capitalize", fontSize: "18px"}}
                variant="button"
                component="div"
                gutterBottom
            >{t('Telephone')}</Typography>
            <TextField 
              disabled={!disableedit}
              value={personal_info.phone_number}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                setpersonal_info({...personal_info, phone_number: event.target.value});
              }}
              onBlur={(event) => {
                handleEditDataSubmit(event,"Phone Number")
              }}
            />
          </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        alignItems="left"
        justifyContent="left"
        direction="row"
        item
        container
        sx={{marginTop: "5px",}}
        spacing={4}
      >
        
         <Grid
            xs={12}
            sm={8}
            md={8}
            lg={8}
            xl={8 }
            direction="row"
            item
            container
          >

            <Typography
                sx={{ textTransform: "capitalize", fontSize: "18px"}}
                variant="button"
                component="div"
                gutterBottom
            >{t('Address')}</Typography>
            <TextField 
              disabled={!disableedit}
              value={personal_info.address}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                setpersonal_info({...personal_info, address: event.target.value});
              }}
              onBlur={(event) => {
                handleEditDataSubmit(event,"Address")
              }}
            />
          </Grid>
         <Grid
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            direction="row"
            item
            container
          >

            <Typography
                sx={{ textTransform: "capitalize", fontSize: "18px"}}
                variant="button"
                component="div"
                gutterBottom
            >{t('Member Role')}</Typography>
            { personal_info.user_type == 'Member' ?(
                <TextField 
                    value="Customer"
                    variant="outlined"
                    fullWidth
                    disabled
                />
              ):(
                <TextField 
                  value="Employee"
                  variant="outlined"
                  fullWidth
                  disabled
                />
              )
            }
          </Grid>
      </Grid>
   

        {showDialogEditData()}
 
    </Grid>
  );
};

export default HomePage;
