// import { history } from "../index";
import {
    server,
    COMPANY_LIST_FETCHING,
    COMPANY_LIST_SUCCESS,
    COMPANY_LIST_FAILED,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { CompanyList } from "../types/conpany_list.type";
const Swal = require('sweetalert2')

export const setCOMPANYLISTFetchingToState = () => ({
    type: COMPANY_LIST_FETCHING,
});
  
export const setCOMPANYLISTSuccessToState = (payload: CompanyList[]) => ({
    type: COMPANY_LIST_SUCCESS,
    payload,
});
  
export const setCOMPANYLISTFailedToState = () => ({
    type: COMPANY_LIST_FAILED,
});

export const loadCompanyLiest = (member_id :any) => {
    return async(dispatch: any) => {
        dispatch(setCOMPANYLISTFetchingToState());
        try {
            let result = await httpClient.get<any>(`${server.DATASHEET_URL}/listcompany/${member_id}`);
            // console.log("result.data")
            // console.log(result.data)
            dispatch(setCOMPANYLISTSuccessToState(result.data));
        } catch (error) {
            dispatch(setCOMPANYLISTFailedToState());
        }
    };
};
  
 
  