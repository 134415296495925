// import { history } from "../index";
import {
    server,
    REQUEST_EDIT_TYPE_LIST_FETCHING,
    REQUEST_EDIT_TYPE_LIST_SUCCESS,
    REQUEST_EDIT_TYPE_LIST_FAILED,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { RequesEditTypeList } from "../types/requet_edit_type_list.type";
const Swal = require('sweetalert2')

export const setRequestEditTypeListFetchingToState = () => ({
    type: REQUEST_EDIT_TYPE_LIST_FETCHING,
});
  
export const setRequestEditTypeListSuccessToState = (payload: RequesEditTypeList[]) => ({
    type: REQUEST_EDIT_TYPE_LIST_SUCCESS,
    payload,
});
  
export const setRequestEditTypeListFailedToState = () => ({
    type: REQUEST_EDIT_TYPE_LIST_FAILED,
});

export const loadRequestEditTypeList = (req_id:number) => {
    return async(dispatch: any) => {
        dispatch(setRequestEditTypeListFetchingToState());
        try {
            let result = await httpClient.get<any>(`${server.REQUEST}/get-request-list/${req_id}`);
            dispatch(setRequestEditTypeListSuccessToState(result.data));
        } catch (error) {
            dispatch(setRequestEditTypeListFailedToState());
        }
    };
};
  