import {
  FORGOT_CHECK_MAIL_FETCHING,
  FORGOT_CHECK_MAIL_SUCCESS,
  FORGOT_CHECK_MAIL_FAILED,
  FORGOT_SEND_MAIL_FETCHING,
  FORGOT_SEND_MAIL_SUCCESS,
  FORGOT_SEND_MAIL_FAILED,
} from "../services/Constants";

export interface ForgotPassState {
  isFetchingCheckMail: boolean;
  isErrorCheckMail: boolean;
  resultCheckMail: any;

  isFetchingSendMail: boolean;
  isErrorSendMail: boolean;
  resultSendMail: any;
}

const initialState: ForgotPassState = {
  isFetchingCheckMail: false,
  isErrorCheckMail: false,
  resultCheckMail: null,

  isFetchingSendMail: false,
  isErrorSendMail: false,
  resultSendMail: null,
};

export default (state = initialState, { type, payload }: any): ForgotPassState => {
  switch (type) {
    case FORGOT_CHECK_MAIL_FETCHING:
      return { ...state, isFetchingCheckMail: true, isErrorCheckMail: false, resultCheckMail: null };
    case FORGOT_CHECK_MAIL_SUCCESS:
      return { ...state, isFetchingCheckMail: false, isErrorCheckMail: false, resultCheckMail: payload };
    case FORGOT_CHECK_MAIL_FAILED:
      return { ...state, isFetchingCheckMail: false, isErrorCheckMail: true, resultCheckMail: null };
    case FORGOT_SEND_MAIL_FETCHING:
      return { ...state, isFetchingSendMail: true, isErrorSendMail: false, resultSendMail: null };
    case FORGOT_SEND_MAIL_SUCCESS:
      return { ...state, isFetchingSendMail: false, isErrorSendMail: false, resultSendMail: payload };
    case FORGOT_SEND_MAIL_FAILED:
      return { ...state, isFetchingSendMail: false, isErrorSendMail: true, resultSendMail: null };
    default:
      return state;
  }
};
