import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import { Link,useMatch, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../..";
import { server, TOKEN,MEMBER_ID } from "../../../services/Constants";
import { Grid, Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as companyListActions from "../../../actions/company.list.action";
import * as requestListActions from "../../../actions/request.list.action";
import * as requestEditTypeListActions from "../../../actions/requesttypeedit.list.action";
import * as requestEditSubTypeListActions from "../../../actions/requestsubtypeedit.list.action";
import * as loginActions from "../../../actions/login.action";
import * as requestEditActions from "../../../actions/request.edit.actions";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import * as requestDeleteActions from "../../../actions/request.delete.actions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from "dayjs";
const Swal = require('sweetalert2');




export default function RequestSubmitPage() {
    const CompanyListReducer = useSelector((state: RootReducers) => state.companyListReducer);
    const requesEditTypeListReducer = useSelector((state: RootReducers) => state.requesEditTypeListReducer);
    const requesEditTypeSubListReducer = useSelector((state: RootReducers) => state.requesEditTypeSubListReducer);
    const requesListReducer = useSelector((state: RootReducers) => state.requesListReducer);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const match = useMatch("submit-request/:id");
    let req_id = match?.params.id;
    React.useEffect(() => {
        if (!localStorage.getItem(TOKEN)) {
        //   navigate("/login");
        dispatch(loginActions.logouttologinpage(navigate));
        }
        dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
    }, []);
    //load conpany list
    React.useEffect(() => {
        dispatch(companyListActions.loadCompanyLiest(localStorage.getItem(MEMBER_ID)));
    }, []);
    React.useEffect(() => {
        dispatch(requestListActions.loadRequestList(Number(req_id)));
    }, []);

    //load request type list
    React.useEffect(() => {
        dispatch(requestEditTypeListActions.loadRequestEditTypeList(Number(req_id)));
    }, []);

    //load sub request  type list
    // React.useEffect(() => {
    //     dispatch(requestEditSubTypeListActions.loadRequestEditSubTypeList(Number(req_id)));
    // }, []);

    //load data
    React.useEffect(() => {
        requesListReducer.result.forEach((item) => {
            setCompanySelected({th_company_name: item.company_name, id: item.datasheet_id})
            setRemark(item.remark) 
            setRequestDate(dayjs(new Date(item.duedate)))
        })
    }, [requesListReducer.result]);
    React.useEffect(() => {
        let  req_type_edit:any[] = [];
        let index = 0;
        requesEditTypeListReducer.result.forEach((item) =>{
            if(item.check_box){
                req_type_edit[index] = {request_type_id: item.id};
                index += 1;
            }
        })
        setRequestTypeSelected(req_type_edit)
    }, [requesEditTypeListReducer.result]);
    React.useEffect(() => {
        let  req_type_edit:any[] = [];
        let index = 0;
        requesEditTypeSubListReducer.result.forEach((item) =>{
            if(item.check_box){
                req_type_edit[index] = {request_sub_type_id: String(item.id)};
                index += 1;
            }
        })
        setRequestSubTypeSelected(req_type_edit)
    }, [requesEditTypeSubListReducer.result]);
    // data test
    const company_list = CompanyListReducer.result;

    const request_type_list = requesEditTypeListReducer.result;
    const request_sub_type_list = requesEditTypeSubListReducer.result

    //get data input
    const [datasheet_id, setCompanySelected] = React.useState<any>({}); 
    const [statusD, setStatusD] = React.useState("1");
    const [statusS, setStatusS] = React.useState("2");
    const [remark, setRemark] = React.useState("");
    const [req_type, setRequestTypeSelected] = React.useState<any[]>([]);
    const [req_sub_type, setRequestSubTypeSelected] = React.useState<any[]>([]);
    const start = Date.now();
    const [requestDate, setRequestDate] = React.useState<Dayjs | null>(dayjs(start));
    const [requestDateValue, setRequestValue] = React.useState<Dayjs | null>(dayjs(start));
    const setReqestDateValue = (newValue: Dayjs | null) => {
        setRequestDate(newValue);
    };
    //get requestype
    const getRequestTypeSelect = (e:any) => {
        // Destructuring
        const { value, checked } = e.target;
        // console.log(value + " : " +checked)
        // Case 1 : The user checks the box
        if (checked) {
            let data = req_type;
            data.push({request_type_id: value});
            setRequestTypeSelected(data)
        }
        // // Case 2  : The user unchecks the box
        else {
            let filtered = req_type.filter(item=>{
                return item.request_type_id != value;
            });
            setRequestTypeSelected(filtered)
        }

    };
     //get request subtype
    const getRequestSubTypeSelect = (e:any) => {
        // Destructuring
        const { value, checked } = e.target;
        
        // Case 1 : The user checks the box
        if (checked) {
            let data = req_sub_type;
            data.push({request_sub_type_id: value});
            setRequestSubTypeSelected(data)
        }
        // Case 2  : The user unchecks the box
        else {
            let filtered = req_sub_type.filter(item=>{
                return item.request_sub_type_id != value;
            });
            setRequestSubTypeSelected(filtered)
        }   
    };

    // const initialValues: RequesCreate = { 
    //     datasheet_id: datasheet_id, 
    //     req_type: req_type,
    //     req_sub_type: req_sub_type,
    //     remark: remark,
    //     status: status
    // };
    const SaveDraftRequest = (event:any) => {
        event.preventDefault();
        if(datasheet_id.id != 0){
            // dispatch(requestEditActions.editRequestEdit(
            //     Number(req_id),
            //     datasheet_id.id,
            //     req_type,
            //     req_sub_type,
            //     remark,
            //     statusD,
            //     navigate
            // ))
        }else{
            Swal.fire({
                title: "ตรวจสอบข้อมูล",
                text: "กรุณาเลือกประเภทของธุรกิจ",
                icon: "warning",
            });            
        }
    };
    const SaveSubmitRequest = (event:any) => {
        event.preventDefault();
        if(datasheet_id.id != 0){
            // dispatch(requestEditActions.editRequestEdit(
            //     Number(req_id),
            //     datasheet_id.id,
            //     req_type,
            //     req_sub_type,
            //     remark,
            //     statusS,
            //     navigate
            // ))
        }else{
            Swal.fire({
                title: "ตรวจสอบข้อมูล",
                text: "กรุณาเลือกประเภทของธุรกิจ",
                icon: "warning",
            });            
        }
    };
    return (
        <Grid  
        xs={12} sm={12} md={10} lg={5} xl={5} 
        container
        justifyContent="center"
        sx={
            { 
                margin: '0 auto'
            }
        }
        >
            <Card 
                sx={
                    { 
                        borderRadius:'25px',
                        marginTop:2,
                        minWidth: '40%',
                        boxShadow: 'unset',
                        width: '100%',
                    }
                }
            >
                <CardContent>
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                            sx={{
                                borderBottom: '#ABA9A6 solid 2px',
                                marginBottom: '1rem'
                            }}
                        >
                            <Typography 
                                gutterBottom 
                                variant="h5" 
                                component="h2" 
                                sx={{
                                    color:'#4F4A41',
                                    textAlign: "left",
                                    marginTop: 2
                                }}
                            >
                                ข้อมูลคำขอ
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2,
                            }}
                        >
                            <Grid 
                                xs={12}  xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>วันที่ต้องการ</Typography>
                            </Grid>
                            <Grid 
                                xs={12}  xl={3} 
                                alignItems="center"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                    <MobileDatePicker   value={requestDate}
                                        disabled
                                        onChange={setReqestDateValue}
                                     
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{ width:'100%' }} />
                                    </LocalizationProvider>
                            </Grid>
                            <Grid 
                                xs={12} sm={2} md={2} lg={2} xl={2} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                >
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item 
                            sx={
                                { 
                                    backgroundColor: '#CE9461',
                                    color:'#ffffff',
                                    padding: '0.7rem'
                                }
                        }
                        >
                            <Typography
                                sx={{
                                    marginLeft: 1, 
                                }}
                            >
                                ส่วนที่ 1 เลือกข้อมูลธุรกิจ
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={6} lg={4} xl={3} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                className="part-heading"
                            >
                                <Typography>เลือก ข้อมูลธุรกิจ</Typography>
                            </Grid>
                            <Grid 
                                xs={12} sm={12} md={12} lg={7} xl={8} 
                                alignItems="center"
                                justifyContent="center" 
                                direction="column" 
                                item container
                            >
                                <Autocomplete
                                    value={datasheet_id}
                                    onChange={(event, value: any) =>{
                                        setCompanySelected(value)
                                    }} 
                                    disabled
                                    size="small"
                                    clearOnEscape
                                    disablePortal
                                    options={company_list}
                                    getOptionLabel={(options) => options.th_company_name}
                                    sx={{ minWidth: '100%' }}
                                    renderInput={(params) => <TextField {...params} label="--เลือก ข้อมูลบริษัท--"  variant="outlined" 
                                />}
                                />
                            </Grid>
                            <Grid 
                                xs={12} sm={2} md={2} lg={2} xl={2} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                >
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item 
                            sx={
                                    { 
                                        backgroundColor: '#CE9461',
                                        color:'#ffffff',
                                        padding: '0.7rem'
                                    }
                            }
                        >
                            <Typography
                                sx={{
                                    marginLeft: 1 
                                }}
                            >
                                ส่วนที่ 2 ประเภทคำขอ
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2
                            }}
                        >
                            <Grid 
                                 xs={12} sm={12} md={12} lg={12} xl={12} 
                                 alignItems="left"
                                 justifyContent="center" 
                                 direction="column" 
                                 item container
                                 sx = {{
                                     marginLeft:2,
                                 }}
                            >
                                {request_type_list.map((name) => (
                                    <div 
                                        key={name.request_type_name} 
                                    >   <FormControlLabel
                                            disabled
                                            label={name.request_type_name}
                                            control={
                                                <Checkbox 
                                                    defaultChecked={name.check_box} 
                                                    value={name.id}
                                                    onChange={(e)=>{
                                                        getRequestTypeSelect(e)
                                                    }}
                                                />
                                            }
                                        />
                                        
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item 
                            sx={
                                    { 
                                        backgroundColor: '#CE9461',
                                        color:'#ffffff',
                                        padding: '0.7rem'
                                    }
                            }
                        >
                            <Typography
                                sx={{
                                    marginLeft: 1 
                                }}
                            >
                                ส่วนที่ 3 เลือกประเภทคำขอย่อย
                            </Typography>
                        </Grid>
                        <Grid 
                            container 
                            sx = {{
                                marginTop:2
                            }}
                        >
                            <Grid 
                                xs={12} sm={12} md={12} lg={12} xl={12} 
                                alignItems="left"
                                justifyContent="center" 
                                direction="column" 
                                item container
                                sx = {{
                                    marginLeft:2
                                }}
                            >
                                {request_sub_type_list.map((name) => (
                                    <div key={name.request_sub_name}>
                                        <FormControlLabel
                                            disabled
                                            label={name.request_sub_name}
                                            control={
                                                <Checkbox 
                                                    defaultChecked={name.check_box} 
                                                    value={name.id}
                                                    onChange={(e)=>getRequestSubTypeSelect(e)}
                                                />
                                            }
                                        />
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                        <br />
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                        >
                            <TextField 
                                disabled
                                id="outlined-basic" 
                                value={remark} 
                                label="เพิ่มเติม" 
                                variant="outlined" 
                                onChange={(e)=>setRemark(e.target.value)}
                                sx = {{
                                    width: '90%',
                                    margin: 'auto'
                                }}
                            />
                        </Grid>
                    <br />
                    <Grid 
                    container   justifyContent="center"
                    >

                        {/* <Button 
                            variant="outlined"
                            onClick={SaveDraftRequest}
                            color="success"
                            sx={{
                                width: 90
                            }}
                        >
                            Draft
                        </Button>
                        <Button 
                            sx={{
                                marginLeft:2,
                                marginRight: 2,
                                width: 90
                            }}
                            variant="outlined"
                            onClick={SaveSubmitRequest}
                        >
                            Save
                        </Button> */}
                        {/* <Button 
                            sx={{
                                width: 90
                            }}
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                DeleteValue()
                            }}
                        >
                            Cancel
                        </Button> */}
                        <Button 
                            sx={{
                                width: 90,
                                backgroundColor: '#CE9461',
                                '&:hover': {backgroundColor: '#CC704B'}
                            }}
                            variant="contained"
                            onClick={() => {
                                navigate("/my-request")
                            }}
                        >
                            Back
                        </Button>
                        
                    </Grid>
                    <br />
                </CardContent>
            </Card>
        </Grid>

  );
}
