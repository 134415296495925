import {
    server,
    NOTIC_FETCHING,
    NOTIC_SUCCESS,
    NOTIC_FAILED
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { Request } from "../types/request.type";
const Swal = require('sweetalert2')

export const setNoticFetchingToState = () => ({
    type: NOTIC_FETCHING,
});

export const setNoticSuccessToState = (
    payload: any[],) => (
        {
            type: NOTIC_SUCCESS,
            payload,
        }
  );
  
export const setNoticSFailedToState = () => ({
    type: NOTIC_FAILED,
});

export const loadNoticRequest = () => {
    return async(dispatch: any) => {
        dispatch(setNoticFetchingToState());
        getNoticRequest(dispatch)
    };
};
const getNoticRequest = async (dispatch: any) => {
    try {
        const result = await httpClient.get<any[]>(`${server.REQUEST_LOG}/get-request-notic-log`);
        dispatch(setNoticSuccessToState(result.data));
    }   catch (error) {
        dispatch(setNoticSFailedToState());
    }
}