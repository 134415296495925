import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { User } from "../../../types/user.type";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
// import * as loginActions from "../../../../src/services/api";
import * as loginActions from "../../../actions/login.action";
import { useAppDispatch } from "../../..";
import Link from '@mui/material/Link';
import { Paper, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

type LoginPageProps = {
  //
};

const LoginPage: React.FC<any> = () => {
  const loginReducer = useSelector((state: RootReducers) => state.loginReducer);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "center" },
    buttons: { marginTop: 2 },
  };

  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(event)
    event.preventDefault();
  };

  const showFormV1 = ({ handleSubmit, handleChange, isSubmitting, values }: FormikProps<User>) => {
    return (
      <form onSubmit={handleSubmit}>
        <label>Username: </label>
        <input type="text" name="username" id="username" onChange={handleChange} value={values.username} />
        <br />
        <label>Password: </label>
        <input type="text" name="password" id="password" onChange={handleChange} value={values.password} />
        <br />

        <button type="submit" disabled={isSubmitting}>
          Submit
        </button>
        <button onClick={() => navigate("/register")}>Register</button>
      </form>
    );
  };

  const showFormV2 = ({ handleSubmit, handleChange, isSubmitting, values }: FormikProps<User>) => {
    return (
      <form onSubmit={handleSubmit} >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label={t('Email / Username')}
          onChange={handleChange}
          value={values.username}
          autoComplete="email"
          autoFocus
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          label={t('Password')}
          onChange={handleChange}
          value={values.password}
          type={showPassword ? 'password' : 'text'}
          InputProps={{
            endAdornment: <InputAdornment position="end">
            <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            
            </InputAdornment>,
          }}
        />
        <br />

        {loginReducer.isError && <Alert severity="error">{t('Login failed')}</Alert>}
   
          <Button onClick={() => navigate("/forgotpassword")} sx={{ color: '#1E90FF' ,textDecoration:"underline"}}>
            {t('Forgot Password')}
          </Button>
        <Stack direction="row" spacing={0} sx={classes.buttons}>
          <Button
            type="submit" 
            variant="contained" 
            disabled={loginReducer.isFetching} 
            sx={{ backgroundColor: '#EC994B', color:'#ffffff', margin: 'auto', width: '40%' , '&:hover': {backgroundColor: '#E8630A'} }}
          >
            {t('Login')}
          </Button>
        </Stack>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center"
        justifyContent="center" direction="column" item container
        >
          <Stack direction="row" spacing={1} sx={classes.buttons}>
            <Typography>
            {t("Don't have an account")}?  
            </Typography>
            <Link href="/register" color="#1E90FF" >
            {t("Register Now")}
            </Link>
          </Stack>
        </Grid>
        {/* <a onClick={() => navigate("/register")}>
          Register Now
          </a> */}
      </form>
    );
  };

  const initialValues: User = { username: "", password: "" };
  return (
    <>
      <Box sx={classes.root}>
        <Card sx={{ maxWidth: '40rem',width: '100%', marginTop: '6rem' }}>
          <CardContent sx={{border: '#ABA9A6 solid 1px', borderRadius: 0, padding: '2rem 2rem'}}>
            <Typography gutterBottom variant="h5" component="h2" sx={{color:'#4F4A41',textAlign:'center'}}>
            {t('Login')}
            </Typography>
            <Formik
              onSubmit={(values, {}) => {
                dispatch(loginActions.login(values, navigate));
              }}
              initialValues={initialValues}
            >
              {(props) => showFormV2(props)}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default LoginPage;
