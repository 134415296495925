// import { history } from "../index";
import {
    OK,
    VERIFYEMAIL_FAILED,
    VERIFYEMAIL_FETCHING,
    VERIFYEMAIL_SUCCESS,
    VERIFY_CHECK_EMAIL_SUCCESS,
    VERIFY_CHECK_EMAIL_FAIL,
    server,
  } from "../services/Constants";
  import { httpClient } from "../services/httpclient";
  import { ContactUs } from "../types/contactus.type";
  
  export const setVerifyEmailFetchingToState = () => ({
    type: VERIFYEMAIL_FETCHING,
  });
  
  export const setVerifyEmailSuccessToState = (payload: any) => ({
    type: VERIFYEMAIL_SUCCESS,
    payload,
  });
  
  export const setVerifyEmailFailedToState = () => ({
    type: VERIFYEMAIL_FAILED,
  });

  export const setVerifyEmailCheckSuccessToState = (payload: any) => ({
    type: VERIFY_CHECK_EMAIL_SUCCESS,
    payload,
  });

  export const setVerifyEmailCheckFailedToState = () => ({
    type: VERIFY_CHECK_EMAIL_FAIL,
  });
  

  export const confirmemail = (data:{}) => {
    console.log(data)
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setVerifyEmailFetchingToState());
        // connect
        const result = await httpClient.post(server.CONFIRM_EMAIL_URL+'/confirmemail', data);
        console.log(result)
        if (result.status == 200 || result.status == 201) {
          dispatch(setVerifyEmailCheckSuccessToState(result.data));
        } else {
          dispatch(setVerifyEmailCheckFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setVerifyEmailCheckFailedToState());
      }
    };
  };
  
  export const verifyemail = (data:{}) => {
    console.log(data)
    return async (dispatch: any) => {
      try {
        dispatch(setVerifyEmailFetchingToState());
        const result = await httpClient.post(server.CONFIRM_EMAIL_URL+'/verifyemail', data);
        if (result.data && result.data !== null) {
          console.log("abcd")
          dispatch(setVerifyEmailSuccessToState(result.data));
        } else {
          dispatch(setVerifyEmailFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setVerifyEmailFailedToState());
      }
    };
  };
  
  export const sendMailRequest = (data: ContactUs, navigate: any) => {
    return async (dispatch: any) => {
      const result = await httpClient.post(server.CONFIRM_EMAIL_URL+'/sendemailrequest', data);
      console.log("result.data")
      console.log(result.data)
      if (result.data.status == 201) {
        navigate("/dashboard");
      } else {

      }
      
    };
  };