// import { history } from "../index";
import {
    server,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";

export const updateNoticDay = () => {
    return async (dispatch: any) => {
        const result = await httpClient.put(server.CHECKLOGIN + `/update`);
    }
}