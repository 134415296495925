import {
    CALL_API,
  } from "../services/Constants";
  import { Provinces } from "../types/provinces.type";
  
  export interface CallapiState {
    isFetching: boolean;
    isError: boolean;
    result:boolean;
  }
  
  const initialState: CallapiState = {
    isFetching: false,
    isError: false,
    result: false,
  };
  
  export default (
    state = initialState,
    { type, payload }: any
  ): CallapiState => {
    switch (type) {
      case CALL_API:
        return { ...state, isFetching: true, isError: false, result: payload };
      default:
        return state;
    }
  };
  