// import { history } from "../index";
import {
    server,
    REQUEST_LIST_FETCHING,
    REQUEST_LIST_SUCCESS,
    REQUEST_LIST_FAILED,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { RequestList } from "../types/request_list.type";
const Swal = require('sweetalert2')

export const setRequestListFetchingToState = () => ({
    type: REQUEST_LIST_FETCHING,
});
  
export const setRequestListSuccessToState = (payload: RequestList[]) => ({
    type: REQUEST_LIST_SUCCESS,
    payload,
});
  
export const setRequestListFailedToState = () => ({
    type: REQUEST_LIST_FAILED,
});

export const loadRequestList = (request_id:number) => {
    return async(dispatch: any) => {
        // dispatch(setRequestListFetchingToState());
        try {
            let result = await httpClient.get<any>(`${server.REQUEST}/get-request-list/${request_id}`);
            dispatch(setRequestListSuccessToState(result.data));
        } catch (error) {
            dispatch(setRequestListFailedToState());
        }
    };
};
  