import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../../services/Constants";
import * as agreementActions from "../../../../actions/agreement.action";
import * as agreementGroupActions from "../../../../actions/agreement_group.action";
import * as servicetypeActions from "../../../../actions/service_type.action";
import * as requestDeleteActions from "../../../../actions/request.delete.actions";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../../reducers";
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import {  useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN,agreementfileUrl,agreementfilepaymodeUrl } from "../../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./AgreementManagement.css";
import { Search as SearchIcon } from "@mui/icons-material";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import * as loginActions from "../../../../actions/login.action";
import Link from '@mui/material/Link';
// import SearchBar from "material-ui-search-bar";
// const CustomToolbar = (props:any) => {
//     return (
//         <Box>
//             <Grid>
//                 <SearchBar {...props} />
//             </Grid>
//         </Box>
//     )
// }



interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}


type AgreementGroupManagementProps = {
  //
};

const AgreementGroupManagement: React.FC<any> = () => {
  const agreementStateReducer = useSelector((state: RootReducers) => state.agreementStateReducer);
  const agreementGroupStateReducer = useSelector((state: RootReducers) => state.agreementGroupStateReducer);
  const servicetypeStateReducer = useSelector((state: RootReducers) => state.serviceTypeStateReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedAgreement, setSelectedAgreement] = React.useState<any>({});
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dataAddAgreement, setdataAddAgreement] = React.useState({name:"",service_type_id:"",agreement_group_id:"",price:"",pdf_file:"",pdf_file_paymode:""});
  const [imageFile, setImageFile] = React.useState<any[]>([]);
  const [imageFilepaymode, setImageFilepaymode] = React.useState<any[]>([]);
  const [imageFilebase64, setImageFilebase64] = React.useState<any[]>([]);
  const [imageFilebase64paymode, setImageFilebase64paymode] = React.useState<any[]>([]);
  const [dataEditAgreement, setdataEditAgreement] = React.useState({id:0,name:"",service_type_id:"",agreement_group_id:"",price:"",pdf_file:"",pdf_file_paymode:""});
  let [agreementGrouplist, setagreementGrouplist] = React.useState<any>([]);
  let [service_type_list, setservice_type_list] = React.useState<any>([]);
  
  const [openaddagreementgroup, setOpenaddagreementgroup] = React.useState(false);
  const [openeditagreementgroup, setOpeneditagreementgroup] = React.useState(false);

  const [opengroup, setopengroup] = React.useState(false);




  let Alllanguages:any = [
    // {
    //   code: 'en',
    //   name: 'English'
    // },
    // {
    //   code: 'th',
    //   name: 'Thai'
    // }
  ];

  React.useEffect(() => {
    setagreementGrouplist(agreementGroupStateReducer.result)
  }, [agreementGroupStateReducer.result]);

  React.useEffect(() => {
    if(servicetypeStateReducer.result){
      setservice_type_list(servicetypeStateReducer.result);
    }
  }, [servicetypeStateReducer.result]);
  

  const handleFileUpload = (event: any) => {
    let input = event.target;
    let count = input.files.length;
    let index = 0;
      if (input.files) {
        while (count--) {
          let reader = new FileReader();
          reader.onload = (e:any) => {
            // base64
            let newStatebase64 = [];
            newStatebase64[0] = e.target.result;
            setImageFilebase64(newStatebase64)
          };
          if (input.files[index].type === "application/pdf" || input.files[index].type === "image/png" || input.files[index].type === "image/jpeg") {
            // data
            let newState = [];
            newState[0] = input.files[index]
            // setImageFile([...imageFile, input.files[index]]);
            setImageFile(newState)
            setdataAddAgreement({...dataAddAgreement,pdf_file:input.files[index].name});
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
  };

  const handleFileUploadpaymode = (event: any) => {
    let input = event.target;
    let count = input.files.length;
    let index = 0;
      if (input.files) {
        while (count--) {
          let reader = new FileReader();
          reader.onload = (e:any) => {
            // base64
            let newStatebase64 = [];
            newStatebase64[0] = e.target.result;
            setImageFilebase64paymode(newStatebase64)
          };
          if (input.files[index].type === "application/pdf") {
            // data
            let newState = [];
            newState[0] = input.files[index]
            // setImageFile([...imageFile, input.files[index]]);
            setImageFilepaymode(newState)
            setdataAddAgreement({...dataAddAgreement,pdf_file_paymode:input.files[index].name});
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
  };

  const handleFileUploadEdit = (event: any) => {
    let input = event.target;
    let count = input.files.length;
    let index = 0;
      if (input.files) {
        while (count--) {
          let reader = new FileReader();
          reader.onload = (e:any) => {
            // base64
            let newStatebase64 = [];
            newStatebase64[0] = e.target.result;
            setImageFilebase64(newStatebase64)
          };
          if (input.files[index].type === "application/pdf" || input.files[index].type === "image/png" || input.files[index].type === "image/jpeg") {
            // data
            let newState = [];
            newState[0] = input.files[index]
            // setImageFile([...imageFile, input.files[index]]);
            setImageFile(newState)
            setdataEditAgreement({...dataEditAgreement,pdf_file:input.files[index].name});
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
  };

  const handleFileUploadEditpaymode = (event: any) => {
    console.log("editpaymode")
    let input = event.target;
    let count = input.files.length;
    let index = 0;
      if (input.files) {
        while (count--) {
          let reader = new FileReader();
          reader.onload = (e:any) => {
            // base64
            let newStatebase64 = [];
            newStatebase64[0] = e.target.result;
            setImageFilebase64paymode(newStatebase64)
          };
          if (input.files[index].type === "application/pdf") {
            // data
            let newState = [];
            newState[0] = input.files[index]
            // setImageFile([...imageFile, input.files[index]]);
            setImageFilepaymode(newState)
            setdataEditAgreement({...dataEditAgreement,pdf_file_paymode:input.files[index].name});
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
  };

  const handleAddAgreementGroup = () => {
    setdataAddAgreement({name:"",service_type_id:"",agreement_group_id:"",price:"",pdf_file:"",pdf_file_paymode:""})
    let newState:any = [];
    setImageFile(newState);
    setImageFilepaymode(newState);
    // console.log("add");
    setopengroup(true);
    setOpenaddagreementgroup(true);
};

const handleEditAgreementGroup = (data:any) => {
  // console.log(data)
    setdataEditAgreement({id:data.id,name:data.name,service_type_id:data.service_type_id,agreement_group_id:data.group_id,price:data.price,pdf_file:data.pdf_file,pdf_file_paymode:data.pdf_file_paymode});
    let newStatebase64 = [];
    let newStatebase64paymode = [];
    newStatebase64[0] = `${agreementfileUrl}/${data.pdf_file}`;
    newStatebase64paymode[0] = `${agreementfilepaymodeUrl}/${data.pdf_file_paymode}`;
    setImageFilebase64(newStatebase64);
    setImageFile(newStatebase64);
    setImageFilebase64paymode(newStatebase64paymode);
    setImageFilepaymode(newStatebase64paymode);
    // agreementfileUrl
    // console.log("edit");
    if(dataEditAgreement.service_type_id){
      setopengroup(false);
      dispatch(agreementGroupActions.loadAGREEMENTGROUPByservice_type_id(dataEditAgreement.service_type_id));
     
    }
    setOpeneditagreementgroup(true);
};


  const handleCloseaddagreementgroup = () => {
    setOpenaddagreementgroup(false);
  };

  const handleCloseeditagreementgroup = () => {
    setOpeneditagreementgroup(false);
  };

  const handleAddAgreementGroupSubmit = (event:any) => {
    event.preventDefault();
    // console.log("SubmitAdd");
    let formData = new FormData();
   
    if(imageFile.length > 0 && dataAddAgreement.pdf_file && dataAddAgreement.pdf_file_paymode){
      formData.append(`file`, imageFile[0]);
      formData.append(`filepaymode`, imageFilepaymode[0]);
      formData.append(`pdf_file`, dataAddAgreement.pdf_file.replace(/\s/g, '_'));
      formData.append(`pdf_file_paymode`, dataAddAgreement.pdf_file_paymode.replace(/\s/g, '_'));
    }else{
      formData.append(`pdf_file`, "");
      formData.append(`pdf_file_paymode`, "");
      Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("Please Select PDF File")}`,
          icon: "error",
        });
        return;
    }
    
    
    formData.append(`name`, dataAddAgreement.name);
    formData.append(`service_type_id`, dataAddAgreement.service_type_id);
    formData.append(`agreement_group_id`, dataAddAgreement.agreement_group_id);
    formData.append(`price`, dataAddAgreement.price);
    dispatch(
        agreementActions.addAgreement(
          formData,
          navigate
        )
      );
  };
  const handleEditAgreementGroupSubmit = (event:any) => {
    event.preventDefault();
    // console.log("SubmitEdit");
    let formData = new FormData();
    if(imageFile.length > 0 && dataEditAgreement.pdf_file && dataEditAgreement.pdf_file_paymode){
      formData.append(`file`, imageFile[0]);
      formData.append(`filepaymode`, imageFilepaymode[0]);
      formData.append(`pdf_file`, dataEditAgreement.pdf_file.replace(/\s/g, '_'));
      formData.append(`pdf_file_paymode`, dataEditAgreement.pdf_file_paymode.replace(/\s/g, '_'));
    }else{
      formData.append(`pdf_file`, "");
      formData.append(`pdf_file_paymode`, "");
      Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("Please Select PDF File")}`,
          icon: "error",
        });
        return;
    }
    
    formData.append(`name`, dataEditAgreement.name);
    formData.append(`service_type_id`, dataEditAgreement.service_type_id);
    formData.append(`agreement_group_id`, dataEditAgreement.agreement_group_id);
    formData.append(`price`, dataEditAgreement.price);
    dispatch(
        agreementActions.editAgreement(
          dataEditAgreement.id,
          formData,
          navigate
        )
      );
  };

  React.useEffect(() => {
    if (agreementStateReducer.isAdddata) {
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated successfully.")}`,
            icon: "success",
          });
          setOpenaddagreementgroup(false);
          dispatch(agreementActions.setAGREEMENTFetchingToState());
          dispatch(agreementActions.loadAGREEMENT());
      return;
    } else if(agreementStateReducer.isAddError) {
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated unsuccessfully.")}`,
            icon: "error",
          });
          setOpenaddagreementgroup(false);
        dispatch(agreementActions.setAGREEMENTFetchingToState());
        dispatch(agreementActions.loadAGREEMENT());
     
    }else if(agreementStateReducer.isEditdata) {
        Swal.fire({
            title: `${t("Information Saved")}`,
            text: `${t("The information has been saved and updated successfully.")}`,
            icon: "success",
          });
          setOpeneditagreementgroup(false);
        dispatch(agreementActions.setAGREEMENTFetchingToState());
        dispatch(agreementActions.loadAGREEMENT());
     
    }else if(agreementStateReducer.isEditError) {
        Swal.fire({
            title: `${t("Information Saved")}`,
            text: `${t("The information has been saved and updated unsuccessfully.")}`,
            icon: "error",
          });
          setOpeneditagreementgroup(false);
        dispatch(agreementActions.setAGREEMENTFetchingToState());
        dispatch(agreementActions.loadAGREEMENT());
     
    }
    
    else if(agreementStateReducer.isDeletedata) {
        Swal.fire({
            title: `${t("Information Saved")}`,
            text: `${t("The information has been saved and updated successfully.")}`,
            icon: "success",
          });
        dispatch(agreementActions.setAGREEMENTFetchingToState());
        dispatch(agreementActions.loadAGREEMENT());
     
    }else if(agreementStateReducer.isDeleteError) {
        Swal.fire({
            title: `${t("Information Saved")}`,
            text: `${t("The information has been saved and updated unsuccessfully.")}`,
            icon: "error",
          });
        dispatch(agreementActions.setAGREEMENTFetchingToState());
        dispatch(agreementActions.loadAGREEMENT());
     
    }else {
    }
  }, [agreementStateReducer]);
  


  //load data table
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      // navigate("/login");
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
    dispatch(agreementGroupActions.loadAGREEMENT_GROUP());
    dispatch(agreementActions.loadAGREEMENT());
    dispatch(servicetypeActions.setSERVICE_TYPEFetchingToState());
    dispatch(servicetypeActions.loadSERVICE_TYPE());

  }, []);
  React.useEffect(() => {

  }, [agreementStateReducer]);

  const requestColumns: GridColDef[] = [
      {
          headerName: "ID",
          field: "id",
          maxWidth: 120,
          minWidth: 50
      },
      {
          headerName: `${t('Name')}`,
          field: "name",
          flex: 2,
          minWidth: 300
      },
      {
        headerName: `${t('Service Type Name')}`,
        field: "service_type_name",
        flex: 1,
        minWidth: 150
    },
      {
        headerName:`${t('Form Group')}`,
        field: "group",
        flex: 1,
        minWidth: 120
    },
    {
      headerName: `${t('Language Group')}`,
      field: "lang",
      flex: 1,
      minWidth: 120
  },
      
      {
          headerName: "",
          field: ".",
          maxWidth: 150,
          align: "center",
          renderCell: ({ row }: GridRenderCellParams<string>) => (
              <Stack direction="row">
                  <IconButton
                      aria-label="edit"
                      size="large"
                      onClick={() => {
                        handleEditAgreementGroup(row);
                      }}
              >
                      <EditIcon 
                          fontSize="inherit" 
                          sx={{ color: blue[700] }}  
                      />
                  </IconButton>
                  <IconButton
                      disabled={row.status == "Close" || row.status == "Cancel" }
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                          // console.log(row)
                          setSelectedAgreement(row);
                          setOpenDialog(true);
                      }}
                  >
                      <DeleteIcon 
                          fontSize="inherit" 
                          sx={{ color: red[500] }}
                      />
                  </IconButton>
              </Stack>
            ),
      },
  ];

  //search
  const [searchText, setSearchText] = React.useState("");
  const [searchText1, setSearchText1] = React.useState("");
  const [tableData, setTableData] = React.useState<any[]>([]);

  React.useEffect(() => {
      let  dataList:any[] = [];
      let index = 0;
      agreementStateReducer.result.forEach((item) =>{
          dataList[index] = item;
          index += 1;
      })
      setTableData(dataList)
  }, [agreementStateReducer.result]);

  const requestSearch = (searchValue: string) => {
      // const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
      // const filteredRows = agreementStateReducer.result.filter((o: any) => {
      //   return Object.keys(o).some((k: any) => {
      //     return searchRegex.test(o[k].toString());
      //   });
      // });
      const filteredRows = agreementStateReducer.result.filter((row) => {
        return row.name?.toString().toLowerCase().includes(searchValue.toLowerCase()) || row.service_type_name?.toString().toLowerCase().includes(searchValue.toLowerCase());
      });
      setTableData(filteredRows);
  };

  // const cancelSearch = () => {
  //     setSearchText("");
  //     requestSearch(searchText);
  // };
  const handleDeleteConfirm = (AGREEMENT_id:number) => {
      dispatch(agreementActions.deleteAgreementGroup(AGREEMENT_id,navigate));
      setOpenDialog(false);
  };



  const showDialog = () => {
      if (selectedAgreement === null) {
          return "";
      }
  return (
      <Dialog
      fullWidth
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">
            {t("Delete Data")}
             
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">  {t("Confirm Delete Data")} : {selectedAgreement.name}</DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="info">
              ยกเลิก
          </Button>
          <Button onClick={() => handleDeleteConfirm(selectedAgreement.id)} color="primary">
              ยืนยัน
          </Button>
          </DialogActions>
      </Dialog>
      );
  };

  const showDialogAddAgreementGroup = () => {
   
return (
  <Dialog
  disableScrollLock
  fullWidth
  open={openaddagreementgroup}
  onClose={handleCloseaddagreementgroup}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
<form onSubmit={handleAddAgreementGroupSubmit}>
  <DialogTitle id="alert-dialog-title" >
  {t("Add Form")} 
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
    </DialogContentText>
    <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Form')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          size="small"
          onChange={(e) => {
                setdataAddAgreement({...dataAddAgreement,name:e.target.value});
           
          }}
          value={dataAddAgreement.name}
        />
         </Grid>
       </Grid>

       <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Service Type')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
       <Select
          fullWidth
          required
          size="small"
          MenuProps={{ disableScrollLock: true }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dataAddAgreement.service_type_id}
          onChange={(e) => {
            setdataAddAgreement({...dataAddAgreement,service_type_id:e.target.value});
            dispatch(agreementGroupActions.loadAGREEMENTGROUPByservice_type_id(e.target.value));
            setopengroup(false);
      }}
          variant="outlined"
          sx={{mt:2}}
        >
          {service_type_list && 
          (service_type_list.map((x:any, i:any) => (
       
                    <MenuItem key={x.name}  value={x.id} >
                      {x.name}
                     
                    </MenuItem>
                
                    )
                  ))}
        
        </Select>
         </Grid>
       </Grid>
       
       <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Form Group')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
       <Select
          fullWidth
          required
          disabled={opengroup}
          size="small"
          MenuProps={{ disableScrollLock: true }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dataAddAgreement.agreement_group_id}
          onChange={(e) => {
            setdataAddAgreement({...dataAddAgreement,agreement_group_id:e.target.value});
      }}
          variant="outlined"
          sx={{mt:2}}
        >
          {agreementGrouplist && 
          (agreementGrouplist.map((x:any, i:any) => (
       
                    <MenuItem key={x.group}  value={x.id} >
                      {x.group}
                     
                    </MenuItem>
                
                    )
                  ))}
        
        </Select>
         </Grid>
       </Grid>


       <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Price')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="number"
          id="email_address"
          size="small"
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => {
                setdataAddAgreement({...dataAddAgreement,price:e.target.value});
           
          }}
          value={dataAddAgreement.price}
        />
         </Grid>
       </Grid>

       <Grid container sx={{mt:2}} >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('PDF File')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
                      {/* <Button >Upload  <input type="file" accept=".pdf" hidden /> </Button> */}
                      <input hidden accept="application/pdf,image/jpeg,image/png" id="file" multiple={true} type="file"
                        onChange={handleFileUpload} />
                    <label htmlFor="file">
                        <Button variant="outlined"  component="span"  onClick={e => e.stopPropagation()}>
                            Upload
                        </Button>
                    </label>  
                    {dataAddAgreement.pdf_file && 
                    <>
                        <Link  
                        href="#"
                        target="_blank"
                        onClick={() => {
                          let pdf_newTab:any = window.open("");
                          pdf_newTab.document.write(
                            "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                            imageFilebase64[0] +
                              "'></iframe></body></html>"
                          );
                        }} >
                       <Typography  variant="inherit"  component="h6"> {dataAddAgreement.pdf_file} </Typography> </Link></>}
         </Grid>
       </Grid>

       <Grid container sx={{mt:2}} >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('PDF File (Pay Mode)')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
                      {/* <Button >Upload  <input type="file" accept=".pdf" hidden /> </Button> */}
                      <input hidden accept=".pdf" id="filepaymode" multiple={true} type="file"
                        onChange={handleFileUploadpaymode} />
                    <label htmlFor="filepaymode">
                        <Button variant="outlined"  component="span"  onClick={e => e.stopPropagation()}>
                            Upload
                        </Button>
                    </label>  
                    {dataAddAgreement.pdf_file_paymode && 
                    <>
                        <Link  
                        href="#"
                        target="_blank"
                        onClick={() => {
                          let pdf_newTab:any = window.open("");
                          pdf_newTab.document.write(
                            "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                            imageFilebase64paymode[0] +
                              "'></iframe></body></html>"
                          );
                        }} >
                       <Typography  variant="inherit"  component="h6"> {dataAddAgreement.pdf_file_paymode} </Typography> </Link></>}
         </Grid>
       </Grid>

  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseaddagreementgroup}> {t("Cancel")} </Button>
    <Button type="submit" autoFocus>
    {t("Save")} 
    </Button>
  </DialogActions>
  </form>
</Dialog>
    );
};

const showDialogEditAgreementGroup = () => {
   
    return (
      <Dialog
      disableScrollLock
      fullWidth
      open={openeditagreementgroup}
      onClose={handleCloseeditagreementgroup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <form onSubmit={handleEditAgreementGroupSubmit}>
      <DialogTitle id="alert-dialog-title">
      {t("Edit Agreement")} 
      </DialogTitle>
      <DialogContent>
    <DialogContentText id="alert-dialog-description">
    </DialogContentText>
    <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Form')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          size="small"
          onChange={(e) => {
                setdataEditAgreement({...dataEditAgreement,name:e.target.value});
          
           
          }}
          value={dataEditAgreement.name}
        />
         </Grid>
       </Grid>

       <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Service Type')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
       <Select
          fullWidth
          required
          size="small"
          MenuProps={{ disableScrollLock: true }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dataEditAgreement.service_type_id}
          onChange={(e) => {
            setdataEditAgreement({...dataEditAgreement,service_type_id:e.target.value});
            dispatch(agreementGroupActions.loadAGREEMENTGROUPByservice_type_id(e.target.value));
            setopengroup(false);
      }}
          variant="outlined"
          sx={{mt:2}}
        >
          {service_type_list && 
          (service_type_list.map((x:any, i:any) => (
       
                    <MenuItem key={x.name}  value={x.id} >
                      {x.name}
                     
                    </MenuItem>
                
                    )
                  ))}
        
        </Select>
         </Grid>
       </Grid>
       
       <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Form Group')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
       <Select
          fullWidth
          required
          disabled={opengroup}
          size="small"
          MenuProps={{ disableScrollLock: true }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dataEditAgreement.agreement_group_id}
          onChange={(e) => {
            setdataEditAgreement({...dataEditAgreement,agreement_group_id:e.target.value});
      }}
          variant="outlined"
          sx={{mt:2}}
        >
          {agreementGrouplist && 
          (agreementGrouplist.map((x:any, i:any) => (
       
                    <MenuItem key={x.group}  value={x.id} >
                      {x.group}
                     
                    </MenuItem>
                
                    )
                  ))}
        
        </Select>
         </Grid>
       </Grid>

       <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Price')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="number"
          id="email_address"
          size="small"
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => {
                setdataEditAgreement({...dataEditAgreement,price:e.target.value});
          
           
          }}
          value={dataEditAgreement.price}
        />
         </Grid>
       </Grid>

       <Grid container sx={{mt:2}} >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('PDF File')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
                      {/* <Button >Upload  <input type="file" accept=".pdf" hidden /> </Button> */}
                      <input hidden accept="application/pdf,image/jpeg,image/png" id="file" multiple={true} type="file"
                        onChange={handleFileUploadEdit} />
                    <label htmlFor="file">
                        <Button variant="outlined"  component="span"  onClick={e => e.stopPropagation()}>
                            Upload
                        </Button>
                    </label>  
                    {dataEditAgreement.pdf_file && 
                    <>
                        <Link  
                        href="#"
                        target="_blank"
                        onClick={() => {
                          let pdf_newTab:any = window.open("");
                          pdf_newTab.document.write(
                            "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                            imageFilebase64[0] +
                              "'></iframe></body></html>"
                          );
                        }} >
                       <Typography  variant="inherit"  component="h6"> {dataEditAgreement.pdf_file} </Typography> </Link></>}
         </Grid>
       </Grid>

       <Grid container sx={{mt:2}} >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('PDF File (Pay Mode)')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
                      {/* <Button >Upload  <input type="file" accept=".pdf" hidden /> </Button> */}
                      <input hidden accept=".pdf" id="filepaymode" multiple={true} type="file"
                        onChange={handleFileUploadEditpaymode} />
                    <label htmlFor="filepaymode">
                        <Button variant="outlined"  component="span"  onClick={e => e.stopPropagation()}>
                            Upload
                        </Button>
                    </label>  
                    {dataEditAgreement.pdf_file_paymode && 
                    <>
                        <Link  
                        href="#"
                        target="_blank"
                        onClick={() => {
                          let pdf_newTab:any = window.open("");
                          pdf_newTab.document.write(
                            "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                            imageFilebase64paymode[0] +
                              "'></iframe></body></html>"
                          );
                        }} >
                       <Typography  variant="inherit"  component="h6"> {dataEditAgreement.pdf_file_paymode} </Typography> </Link></>}
         </Grid>
       </Grid>

  </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseeditagreementgroup}> {t("Cancel")} </Button>
        <Button type="submit" autoFocus>
        {t("Save")} 
        </Button>
      </DialogActions>
      </form>
    </Dialog>
        );
    };

  return (
    <Container fixed sx={{ mt: 3}} >
      <Box 
      sx={{}}
     >
          {/* Summary Icons */}
          <Grid 
          container item
          justifyContent="center"
          alignItems="center"
          sx={{
              margin: 'auto',
              width: '100%',
              maxWidth: '60rem'
          }}
          ><Typography variant="body1">{t('Form PDF Management Page')}</Typography>

          </Grid>

          <Grid container justifyContent="flex-end">
              <Button 
                  size="medium"
                  variant="contained" 
                  sx={{
                      marginTop: 2,
                      marginLeft:2,
                      marginRight: 2,
                      minWidth: 200,
                      backgroundColor: "#c79059"
                  }}
                  className="requestButton"
                  onClick={() => { handleAddAgreementGroup()}}
              >
                  {t("Add Form")} 
              </Button>
          </Grid>
          <br />
          <Grid>
          <Grid container spacing={2}>
 
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
   <TextField 
   fullWidth 
                  placeholder="ค้นหา..."
                  variant="outlined"
                  size="small"
                  InputProps={{
                      endAdornment: (<IconButton><SearchIcon /></IconButton>)
                  }}
                  onChange={(e)=>{
                      requestSearch(e.target.value)
                      setSearchText(e.target.value)
                  }} 
                  sx={{
                      //  width:500,
                      "& .MuiInput-underline:before": {
                          borderBottom: 1,
                          borderColor: "divider",
                      },
                      marginBottom:2
                  }}
              />
  </Grid>
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
  </Grid>
</Grid>
              
              <DataGrid
                  // components={{ Toolbar: CustomToolbar }}
                  sx={{ backgroundColor: "white"}}  
                  rows={tableData}
                  columns={requestColumns}
                  // componentsProps={{
                  //     toolbar: {
                  //       value: searchText,
                  //       onChange: (searchVal: string) => requestSearch(searchVal),
                  //       onCancelSearch: () => cancelSearch()
                  //     }
                  // }}
                  autoHeight
                  pageSize={10}
                  rowsPerPageOptions={[10]}
              />
          </Grid>
          <br />
          <br />
          {showDialog()}
          {showDialogAddAgreementGroup()}
          {showDialogEditAgreementGroup()}
      </Box>
      </Container>
);
                }
export default AgreementGroupManagement;
