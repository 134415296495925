import * as React from "react";
import "./NotFoundPage.css";
import { Paper, Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import image from "../../../components/layouts/assets/number_404.webp";
import { useNavigate, Link } from "react-router-dom";



const gridStyles = {
  backgroundColor: "blanchedalmond",
  width: 1,
  paddingBottom: 1,
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center"
}));

const NotFoundPage: React.FC<any> = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'ไทย'
    }
  ];
 

  return (
    <Container fixed maxWidth="lg" >

        <Grid
            container
            justifyContent="start"
            className="notfound-container"
        >
            <Grid className='container-text' xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid
                    container
                    justifyContent="start"
                    alignItems="center"
                >
                    <Grid xs={12} sm={12} md={12} lg={6} xl={6} sx={{paddingBottom: "30px"}}>
                        <Typography className="notfound-text-heading" sx={{paddingBottom: "30px"}}>
                            {t(`Oops! Page not found`)}
                        </Typography>
                        <Typography className="notfound-text-descritons" sx={{color: "#c3885e",paddingBottom: "30px"}} >
                            {t("The page you are looking for does not exist or an error occurred.")}
                        </Typography>
                        <Button
                            onClick={() => navigate("/home")}
                            size="medium"
                            sx={{   
                            paddingTop:"15px",
                            paddingBottom:"15px",
                            backgroundColor: "#4d4d4d",
                            "&:hover": {
                                backgroundColor: "#605f5f",
                            },
                            width: "280px",
                        }}
                      >
                        <Typography sx={{fontSize: "18px",fontWeight: "700", fontFamily: "Arial",color: "white"}} >{t("back to homepage")}</Typography>
                      </Button>
                    </Grid>
                    <Grid xs={0} sm={0} md={0} lg={1} xl={1} />
                    <Grid xs={12} sm={12} md={12} lg={5} xl={5} display="flex" justifyContent="center">
                        <img src={image} className="img-about" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>

  );

};


export default NotFoundPage;
