import {
    REQUEST_EDIT_SUB_TYPE_LIST_FETCHING,
    REQUEST_EDIT_SUB_TYPE_LIST_SUCCESS,
    REQUEST_EDIT_SUB_TYPE_LIST_FAILED,
} from "../services/Constants";
import { RequesEditSubTypeList } from "../types/requet_edit_sub_type_list.type";
      
    export interface RequesEditSubTypeListState {
        result: RequesEditSubTypeList[];
        isFetching: boolean;
        isError: boolean;
    }
      
    const initialState: RequesEditSubTypeListState = {
        result: [],
        isFetching: false,
        isError: false,
    };
      
export default (state = initialState, { type, payload}: any) => {
    switch (type) {
        case REQUEST_EDIT_SUB_TYPE_LIST_FETCHING:
            return { ...state, result: [], isFetching: true, isError: true };
        case REQUEST_EDIT_SUB_TYPE_LIST_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false };
        case REQUEST_EDIT_SUB_TYPE_LIST_FAILED:
            return { ...state, result: [], isFetching: false, isError: true };
        default:
            return state;
        }
    };
      