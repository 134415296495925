import {
  RESET_CHECK_PASSWORD_MATCH_SUCCESS,
  RESET_CHECK_PASSWORD_MATCH_FAILED,
  RESET_UPDATE_NEW_PASSWORD_SUCCESS,
  RESET_UPDATE_NEW_PASSWORD_FAILED,

  } from "../services/Constants";
  const Swal = require('sweetalert2')
  
  export interface ResetState {
    isErrorCheckPasswordMatch: boolean;
    isErrorUpdate: boolean;
    result: any;
  }
  
  const initialState: ResetState = {
    isErrorCheckPasswordMatch: false,
    isErrorUpdate: false,
    result: null,
  };
  
  export default (state = initialState, { type, payload }: any): ResetState => {
    switch (type) {
      case RESET_CHECK_PASSWORD_MATCH_SUCCESS:
        return { ...state, isErrorCheckPasswordMatch: false, isErrorUpdate: false, result: null };
      case RESET_CHECK_PASSWORD_MATCH_FAILED:
        return { ...state, isErrorCheckPasswordMatch: true, isErrorUpdate: false, result: null };
      case RESET_UPDATE_NEW_PASSWORD_SUCCESS:
        return { ...state, isErrorCheckPasswordMatch: false, isErrorUpdate: false, result: null };
      case RESET_UPDATE_NEW_PASSWORD_FAILED:
        return { ...state, isErrorCheckPasswordMatch: false, isErrorUpdate: true, result: null };
      default:
        return state;
    }
  };
  