import {
  AGREEMENT_GROUP_FETCHING,
  AGREEMENT_GROUP_SUCCESS,
  AGREEMENT_GROUP_ADD_SUCCESS,
  AGREEMENT_GROUP_ADD_FAILED,
  AGREEMENT_GROUP_EDIT_SUCCESS,
  AGREEMENT_GROUP_EDIT_FAILED,
  AGREEMENT_GROUP_DELETE_SUCCESS,
  AGREEMENT_GROUP_DELETE_FAILED,
  AGREEMENT_GROUP_FAILED,
  AGREEMENT_GROUP_CLEAR,
  } from "../services/Constants";
  import { AgreementGroup } from "../types/agreement_group.type";
    
    export interface AgreementGroupState {
        result: AgreementGroup[];
        isFetching: boolean;
        isError: boolean;
        isAdddata: boolean,
        isEditdata: boolean,
        isAddError: boolean;
        isEditError: boolean;
        isDeletedata: boolean;
        isDeleteError: boolean;
    }
    
    const initialState: AgreementGroupState = {
        result: [],
        isFetching: false,
        isError: false,
        isAdddata: false,
        isEditdata: false,
        isAddError: false,
        isEditError: false,
        isDeletedata: false,
        isDeleteError: false,
    };
    
    export default (state = initialState, { type, payload}: any) => {
      switch (type) {
        case AGREEMENT_GROUP_FETCHING:
          return { ...state, result: [], isFetching: true,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: false};
        case AGREEMENT_GROUP_SUCCESS:
          return { 
            ...state, 
            result: payload,
            isFetching: false,
            isAdddata: false,
            isEditdata: false,
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
          case AGREEMENT_GROUP_ADD_SUCCESS:
          return { 
            ...state, 
            isFetching: false,
            isAdddata: true,
            isEditdata: false, 
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
          case AGREEMENT_GROUP_EDIT_SUCCESS:
          return { 
            ...state, 
            isFetching: false, 
            isAdddata: false,
            isEditdata: true, 
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
        case AGREEMENT_GROUP_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false,
             isError: true,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: false};
        case AGREEMENT_GROUP_ADD_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false,
             isError: false,isAddError: true,isEditError: false,isDeletedata: false,isDeleteError: false};
          case AGREEMENT_GROUP_EDIT_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: true,isDeletedata: false,isDeleteError: false};
            case AGREEMENT_GROUP_DELETE_SUCCESS:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: true,isDeleteError: false};
            case AGREEMENT_GROUP_DELETE_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: true};
        default:
          return state;
      }
      
    };
    