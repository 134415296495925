// import { history } from "../index";
import {
    DepartmentManagement_FETCHING,
    DepartmentManagement_SUCCESS,
    DepartmentManagement_ADD_SUCCESS,
    DepartmentManagement_ADD_FAILED,
    DepartmentManagement_EDIT_SUCCESS,
    DepartmentManagement_EDIT_FAILED,
    DepartmentManagement_DELETE_SUCCESS,
    DepartmentManagement_DELETE_FAILED,
    DepartmentManagement_FAILED,
    DepartmentManagement_CLEAR,
    server,
    MEMBER_ID,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { DepartmentManagement } from "../types/departmentmanagement.type";
const Swal = require('sweetalert2')

export const setDepartment_ManagementFetchingToState = () => ({
    type: DepartmentManagement_FETCHING,
});
  
export const setDepartment_ManagementSuccessToState = (
    payload: DepartmentManagement[],) => (
        {
            type: DepartmentManagement_SUCCESS,
            payload,
        }
);

export const setDepartment_ManagementAddSuccessToState = (payload: any) => ({
            type: DepartmentManagement_ADD_SUCCESS,
            payload,
});

export const setDepartment_ManagementAddFailedToState = () => ({
    type: DepartmentManagement_ADD_FAILED,
});

export const setDepartment_ManagementEditSuccessToState = (payload: any) => ({
    type: DepartmentManagement_EDIT_SUCCESS,
    payload,
});

export const setDepartment_ManagementEditFailedToState = () => ({
    type: DepartmentManagement_EDIT_FAILED,
});

export const setDepartment_ManagementDeleteSuccessToState = (payload: any) => ({
    type: DepartmentManagement_DELETE_SUCCESS,
    payload,
});

export const setDepartment_ManagementDeleteFailedToState = () => ({
    type: DepartmentManagement_DELETE_FAILED,
});
  
export const setDepartment_ManagementFailedToState = () => ({
    type: DepartmentManagement_FAILED,
});

export const setDepartment_ManagementClearToState = () => ({
    type: DepartmentManagement_CLEAR,
});
  

export const loadDepartment_Management = () => {
    return async(dispatch: any) => {
        dispatch(setDepartment_ManagementFetchingToState());
        getDataDepartment_Management(dispatch)
    };
};

const getDataDepartment_Management = async (dispatch: any) => {
    try {
        const result = await httpClient.get<DepartmentManagement[]>(`${server.DEPARTMENT}/getAllActive`);
        dispatch(setDepartment_ManagementSuccessToState(
            result.data 
        ));
    }   catch (error) {
        dispatch(setDepartment_ManagementFailedToState());
    }
}
  
  
export const addDepartmentManagement = (data: any, navigate: any) => {
    console.log(data)
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setDepartment_ManagementFetchingToState());
        // connect
        const result = await httpClient.post(server.DEPARTMENT+"/add", data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setDepartment_ManagementAddSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setDepartment_ManagementAddFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setDepartment_ManagementAddFailedToState());
      }
    };
  };

  export const editDepartmentManagement = (data: any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setDepartment_ManagementFetchingToState());
        // connect
        const result = await httpClient.put(`${server.DEPARTMENT}/${data.id}`, data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setDepartment_ManagementEditSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setDepartment_ManagementEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setDepartment_ManagementEditFailedToState());
      }
    };
  };


  export const deleteDepartmentManagement = (Department_Management_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.DEPARTMENT + `/${Department_Management_id}`);
            console.log(result);
            if (result.status == 204) {
               dispatch(setDepartment_ManagementDeleteSuccessToState(result.data));
             } else {
               dispatch(setDepartment_ManagementDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setDepartment_ManagementDeleteFailedToState());
        }
    };
};
  