// import { history } from "../index";
import {
    LOGIN_LOGS_FETCHING,
    LOGIN_LOGS_SUCCESS,
    LOGIN_LOGS_FAILED,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { GeneralMenu } from "../types/generalmenu.type";
const Swal = require('sweetalert2')

export const setLOGIN_LOGSFetchingToState = () => ({
    type: LOGIN_LOGS_FETCHING,
});
  
export const setLOGIN_LOGSSuccessToState = (
    payload: GeneralMenu[],) => (
        {
            type: LOGIN_LOGS_SUCCESS,
            payload,
        }
);

export const setLOGIN_LOGSFailedToState = () => ({
    type: LOGIN_LOGS_FAILED,
});



export const loadLOGIN_LOGS = () => {
    return async(dispatch: any) => {
        dispatch(setLOGIN_LOGSFetchingToState());
        getDataLOGIN_LOGS(dispatch)
    };
};

// export const loadLOGIN_LOGS_Search = (query:any) => {
//   return async(dispatch: any) => {
//       dispatch(setLOGIN_LOGSFetchingToState());
//       getDataLOGIN_LOGS_Search(query,dispatch);
//   };
// };

const getDataLOGIN_LOGS = async (dispatch: any) => {
    try {
        const result = await httpClient.get<[]>(`${server.LOGINOUTLOG}/getAll`);
        dispatch(setLOGIN_LOGSSuccessToState(
            result.data 
        ));
    }   catch (error) {
        dispatch(setLOGIN_LOGSFailedToState());
    }
}

export const getDataLOGIN_LOGS_Search = (query: any, navigate: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(setLOGIN_LOGSFetchingToState());
      const result = await httpClient.get<[]>(`${server.LOGINOUTLOG}/getSearchData`,query);
      dispatch(setLOGIN_LOGSSuccessToState(
          result.data 
      ));
         
    } catch (error) {
      dispatch(setLOGIN_LOGSFailedToState());
    }
  };
};
  
