import {
  SERVICE_TYPE_FETCHING,
  SERVICE_TYPE_SUCCESS,
  SERVICE_TYPE_ADD_SUCCESS,
  SERVICE_TYPE_ADD_FAILED,
  SERVICE_TYPE_EDIT_SUCCESS,
  SERVICE_TYPE_EDIT_FAILED,
  SERVICE_TYPE_DELETE_SUCCESS,
  SERVICE_TYPE_DELETE_FAILED,
  SERVICE_TYPE_FAILED,
  SERVICE_TYPE_CLEAR,
  SERVICE_TYPE_SUCCESS_All_SERVICE,
  } from "../services/Constants";
  import { ServiceType } from "../types/service_type.type";
    
    export interface ServiceTypeState {
        result: ServiceType[];
        resultallservices: ServiceType[];
        isFetching: boolean;
        isError: boolean;
        isAdddata: boolean,
        isEditdata: boolean,
        isAddError: boolean;
        isEditError: boolean;
        isDeletedata: boolean;
        isDeleteError: boolean;
    }
    
    const initialState: ServiceTypeState = {
        result: [],
        resultallservices: [],
        isFetching: false,
        isError: false,
        isAdddata: false,
        isEditdata: false,
        isAddError: false,
        isEditError: false,
        isDeletedata: false,
        isDeleteError: false,
    };
    
    export default (state = initialState, { type, payload}: any) => {
      switch (type) {
        case SERVICE_TYPE_FETCHING:
          return { ...state, result: [], isFetching: true,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: false};
        case SERVICE_TYPE_SUCCESS:
          return { 
            ...state, 
            result: payload,
            isFetching: false,
            isAdddata: false,
            isEditdata: false,
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
          case SERVICE_TYPE_SUCCESS_All_SERVICE:
          return { 
            ...state, 
            resultallservices: payload,
            isFetching: false,
            isAdddata: false,
            isEditdata: false,
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
          case SERVICE_TYPE_ADD_SUCCESS:
          return { 
            ...state, 
            isFetching: false,
            isAdddata: true,
            isEditdata: false, 
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
          case SERVICE_TYPE_EDIT_SUCCESS:
          return { 
            ...state, 
            isFetching: false, 
            isAdddata: false,
            isEditdata: true, 
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
        case SERVICE_TYPE_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false,
             isError: true,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: false};
        case SERVICE_TYPE_ADD_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false,
             isError: false,isAddError: true,isEditError: false,isDeletedata: false,isDeleteError: false};
          case SERVICE_TYPE_EDIT_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: true,isDeletedata: false,isDeleteError: false};
            case SERVICE_TYPE_DELETE_SUCCESS:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: true,isDeleteError: false};
            case SERVICE_TYPE_DELETE_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: true};
        default:
          return state;
      }
      
    };
    