// import { history } from "../index";
import { AnyARecord } from "dns";
import {
    MESSAGE_FETCHING,
    MESSAGE_SUCCESS,
    MESSAGE_ADD_SUCCESS,
    MESSAGE_ADD_FAILED,
    MESSAGE_EDIT_SUCCESS,
    MESSAGE_EDIT_FAILED,
    MESSAGE_DELETE_SUCCESS,
    MESSAGE_DELETE_FAILED,
    MESSAGE_FAILED,
    MESSAGE_CLEAR,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { Massage } from "../types/massage.type";
import i18n from 'i18next';
const Swal = require('sweetalert2')
export const Alertpopup = (lang:any) => {
  return (
    Swal.fire({
      title: `${i18n.t(`${lang}`)}`,
      text: `${i18n.t(`${lang}`)}`,
        icon: "error",
      })
   );
}
export const setMassageFetchingToState = () => ({
    type: MESSAGE_FETCHING,
});
  
export const setMassageSuccessToState = (
    payload: Massage[],) => (
        {
            type: MESSAGE_SUCCESS,
            payload,
        }
);


export const setMassageAddSuccessToState = (payload: any) => ({
            type: MESSAGE_ADD_SUCCESS,
            payload,
});

export const setMassageAddFailedToState = () => ({
    type: MESSAGE_ADD_FAILED,
});

export const setMassageEditSuccessToState = (payload: any) => ({
    type: MESSAGE_EDIT_SUCCESS,
    payload,
});

export const setMassageEditFailedToState = () => ({
    type: MESSAGE_EDIT_FAILED,
});

export const setMassageDeleteSuccessToState = (payload: any) => ({
    type: MESSAGE_DELETE_SUCCESS,
    payload,
});

export const setMassageDeleteFailedToState = () => ({
    type: MESSAGE_DELETE_FAILED,
});
  
export const setMassageFailedToState = () => ({
    type: MESSAGE_FAILED,
});


export const setMassageClearToState = () => ({
    type: MESSAGE_CLEAR,
});
  

export const loadRequestMassage = (req_id:number) => {
    return async(dispatch: any) => {
        dispatch(setMassageFetchingToState());
        getDataMassage(req_id,dispatch)
    };
};

const getDataMassage = async (req_id:number,dispatch: any) => {
    try {
        const result = await httpClient.get<any[]>(`${server.Massage}/request-message/${req_id}`);
        dispatch(setMassageSuccessToState(result.data));
    }   catch (error) {
        dispatch(setMassageFailedToState());
    }
}

export const loadUserById = (user_id:number) => {
  return async(dispatch: any) => {
      dispatch(setMassageFetchingToState());
      getDataUserByID(dispatch,user_id)
  };
};


const getDataUserByID = async (dispatch: any, user_id:number) => {
  try {
      const result = await httpClient.get<Massage[]>(`${server.User}/getbyid/${user_id}`);
      dispatch(setMassageSuccessToState(result.data));
  }   catch (error) {
      dispatch(setMassageFailedToState());
  }
}

export const addRequestMassage = (data: any, navigate: any) => {
    return async (dispatch: any) => {
        try {
            // begin connecting...
            dispatch(setMassageFetchingToState());

            const result = await httpClient.post(server.Massage + "/add", data);

            if (result.status == 200 || result.status == 201) {
                dispatch(setMassageAddSuccessToState(result.data));
                // navigate(`/my-request-select/${result.data.request_id}`);
            } else {

                dispatch(setMassageAddFailedToState());
            }

        } catch (error) {
            dispatch(setMassageAddFailedToState());
        }
    };
  };

  export const editUsers = (data: any,user_id:number, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setMassageFetchingToState());
        // connect
        const result = await httpClient.put(`${server.User}/${user_id}`, data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setMassageEditSuccessToState(result.data));
           navigate("/general-users");
        } else if(result.status == 204){
            dispatch(Alertpopup("Password Incorrect"));
        } else {
          dispatch(setMassageEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setMassageEditFailedToState());
      }
    };
  };


  export const deleteUsers = (user_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.User + `/${user_id}`);
            if (result.status == 204) {
               dispatch(setMassageDeleteSuccessToState(result.data));
             } else {
               dispatch(setMassageDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setMassageDeleteFailedToState());
        }
    };
};
  