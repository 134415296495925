import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import * as forgotPasswordAction from "../../../actions/forgot.pass.action";
import { useAppDispatch } from "../../..";
import { FORGOT_PASS } from "../../../types/member.type";
import Link from '@mui/material/Link';
import { useTranslation } from "react-i18next";

type ForgotPasswordPageProps = {
  //
};

const ForgotPasswordPage: React.FC<any> = () => {
  const forgotPassReducer = useSelector((state: RootReducers) => state.forgotPassReducer);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "center" },
    buttons: { justifyContent: "center",marginBottom:"1.5rem", marginTop:"0.5rem"},
  };

  const { t, i18n } = useTranslation();

  const showFormV2 = ({ handleSubmit, handleChange, isSubmitting, values }: FormikProps<FORGOT_PASS>) => {
    return (
      <form onSubmit={handleSubmit}>
        <Stack sx={{alignItems: "center"}}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('Email')}
            onChange={handleChange}
            value={values.email}
          />
        </Stack>
        <br />

        {forgotPassReducer.isErrorCheckMail && <Alert severity="error">{t(`Email Not Found`)}</Alert>}
        {forgotPassReducer.isErrorSendMail && <Alert severity="error">{t(`Send Email failed`)}</Alert>}

        <Stack direction="row" spacing={2} sx={classes.buttons}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={forgotPassReducer.isFetchingCheckMail}
            sx={{backgroundColor: '#EC994B', color:'#ffffff', margin: 'auto', width: '20%' , '&:hover': {backgroundColor: '#E8630A'} }}
          >
            {t('Send')}
          </Button>
        </Stack>
        <Stack sx={{justifyContent: "center",textAlign:'center'}}>
          <Button onClick={() => navigate("/login")} sx={{ color: '#1E90FF'}}>
            {"< "}{t('Back To Login')}
          </Button>
        </Stack>
      </form>
    );
  };

  const initialValues: FORGOT_PASS = { email: "" };
  return (
    <>
      <Box sx={classes.root}>
        <Card sx={{ maxWidth: '40rem',width: '90%', marginTop: '6rem' }}>
          <CardContent sx={{border: '#ABA9A6 solid 1px', borderRadius: 0, padding: '2rem 2rem'}}>
            <Typography gutterBottom variant="h5" component="h2" sx={{color:'#4F4A41',textAlign:'center'}}>
              {t('Forgot Password')}
            </Typography>
            <Typography sx={{ fontSize: "16px",paddingTop: '1rem',paddingBottom:"0.5rem",textAlign:"center",color:"#4F4A41"}}>
              {t(`Enter your email and we'll send you a link to reset your password`)}
            </Typography>
            <Formik
              onSubmit={(values, {}) => {
                // dispatch(loginActions.login(values, navigate));
                dispatch(forgotPasswordAction.checkEmailToResetPassword(values,navigate));
              }}
              initialValues={initialValues}
            >
              {(props) => showFormV2(props)}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default ForgotPasswordPage;
