import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  REQUEST_COUNT_DRAFT,
} from "../services/Constants";
import { Request } from "../types/request.type";
  
  export interface RequestState {
      result: Request[];
      res_skip: any[];
      isFetching: boolean;
      isError: boolean;
      res_count: number
      oper_count: number
      sucess_count: number
  }
  
  const initialState: RequestState = {
      result: [],
      res_skip: [],
      isFetching: false,
      isError: false,
      res_count: 0,
      oper_count: 0,
      sucess_count: 0,
  };
  
  export default (state = initialState, { type, payload,res_skip, res_count, oper_count, sucess_count}: any) => {
    switch (type) {
      case REQUEST_FETCHING:
        return { ...state, result: [], isFetching: true, isError: false};
      case REQUEST_SUCCESS:
        return { 
          ...state, 
          result: payload, 
          res_skip: res_skip,
          res_count: res_count, 
          oper_count: oper_count, 
          sucess_count: sucess_count, 
          isFetching: false, 
          isError: false,
        };
      case REQUEST_FAILED:
        return { ...state, result: [], isFetching: false, isError: true};
      default:
        return state;
    }
  };
  