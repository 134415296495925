// import { history } from "../index";
import {
    CALL_API,
    server,
    TOKEN,
  } from "../services/Constants";
  import { httpClient } from "../services/httpclient";
  import * as menuconfigActions from "../actions/menuconfig.action";
  import * as loginActions from "../actions/login.action";
  
  
  export const setCheckCallApiToState = (payload: Boolean) => ({
    type: CALL_API,
    payload
  });

  export const getCheckApi = (data:Boolean) => {
    return async (dispatch: any) => {
        console.log("เข้า action" + data)
        dispatch(setCheckCallApiToState(data));
    };
  };

  export const logout = () => {
    return async (dispatch: any) => {
      localStorage.removeItem(TOKEN);
      localStorage.clear();
      localStorage.setItem('i18nextLng', 'en');
      dispatch(loginActions.setLogoutToState());
      dispatch(menuconfigActions.loadresetdataMENU_CONFIG());  
      window.location.href = "/home"
  };
  };
  