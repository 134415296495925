import {
    REQUEST_TYPE_LIST_FETCHING,
    REQUEST_TYPE_LIST_SUCCESS,
    REQUEST_TYPE_LIST_FAILED,
    REQUEST_TYPE_ADD_SUCCESS,
    REQUEST_TYPE_ADD_FAILED,
    REQUEST_TYPE_EDIT_SUCCESS,
    REQUEST_TYPE_EDIT_FAILED,
    REQUEST_TYPE_DELETE_SUCCESS,
    REQUEST_TYPE_DELETE_FAILED
} from "../services/Constants";
import { RequesTypeList } from "../types/requet_type_list.type";
      
    export interface RequesTypeListState {
        result: RequesTypeList[];
        isFetching: boolean;
        isError: boolean;
        isAdd: boolean;
        isAddError: boolean;
        isEdit: boolean;
        isEditError: boolean;
        isDelete: boolean;
        isDeleteError: boolean;
    }
      
    const initialState: RequesTypeListState = {
        result: [],
        isFetching: false,
        isError: false,
        isAdd: false,
        isAddError: false,
        isEdit: false,
        isEditError: false,
        isDelete: false,
        isDeleteError: false,
    };
      
export default (state = initialState, { type, payload}: any) => {
    switch (type) {
        case REQUEST_TYPE_LIST_FETCHING:
            return { ...state, result: [], isFetching: true,isAdd: false,isAddError: false, isEdit: false,isEditError: false, isError: true,isDelete: false,isDeleteError: false };
        case REQUEST_TYPE_LIST_SUCCESS:
            return { ...state, result: payload, isFetching: false, isAdd: false,isAddError: false, isEdit: false,isEditError: false, isError: false,isDelete: false,isDeleteError: false };
        case REQUEST_TYPE_LIST_FAILED:
            return { ...state, result: [], isFetching: false, isAdd: false, isAddError: false, isEdit: false,isEditError: false, isError: false,isDelete: false,isDeleteError: false };
        case REQUEST_TYPE_ADD_SUCCESS:
            return { ...state, result: [], isFetching: false, isAdd: true,isAddError: false,isEdit: false,isEditError: false, isError: false ,isDelete: false,isDeleteError: false};
        case REQUEST_TYPE_ADD_FAILED:
            return { ...state, result: [], isFetching: false, isAdd: false,isAddError: true,isEdit: false,isEditError: false, isError: false ,isDelete: false,isDeleteError: false};
        case REQUEST_TYPE_EDIT_SUCCESS:
            return { ...state, result: [],isFetching: false, isAdd: false, isAddError: false,isEdit: true,isEditError: false, isError: false ,isDelete: false,isDeleteError: false};
        case REQUEST_TYPE_EDIT_FAILED:
            return { ...state, result: [], isFetching: false, isAdd: false ,isAddError: false , isEdit: false,isEditError: true,isError: false ,isDelete: false,isDeleteError: false};
        case REQUEST_TYPE_DELETE_SUCCESS:
            return { ...state, result: [],isFetching: false, isAdd: false, isAddError: false,isEdit: false,isEditError: false, isError: false,isDelete: true,isDeleteError: false };
        case REQUEST_TYPE_DELETE_FAILED:
            return { ...state, result: [], isFetching: false, isAdd: false ,isAddError: false , isEdit: false,isEditError: false,isError: false ,isDelete: false,isDeleteError: true};
        default:
            return state;
        }
    };
      