// import { history } from "../index";
import {
    MENU_CONFIG_FETCHING,
    MENU_CONFIG_SUCCESS,
    MENU_CONFIG_ADD_SUCCESS,
    MENU_CONFIG_ADD_FAILED,
    MENU_CONFIG_EDIT_SUCCESS,
    MENU_CONFIG_EDIT_FAILED,
    MENU_CONFIG_DELETE_SUCCESS,
    MENU_CONFIG_DELETE_FAILED,
    MENU_CONFIG_FAILED,
    MENU_CONFIG_CLEAR,
    MENU_CONFIG_FIND_BY_ID,
    MENU_CONFIG_FIND_BY_ID_FOR_HEADER,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { Menuconfig } from "../types/menuconfig.type";
import * as loginActions from "../actions/login.action";
const Swal = require('sweetalert2')

export const setMENU_CONFIGFetchingToState = () => ({
    type: MENU_CONFIG_FETCHING,
});
  
export const setMENU_CONFIGSuccessToState = (
    payload: Menuconfig[],) => (
        {
            type: MENU_CONFIG_SUCCESS,
            payload,
        }
);

export const setMENU_CONFIGFindByIdToState = (payload: Menuconfig[],) => (
      {
          type: MENU_CONFIG_FIND_BY_ID,
          payload,
      }
);

export const setMENU_CONFIGFindByIdforHeaderToState = (payload: Menuconfig[],) => (
  {
      type: MENU_CONFIG_FIND_BY_ID_FOR_HEADER,
      payload,
  }
);

export const setMENU_CONFIGAddSuccessToState = (payload: any) => ({
            type: MENU_CONFIG_ADD_SUCCESS,
            payload,
});

export const setMENU_CONFIGAddFailedToState = () => ({
    type: MENU_CONFIG_ADD_FAILED,
});

export const setMENU_CONFIGEditSuccessToState = (payload: any) => ({
    type: MENU_CONFIG_EDIT_SUCCESS,
    payload,
});

export const setMENU_CONFIGEditFailedToState = () => ({
    type: MENU_CONFIG_EDIT_FAILED,
});

export const setMENU_CONFIGDeleteSuccessToState = (payload: any) => ({
    type: MENU_CONFIG_DELETE_SUCCESS,
    payload,
});

export const setMENU_CONFIGDeleteFailedToState = () => ({
    type: MENU_CONFIG_DELETE_FAILED,
});
  
export const setMENU_CONFIGFailedToState = () => ({
    type: MENU_CONFIG_FAILED,
});

export const setMENU_CONFIGClearToState = () => ({
    type: MENU_CONFIG_CLEAR,
});
  

export const loadresetdataMENU_CONFIG = () => {
  return async(dispatch: any) => {
      dispatch(setMENU_CONFIGFetchingToState());
  };
};

export const loadMENU_CONFIG = () => {
    return async(dispatch: any) => {
        dispatch(setMENU_CONFIGFetchingToState());
        getDataMENU_CONFIG(dispatch)
    };
};

const getDataMENU_CONFIG = async (dispatch: any) => {
    try {
        const result = await httpClient.get<Menuconfig[]>(`${server.MENU_CONFIG}/getAll`);
        dispatch(setMENU_CONFIGSuccessToState(
            result.data 
        ));
    }   catch (error) {
        dispatch(setMENU_CONFIGFailedToState());
    }
}

export const getMENU_CONFIGFindByroleId = (id: number) => {
  return async (dispatch: any) => {
    try {
      // begin connecting...
      // dispatch(setMENU_CONFIGFetchingToState());
      // connect
      const result = await httpClient.get(server.MENU_CONFIG + `/getByrole_id/${id}`);
      if (result.status === 200) {
        // result.data.unshift({id: 0,code: "00",name_en: "-- select provinces --",name_th: "-- เลือกจังหวัด --",geography_id: 0,created_at: null,updatedAt:  null})
        // setTimeout(() => {
          dispatch(setMENU_CONFIGFindByIdToState(result.data));
          // alert("Provinces Successfully");
          // navigate("/login");
        // }, 1000);
      } else {
        dispatch(setMENU_CONFIGFetchingToState());
      }
    } catch (error) {
      // error
      dispatch(setMENU_CONFIGFetchingToState());
    }
  };
};
  
export const getMENU_CONFIGFindByroleIdforHeader = (id: number) => {
  return async (dispatch: any) => {
    try {
      // begin connecting...
      dispatch(setMENU_CONFIGFetchingToState());
      // connect
      const result = await httpClient.get(server.MENU_CONFIG + `/getByrole_id/${id}`);
      if (result.status === 200) {
        // result.data.unshift({id: 0,code: "00",name_en: "-- select provinces --",name_th: "-- เลือกจังหวัด --",geography_id: 0,created_at: null,updatedAt:  null})
        // setTimeout(() => {
          dispatch(setMENU_CONFIGFindByIdforHeaderToState(result.data));
          // alert("Provinces Successfully");
          // navigate("/login");
        // }, 1000);
      } else {
        dispatch(setMENU_CONFIGFetchingToState());
      }
    } catch (error) {
      // error
      dispatch(setMENU_CONFIGFetchingToState());
    }
  };
};

export const getMENU_CONFIGCheckRouteConfigmenu = (router_path: any, navigate: any) => {
  return async (dispatch: any) => {
    try {
      // connect
      const result = await httpClient.get(server.MENU_CONFIG + `/checkroute_configmenu/${router_path}`);
      if (result.status === 200) {
      } else {
        dispatch(loginActions.logouttologinpage(navigate));
      }
    } catch (error) {
      dispatch(loginActions.logouttologinpage(navigate));
    }
  };
};
  
export const addMENU_CONFIG = (data: any,datamenuconfig:any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setMENU_CONFIGFetchingToState());
        // connect
        const result = await httpClient.post(server.MENU_CONFIG+"/add", data);
        if (result.status == 200 || result.status == 201) {
          datamenuconfig.forEach(async(item:any,index:number) => {
            await httpClient.post(server.MENU_CONFIG+"/add", {role_id:result.data.id,menu_id:item});
          });
           dispatch(setMENU_CONFIGAddSuccessToState(result.data));
           navigate("/general-MENU_CONFIG");
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setMENU_CONFIGAddFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setMENU_CONFIGAddFailedToState());
      }
    };
  };

  export const editMENU_CONFIG = (data: any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setMENU_CONFIGFetchingToState());
        // connect
        const result = await httpClient.put(`${server.MENU_CONFIG}/${data.id}`, data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setMENU_CONFIGEditSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setMENU_CONFIGEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setMENU_CONFIGEditFailedToState());
      }
    };
  };


  export const deleteMENU_CONFIG = (MENU_CONFIG_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.MENU_CONFIG + `/${MENU_CONFIG_id}`);
            await httpClient.delete(server.MENU_CONFIG + `/deleteby_role_id/${MENU_CONFIG_id}`);
            if (result.status == 204) {
               dispatch(setMENU_CONFIGDeleteSuccessToState(result.data));
             } else {
               dispatch(setMENU_CONFIGDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setMENU_CONFIGDeleteFailedToState());
        }
    };
};
  

export const getMENU_CONFIGCheckTokenOnly = (navigate: any) => {
  return async (dispatch: any) => {
    try {
      // connect
      const result = await httpClient.get(server.MENU_CONFIG + `/checktoken`);
      if (result.status === 200) {
      } else {
        dispatch(loginActions.logouttologinpage(navigate));
      }
    } catch (error) {
      dispatch(loginActions.logouttologinpage(navigate));
    }
  };
};