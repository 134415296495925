import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../..";
import { server, TOKEN } from "../../../services/Constants";
import Container from '@mui/material/Container';
import * as loginActions from "../../../actions/login.action";
import { Grid, Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import styled from '@emotion/styled';
import * as RequestLogActions from "../../../actions/request_log.action";
import * as requestTrackingActions from "../../../actions/request.tracking.action";
import * as RequestAssignActions from "../../../actions/request_assign.action";
import * as userActions from "../../../actions/user.action";
import * as agreementActions from "../../../actions/agreement.action";
import * as menuconfigActions from "../../../actions/menuconfig.action";
import * as stripe_paymentActions from "../../../actions/stripe_payment.action";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import "./YourCard.css";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { BsCart3 } from "react-icons/bs";
import {encode,decode} from "../../layouts/assets/calculator/decode_encode";
import { GrDocumentText } from "react-icons/gr";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {loadStripe} from '@stripe/stripe-js';
import {EmbeddedCheckoutProvider,EmbeddedCheckout} from '@stripe/react-stripe-js';


interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

  const YourCard: React.FC<any> = () => {
    const { t } = useTranslation();
    const RequestLogStateReducer = useSelector((state: RootReducers) => state.RequestLogStateReducer);
    const requesTrackingReducer = useSelector((state: RootReducers) => state.requesTrackingReducer);
    const RequestAssignStateReducer = useSelector((state: RootReducers) => state.RequestAssignStateReducer);
    const UserStateReducer = useSelector((state: RootReducers) => state.userStateReducer);
    const agreementStateReducer = useSelector((state: RootReducers) => state.agreementStateReducer);
    const stripePaymentStateReducer = useSelector((state: RootReducers) => state.stripePaymentStateReducer);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    let [countcart, setcountcart] = React.useState(0);
    const [tableData, setTableData] = React.useState<any[]>([]);
    let [datamycart, setdatamycart] = React.useState<any[]>([]);
    let [totalcost, settotalcost] = React.useState(0);
    const [clientSecret, setClientSecret] = React.useState<any>('');
    const [checkoutSessionID, setcheckoutSessionID] = React.useState<any>('');
    // pk_test_51FOhyhCiQSLSNSsfgWfPOiCJFNKyLEyr3baF5H4hBcpm4fUxMDvu10CXH8io69YXTd1paB1u1JRUlGWDgmGlirub00zSV340A9
    const stripePromise = loadStripe('pk_test_51Nn1nlAXAFZfMq9NdFPD4Cignl0WpECylmPArkIZdUyPIcTGslmJRgvXPSkQfaooK43d2oXkyol1HHolnXsXu2uU00fI8sGL19');
   
    React.useEffect(() => {
      const callawaitfunction = async () =>{
        dispatch(agreementActions.loadAGREEMENTACTIVE());
        setClientSecret('');
        setcheckoutSessionID('');
        // dispatch(stripe_paymentActions.loadSTRIPE_PAYMENT());
      }
      callawaitfunction();
    }, []);

    React.useEffect(() => {
      setClientSecret('');
      setcheckoutSessionID('');
      if(stripePaymentStateReducer.result){
        setClientSecret(stripePaymentStateReducer.result[0]?.clientSecret);
        setcheckoutSessionID(stripePaymentStateReducer.result[0]?.checkoutSessionID);
      }
    }, [stripePaymentStateReducer.result]);
    
    React.useEffect(() => {
    if(agreementStateReducer.result && datamycart.length > 0){
      let  dataList:any[] = [];
      let index = 0;
      let dataAgreement = agreementStateReducer.result;
      let quantity = 0;
      let total = 0;
      let total_cost = 0;

              datamycart.forEach((datacart:any,indexcart:number)=>{
                for(let i =0;i < dataAgreement.length;i++){
                  quantity = 0;
                  total = 0;
          if(datacart.id == dataAgreement[i].id){
            quantity = datacart.count;
            total = Number(quantity * dataAgreement[i].price);
            dataList[index] = dataAgreement[i];
            dataList[index].quantity = quantity;
            dataList[index].total = total;
            total_cost += total;
            index += 1;
            return;
          }
                }
          })
          settotalcost(total_cost);
        setTableData(dataList);
      
      }
     
    }, [agreementStateReducer.result]);
    React.useEffect(() => {
      setClientSecret('');
      setcheckoutSessionID('');
      if(localStorage.getItem("YourCart")){
    try{
      setcountcart(JSON.parse(decode(localStorage.getItem("YourCart"))).length);
      setdatamycart(JSON.parse(decode(localStorage.getItem("YourCart"))));
    
    }catch (error) {
      setcountcart(0)
      localStorage.removeItem("YourCart");
  }
        agreementStateReducer.isFetchingAddtoCart = false;
      }
      
    }, [agreementStateReducer.isFetchingAddtoCart,localStorage.getItem("YourCart")]);
    const handleComplete =()=>{
      dispatch(stripe_paymentActions.sendchecksession_statusSTRIPE_PAYMENT(checkoutSessionID,navigate));
      // navigate('/success-payment');
    }
    const options = {
      clientSecret,
      onComplete: handleComplete
    };
    const requestColumns: GridColDef[] = [
      {
          headerName: `${t('Product Details')}`,
          field: "name",
          flex: 1,
          width: 350, minWidth: 350, maxWidth: 400,
          headerAlign: 'left',
          align: "left",
          sortable:false,
          renderCell: ({ row }: GridRenderCellParams<string>) => (
                    <>
                    <Grid container>
                    <Grid 
                    item
                      xs={4} sm={4} md={4} lg={4} xl={4} 
                      direction="column"
                      alignSelf="center"
                      textAlign='center'
                      sx={{ }}
                    >
                    <GrDocumentText size={70}  />
                    </Grid>
                    <Grid 
                    item
                      xs={1} sm={1} md={1} lg={1} xl={1} 
                      direction="column"
                      sx={{}}
                    ></Grid>
                    <Grid 
                    item
                      xs={7} sm={7} md={7} lg={7} xl={7} 
                      direction="column"
                      sx={{alignSelf:'center',}}
                    >
                       <Typography 
                        gutterBottom 
                        variant="body1"
                        sx={{
                          color:'#4F4A41',
                        }}
                      >
                         {row.name}
                      </Typography>

                      <Typography 
                       sx={{ 
                        textTransform: "none",
                        borderColor:'#ffffff',
                        // backgroundColor: '#ffffff', 
                        color:'gray',
                        '&:hover': {
                          color:'gray',
                          textDecoration: 'underline',
                          cursor:'pointer',
                        }, 
                          height:22,
                        width:'60%',
                      }}
                        onClick={(e) => {
                          let newsetdatamycart =  [...datamycart];
                          let newsetdatatable = [...tableData];
                          // return;
                          let total_cost = 0;
                          datamycart.forEach((item:any,index:number) => {
                            if(item.id == row.id){
                              newsetdatamycart.splice(index, 1);
                              newsetdatatable.splice(index, 1);
        
                              setTableData(newsetdatatable)
                          }
                          if(index == datamycart.length-1){
                            newsetdatatable.forEach((x:any,i:any) => {
                              total_cost += x.quantity*x.price;
                            })
                            settotalcost(total_cost);
                              localStorage.setItem("YourCart",encode(JSON.stringify(newsetdatamycart)));
                              dispatch(agreementActions.setAGREEMENTFetchingAddtoCartToState());
                          }
                        });
                        
                         
                        }}
                      >
                         {t('Remove')}
                      </Typography>

                      {/* <Button
                variant="outlined" 
                // sx={{ 
                //   borderColor:'#FF2400',
                //   backgroundColor: '#FF0000', 
                //   color:'#ffffff',
                //   '&:hover': {backgroundColor: '#FF2400'}, 
                //   height:22,
                //   width:'60%',
                // }}
                sx={{ 
                  textTransform: "none",
                  borderColor:'#ffffff',
                  // backgroundColor: '#ffffff', 
                  color:'gray',
                  '&:hover': {
                    backgroundColor: '#FF0000',
                    color:'#ffffff',
                    borderColor:'#FF2400',
                  }, 
                    height:22,
                  width:'60%',
                }}

                onClick={(e) => {
                  let newsetdatamycart =  [...datamycart];
                  let newsetdatatable = [...tableData];
                  // return;
                  let total_cost = 0;
                  datamycart.forEach((item:any,index:number) => {
                    if(item.id == row.id){
                      newsetdatamycart.splice(index, 1);
                      newsetdatatable.splice(index, 1);

                      setTableData(newsetdatatable)
                  }
                  if(index == datamycart.length-1){
                    newsetdatatable.forEach((x:any,i:any) => {
                      total_cost += x.quantity*x.price;
                    })
                    settotalcost(total_cost);
                      localStorage.setItem("YourCart",encode(JSON.stringify(newsetdatamycart)));
                      dispatch(agreementActions.setAGREEMENTFetchingAddtoCartToState());
                  }
                });
                
                 
                }}
                
              >
            {t('Remove')}
            </Button> */}
                        </Grid>
                        </Grid>
                    </>
        ),
          
      },
      {
          headerName: `${t('Quantity')}`,
          field: "quantity",
          flex: 1,
          minWidth: 100,
          headerAlign: 'center',
          align: "center",
          sortable:false,
          renderCell: ({ row }: GridRenderCellParams<string>) => (
            <Stack direction="row">

                    <>
                    <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="number"
          id="quantity"
          size="small"
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => {
            let total_cost = 0;
            datamycart.forEach((item:any,index:any) => {
              if(item.id == row.id){
                tableData[index].quantity = Number(e.target.value);
              item.count = Number(e.target.value);
              tableData[index].total = Number(e.target.value)*Number(tableData[index].price);
            }
            total_cost += tableData[index].quantity*Number(tableData[index].price);
            if(index == datamycart.length-1){
              settotalcost(total_cost);
                localStorage.setItem("YourCart",encode(JSON.stringify(datamycart)));
                dispatch(agreementActions.setAGREEMENTFetchingAddtoCartToState());
            }
          });
          
           
          }}
          value={row.quantity}
        />
                    </>
            </Stack>
        ),
      },
      {
          headerName: `${t('Price')}`,
          field: "price",
          flex: 1,
          minWidth: 100,
          headerAlign: 'center',
          align: "center",
          sortable:false,
          renderCell: ({ row }: GridRenderCellParams<string>) => (
            <>
            ฿{row.price}
            </>
          ),
      },
      {
          headerName:`${t('Total')}`,
          field: "total",
          flex: 2,
          minWidth: 100,
          headerAlign: 'center',
          align: "center",
          sortable:false,
          renderCell: ({ row }: GridRenderCellParams<string>) => (
            <>
            ฿{row.total}
            </>
          ),
      },
  ];

    return (
        <Container fixed sx={{ mt: 3}} >
           {clientSecret ? (
        <Box id="checkout">
           <Grid container>
            <Grid 
                    sx={{alignSelf:"left", textAlign: 'left',}}
                    item xs={12}
                    sm={12}
                    md={2}
                    lg={1}
                    xl={1}>
                    </Grid>
           <Grid 
                    sx={{alignSelf:"left", textAlign: 'left',}}
                    item xs={12}
                    sm={12}
                    md={10}
                    lg={11}
                    xl={11}>
          <Button
              startIcon={<ArrowBackIcon fontSize="large" />}
                onClick={() => {
                  setClientSecret('');
                  setcheckoutSessionID('');
                // navigate("/your-cart")
              }}
              
                variant="outlined"
                sx={{ 
                  borderColor:'#ffffff',
                  // backgroundColor: '#CA965C', 
                  color:'#CA965C',
                  marginTop: '1rem',
                  marginBottom: '2rem',
                  '&:hover': {
                    backgroundColor: '#E8630A',
                    color:'#ffffff',
                    borderColor:'#ffffff',
                  }, 
                  // width:'20%',
                }}
                
              >
              {t('Back')}
            </Button>
            </Grid>
            </Grid>
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
        </Box>
     ) :
     <>
          {countcart <= 0 ?
            <Card 
                sx={
                    { 
                        borderRadius:'25px',
                        marginTop:2,
                        minWidth: '40%',
                        boxShadow: 'unset',
                        width: '100%',
                    }
                }
            >
              <CardContent>
                  <Grid container>
                    <Grid 
                    item
                      xs={12} sm={12} md={12} lg={12} xl={12} 
                      direction="column"
                      sx={{
                        borderBottom: '#ABA9A6 solid 2px',
                          marginBottom: "2rem"
                      }}
                    >
                      <Typography 
                        gutterBottom 
                        variant="h5" 
                        component="h2" 
                        sx={{
                          color:'#4F4A41',
                          textAlign: "left",
                          marginTop: 2,
                        }}
                      >
                        {t(`Your Cart`)}
                      </Typography>
                    </Grid>
                  </Grid>



                  <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center", textAlign: 'center',}}
                    item xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}>
                      <BsCart3 size={100}/>
    <Typography  gutterBottom 
                        variant="h5" 
                        component="h2" 
                        sx={{
                          color:'#4F4A41',
                          textAlign: "center",
                          marginTop: 2,
                        }}>
{t('Your cart is empty')}
</Typography>

<Typography  gutterBottom 
                        variant="h6" 
                        component="h2" 
                        sx={{
                          color:'#4F4A41',
                          textAlign: "center",
                          marginTop: 2,
                        }}>
{t('Looks like you haven’t added any forms to your cart')}
</Typography>

<Button
                onClick={() => navigate("/all-services")}
                variant="contained" 
                sx={{ 
                  backgroundColor: '#CA965C', 
                  color:'#ffffff',
                  marginTop: '1rem',
                  marginBottom: '2rem',
                  '&:hover': {backgroundColor: '#E8630A'}, 
                }}
                
              >
              {t('Browse Service List')}
            </Button>

</Grid>
       </Grid>
              </CardContent>
            </Card>

            :
<Grid container>
  {/* data detail */}
                    <Grid 
                    item
                      xs={12} sm={12} md={8} lg={8} xl={8} 
                      direction="column"
                      sx={{ }}
                    >
            <Card 
                sx={
                    { 
                        borderRadius:'25px',
                        marginTop:2,
                        minWidth: '40%',
                        boxShadow: 'unset',
                        width: '100%',
                    }
                }
            >
              <CardContent>
                  <Grid container>
                    <Grid 
                    item
                      xs={12} sm={6} md={6} lg={6} xl={6} 
                      direction="column"
                      sx={{
                        borderBottom: '#ABA9A6 solid 2px',
                          marginBottom: "2rem"
                      }}
                    >
                      <Typography 
                        gutterBottom 
                        variant="h5" 
                        component="h2" 
                        sx={{
                          color:'#4F4A41',
                          textAlign: "left",
                          marginTop: 2,
                        }}
                      >
                        {t(`Your Cart`)}
                      </Typography>
                    </Grid>

                    <Grid 
                    item
                      xs={12} sm={6} md={6} lg={6} xl={6} 
                      direction="column"
                      sx={{
                        borderBottom: '#ABA9A6 solid 2px',
                          marginBottom: "2rem"
                      }}
                    >
                      <Typography 
                        gutterBottom 
                        variant="h5" 
                        component="h2" 
                        sx={{
                          color:'#4F4A41',
                          textAlign: "right",
                          marginTop: 2,
                        }}
                      >
                        {countcart} {t(`Items`)}
                      </Typography>
                    </Grid>
                  </Grid>



                  <Grid container >
    
                <Grid item xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <DataGrid
                    
                       sx={{
                        '& .MuiDataGrid-columnHeaders': {
                          border: 'none', 
                          borderRadius: 'none',
                          borderColor: 'none',
                        
                        },
                        '& .MuiDataGrid-iconSeparator': {
                          display: 'none',
                          borderColor: 'none',
                        },
                      
                      '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                      },
            
                      '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                      
                        border: 'none', 
                        borderRadius: 'none',
                        borderColor: 'none',
                        // p: 2,
                        // minWidth: 300,
                        
                    }}

                        rows={tableData}
                        columns={requestColumns}
                        autoHeight
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        // hideFooter
                        disableColumnSelector
                        disableColumnMenu
                        disableColumnFilter
                        disableVirtualization
                        disableSelectionOnClick
                        disableDensitySelector
                        getRowHeight={() => 'auto'}
                    />

                    <br />
                </Grid>
            </Grid>
              </CardContent>
            </Card>
            </Grid>


              {/* data total */}
            <Grid 
            item
              xs={12} sm={12} md={4} lg={4} xl={4} 
              direction="column"
              sx={{}}
            >
              <Card 
                sx={
                    { 
                        borderRadius:'25px',
                        marginTop:2,
                        minWidth: '40%',
                        boxShadow: 'unset',
                        width: '100%',
                    }
                }
            >
              <CardContent>
                  <Grid container>
                    <Grid 
                    item
                      xs={12} sm={12} md={12} lg={12} xl={12} 
                      direction="column"
                      sx={{
                        borderBottom: '#ABA9A6 solid 2px',
                          marginBottom: "2rem"
                      }}
                    >
                      <Typography 
                        gutterBottom 
                        variant="h5" 
                        component="h2" 
                        sx={{
                          color:'#4F4A41',
                          textAlign: "left",
                          marginTop: 2,
                        }}
                      >
                        {t(`Order Summary`)}
                      </Typography>
                    </Grid>

                    
                   
                  </Grid>

                  <Grid container>
                  <Grid 
                    item
                      xs={12} sm={6} md={6} lg={6} xl={6} 
                      direction="column"
                      sx={{
                        borderBottom: '#ABA9A6 solid 2px',
                          marginBottom: "2rem"
                      }}
                    >
                      <Typography 
                        gutterBottom 
                        variant="h6" 
                        component="h2" 
                        sx={{
                          color:'#4F4A41',
                          textAlign: "left",
                        }}
                      >
                         {t(`Total Cost`)}
                      </Typography>
                    </Grid>

                    <Grid 
                    item
                      xs={12} sm={6} md={6} lg={6} xl={6} 
                      direction="column"
                      sx={{
                        borderBottom: '#ABA9A6 solid 2px',
                          marginBottom: "2rem"
                      }}
                    >
                      <Typography 
                        gutterBottom 
                        variant="h6" 
                        component="h2" 
                        sx={{
                          color:'#4F4A41',
                          textAlign: "right",
                          marginBottom: "2rem",
                        }}
                      >
                       ฿{totalcost}
                      </Typography>
                    </Grid>
                    </Grid>
                 
                     
                  <Grid container >
                <Grid item xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{alignSelf:"center", textAlign: 'center',}}
                >
                  <Button
                onClick={async() => {
                 await dispatch(stripe_paymentActions.sendDataSTRIPE_PAYMENT(tableData,navigate));
                }}
                variant="contained" 
                sx={{ 
                  backgroundColor: '#CA965C', 
                  color:'#ffffff',
                  marginTop: '1rem',
                  marginBottom: '2rem',
                  '&:hover': {backgroundColor: '#E8630A'}, 
                  width:'80%',
                }}
                
              >
              {t('Check Out')}
            </Button>
                </Grid>
            </Grid>
              </CardContent>
            </Card>
               </Grid>
            </Grid>
           
            }


<Grid container>
<Grid 
                    item
                      xs={12} sm={12} md={12} lg={3} xl={3} 
                      direction="column"
                      sx={{
                        // borderBottom: '#ABA9A6 solid 2px',
                        //   marginBottom: "2rem"
                      }}
                    >
                     
                      <Button
                      startIcon={<ArrowBackIcon fontSize="large" />}
                onClick={() => navigate("/all-services")}
                variant="outlined"
                sx={{ 
                  borderColor:'#ffffff',
                  // backgroundColor: '#CA965C', 
                  color:'#CA965C',
                  marginTop: '1rem',
                  marginBottom: '2rem',
                  '&:hover': {
                    backgroundColor: '#E8630A',
                    color:'#ffffff',
                    borderColor:'#ffffff',
                  }, 
                  // width:'20%',
                }}
                
              >
              {t('Continue Shopping')}
            </Button>
                    </Grid>
                  <Grid 
                    item
                      xs={12} sm={12} md={12} lg={9} xl={9} 
                      direction="column"
                    >
                    </Grid>
                    </Grid>
                    </>}
        </Container>
  );
};
export default YourCard;
