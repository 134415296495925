// import { history } from "../index";
import {
  OK,
  PERONAL_INFOMATION_FAILED,
  PERONAL_INFOMATION_FETCHING,
  PERONAL_INFOMATION_SUCCESS,
  server,
  TOKEN,
  MEMBER_ID,
  LOGOUT,
} from "../services/Constants";
import { User } from "../types/user.type";
import { httpClient } from "../services/httpclient";
import * as menuconfigActions from "./menuconfig.action";
import i18n from 'i18next';
const Swal = require('sweetalert2');


export const setPERONAL_INFOMATIONFetchingToState = () => ({
  type: PERONAL_INFOMATION_FETCHING,
});

export const setPERONAL_INFOMATIONSuccessToState = (payload: any) => ({
  type: PERONAL_INFOMATION_SUCCESS,
  payload,
});

export const setPERONAL_INFOMATIONFailedToState = () => ({
  type: PERONAL_INFOMATION_FAILED,
});

export const setLogoutToState = () => ({
  type: LOGOUT,
});

export const getPERONAL_INFOMATIONFindByroleId = (id: number) => {
  return async (dispatch: any) => {
    try {
      const result = await httpClient.get(server.User + `/getByidforpersonal_info/${id}`);
      if (result.status === 200) {

          dispatch(setPERONAL_INFOMATIONSuccessToState(result.data[0]));

      } else {
        dispatch(setPERONAL_INFOMATIONFailedToState());
      }
    } catch (error) {
      // error
      dispatch(setPERONAL_INFOMATIONFailedToState());
    }
  };
};

export const EditPERONAL_INFOMATION = (data:any, navigate: any) => {
  return async (dispatch: any) => {
    try {
      const result = await httpClient.put(server.User + `/updateuser_member/${data.id}`,data);
      if (result.status === 200 || result.status == 201) {
        Swal.fire({
          title: `${i18n.t('Information Saved')}`,
          text:  `${i18n.t('The information has been saved and updated successfully.')}`,
          icon: 'success',
        });
        dispatch(getPERONAL_INFOMATIONFindByroleId(data.id));
      } else {
        Swal.fire({
          title: `${i18n.t('Information Saved')}`,
          text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,
          icon: 'error',
        });
        dispatch(setPERONAL_INFOMATIONFailedToState());
      }
    } catch (error) {
      // error
      dispatch(setPERONAL_INFOMATIONFailedToState());
    }
  };
};