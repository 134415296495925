// import { history } from "../index";
import * as React from "react";
import {
    server,
    REQUEST_EDIT_FETCHING,
    REQUEST_EDIT_SUCCESS,
    REQUEST_EDIT_FAILED,
    MEMBER_ID,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import i18n from 'i18next';
const Swal = require('sweetalert2')

export const setRequestEditFetchingToState = () => ({
    type: REQUEST_EDIT_FETCHING,
});
  
export const setRequestEditSuccessToState = () => ({
    type: REQUEST_EDIT_SUCCESS,

});
  
export const setRequestEditFailedToState = () => ({
    type: REQUEST_EDIT_FAILED,
});

export const editRequestEdit = (   
        request_id_main:number,       
        datasheet_id: number,
        req_type: number,
        req_sub_type: any[],
        reqDate: any,
        remark: string,
        status :string,
        navigate: any
    ) => {
    return async(dispatch: any) => {
        dispatch(setRequestEditFetchingToState());
            // let request_id_data: number;
        let updateDataRequest = {
            datasheet_id: datasheet_id,
            request_type:req_type,
            status: status,
            duedate: reqDate,
            remark: remark,
            user_update : localStorage.getItem(MEMBER_ID),
        }
            
        let result_update = await httpClient.put(`${server.REQUEST}/${request_id_main}`, updateDataRequest);
        console.log(result_update.status)
        if(result_update.status == 200){
            await httpClient.delete(`${server.REQUEST_DETAIL}/${request_id_main}`);
            let addRequestDetail = {};
            let checkAddDetail = 0;
            for(let i=0;i< req_sub_type.length;i++){
                addRequestDetail = {
                    request_id: request_id_main,
                    sub_type_id: Number(req_sub_type[i].request_sub_type_id),
                    user_create : localStorage.getItem(MEMBER_ID),
                    user_update : localStorage.getItem(MEMBER_ID),
                }
                
                let result_addDetail = await httpClient.post(`${server.REQUEST_DETAIL}/add`, addRequestDetail)
                if(result_addDetail.status == 200 || result_addDetail.status == 201){

                }else{
                    checkAddDetail += 1;
                }
            }
            if(checkAddDetail > 0){
                await httpClient.delete(`${server.REQUEST}/${request_id_main}`);
                await httpClient.delete(`${server.REQUEST_DETAIL}/${request_id_main}`);
                Swal.fire({
                    title: `${i18n.t('Information Saved')}`,
                    text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,
                    icon: "error",
                });
                return; 
            }else{

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${i18n.t('Information Saved')}`,
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate("/my-request");
            }
        }else{
            dispatch(setRequestEditFailedToState());
            Swal.fire({
                title: `${i18n.t('Information Saved')}`,
                text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,
                icon: "error",
            });
            return;
        }
    };
};
export const editRequestEditStatus = (   
        request_id_main:number,       
        status :number,
        navigate: any
    ) => {
    return async(dispatch: any) => {
        dispatch(setRequestEditFetchingToState());
            // let request_id_data: number;
        let updateDataRequest = {
            status: status,
            user_update : localStorage.getItem(MEMBER_ID),
        }
            
        let result_update = await httpClient.put(`${server.REQUEST}/tracking/${request_id_main}`, updateDataRequest);
        if(result_update.status == 200){
            

            
        }else{
            dispatch(setRequestEditFailedToState());
            Swal.fire({
                title: `${i18n.t('Information Saved')}`,
                text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,
                icon: "error",
            });
            return;
        }
    };
};
export const editRequestTrakingEditStatus = (   
    request_id_main:number,       
    status :number,
    data_mas :any,
    navigate: any
) => {
    return async(dispatch: any) => {
        dispatch(setRequestEditFetchingToState());
            // let request_id_data: number;
        let updateDataRequest = {
            status: status,
            user_update : localStorage.getItem(MEMBER_ID),
        }
        let result_update:any = {status:0};
        let check_status = 0;
        if(Number(status) > 0){
            result_update = await httpClient.put(`${server.REQUEST}/tracking/${request_id_main}`, updateDataRequest);
        }else{
            result_update.status = 200;
        }
        
        if(result_update.status == 200){

            if(data_mas){

                const result = await httpClient.post(server.Massage + "/add", data_mas);
                if (result.status == 200 || result.status == 201) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${i18n.t('Information Saved')}`,
                        text:  `${i18n.t('The information has been saved and updated successfully.')}`,
                        showConfirmButton: false,
                        timer: 1500
                    })

                    window.location.reload();
                } else {
                    dispatch(setRequestEditFailedToState());
                    Swal.fire({
                        title: `${i18n.t('Information Saved')}`,
                        text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,
                        icon: "error",
                    });
                    return;
                }
            }else{
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${i18n.t('Information Saved')}`,
                    text:  `${i18n.t('The information has been saved and updated successfully.')}`,
                    showConfirmButton: false,
                    timer: 1500
                })

                window.location.reload();
            }

            
        }else{
            dispatch(setRequestEditFailedToState());
            Swal.fire({
                title: `${i18n.t('Information Saved')}`,
                text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,
                icon: "error",
            });
            return;
        }
    };
};

  