import {
  REGISTER_FAILED,
  REGISTER_FETCHING,
  REGISTER_SUCCESS,
  REGISTER_USERNAME_FAILED
} from "../services/Constants";

export interface RegisterState {
  isFetching: boolean;
  isError: boolean;
  isErrorusername: boolean;
  result: any;
}

const initialState: RegisterState = {
  isFetching: false,
  isError: false,
  isErrorusername: false,
  result: null,
};

export default (
  state = initialState,
  { type, payload }: any
): RegisterState => {
  switch (type) {
    case REGISTER_FETCHING:
      return { ...state, isFetching: true, isError: false,isErrorusername: false, result: null };
    case REGISTER_SUCCESS:
      return { ...state, isFetching: false, isError: false,isErrorusername: false, result: payload };
    case REGISTER_FAILED:
      return { ...state, isFetching: false, isError: true,isErrorusername: false, result: null };
      case REGISTER_USERNAME_FAILED:
        return { ...state, isFetching: false,isError: false, isErrorusername: true, result: null };
    default:
      return state;
  }
};
