import {
    REQUEST_TRACKING_FETCHING,
    REQUEST_TRACKING_SUCCESS,
    REQUEST_TRACKING_FAILED,
  } from "../services/Constants";
  import { RequestTracking } from "../types/request_tracking.type";
    
    export interface RequestTrackingState {
        result: RequestTracking[];
        isFetching: boolean;
        isError: boolean;
        res2: number
        res3: number
        res6: number
        res4: number
        res9: number
    }
    
    const initialState: RequestTrackingState = {
        result: [],
        isFetching: false,
        isError: false,
        res2: 0,
        res3: 0,
        res6: 0,
        res4: 0,
        res9: 0,
    };
    
    export default (state = initialState, { type, payload, res2, res3, res6, res4, res9}: any) => {
      switch (type) {
        case REQUEST_TRACKING_FETCHING:
          return { ...state, result: [], isFetching: true, isError: false};
        case REQUEST_TRACKING_SUCCESS:
          return { 
            ...state, 
            result: payload, 
            res2: res2, 
            res3: res3, 
            res6: res6, 
            res4: res4, 
            res9: res9, 
            isFetching: false, 
            isError: false,
          };
        case REQUEST_TRACKING_FAILED:
          return { ...state, result: [], isFetching: false, isError: true};
        default:
          return state;
      }
    };
    