import {
  USER_FETCHING,
  USER_SUCCESS,
  USER_ADD_SUCCESS,
  USER_ADD_FAILED,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAILED,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED,
  USER_FAILED,
  CHECK_USER_NAME_SUCCESS,
  CHECK_USER_NAME_FAILED,
  USER_CLEAR,
} from "../services/Constants";
  import { Users } from "../types/users.type";
      
  export interface UserState {
    result: Users[];
    isFetching: boolean;
    isError: boolean;
    isAdddata: boolean,
    isEditdata: boolean,
    isAddError: boolean;
    isEditError: boolean;
    isDeletedata: boolean;
    isDeleteError: boolean;
    isCheckUserName: boolean;
  }
      
      const initialState: UserState = {
          result: [],
          isFetching: false,
          isError: false,
          isAdddata: false,
          isEditdata: false,
          isAddError: false,
          isEditError: false,
          isDeletedata: false,
          isDeleteError: false,
          isCheckUserName: false,
      };
      
      export default (state = initialState, { type, payload}: any) => {
        switch (type) {
          case USER_FETCHING:
            return { 
              ...state, 
              result: [], 
              isFetching: true,
              isAdddata: false,
              isEditdata: false, 
              isError: false,
              isAddError: false,
              isEditError: false,
              isDeletedata: false,
              isDeleteError: false,
              isCheckUserName: false
              
            };
          case USER_SUCCESS:
            return { 
              ...state, 
              result: payload,
              isFetching: false,
              isAdddata: false,
              isEditdata: false,
              isError: false,
              isAddError: false,
              isEditError: false,
              isDeletedata: false,
              isDeleteError: false,
              isCheckUserName: false
            };
          case CHECK_USER_NAME_SUCCESS:
            return { 
              ...state, 
              result: payload,
              isFetching: false,
              isAdddata: false,
              isEditdata: false,
              isError: false,
              isAddError: false,
              isEditError: false,
              isDeletedata: false,
              isDeleteError: false,
              isCheckUserName: true
            };
          case CHECK_USER_NAME_FAILED:
            return { 
              ...state, 
              result: payload,
              isFetching: false,
              isAdddata: false,
              isEditdata: false,
              isError: false,
              isAddError: false,
              isEditError: false,
              isDeletedata: false,
              isDeleteError: false,
              isCheckUserName: false
            };
          case USER_ADD_SUCCESS:
            return { 
              ...state, 
              isFetching: false,
              isAdddata: true,
              isEditdata: false, 
              isError: false,
              isAddError: false,
              isEditError: false,
              isDeletedata: false,
              isDeleteError: false,
              isCheckUserName: false
            };
            case USER_EDIT_SUCCESS:
            return { 
              ...state, 
              isFetching: false, 
              isAdddata: false,
              isEditdata: true, 
              isError: false,
              isAddError: false,
              isEditError: false,
              isDeletedata: false,
              isDeleteError: false,
              isCheckUserName: false
            };
          case USER_FAILED:
            return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false,
               isError: true,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: false,isCheckUserName: false};
          case USER_ADD_FAILED:
            return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false,isCheckUserName: false,
               isError: false,isAddError: true,isEditError: false,isDeletedata: false,isDeleteError: false};
          case USER_EDIT_FAILED:
            return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, isCheckUserName: false,
              isError: false,isAddError: false,isEditError: true,isDeletedata: false,isDeleteError: false};
          case USER_DELETE_SUCCESS:
            return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, isCheckUserName: false,
              isError: false,isAddError: false,isEditError: false,isDeletedata: true,isDeleteError: false};
          case USER_DELETE_FAILED:
            return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, isCheckUserName: false,
              isError: false,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: true};
          default:
            return state;
        }
        
      };
      