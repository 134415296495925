import {
  MENU_CONFIG_FETCHING,
  MENU_CONFIG_SUCCESS,
  MENU_CONFIG_ADD_SUCCESS,
  MENU_CONFIG_ADD_FAILED,
  MENU_CONFIG_EDIT_SUCCESS,
  MENU_CONFIG_EDIT_FAILED,
  MENU_CONFIG_DELETE_SUCCESS,
  MENU_CONFIG_DELETE_FAILED,
  MENU_CONFIG_FAILED,
  MENU_CONFIG_FIND_BY_ID,
  MENU_CONFIG_FIND_BY_ID_FOR_HEADER,
  MENU_CONFIG_CLEAR,
  } from "../services/Constants";
  import { Menuconfig } from "../types/menuconfig.type";
    
    export interface MenuconfigState {
        result: Menuconfig[];
        isFetching: boolean;
        isError: boolean;
        isAdddata: boolean,
        isEditdata: boolean,
        isAddError: boolean;
        isEditError: boolean;
        isDeletedata: boolean;
        isDeleteError: boolean;
        result_by_id: any;
        result_by_id_for_header:any;
    }
    
    const initialState: MenuconfigState = {
        result: [],
        isFetching: false,
        isError: false,
        isAdddata: false,
        isEditdata: false,
        isAddError: false,
        isEditError: false,
        isDeletedata: false,
        isDeleteError: false,
        result_by_id: null,
        result_by_id_for_header: null,
    };
    
    export default (state = initialState, { type, payload}: any) => {
      switch (type) {
        case MENU_CONFIG_FETCHING:
          return { ...state, result: [], result_by_id: null,result_by_id_for_header: null, isFetching: true,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: false};
        case MENU_CONFIG_SUCCESS:
          return { 
            ...state, 
            result: payload,
            result_by_id: null,
            isFetching: false,
            isAdddata: false,
            isEditdata: false,
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
          case MENU_CONFIG_ADD_SUCCESS:
          return { 
            ...state, 
            result_by_id: null,
            isFetching: false,
            isAdddata: true,
            isEditdata: false, 
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
          case MENU_CONFIG_EDIT_SUCCESS:
          return { 
            ...state, 
            result_by_id: null,
            isFetching: false, 
            isAdddata: false,
            isEditdata: true, 
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
        case MENU_CONFIG_FAILED:
          return { ...state, result: [],result_by_id: null, isFetching: false,isAdddata: false,isEditdata: false,
             isError: true,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: false};
        case MENU_CONFIG_ADD_FAILED:
          return { ...state, result: [],result_by_id: null, isFetching: false,isAdddata: false,isEditdata: false,
             isError: false,isAddError: true,isEditError: false,isDeletedata: false,isDeleteError: false};
          case MENU_CONFIG_EDIT_FAILED:
          return { ...state, result: [],result_by_id: null, isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: true,isDeletedata: false,isDeleteError: false};
            case MENU_CONFIG_DELETE_SUCCESS:
          return { ...state, result: [],result_by_id: null, isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: true,isDeleteError: false};
            case MENU_CONFIG_DELETE_FAILED:
          return { ...state, result: [],result_by_id: null, isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: true};
            case MENU_CONFIG_FIND_BY_ID:
          return { ...state, result_by_id: payload };
          case MENU_CONFIG_FIND_BY_ID_FOR_HEADER:
            return { ...state, result_by_id_for_header: payload };

        default:
          return state;
      }
      
    };
    