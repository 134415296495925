import {
    DIRECTOR_FETCHING,
    DIRECTOR_LIST,
  } from "../services/Constants";
  import { Provinces } from "../types/provinces.type";
  
  export interface DirectorState {
    isFetching: boolean;
    isError: boolean;
    result:any;
  }
  
  const initialState: DirectorState = {
    isFetching: false,
    isError: false,
    result: null,
  };
  
  export default (
    state = initialState,
    { type, payload }: any
  ): DirectorState => {
    switch (type) {
        case DIRECTOR_FETCHING:
            return { ...state, isFetching: true, isError: false, result: null };
      case DIRECTOR_LIST:
        return { ...state, isFetching: false, isError: false, result: payload };
      default:
        return state;
    }
  };
  