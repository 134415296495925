// import { history } from "../index";
import {
    server,
    REQUEST_DELETE_FETCHING,
    REQUEST_DELETE_SUCCESS,
    REQUEST_DELETE_FAILED,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import i18n from 'i18next';
import * as requestActions from "../actions/request.action";
const Swal = require('sweetalert2')

export const setRequestDeleteFetchingToState = () => ({
    type: REQUEST_DELETE_FETCHING,
});
  
export const setRequestDeleteSuccessToState = () => ({
    type: REQUEST_DELETE_SUCCESS,
});
  
export const setRequestDeleteFailedToState = () => ({
    type: REQUEST_DELETE_FAILED,
});

export const deleteRequestList = (request_id:number,navigate:any,location_d:string) => {
    return async(dispatch: any) => {
        dispatch(setRequestDeleteFetchingToState());
        try {
            await httpClient.delete(`${server.REQUEST}/${request_id}`);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: `${i18n.t('Information Saved')}`,
                text:  `${i18n.t('The information has been saved and updated successfully.')}`,
                showConfirmButton: false,
                timer: 1500
            })
            if(location_d == "H"){
                navigate("/my-request")
            }
            dispatch(setRequestDeleteSuccessToState());
        } catch (error) {
            Swal.fire({
                title: `${i18n.t('Information Saved')}`,
                text:  `${i18n.t('The information has been saved and updated unsuccessfully.')}`,
                icon: "error",
            });
            dispatch(setRequestDeleteFailedToState());
        }
    };
};
  