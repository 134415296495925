// import { history } from "../index";
import {
    ROLES_FETCHING,
    ROLES_SUCCESS,
    ROLES_ADD_SUCCESS,
    ROLES_ADD_FAILED,
    ROLES_EDIT_SUCCESS,
    ROLES_EDIT_FAILED,
    ROLES_DELETE_SUCCESS,
    ROLES_DELETE_FAILED,
    ROLES_FAILED,
    ROLES_CLEAR,
    ROLES_FIND_BY_ID,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { Roles } from "../types/roles.type";
import i18n from 'i18next';
const Swal = require('sweetalert2');

export const Alertpopup = (lang:any) => {
    return (
      Swal.fire({
        title: `${i18n.t(`${lang}`)}`,
        text: `${i18n.t(`${lang}`)}`,
          icon: "error",
        })
     );
}

export const setROLESFetchingToState = () => ({
    type: ROLES_FETCHING,
});
  
export const setROLESSuccessToState = (
    payload: Roles[],) => (
        {
            type: ROLES_SUCCESS,
            payload,
        }
);

export const setROLESFindByIdToState = (payload: Roles[],) => (
      {
          type: ROLES_FIND_BY_ID,
          payload,
      }
);

export const setROLESAddSuccessToState = (payload: any) => ({
            type: ROLES_ADD_SUCCESS,
            payload,
});

export const setROLESAddFailedToState = () => ({
    type: ROLES_ADD_FAILED,
});

export const setROLESEditSuccessToState = (payload: any) => ({
    type: ROLES_EDIT_SUCCESS,
    payload,
});

export const setROLESEditFailedToState = () => ({
    type: ROLES_EDIT_FAILED,
});

export const setROLESDeleteSuccessToState = (payload: any) => ({
    type: ROLES_DELETE_SUCCESS,
    payload,
});

export const setROLESDeleteFailedToState = () => ({
    type: ROLES_DELETE_FAILED,
});
  
export const setROLESFailedToState = () => ({
    type: ROLES_FAILED,
});

export const setROLESClearToState = () => ({
    type: ROLES_CLEAR,
});
  

export const loadROLES = () => {
    return async(dispatch: any) => {
        dispatch(setROLESFetchingToState());
        getDataROLES(dispatch)
    };
};

const getDataROLES = async (dispatch: any) => {
    try {
        const result = await httpClient.get<Roles[]>(`${server.ROLES}/getAll`);
        result.data.forEach((item)=>{
            if(item.status == 1){
                item.status = "Active"
            }
            else if(item.status == 0){
                item.status = "Inactive"
            }else{
                item.status = ""
            }
            
        })
        dispatch(setROLESSuccessToState(
            result.data 
        ));
    }   catch (error) {
        dispatch(setROLESFailedToState());
    }
}

export const getROLESFindById = (id: number) => {
  return async (dispatch: any) => {
    try {
      // begin connecting...
      dispatch(setROLESFetchingToState());
      // connect
      const result = await httpClient.get(server.ROLES + `/getByid/${id}`);
      if (result.status === 200) {
        // result.data.unshift({id: 0,code: "00",name_en: "-- select provinces --",name_th: "-- เลือกจังหวัด --",geography_id: 0,created_at: null,updatedAt:  null})
        // setTimeout(() => {
          dispatch(setROLESFindByIdToState(result.data));
          // alert("Provinces Successfully");
          // navigate("/login");
        // }, 1000);
      } else {
        dispatch(setROLESFetchingToState());
      }
    } catch (error) {
      // error
      dispatch(setROLESFetchingToState());
    }
  };
};
  
  
export const addRoles = (data: any,datamenuconfig:any,dataroleconfig:any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setROLESFetchingToState());
        // connect
        const result = await httpClient.post(server.ROLES+"/add", data);
        if (result.status == 200 || result.status == 201) {
          datamenuconfig.forEach(async(item:any,index:number) => {
            await httpClient.post(server.MENU_CONFIG+"/add", {role_id:result.data.id,menu_id:item});
          });

          dataroleconfig.forEach(async(item:any,index:number) => {
            await httpClient.post(server.ROLE_CONFIG_REQUEST_TYPE+"/add", {role_id:result.data.id,request_type_id:item.id});
          });
           dispatch(setROLESAddSuccessToState(result.data));
           navigate("/general-roles");
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else if(result.status == 204){
          dispatch(Alertpopup("Password Incorrect"));
        }
        else {
          dispatch(setROLESAddFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setROLESAddFailedToState());
      }
    };
  };

  export const editRoles = (data: any,datamenuconfig:any,dataroleconfig:any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setROLESFetchingToState());
        // connect
        const result = await httpClient.put(`${server.ROLES}/${data.id}`, data);
        if (result.status == 200 || result.status == 201) {
          await httpClient.delete(server.MENU_CONFIG+`/deleteby_role_id/${data.id}`);
          await httpClient.delete(server.ROLE_CONFIG_REQUEST_TYPE+`/deleteby_role_id/${data.id}`);

          datamenuconfig.forEach(async(item:any,index:number) => {
            await httpClient.post(server.MENU_CONFIG+"/add", {role_id:data.id,menu_id:item});
          });

          dataroleconfig.forEach(async(item:any,index:number) => {
            await httpClient.post(server.ROLE_CONFIG_REQUEST_TYPE+"/add", {role_id:data.id,request_type_id:item.id});
          });
           dispatch(setROLESEditSuccessToState(result.data));
           navigate("/general-roles");
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else if(result.status == 204){
          dispatch(Alertpopup("Password Incorrect"));
        }
        
        else {
          dispatch(setROLESEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setROLESEditFailedToState());
      }
    };
  };


  export const deleteRoles = (ROLES_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.ROLES + `/${ROLES_id}`);
            await httpClient.delete(server.MENU_CONFIG + `/deleteby_role_id/${ROLES_id}`);
            await httpClient.delete(server.ROLE_CONFIG_REQUEST_TYPE+`/deleteby_role_id/${ROLES_id}`);
            if (result.status == 204) {
               dispatch(setROLESDeleteSuccessToState(result.data));
             } else {
               dispatch(setROLESDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setROLESDeleteFailedToState());
        }
    };
};
  