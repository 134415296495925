// import { history } from "../index";
import {
    OK,
    PROVINCES_FETCHING,
    PROVINCES_SUCCESS,
    PROVINCES_FAILED,
    server,
  } from "../services/Constants";
  import { Provinces } from "../types/provinces.type";
  import { httpClient } from "../services/httpclient";
  
  export const setProvincesFetchingToState = () => ({
    type: PROVINCES_FETCHING, PROVINCES_FETCHING
  });
  
  export const setProvincesSuccessToState = (payload: Provinces[]) => ({
    type: PROVINCES_SUCCESS,
    payload,
  });
  
  export const setProvincesFailedToState = () => ({
    type: PROVINCES_FAILED,
  });
  
  export const getAllProvinces = () => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        // dispatch(setProvincesFetchingToState());
        // connect
        const result = await httpClient.get<Provinces[]>(server.PROVINCES_URL + `/getAll`);
        if (result.status === 200) {
          // result.data.unshift({id: 0,code: "00",name_en: "-- select provinces --",name_th: "-- เลือกจังหวัด --",geography_id: 0,created_at: null,updatedAt:  null})
          // setTimeout(() => {
            dispatch(setProvincesSuccessToState(result.data));
            // alert("Provinces Successfully");
            // navigate("/login");
          // }, 1000);
        } else {
          dispatch(setProvincesFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setProvincesFailedToState());
      }
    };
  };
  