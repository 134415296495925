// import { history } from "../index";
import {
    ROLE_CONFIG_REQUEST_TYPE_FETCHING,
    ROLE_CONFIG_REQUEST_TYPE_SUCCESS,
    ROLE_CONFIG_REQUEST_TYPE_ADD_SUCCESS,
    ROLE_CONFIG_REQUEST_TYPE_ADD_FAILED,
    ROLE_CONFIG_REQUEST_TYPE_EDIT_SUCCESS,
    ROLE_CONFIG_REQUEST_TYPE_EDIT_FAILED,
    ROLE_CONFIG_REQUEST_TYPE_DELETE_SUCCESS,
    ROLE_CONFIG_REQUEST_TYPE_DELETE_FAILED,
    ROLE_CONFIG_REQUEST_TYPE_FAILED,
    ROLE_CONFIG_REQUEST_TYPE_CLEAR,
    ROLE_CONFIG_REQUEST_TYPE_FIND_BY_ID,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { Menuconfig } from "../types/menuconfig.type";
import * as loginActions from "./login.action";
const Swal = require('sweetalert2')

export const setROLE_CONFIG_REQUEST_TYPEFetchingToState = () => ({
    type: ROLE_CONFIG_REQUEST_TYPE_FETCHING,
});
  
export const setROLE_CONFIG_REQUEST_TYPESuccessToState = (
    payload: Menuconfig[],) => (
        {
            type: ROLE_CONFIG_REQUEST_TYPE_SUCCESS,
            payload,
        }
);

export const setROLE_CONFIG_REQUEST_TYPEFindByIdToState = (payload: Menuconfig[],) => (
      {
          type: ROLE_CONFIG_REQUEST_TYPE_FIND_BY_ID,
          payload,
      }
);


export const setROLE_CONFIG_REQUEST_TYPEAddSuccessToState = (payload: any) => ({
            type: ROLE_CONFIG_REQUEST_TYPE_ADD_SUCCESS,
            payload,
});

export const setROLE_CONFIG_REQUEST_TYPEAddFailedToState = () => ({
    type: ROLE_CONFIG_REQUEST_TYPE_ADD_FAILED,
});

export const setROLE_CONFIG_REQUEST_TYPEEditSuccessToState = (payload: any) => ({
    type: ROLE_CONFIG_REQUEST_TYPE_EDIT_SUCCESS,
    payload,
});

export const setROLE_CONFIG_REQUEST_TYPEEditFailedToState = () => ({
    type: ROLE_CONFIG_REQUEST_TYPE_EDIT_FAILED,
});

export const setROLE_CONFIG_REQUEST_TYPEDeleteSuccessToState = (payload: any) => ({
    type: ROLE_CONFIG_REQUEST_TYPE_DELETE_SUCCESS,
    payload,
});

export const setROLE_CONFIG_REQUEST_TYPEDeleteFailedToState = () => ({
    type: ROLE_CONFIG_REQUEST_TYPE_DELETE_FAILED,
});
  
export const setROLE_CONFIG_REQUEST_TYPEFailedToState = () => ({
    type: ROLE_CONFIG_REQUEST_TYPE_FAILED,
});

export const setROLE_CONFIG_REQUEST_TYPEClearToState = () => ({
    type: ROLE_CONFIG_REQUEST_TYPE_CLEAR,
});
  

export const loadresetdataROLE_CONFIG_REQUEST_TYPE = () => {
  return async(dispatch: any) => {
      dispatch(setROLE_CONFIG_REQUEST_TYPEFetchingToState());
  };
};

export const loadROLE_CONFIG_REQUEST_TYPE = () => {
    return async(dispatch: any) => {
        dispatch(setROLE_CONFIG_REQUEST_TYPEFetchingToState());
        getDataROLE_CONFIG_REQUEST_TYPE(dispatch)
    };
};

const getDataROLE_CONFIG_REQUEST_TYPE = async (dispatch: any) => {
    try {
        const result = await httpClient.get<Menuconfig[]>(`${server.ROLE_CONFIG_REQUEST_TYPE}/getAll`);
        dispatch(setROLE_CONFIG_REQUEST_TYPESuccessToState(
            result.data 
        ));
    }   catch (error) {
        dispatch(setROLE_CONFIG_REQUEST_TYPEFailedToState());
    }
}

export const getROLE_CONFIG_REQUEST_TYPEFindByroleId = (id: number) => {
  return async (dispatch: any) => {
    try {
      // begin connecting...
      // dispatch(setROLE_CONFIG_REQUEST_TYPEFetchingToState());
      // connect
      const result = await httpClient.get(server.ROLE_CONFIG_REQUEST_TYPE + `/getByrole_id/${id}`);
      if (result.status === 200) {
        // result.data.unshift({id: 0,code: "00",name_en: "-- select provinces --",name_th: "-- เลือกจังหวัด --",geography_id: 0,created_at: null,updatedAt:  null})
        // setTimeout(() => {
          dispatch(setROLE_CONFIG_REQUEST_TYPEFindByIdToState(result.data));
          // alert("Provinces Successfully");
          // navigate("/login");
        // }, 1000);
      } else {
        dispatch(setROLE_CONFIG_REQUEST_TYPEFetchingToState());
      }
    } catch (error) {
      // error
      dispatch(setROLE_CONFIG_REQUEST_TYPEFetchingToState());
    }
  };
};
  

export const getROLE_CONFIG_REQUEST_TYPECheckRouteConfigmenu = (router_path: any, navigate: any) => {
  return async (dispatch: any) => {
    try {
      // connect
      const result = await httpClient.get(server.ROLE_CONFIG_REQUEST_TYPE + `/checkroute_configmenu/${router_path}`);
      if (result.status === 200) {
      } else {
        dispatch(loginActions.logouttologinpage(navigate));
      }
    } catch (error) {
      dispatch(loginActions.logouttologinpage(navigate));
    }
  };
};
  
export const addROLE_CONFIG_REQUEST_TYPE = (data: any,datamenuconfig:any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setROLE_CONFIG_REQUEST_TYPEFetchingToState());
        // connect
        const result = await httpClient.post(server.ROLE_CONFIG_REQUEST_TYPE+"/add", data);
        if (result.status == 200 || result.status == 201) {
          datamenuconfig.forEach(async(item:any,index:number) => {
            await httpClient.post(server.ROLE_CONFIG_REQUEST_TYPE+"/add", {role_id:result.data.id,menu_id:item});
          });
           dispatch(setROLE_CONFIG_REQUEST_TYPEAddSuccessToState(result.data));
           navigate("/general-ROLE_CONFIG_REQUEST_TYPE");
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setROLE_CONFIG_REQUEST_TYPEAddFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setROLE_CONFIG_REQUEST_TYPEAddFailedToState());
      }
    };
  };

  export const editROLE_CONFIG_REQUEST_TYPE = (data: any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setROLE_CONFIG_REQUEST_TYPEFetchingToState());
        // connect
        const result = await httpClient.put(`${server.ROLE_CONFIG_REQUEST_TYPE}/${data.id}`, data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setROLE_CONFIG_REQUEST_TYPEEditSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setROLE_CONFIG_REQUEST_TYPEEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setROLE_CONFIG_REQUEST_TYPEEditFailedToState());
      }
    };
  };


  export const deleteROLE_CONFIG_REQUEST_TYPE = (ROLE_CONFIG_REQUEST_TYPE_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.ROLE_CONFIG_REQUEST_TYPE + `/${ROLE_CONFIG_REQUEST_TYPE_id}`);
            await httpClient.delete(server.ROLE_CONFIG_REQUEST_TYPE + `/deleteby_role_id/${ROLE_CONFIG_REQUEST_TYPE_id}`);
            if (result.status == 204) {
               dispatch(setROLE_CONFIG_REQUEST_TYPEDeleteSuccessToState(result.data));
             } else {
               dispatch(setROLE_CONFIG_REQUEST_TYPEDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setROLE_CONFIG_REQUEST_TYPEDeleteFailedToState());
        }
    };
};
  