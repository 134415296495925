// import { history } from "../index";
import { AnyARecord } from "dns";
import {
    USER_FETCHING,
    USER_SUCCESS,
    CHECK_USER_NAME_SUCCESS,
    CHECK_USER_NAME_FAILED,
    USER_ADD_SUCCESS,
    USER_ADD_FAILED,
    USER_EDIT_SUCCESS,
    USER_EDIT_FAILED,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAILED,
    USER_FAILED,
    USER_CLEAR,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { Users } from "../types/users.type";
import i18n from 'i18next';
const Swal = require('sweetalert2')
export const Alertpopup = (lang:any) => {
  return (
    Swal.fire({
      title: `${i18n.t(`${lang}`)}`,
      text: `${i18n.t(`${lang}`)}`,
        icon: "error",
      })
   );
}
export const setUserFetchingToState = () => ({
    type: USER_FETCHING,
});
  
export const setUserSuccessToState = (
    payload: Users[],) => (
        {
            type: USER_SUCCESS,
            payload,
        }
);
export const setCheckUserNameSuccessToState = (
    payload: Users[],) => (
        {
            type: CHECK_USER_NAME_SUCCESS,
            payload,
        }
);

export const setUserAddSuccessToState = (payload: any) => ({
            type: USER_ADD_SUCCESS,
            payload,
});

export const setUserAddFailedToState = () => ({
    type: USER_ADD_FAILED,
});

export const setUserEditSuccessToState = (payload: any) => ({
    type: USER_EDIT_SUCCESS,
    payload,
});

export const setUserEditFailedToState = () => ({
    type: USER_EDIT_FAILED,
});

export const setUserDeleteSuccessToState = (payload: any) => ({
    type: USER_DELETE_SUCCESS,
    payload,
});

export const setUserDeleteFailedToState = () => ({
    type: USER_DELETE_FAILED,
});
  
export const setUserFailedToState = () => ({
    type: USER_FAILED,
});
export const setCheckUserNameFailedToState = () => ({
    type: CHECK_USER_NAME_FAILED,
});

export const setUserClearToState = () => ({
    type: USER_CLEAR,
});
  

export const loadUser = () => {
    return async(dispatch: any) => {
        dispatch(setUserFetchingToState());
        getDataUser(dispatch)
    };
};

const getDataUser = async (dispatch: any) => {
    try {
        const result = await httpClient.get<Users[]>(`${server.User}/getAll`);
        dispatch(setUserSuccessToState(result.data));
    }   catch (error) {
        dispatch(setUserFailedToState());
    }
}
export const loadUserAuto = (req_id:number) => {
  return async(dispatch: any) => {
      dispatch(setUserFetchingToState());
      getDataUserAuto(dispatch,req_id)
  };
};
export const loadUserDep = () => {
  return async(dispatch: any) => {
      dispatch(setUserFetchingToState());
      getDataUserDep(dispatch)
  };
};
export const loadUserById = (user_id:number) => {
  return async(dispatch: any) => {
      dispatch(setUserFetchingToState());
      getDataUserByID(dispatch,user_id)
  };
};
export const loadUserByAdmin = () => {
  return async(dispatch: any) => {
      dispatch(setUserFetchingToState());
      getDataUserByAdmin(dispatch)
  };
};
export const loadCheckUserName = (user_name:string) => {
  return async(dispatch: any) => {
      dispatch(setUserFetchingToState());
      CheckUserName(dispatch,user_name)
  };
};

export const loadAllUserandMember = () => {
  return async(dispatch: any) => {
      dispatch(setUserFetchingToState());
      getDataAllUserandMember(dispatch)
  };
};

const getDataUserByID = async (dispatch: any, user_id:number) => {
  try {
      const result = await httpClient.get<Users[]>(`${server.User}/getbyid/${user_id}`);
      dispatch(setUserSuccessToState(result.data));
  }   catch (error) {
      dispatch(setUserFailedToState());
  }
}
const getDataUserByAdmin = async (dispatch: any) => {
  try {
      const result = await httpClient.get<Users[]>(`${server.User}/getAll-by-admin`);
      dispatch(setUserSuccessToState(result.data));
  }   catch (error) {
      dispatch(setUserFailedToState());
  }
}
const getDataUserAuto = async (dispatch: any, req_id:number) => {
  try {
      // console.log(`req_status: ` +req_id)
      const result = await httpClient.get<Users[]>(`${server.User}/getAllAuto/${req_id}`);
      dispatch(setUserSuccessToState(result.data));
  }   catch (error) {
      dispatch(setUserFailedToState());
  }
}

const getDataAllUserandMember = async (dispatch: any) => {
  try {
      const result = await httpClient.get<Users[]>(`${server.User}/getAllUserandMember`);
      dispatch(setUserSuccessToState(result.data));
  }   catch (error) {
      dispatch(setUserFailedToState());
  }
}

const getDataUserDep = async (dispatch: any) => {
  try {
     
      const result = await httpClient.get<any>(`${server.User}/getAll-by-dep`);
      //  console.log(`res_dep`)
      //  console.log(result.data)
      dispatch(setUserSuccessToState(result.data));
  }   catch (error) {
      dispatch(setUserFailedToState());
  }
}
const CheckUserName = async (dispatch: any, user_name:string) => {
  try {
      const result = await httpClient.get<any[]>(`${server.User}/check-user-name/${user_name}`);
      // console.log(result.data)
      if(result.data[0].status_check < 1){
        dispatch(setCheckUserNameSuccessToState(result.data));
      }else{
        dispatch(setCheckUserNameFailedToState());
      }
  }   catch (error) {
  }
}
export const addUsers = (data: any, navigate: any) => {
    console.log(data)
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setUserFetchingToState());
        // connect
        const result_check = await httpClient.get<any[]>(`${server.User}/check-user-name/${data.user_name}`);
        // console.log(result_check.data)
        if(result_check.data[0].status_check >= 1){
            Swal.fire({
                title: `${i18n.t('Information Saved')}`,
                text:  `${i18n.t('The information has been saved and updated successfully.')}`,
                icon: "error",
            });
 

        }else{
          const result = await httpClient.post(server.User+"/add", data);
          if (result.status == 200 || result.status == 201) {
            dispatch(setUserAddSuccessToState(result.data));
            navigate("/general-users");
          } else if(result.status == 204){
            dispatch(Alertpopup("Password Incorrect"));
          } else {

            dispatch(setUserAddFailedToState());
          }
        }
      } catch (error) {
        // error
        dispatch(setUserAddFailedToState());
      }
    };
  };

  export const editUsers = (data: any,user_id:number, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setUserFetchingToState());
        // connect
        const result = await httpClient.put(`${server.User}/${user_id}`, data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setUserEditSuccessToState(result.data));
           navigate("/general-users");
        } else if(result.status == 204){
            dispatch(Alertpopup("Password Incorrect"));
        } else {
          dispatch(setUserEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setUserEditFailedToState());
      }
    };
  };


  export const deleteUsers = (user_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.User + `/${user_id}`);
            if (result.status == 204) {
               dispatch(setUserDeleteSuccessToState(result.data));
             } else {
               dispatch(setUserDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setUserDeleteFailedToState());
        }
    };
};
  