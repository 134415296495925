import {
    REQUEST_SUB_TYPE_LIST_FETCHING,
    REQUEST_SUB_TYPE_LIST_SUCCESS,
    REQUEST_SUB_TYPE_LIST_FAILED,
    REQUEST_SUB_TYPE_ADD_SUCCESS,
    REQUEST_SUB_TYPE_ADD_FAILED,
    REQUEST_SUB_TYPE_EDIT_SUCCESS,
    REQUEST_SUB_TYPE_EDIT_FAILED,
    REQUEST_SUB_TYPE_DELETE_SUCCESS,
    REQUEST_SUB_TYPE_DELETE_FAILED,
} from "../services/Constants";
import { RequesSubTypeList } from "../types/request_sub_type_list.type";
      
    export interface RequestSubTypeListState {
        result: RequesSubTypeList[];
        isFetching: boolean;
        isError: boolean;
        isAdd: boolean;
        isAddError: boolean;
        isEdit: boolean;
        isEditError: boolean;
        isDelete: boolean;
        isDeleteError: boolean;
    }
      
    const initialState: RequestSubTypeListState = {
        result: [],
        isFetching: false,
        isError: false,
        isAdd: false,
        isAddError: false,
        isEdit: false,
        isEditError: false,
        isDelete: false,
        isDeleteError: false,
    };
      
export default (state = initialState, { type, payload}: any) => {
    switch (type) {
        case REQUEST_SUB_TYPE_LIST_FETCHING:
            return { ...state, result: [], isFetching: true, isError: true ,isAdd: false,isAddError: false, isEdit: false,isEditError: false,isDelete: false,isDeleteError: false,};
        case REQUEST_SUB_TYPE_LIST_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false,isAdd: false,isAddError: false, isEdit: false,isEditError: false ,isDelete: false,isDeleteError: false,};
        case REQUEST_SUB_TYPE_LIST_FAILED:
            return { ...state, result: [], isFetching: false, isError: true ,isAdd: false,isAddError: false, isEdit: false,isEditError: false,isDelete: false,isDeleteError: false,};
        case REQUEST_SUB_TYPE_ADD_SUCCESS:
            return { ...state, result: [], isFetching: false, isError: true ,isAdd: true,isAddError: false, isEdit: false,isEditError: false,isDelete: false,isDeleteError: false,};
        case REQUEST_SUB_TYPE_ADD_FAILED:
            return { ...state, result: [], isFetching: false, isError: true ,isAdd: false,isAddError: true, isEdit: false,isEditError: false,isDelete: false,isDeleteError: false,};
        case REQUEST_SUB_TYPE_EDIT_SUCCESS:
            return { ...state, result: [], isFetching: false, isError: true ,isAdd: false,isAddError: false, isEdit: true,isEditError: false,isDelete: false,isDeleteError: false,};
        case REQUEST_SUB_TYPE_EDIT_FAILED:
            return { ...state, result: [], isFetching: false, isError: true ,isAdd: false,isAddError: false, isEdit: false,isEditError: true,isDelete: false,isDeleteError: false,};
        case REQUEST_SUB_TYPE_DELETE_SUCCESS:
            return { ...state, result: [], isFetching: false, isError: true ,isAdd: false,isAddError: false, isEdit: false,isEditError: false,isDelete: true,isDeleteError: false,};
        case REQUEST_SUB_TYPE_DELETE_FAILED:
            return { ...state, result: [], isFetching: false, isError: true ,isAdd: false,isAddError: false, isEdit: false,isEditError: false,isDelete: false,isDeleteError: true,};
        default:
            return state;
        }
    };
      