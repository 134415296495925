import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../../services/Constants";
import * as requestTypeListActions from "../../../../actions/requesttype.list.action";
import * as generalMenuActions from "../../../../actions/generalmenu.action";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import * as requestDeleteActions from "../../../../actions/request.delete.actions";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import { RootReducers } from "../../../../reducers";
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN } from "../../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./RequestSubType.css";
import { Search as SearchIcon } from "@mui/icons-material";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import * as requestSubTypeListActions from "../../../../actions/requestsubtype.list.action";

import * as loginActions from "../../../../actions/login.action";
import i18next from 'i18next';
// import SearchBar from "material-ui-search-bar";
// const CustomToolbar = (props:any) => {
//     return (
//         <Box>
//             <Grid>
//                 <SearchBar {...props} />
//             </Grid>
//         </Box>
//     )
// }



interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}


type GeneralMenuProps = {
  //
};

const GeneralMenu: React.FC<any> = () => {
  const requesTypeListReducer = useSelector((state: RootReducers) => state.requesTypeListReducer);
  const generalMenuStateReducer = useSelector((state: RootReducers) => state.generalMenuStateReducer);
  const requestSubTypeListReducer = useSelector((state: RootReducers) => state.requestSubTypeListReducer);
  const menuconfigReducer = useSelector((state: RootReducers) => state.menuconfigStateReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedDeleteRequestSubType, setselectedDeleteRequestSubType] = React.useState<any>({});
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dataAddRequestSubType, setDataAddRequestSubType] = React.useState({request_sub_type:"",request_sub_type_en:""});
  const [dataEditRequestSubType, setdataEditRequestSubType] = React.useState({request_sub_type:"",request_sub_type_en:""});
  
  const [openaddGeneralMenu, setOpenaddGeneralMenu] = React.useState(false);
  const [openeditGeneralMenu, setOpeneditGeneralMenu] = React.useState(false);
  const [request_type_list, setRequesttypelist] = React.useState<any[]>([]);
  const [checkedrequestsubtype, setcheckedrequestsubtype] = React.useState<any>([]);
  const [checkedrequestsubtypeedit, setcheckedrequestsubtypeedit] = React.useState<any>([]);
  const [request_id_edit, setRequestTypeIdEdit] = React.useState(0);
  const Alllanguages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'Thai'
    }
  ];

  const handleAddGeneralMenu = () => {
    setDataAddRequestSubType({request_sub_type:"",request_sub_type_en:""})
    console.log("add");
    setOpenaddGeneralMenu(true);
};

const handleEditGeneralMenu = (data:any) => {
    console.log(data)
    setRequestTypeIdEdit(data.id)
    setdataEditRequestSubType({request_sub_type: data.request_sub_name, request_sub_type_en: data.request_sub_name_en});
    let data_request_type = [];
    data_request_type = request_type_list.find(option => Number(option.id) === Number(data.requesttype_id))
    // console.log(data_request_type);
    setcheckedrequestsubtypeedit(data_request_type)
    setOpeneditGeneralMenu(true);
};


  const handleCloseaddGeneralMenu = () => {
    setOpenaddGeneralMenu(false);
  };

  const handleCloseeditGeneralMenu = () => {
    setOpeneditGeneralMenu(false);
  };

  const handleAddGeneralMenuSubmit = (event:any) => {
    event.preventDefault();
    console.log("SubmitAdd");
    if(checkedrequestsubtype.length == 0){
      Swal.fire({
        title: `${t("Check Data")}`,
        text: `${t("Please Enter the Reques Type")}`,
        icon: "error",
      });
      return;
    }
    let dataAdd = {
      request_sub_name: "",
      request_sub_name_en: "",
      requesttype_id: 0,
    }
    dataAdd.request_sub_name = dataAddRequestSubType.request_sub_type;
    dataAdd.request_sub_name_en = dataAddRequestSubType.request_sub_type_en;
    dataAdd.requesttype_id = Number(checkedrequestsubtype.id);
    // console.log(dataAdd)
    dispatch(requestSubTypeListActions.addRequestSubType(dataAdd,navigate));
  };
  const handleEditGeneralMenuSubmit = (event:any) => {
    event.preventDefault();
    console.log("SubmitEdit");

    let dataEdit = {
      request_sub_name: "",
      request_sub_name_en: "",
      requesttype_id: 0,
    }
    dataEdit.request_sub_name = dataEditRequestSubType.request_sub_type;
    dataEdit.request_sub_name_en = dataEditRequestSubType.request_sub_type_en;
    dataEdit.requesttype_id = Number(checkedrequestsubtypeedit.id);
    // console.log(dataEdit)
    console.log(request_id_edit)
    dispatch(requestSubTypeListActions.editRequestSubType(request_id_edit,dataEdit,navigate));
  };

  React.useEffect(() => {
    if (requestSubTypeListReducer.isAdd) {
        setOpenaddGeneralMenu(false);
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated successfully.")}`,
            icon: "success",
          });
        
        dispatch(requestSubTypeListActions.loadRequestSubTypeDetailList());
        return;
    } 
    if(requestSubTypeListReducer.isAddError) {
        setOpenaddGeneralMenu(false);
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated unsuccessfully.")}`,
            icon: "error",
          });

        dispatch(requestSubTypeListActions.loadRequestSubTypeDetailList());
        return;
    }
    if(requestSubTypeListReducer.isEdit) {
        setOpeneditGeneralMenu(false);
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated successfully.")}`,
            icon: "success",
          });
        dispatch(requestSubTypeListActions.loadRequestSubTypeDetailList());
        return;
    }
    if(requestSubTypeListReducer.isEditError) {
        setOpeneditGeneralMenu(false);
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated unsuccessfully.")}`,
            icon: "error",
          });
        dispatch(requestSubTypeListActions.loadRequestSubTypeDetailList());
        return;
     
    }
    
    if(requestSubTypeListReducer.isDelete) {
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated successfully.")}`,
            icon: "success",
          });
        dispatch(requestSubTypeListActions.loadRequestSubTypeDetailList());
        return;
     
    }
    if(requestSubTypeListReducer.isDeleteError) {
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated unsuccessfully.")}`,
            icon: "error",
        });
        dispatch(requestSubTypeListActions.loadRequestSubTypeDetailList());
        return;  
    }
  }, [requestSubTypeListReducer]);
  
 

  //load data table
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      dispatch(loginActions.logouttologinpage(navigate));
    }
    const callawaitfunction = async () =>{
      await dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
      await dispatch(requestTypeListActions.loadRequestTypeListData());
      await dispatch(requestSubTypeListActions.loadRequestSubTypeDetailList());
      await dispatch(generalMenuActions.loadGENERAL_MENU());
    }
    callawaitfunction();
      // menuconfigReducer
  }, []);

  React.useEffect(() => {
    if(requesTypeListReducer.result.length >0){
      let  dataList:any[] = [];
      let index = 0;
      requesTypeListReducer.result.forEach((item) =>{
        if(i18next.language == 'en'){
          item.request_name_show = item.request_type_name_en;
        }else if(i18next.language == 'th'){
          item.request_name_show = item.request_type_name;
        }else{
          return;
        }
          dataList[index] = item;
          index += 1;
      })
      console.log("dataList request")
      console.log(dataList)
      setRequesttypelist(dataList)
    }
  }, [requesTypeListReducer.result,i18next.language]);

  React.useEffect(() => {

  }, [generalMenuStateReducer]);

  const requestColumns: GridColDef[] = [
      {
          headerName: `${t('ID')}`,
          field: "id",
          maxWidth: 120,
          minWidth: 50 
      },
      {
          headerName: `${t("Request Type")}`,
          field: "request_type_show",
          flex: 1,
          minWidth: 150 
      },
      {
          headerName: `${t("Request Sub Type(TH)")}`,
          field: "request_sub_name",
          flex: 1,
          minWidth: 150 
      },
      {
        headerName: `${t("Request Sub Type(EN)")}`,
        field: "request_sub_name_en",
        flex: 1,
        minWidth: 150 
      },
      
      {
          headerName: "",
          field: ".",
          maxWidth: 150,
          align: "center",
          renderCell: ({ row }: GridRenderCellParams<string>) => (
              <Stack direction="row">
                  <IconButton
                      aria-label="edit"
                      size="large"
                      onClick={() => {
                        handleEditGeneralMenu(row);
                      }}
              >
                      <EditIcon 
                          fontSize="inherit" 
                          sx={{ color: blue[700] }}  
                      />
                  </IconButton>
                  <IconButton
                      disabled={row.status == "Close" || row.status == "Cancel" }
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                          // console.log(row)
                          setRequestTypeIdEdit(row.id)
                          setselectedDeleteRequestSubType(row);
                          setOpenDialog(true);
                      }}
                  >
                      <DeleteIcon 
                          fontSize="inherit" 
                          sx={{ color: red[500] }}
                      />
                  </IconButton>
              </Stack>
            ),
      },
  ];

  //search
  const [searchText, setSearchText] = React.useState("");
  const [searchText1, setSearchText1] = React.useState("");
  const [tableData, setTableData] = React.useState<any[]>([]);

  React.useEffect(() => {
      let  dataList:any[] = [];
      let index = 0;
      requestSubTypeListReducer.result.forEach((item) =>{
          if(i18next.language == 'en'){
            item.request_type_show = item.request_type_name_en;
          }else if(i18next.language == 'th'){
            item.request_type_show = item.request_type_name;
          }else{
            return;
          }
          dataList[index] = item;
          index += 1;
      })
      console.log("data table")
      console.log(dataList)
      setTableData(dataList)
  }, [requestSubTypeListReducer.result,i18next.language]);

  const requestSearch = (searchValue: string) => {
      // const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
      // const filteredRows = generalMenuStateReducer.result.filter((o: any) => {
      //   return Object.keys(o).some((k: any) => {
      //     return searchRegex.test(o[k].toString());
      //   });
      // });
      const filteredRows = generalMenuStateReducer.result.filter((row) => {
        return row.menu_name?.toString().toLowerCase().includes(searchValue.toLowerCase());
      });
      setTableData(filteredRows);
  };

  // const cancelSearch = () => {
  //     setSearchText("");
  //     requestSearch(searchText);
  // };
  const handleDeleteConfirm = () => {
      // console.log(selectedDeleteRequestSubType)
      // console.log(request_id_edit)
      setOpenDialog(false);
      dispatch(requestSubTypeListActions.deleteSubRequestType(Number(request_id_edit),navigate));
      
  };



  const showDialog = () => {
      if (selectedDeleteRequestSubType === null) {
          return "";
      }
  return (
      <Dialog
      fullWidth
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">
            {t("Delete Data")}
             
          </DialogTitle>
          <DialogContent>
          { i18next.language == "en" &&
            <DialogContentText id="alert-dialog-slide-description">  
              {t("Confirm Delete Data")} : {selectedDeleteRequestSubType.request_sub_name_en}
            </DialogContentText>
          }
          { i18next.language == "th" &&
            <DialogContentText id="alert-dialog-slide-description">  
              {t("Confirm Delete Data")} : {selectedDeleteRequestSubType.request_sub_name}
            </DialogContentText>
          }
          </DialogContent>
          <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="info">
              ยกเลิก
          </Button>
          <Button onClick={() => handleDeleteConfirm()} color="primary">
              ยืนยัน
          </Button>
          </DialogActions>
      </Dialog>
      );
  };

  const showDialogAddGeneralMenu = () => {
   
return (
  <Dialog
  disableScrollLock
  fullWidth
  open={openaddGeneralMenu}
  onClose={handleCloseaddGeneralMenu}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
<form onSubmit={handleAddGeneralMenuSubmit}>
  <DialogTitle id="alert-dialog-title" >
  {t("Add Request Sub Type")} 
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
    </DialogContentText>
    <Grid container  >
            <Grid 
              sx={{alignSelf:"center",mt:2}}
              item xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
            <Typography  variant="inherit"  component="h6">
              {t('Reques Type')} :
            </Typography>
          </Grid>
          <Grid 
            sx={{alignSelf:"center",mt:2}}
            item 
            xs={12}
            sm={8}
            md={8}
            lg={8}
            xl={8}
          >
              <Autocomplete
                  disableClearable={true}
                  size="small"
                  fullWidth
                  id="multiple-limit-tags"
                  options={request_type_list || null}
                  getOptionLabel={(option) => option.request_name_show}
                  isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                  onChange={(event, newValue:any) => {
                    setcheckedrequestsubtype(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                    variant="outlined"
                    {...params} label={t("Select")}  />
                  )}
                  sx={{
                    //  width:500,
                    "& .MuiInput-underline:before": {
                      borderBottom: 1,
                      borderColor: "divider",
                    },
                  }}
              />
          </Grid>
        </Grid>
    <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Request Sub Type(TH)')} :
</Typography>
</Grid>
<Grid sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          size="small"
          onChange={(e) => {
            setDataAddRequestSubType({...dataAddRequestSubType,request_sub_type:e.target.value});
           
          }}
          value={dataAddRequestSubType.request_sub_type}
        />
         </Grid>
       </Grid>
       
       <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Request Sub Type(EN)')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
      <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          size="small"
          onChange={(e) => {
            setDataAddRequestSubType({...dataAddRequestSubType,request_sub_type_en:e.target.value});
          
           
          }}
          value={dataAddRequestSubType.request_sub_type_en}
        />
         </Grid>
       </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseaddGeneralMenu}> {t("Cancel")} </Button>
    <Button type="submit" autoFocus>
    {t("Save")} 
    </Button>
  </DialogActions>
  </form>
</Dialog>
    );
};

const showDialogEditGeneralMenu = () => {
   
    return (
      <Dialog
      disableScrollLock
      fullWidth
      open={openeditGeneralMenu}
      onClose={handleCloseeditGeneralMenu}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <form onSubmit={handleEditGeneralMenuSubmit}>
      <DialogTitle id="alert-dialog-title">
      {t("Edit Request Sub Type")} 
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        </DialogContentText>
        <Grid container  >
            <Grid 
              sx={{alignSelf:"center",mt:2}}
              item xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
            <Typography  variant="inherit"  component="h6">
              {t('Reques Type')} :
            </Typography>
          </Grid>
          <Grid 
            sx={{alignSelf:"center",mt:2}}
            item 
            xs={12}
            sm={8}
            md={8}
            lg={8}
            xl={8}
          >
              <Autocomplete
                  disableClearable={true}
                  value={checkedrequestsubtypeedit}
                  size="small"
                  fullWidth
                  id="multiple-limit-tags"
                  options={request_type_list || null}
                  getOptionLabel={(option) => option.request_name_show}
                  isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                  onChange={(event, newValue:any) => {
                    setcheckedrequestsubtypeedit(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                    variant="outlined"
                    {...params} label={t("Select")}  />
                  )}
                  sx={{
                    //  width:500,
                    "& .MuiInput-underline:before": {
                      borderBottom: 1,
                      borderColor: "divider",
                    },
                  }}
              />
          </Grid>
        </Grid>
        <Grid container  >
                        <Grid 
                        sx={{alignSelf:"center",mt:2}}
                        item xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}>
        <Typography  variant="inherit"  component="h6">
    {t('Request Sub Type(TH)')} :
    </Typography>
    </Grid>
    
    <Grid  sx={{alignSelf:"center",mt:2}} item xs={12}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email_address"
              size="small"
              onChange={(e) => {
                    setdataEditRequestSubType({...dataEditRequestSubType,request_sub_type:e.target.value});
              
               
              }}
              value={dataEditRequestSubType.request_sub_type}
            />
             </Grid>
             </Grid>

             <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Request Sub Type(EN)')} :
</Typography>
</Grid> 
             <Grid   sx={{alignSelf:"center",mt:2}} item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
       <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          size="small"
          onChange={(e) => {
                setdataEditRequestSubType({...dataEditRequestSubType,request_sub_type_en:e.target.value});
          
           
          }}
          value={dataEditRequestSubType.request_sub_type_en}
        />
         </Grid>
           </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseeditGeneralMenu}> {t("Cancel")} </Button>
        <Button type="submit" autoFocus>
        {t("Save")} 
        </Button>
      </DialogActions>
      </form>
    </Dialog>
        );
    };

  return (
    <Container fixed sx={{ mt: 3}} >
      <Box 
      sx={{}}
     >
          {/* Summary Icons */}
          <Grid 
          container item
          justifyContent="center"
          alignItems="center"
          sx={{
              margin: 'auto',
              width: '100%',
              maxWidth: '60rem'
          }}
          ><Typography variant="body1">{t(`Request Sub Type Management Page`)}</Typography>

          </Grid>

          <Grid container justifyContent="flex-end">
              <Button 
                  size="medium"
                  variant="contained" 
                  sx={{
                      marginTop: 2,
                      marginLeft:2,
                      marginRight: 2,
                      minWidth: 200,
                      backgroundColor: "#c79059"
                  }}
                  className="requestButton"
                  onClick={() => { handleAddGeneralMenu()}}
              >
                  {t("Add Request Sub Type")} 
              </Button>
          </Grid>
          <br />
          <Grid>
          <Grid container spacing={2}>
 
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
   <TextField 
   fullWidth 
                  placeholder="ค้นหา..."
                  variant="outlined"
                  size="small"
                  InputProps={{
                      endAdornment: (<IconButton><SearchIcon /></IconButton>)
                  }}
                  onChange={(e)=>{
                      requestSearch(e.target.value)
                      setSearchText(e.target.value)
                  }} 
                  sx={{
                      //  width:500,
                      "& .MuiInput-underline:before": {
                          borderBottom: 1,
                          borderColor: "divider",
                      },
                      marginBottom:2
                  }}
              />
  </Grid>
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
  </Grid>
</Grid>
              
              <DataGrid
                  // components={{ Toolbar: CustomToolbar }}
                  sx={{ backgroundColor: "white"}}  
                  rows={tableData}
                  columns={requestColumns}
                  // componentsProps={{
                  //     toolbar: {
                  //       value: searchText,
                  //       onChange: (searchVal: string) => requestSearch(searchVal),
                  //       onCancelSearch: () => cancelSearch()
                  //     }
                  // }}
                  autoHeight
                  pageSize={10}
                  rowsPerPageOptions={[10]}
              />
          </Grid>
          <br />
          <br />
          {showDialog()}
          {showDialogAddGeneralMenu()}
          {showDialogEditGeneralMenu()}
      </Box>
      </Container>
);
                }
export default GeneralMenu;
