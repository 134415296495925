// import { history } from "../index";
import {
    server,
    REQUEST_SUB_TYPE_LIST_FETCHING,
    REQUEST_SUB_TYPE_LIST_SUCCESS,
    REQUEST_SUB_TYPE_LIST_FAILED,
    REQUEST_SUB_TYPE_ADD_SUCCESS,
    REQUEST_SUB_TYPE_ADD_FAILED,
    REQUEST_SUB_TYPE_EDIT_SUCCESS,
    REQUEST_SUB_TYPE_EDIT_FAILED,
    REQUEST_SUB_TYPE_DELETE_SUCCESS,
    REQUEST_SUB_TYPE_DELETE_FAILED,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { RequesSubTypeList } from "../types/request_sub_type_list.type";
const Swal = require('sweetalert2')

export const setRequestSubTypeListFetchingToState = () => ({
    type: REQUEST_SUB_TYPE_LIST_FETCHING,
});
  
export const setRequestSubTypeListSuccessToState = (payload: RequesSubTypeList[]) => ({
    type: REQUEST_SUB_TYPE_LIST_SUCCESS,
    payload,
});
  
export const setRequestSubTypeListFailedToState = () => ({
    type: REQUEST_SUB_TYPE_LIST_FAILED,
});

export const setRequestSubTypeAddSuccessToState = (payload:any) => ({
    type: REQUEST_SUB_TYPE_ADD_SUCCESS,
    payload,
});

export const setRequestSubTypeAddFailToState = () => ({
    type: REQUEST_SUB_TYPE_ADD_FAILED,
});
export const setRequestSubTypeEditSuccessToState = (payload:any) => ({
    type: REQUEST_SUB_TYPE_EDIT_SUCCESS,
    payload,
});

export const setRequestSubTypeEditFailToState = () => ({
    type: REQUEST_SUB_TYPE_EDIT_FAILED,
});
export const setRequestSubTypeDeleteSuccessToState = (payload:any) => ({
    type: REQUEST_SUB_TYPE_DELETE_SUCCESS,
    payload,
});

export const setRequestSubTypeDeleteFailToState = () => ({
    type: REQUEST_SUB_TYPE_DELETE_FAILED,
});

export const loadRequestSubTypeList = () => {
    return async(dispatch: any) => {
        dispatch(setRequestSubTypeListFetchingToState());
        try {
            let result = await httpClient.get<any>(`${server.REQUEST_SUB_TYPE}`);
            // console.log("result.data")
            // alert(JSON.stringify(result.data))
            dispatch(setRequestSubTypeListSuccessToState(result.data));
        } catch (error) {
            dispatch(setRequestSubTypeListFailedToState());
        }
    };
};
export const loadRequestSubTypeDetailList = () => {
    return async(dispatch: any) => {
        dispatch(setRequestSubTypeListFetchingToState());
        try {
            let result = await httpClient.get<any>(`${server.REQUEST_SUB_TYPE}/detail`);
            // console.log("result.data")
            // alert(JSON.stringify(result.data))
            dispatch(setRequestSubTypeListSuccessToState(result.data));
        } catch (error) {
            dispatch(setRequestSubTypeListFailedToState());
        }
    };
};

export const addRequestSubType = (data: any, navigate: any) => {
    console.log("data action")
    console.log(data)
    return async (dispatch: any) => {
      try {
        dispatch( setRequestSubTypeListFetchingToState())
        const result = await httpClient.post(server.REQUEST_SUB_TYPE+"/add", data);
        if (result.status == 200 || result.status == 201) {
            dispatch(setRequestSubTypeAddSuccessToState(result.data))
        } 
      } catch (error) {
        dispatch(setRequestSubTypeAddFailToState())
      }
    };
};

export const editRequestSubType = (id:number,data: any, navigate: any) => {
    console.log("data action")
    console.log(data)
    return async (dispatch: any) => {
      try {
        dispatch( setRequestSubTypeListFetchingToState())
        const result = await httpClient.put(server.REQUEST_SUB_TYPE+`/edit/${id}`, data);
        if (result.status == 200 || result.status == 201) {
            dispatch(setRequestSubTypeEditSuccessToState(result.data))
        } 
      } catch (error) {
        dispatch(setRequestSubTypeEditFailToState())
      }
    };
};

export const deleteSubRequestType = (id:number, navigate: any) => {
    return async (dispatch: any) => {
      try {
        dispatch( setRequestSubTypeListFetchingToState())
        const result = await httpClient.delete(server.REQUEST_SUB_TYPE+`/${id}`);
        if (result.status == 200 || result.status == 201) {
            dispatch(setRequestSubTypeDeleteSuccessToState(result.data))
        } 
      } catch (error) {
        dispatch(setRequestSubTypeDeleteFailToState())
      }
    };
};