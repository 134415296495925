import {
    REQUEST_ASSIGN_FETCHING,
    REQUEST_ASSIGN_SUCCESS,
    REQUEST_ASSIGN_FAILED,
    REQUEST_ASSIGN_LEVEL_SUCCESS,
    REQUEST_ASSIGN_LEVEL_FAILED
  } from "../services/Constants";
  import { Request } from "../types/request.type";
    
    export interface RequestAssignState {
        result: any[];
        isFetching: boolean;
        isError: boolean;
        isResAll: boolean;
        isResOwner: boolean;
        res_count: number
        oper_count: number
        sucess_count: number
    }
    
    const initialState: RequestAssignState = {
        result: [],
        isFetching: false,
        isError: false,
        isResAll: false,
        isResOwner: false,
        res_count: 0,
        oper_count: 0,
        sucess_count: 0,
    };
    
    export default (state = initialState, { type, payload, res_count, oper_count, sucess_count}: any) => {
      switch (type) {
        case REQUEST_ASSIGN_FETCHING:
          return { ...state, result: [], isFetching: true, isError: false, isResAll: false, isResOwner: false};
        case REQUEST_ASSIGN_SUCCESS:
          return { 
            ...state, 
            result: payload, 
            isFetching: false, 
            isError: false,
            isResAll: true,
            isResOwner: false
          };
        case REQUEST_ASSIGN_FAILED:
          return { ...state, result: [], isFetching: false, isError: true, isResAll: false, isResOwner: false};
        case REQUEST_ASSIGN_LEVEL_SUCCESS:
            return { 
              ...state, 
              result: payload, 
              isFetching: false, 
              isError: false,
              isResAll: false,
              isResOwner: true
            };
        case REQUEST_ASSIGN_LEVEL_FAILED:
          return { ...state, result: [], isFetching: false, isError: true, isResAll: false, isResOwner: false};
        default:
          return state;
      }
    };
    