import * as React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  TextField,
  Divider,
  SxProps,
  Theme,
} from "@mui/material";
import { FormikProps, Form, Field, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DataSheetCreate } from "../../../types/data_sheet.type";
import { Paper, Grid } from "@mui/material";
import { useAppDispatch } from "../../..";
import { useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";

import dayjs, { Dayjs } from "dayjs";
import { MEMBER_ID, server, TOKEN } from "../../../services/Constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import ShareHolderTH from "../../layouts/ShareHolderTH";
import ShareHolderEN from "../../layouts/ShareHolderEN";
import DirectorTH from "../../layouts/DirectorTH";
import DirectorEN from "../../layouts/DirectorEN";

import * as dataSheetCreateActions from "../../../actions/datasheet.create.action";
import * as directorActions from "../../../actions/director.action";
import * as shareholderActions from "../../../actions/shareholder.action";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import 'dayjs/locale/en-gb';
import * as loginActions from "../../../actions/login.action";
import { useTranslation } from "react-i18next";
import * as menuconfigActions from "../../../actions/menuconfig.action";

type DataSheetEditPageProps = {
  //
};

const DataSheetEditPage: React.FC<any> = () => {
  const { t,i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const Swal = require('sweetalert2')
  const dataSheetCreateReducer = useSelector((state: RootReducers) => state.dataSheetCreateReducer);

  const start = Date.now();
  const [defaultvalue, setdefaultvalue] = React.useState<DataSheetCreate>({});
  // Config TH
  const [valueDTP_TH_Issued_On, setValueDTP_TH_Issued_On] = React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_TH_Statutory_Meet_Date, setValueDTP_TH_Statutory_Meet_Date] = React.useState<Dayjs | null>(
    dayjs(start)
  );
  const [valueDTP_TH_Income_Tax_Return_Filing_Date, setDTP_TH_Income_Tax_Return_Filing_Date] =
    React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_TH_Publish_Notice_In_Newspaper_Date, setDTP_TH_Publish_Notice_In_Newspaper_Date] =
    React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_TH_Vat_Issued_On, setDTP_TH_Vat_Issued_On] = React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_TH_Tax_Issued_On, setDTP_TH_Tax_Issued_On] = React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_TH_Social_Security_Date, setDTP_TH_Social_Security_Date] = React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_TH_Last_Revised_Date, setDTP_TH_Last_Revised_Date] = React.useState<Dayjs | null>(dayjs(start));

  const handleDTP_TH_Issued_OnChange = (newValue: Dayjs | null) => {
    setValueDTP_TH_Issued_On(newValue);
  };
  const handleDTP_TH_Statutory_Meet_Date_OnChange = (newValue: Dayjs | null) => {
    setValueDTP_TH_Statutory_Meet_Date(newValue);
  };
  const handleDTP_TH_Income_Tax_Return_Filing_Date_OnChange = (newValue: Dayjs | null) => {
    setDTP_TH_Income_Tax_Return_Filing_Date(newValue);
  };

  const handleDTP_TH_Publish_Notice_In_Newspaper_Date_OnChange = (newValue: Dayjs | null) => {
    setDTP_TH_Publish_Notice_In_Newspaper_Date(newValue);
  };
  const handleDTP_TH_Vat_Issued_On_OnChange = (newValue: Dayjs | null) => {
    setDTP_TH_Vat_Issued_On(newValue);
  };
  const handleDTP_TH_Tax_Issued_On_OnChange = (newValue: Dayjs | null) => {
    setDTP_TH_Tax_Issued_On(newValue);
  };

  const handleDTP_TH_Social_Security_Date_OnChange = (newValue: Dayjs | null) => {
    setDTP_TH_Social_Security_Date(newValue);
  };

  const handleDTP_TH_Last_Revised_Date_OnChange = (newValue: Dayjs | null) => {
    setDTP_TH_Last_Revised_Date(newValue);
  };
  // Config TH

  // Config EN
  const [valueDTP_EN_Issued_On, setValueDTP_EN_Issued_On] = React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_EN_Statutory_Meet_Date, setValueDTP_EN_Statutory_Meet_Date] = React.useState<Dayjs | null>(
    dayjs(start)
  );
  const [valueDTP_EN_Income_Tax_Return_Filing_Date, setDTP_EN_Income_Tax_Return_Filing_Date] =
    React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_EN_Publish_Notice_In_Newspaper_Date, setDTP_EN_Publish_Notice_In_Newspaper_Date] =
    React.useState<Dayjs | null>(dayjs(start));

  const [valueDTP_EN_Vat_Issued_On, setDTP_EN_Vat_Issued_On] = React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_EN_Tax_Issued_On, setDTP_EN_Tax_Issued_On] = React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_EN_Social_Security_Date, setDTP_EN_Social_Security_Date] = React.useState<Dayjs | null>(dayjs(start));
  const [valueDTP_EN_Last_Revised_Date, setDTP_EN_Last_Revised_Date] = React.useState<Dayjs | null>(dayjs(start));

  const handleDTP_EN_Issued_OnChange = (newValue: Dayjs | null) => {
    setValueDTP_EN_Issued_On(newValue);
  };
  const handleDTP_EN_Statutory_Meet_Date_OnChange = (newValue: Dayjs | null) => {
    setValueDTP_EN_Statutory_Meet_Date(newValue);
  };
  const handleDTP_EN_Income_Tax_Return_Filing_Date_OnChange = (newValue: Dayjs | null) => {
    setDTP_EN_Income_Tax_Return_Filing_Date(newValue);
  };

  const handleDTP_EN_Publish_Notice_In_Newspaper_Date_OnChange = (newValue: Dayjs | null) => {
    setDTP_EN_Publish_Notice_In_Newspaper_Date(newValue);
  };
  const handleDTP_EN_Vat_Issued_On_OnChange = (newValue: Dayjs | null) => {
    setDTP_EN_Vat_Issued_On(newValue);
  };
  const handleDTP_EN_Tax_Issued_On_OnChange = (newValue: Dayjs | null) => {
    setDTP_EN_Tax_Issued_On(newValue);
  };
  const handleDTP_EN_Social_Security_Date_OnChange = (newValue: Dayjs | null) => {
    setDTP_EN_Social_Security_Date(newValue);
  };

  const handleDTP_EN_Last_Revised_Date_OnChange = (newValue: Dayjs | null) => {
    setDTP_EN_Last_Revised_Date(newValue);
  };
  // Config EN
  const [datasheet_number, setdatasheet_number] = React.useState("");
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "center" },
    buttons: { marginTop: 2 },
  };
  const { id } = useParams();
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      // navigate("/login");
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(menuconfigActions.getMENU_CONFIGCheckTokenOnly(navigate));
    dispatch(dataSheetCreateActions.getDataSheetFindById(Number(id)));
    dispatch(directorActions.getDirectorList(Number(id)));
    dispatch(shareholderActions.getShare_HolderrList(Number(id)));
  }, []);
  let initialValues: DataSheetCreate = {};
  React.useEffect(() => {
    if (dataSheetCreateReducer.result_by_id) {
      initialValues = dataSheetCreateReducer.result_by_id;
      setdefaultvalue(dataSheetCreateReducer.result_by_id);
      // set default value TH
      setValueDTP_TH_Issued_On(dayjs(new Date(dataSheetCreateReducer.result_by_id.th_issued_on)));
      setValueDTP_TH_Statutory_Meet_Date(dayjs(new Date(dataSheetCreateReducer.result_by_id.th_statutory_meet_date)));
      setDTP_TH_Income_Tax_Return_Filing_Date(
        dayjs(new Date(dataSheetCreateReducer.result_by_id.th_income_tax_return_filing_date))
      );
      setDTP_TH_Vat_Issued_On(
        dayjs(new Date(dataSheetCreateReducer.result_by_id.th_vat_issued_on))
      );
      setDTP_TH_Tax_Issued_On(
        dayjs(new Date(dataSheetCreateReducer.result_by_id.th_tax_issued_on))
      );
      setDTP_TH_Publish_Notice_In_Newspaper_Date(
        dayjs(new Date(dataSheetCreateReducer.result_by_id.th_publish_notice_in_newspaper_date))
      );
      setDTP_TH_Social_Security_Date(dayjs(new Date(dataSheetCreateReducer.result_by_id.th_social_security_date)));
      // set default value EN
      setValueDTP_EN_Issued_On(dayjs(new Date(dataSheetCreateReducer.result_by_id.en_issued_on)));
      setValueDTP_EN_Statutory_Meet_Date(dayjs(new Date(dataSheetCreateReducer.result_by_id.en_statutory_meet_date)));
      setDTP_EN_Income_Tax_Return_Filing_Date(
        dayjs(new Date(dataSheetCreateReducer.result_by_id.en_income_tax_return_filing_date))
      );
      setDTP_EN_Vat_Issued_On(
        dayjs(new Date(dataSheetCreateReducer.result_by_id.en_vat_issued_on))
      );
      setDTP_EN_Tax_Issued_On(
        dayjs(new Date(dataSheetCreateReducer.result_by_id.en_tax_issued_on))
      );
      setDTP_EN_Publish_Notice_In_Newspaper_Date(
        dayjs(new Date(dataSheetCreateReducer.result_by_id.en_publish_notice_in_newspaper_date))
      );
      setDTP_EN_Social_Security_Date(dayjs(new Date(dataSheetCreateReducer.result_by_id.en_social_security_date)));
      setdatasheet_number(dataSheetCreateReducer.result_by_id.datasheet_number);
    }
  }, [dataSheetCreateReducer]);
  const validateNumber = /^[0-9\b]+$/;
  const showForm = ({
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isSubmitting,
  }: FormikProps<DataSheetCreate>) => {
    return (
      <form onSubmit={handleSubmit}>
         <Card className="datasheet-container">
         <CardContent sx={{ padding: 1 }}>
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Box sx={classes.root}>
              <Typography gutterBottom variant="subtitle1">
              </Typography>
            </Box>
            <br />
            <Box sx={classes.root}>
              <Grid
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                alignItems="center"
                justifyContent="center"
                direction="row"
                item
                container
              ></Grid>
              <Grid
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                alignItems="center"
                justifyContent="center"
                direction="row"
                item
                container
              >
                <Typography gutterBottom variant="h5">
                  {t(`DATA SHEET`)}
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                alignItems="right"
                justifyContent="right"
                direction="row"
                item
                container
              >
                <Typography gutterBottom variant="h6">
                  {datasheet_number}
                </Typography>
              </Grid>
            </Box>
            {/* <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    <div>ทนายความผู้รับผิดชอบ :</div>
                  </Grid>
                  <Grid item xs={7}>
                    <div>....</div>
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    <div>Responsible Lawyer :</div>
                  </Grid>
                  <Grid item xs={7}>
                    <div>....</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    <div>วันแก้ไขสุดท้าย :</div>
                  </Grid>
                  <Grid item xs={7}>
                    <div>....</div>
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    <div>late last revised :</div>
                  </Grid>
                  <Grid item xs={7}>
                    <div>....</div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
            <br />
            <Divider />
            <Divider />
            <Divider />
            <Divider />
            <Grid container spacing={5} className="info-container">
              {/* Thai Data Input */}
              <Grid item xs={12} md={6} lg={6}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      1. ชื่อบริษัท : <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_company_name"
                      onChange={handleChange}
                      value={values.th_company_name}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      2. เลขทะเบียนบริษัท : <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_regis_no"
                      onChange={(e) => {

                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          console.log("else")
                          e.target.value = e.target.value.substring(0,e.target.value.length - 1);
                        }
                      }}
                      // onChange={handleChange}
                      value={values.th_regis_no}
                      size="small"
                      type="text" 
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom className="input-label" sx={{ paddingLeft: '3px' }}>
                      จดทะเบียนเมื่อวันที่ : <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        value={valueDTP_TH_Issued_On}
                        onChange={handleDTP_TH_Issued_OnChange}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ marginBottom: '1rem' }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      3. ที่ตั้งสำนักงาน (สำนักงานใหญ่) :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_ho_address"
                      onChange={handleChange}
                      value={values.th_ho_address}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      &nbsp;&nbsp;&nbsp; สาขา (ถ้ามี) :
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_ho_branch"
                      onChange={handleChange}
                      value={values.th_ho_branch}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      4. ทุนจดทะเบียน :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_capital"
                      onChange={handleChange}
                      value={values.th_capital}
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0} }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      &nbsp;&nbsp;&nbsp; จำนวนหุ้น :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_no_of_shares"
                      onChange={handleChange}
                      value={values.th_no_of_shares}
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0} }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      &nbsp;&nbsp;&nbsp; มูลค่าหุ้น :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_par_value"
                      onChange={handleChange}
                      value={values.th_par_value}
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0} }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      &nbsp;&nbsp;&nbsp; มูลค่าชำระแล้ว :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_paid_up"
                      onChange={handleChange}
                      value={values.th_paid_up}
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0} }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      5. วันที่ประชุมตั้งบริษัท :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_TH_Statutory_Meet_Date}
                        onChange={handleDTP_TH_Statutory_Meet_Date_OnChange}
                        
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ paddingBottom: '1rem' }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <ShareHolderTH />

                <DirectorTH />

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      8. กรรมการผู้มีอำนาจ : (1)<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_authorize_director_1"
                      onChange={handleChange}
                      value={values.th_authorize_director_1}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5} container justifyContent="end" alignItems="end" >
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom className="input-label" sx={{paddingLeft: '4rem'}}>
                      (2) &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_authorize_director_2"
                      onChange={handleChange}
                      value={values.th_authorize_director_2}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      9. รอบปีบัญชี : <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_fiscal_year"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.th_fiscal_year}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      10. ผู้สอบบัญชี :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_auditor"
                      onChange={handleChange}
                      value={values.th_auditor}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      &nbsp;&nbsp;&nbsp; เลขทะเบียน :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_cpa_no"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.th_cpa_no}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      11. ทะเบียนภาษีมูลค่าเพิ่ม :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_vat_no"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.th_vat_no}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom className="input-label">
                      &nbsp;&nbsp;&nbsp; ออกให้เมื่อ :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_TH_Vat_Issued_On}
                        onChange={handleDTP_TH_Vat_Issued_On_OnChange}
                       
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ paddingBottom: '1rem' }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      12. เลขประจำตัวผู้เสียภาษี :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_tax_id"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.th_tax_id}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom className="input-label">
                      &nbsp;&nbsp;&nbsp; ออกให้เมื่อ :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_TH_Tax_Issued_On}
                        onChange={handleDTP_TH_Tax_Issued_On_OnChange}
                        
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ paddingBottom: '1rem' }} />
                    </LocalizationProvider>
                    {/* <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_tax_issued_on"
                      onChange={handleChange}
                      value={values.th_tax_issued_on}
                      size="small"
                    /> */}
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      13. วันยื่นภาษีเงินได้นิติบุคคล :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_TH_Income_Tax_Return_Filing_Date}
                        onChange={handleDTP_TH_Income_Tax_Return_Filing_Date_OnChange}
                      
                      slotProps={{ textField: { size: 'small' } }}
                        sx={{ width:'100%' }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      14. หนังสือเชิญประชุมกรรมการ :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_notice_for_director_meeting"
                      onChange={handleChange}
                      value={values.th_notice_for_director_meeting}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      15. รายงานการประชุมกรรมการ :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_minutes_for_director_meeting"
                      onChange={handleChange}
                      value={values.th_minutes_for_director_meeting}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      16. หนังสือเชิญประชุมผู้ถือหุ้น :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_notice_for_ordinary_general_meeting"
                      onChange={handleChange}
                      value={values.th_notice_for_ordinary_general_meeting}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      17. ประกาศโฆษณาหนังสือพิมพ์ วันที่ :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_TH_Publish_Notice_In_Newspaper_Date}
                        onChange={handleDTP_TH_Publish_Notice_In_Newspaper_Date_OnChange}
                        
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{marginBottom: '0.5rem'}} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      18. รายงานการประชุมใหญ่ผู้ถือหุ้น :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_minutes_of_ordinary_general_meeting"
                      onChange={handleChange}
                      value={values.th_minutes_of_ordinary_general_meeting}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      19. เลขานุการ/นายทะเบียน :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_registrar"
                      onChange={handleChange}
                      value={values.th_registrar}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      &nbsp;&nbsp;&nbsp; ใบอนุญาติทนายความ :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_atty_license_no"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.th_atty_license_no}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      20. ทะเบียนประกันสังคม :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="th_social_security_no"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.th_social_security_no}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      &nbsp;&nbsp;&nbsp; วันที่ :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2, marginTop: '0.5rem'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_TH_Social_Security_Date}
                        onChange={handleDTP_TH_Social_Security_Date_OnChange}
                        
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ paddingBottom: '1rem' }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      21. วันที่แก้ไขสุดท้าย :
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        disabled
                        
                        value={valueDTP_TH_Last_Revised_Date}
                        onChange={handleDTP_TH_Last_Revised_Date_OnChange}
                      
                      slotProps={{ textField: { size: 'small' } }}
                        />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              {/* Thai Data Input */}
              {/* Eng Data Input */}
              <Grid item xs={6}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Name of the Company :<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_company_name"
                      onChange={handleChange}
                      value={values.en_company_name}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Registration No. :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField  
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_regis_no"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.en_regis_no}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      Issued on :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_EN_Issued_On}
                        onChange={handleDTP_EN_Issued_OnChange}
                     
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ marginBottom: '1rem' }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Address (Head Office) :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_ho_address"
                      onChange={handleChange}
                      value={values.en_ho_address}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      Branch (If any) :
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_ho_branch"
                      onChange={handleChange}
                      value={values.en_ho_branch}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Capital :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_capital"
                      onChange={handleChange}
                      value={values.en_capital}
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0} }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      No. of Shares :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_no_of_shares"
                      onChange={handleChange}
                      value={values.en_no_of_shares}
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0} }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      Par Value :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_par_value"
                      onChange={handleChange}
                      value={values.en_par_value}
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0} }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      Paidup :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_paid_up"
                      onChange={handleChange}
                      value={values.en_paid_up}
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0} }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Statutory Meeting Date :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_EN_Statutory_Meet_Date}
                        onChange={handleDTP_EN_Statutory_Meet_Date_OnChange}
                      
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ paddingBottom: '1rem' }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <ShareHolderEN />

                <DirectorEN />

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Authorized Directors : (1)<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_authorize_director_1"
                      onChange={handleChange}
                      value={values.en_authorize_director_1}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5} container justifyContent="end" alignItems="end" >
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom className="input-label" sx={{paddingLeft: '4rem'}}>
                      (2)
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_authorize_director_2"
                      onChange={handleChange}
                      value={values.en_authorize_director_2}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Fiscal Year :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_fiscal_year"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.en_fiscal_year}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Auditor :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_auditor"
                      onChange={handleChange}
                      value={values.en_auditor}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      CPA No. :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_cpa_no"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.en_cpa_no}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      VAT No. :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_vat_no"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.en_vat_no}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      Issued on :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_EN_Vat_Issued_On}
                        onChange={handleDTP_EN_Vat_Issued_On_OnChange}
                       
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ marginBottom: '1rem' }}/>
                    </LocalizationProvider>
                    {/* <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_vat_issued_on"
                      onChange={handleChange}
                      value={values.en_vat_issued_on}
                      size="small"
                    /> */}
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Tax ID No. :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_tax_id"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.en_tax_id}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      Issued on :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_EN_Tax_Issued_On}
                        onChange={handleDTP_EN_Tax_Issued_On_OnChange}
                       
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ marginBottom: '1rem' }} />
                    </LocalizationProvider>
                    {/* <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_tax_issued_on"
                      onChange={handleChange}
                      value={values.en_tax_issued_on}
                      size="small"
                    /> */}
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Income Tax Return Filing Date :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_EN_Income_Tax_Return_Filing_Date}
                        onChange={handleDTP_EN_Income_Tax_Return_Filing_Date_OnChange}
                        
                      slotProps={{ textField: { size: 'small' } }}
                        sx={{ width:'100%' }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Notice for Director Meeting :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_notice_for_director_meeting"
                      onChange={handleChange}
                      value={values.en_notice_for_director_meeting}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Minutes of Director Meeting :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_minutes_for_director_meeting"
                      onChange={handleChange}
                      value={values.en_minutes_for_director_meeting}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Notice for Ordinary General Meeting :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_notice_for_ordinary_general_meeting"
                      onChange={handleChange}
                      value={values.en_notice_for_ordinary_general_meeting}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Publish Notice in Newspaper Date :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_EN_Publish_Notice_In_Newspaper_Date}
                        onChange={handleDTP_EN_Publish_Notice_In_Newspaper_Date_OnChange}
                    
                        slotProps={{ textField: { size: 'small' } }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Minutes of Ordinary General Meeting :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_minutes_of_ordinary_general_meeting"
                      onChange={handleChange}
                      value={values.en_minutes_of_ordinary_general_meeting}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Secretariat/Registrar :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_registrar"
                      onChange={handleChange}
                      value={values.en_registrar}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                   <Grid item xs={5}>
                    {/* <span className="ml10 text--red"> จดทะเบียนเมื่อวันที่ : </span> */}
                    <Typography variant="body1" gutterBottom>
                      Att. License No. :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_atty_license_no"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.en_atty_license_no}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Social Security No. :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="en_social_security_no"
                      onChange={(e) => {
                        if (e.target.value === '' || validateNumber.test(e.target.value) == true) {
                          handleChange(e)
                        } else {
                          e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                        }
                      }}
                      value={values.en_social_security_no}
                      size="small"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Date :<span style={{ color: "red" }}>* </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sx={{mt:2}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        
                        value={valueDTP_EN_Social_Security_Date}
                        onChange={handleDTP_EN_Social_Security_Date_OnChange}
                       
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ paddingBottom: '1rem' }} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="body1" gutterBottom>
                      Date last revised :
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                      <MobileDatePicker
                        disabled
                        
                        value={valueDTP_EN_Last_Revised_Date}
                        onChange={handleDTP_EN_Last_Revised_Date_OnChange}
                       
                      slotProps={{ textField: { size: 'small' } }}
                         />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              {/* Eng Data Input */}
            </Grid>
          </CardContent>
          <CardActions sx={{margin: '2rem 0', justifyContent: 'center'}}>
            <Button variant="contained" type="submit" sx={{ marginRight: '4rem', backgroundColor: '#254E58', width: '10rem' }}>
              {t(`Edit`)}
            </Button>
            <Button component={Link} to="/business-information" variant="outlined" color="error" sx={{width: '10rem'}}>
              {t(`Cancel`)}
            </Button>
          </CardActions>
        </Card>
        <br />
        <br />
        <br />
        <br />
      </form>
    );
  };

  return (
    <Box>
      <Formik
        onSubmit={(values, {}) => {
          //validate th value not null 
          const share_holder_th = dataSheetCreateReducer.result_share_holder_th;
          if(share_holder_th){
            let conunt_name_th = 0
            share_holder_th.forEach((item:any) => {
              if(item.th_share_holder != ""){
                conunt_name_th += 1;
              }
            });
            if(conunt_name_th < 2){
              Swal.fire({
                title: "ตรวจสอบข้อมูล !!",
                text: `รายชื่อหุ้นผู้ถือหุ้น/Shareholders อย่างน้อย 2 รายการ !!`,
                icon: "warning",
              });
              return;
            }
          }else{
            Swal.fire({
                title: "ตรวจสอบข้อมูล !!",
                text: `กรุณาบันทึกข้อมูล รายชื่อหุ้นผู้ถือหุ้น/Shareholders !!`,
                icon: "warning",
            });
            return;
          }
          const share_holder_en = dataSheetCreateReducer.result_share_holder_en;
          if(share_holder_en){
            let conunt_name_en = 0
            share_holder_en.forEach((item:any) => {
              if(item.en_share_holder != ""){
                conunt_name_en += 1;
              }
            });
            if(conunt_name_en < 2){
              Swal.fire({
                title: "ตรวจสอบข้อมูล !!",
                text: `Number of Shares Held อย่างน้อย 2 รายการ !!`,
                icon: "warning",
              });
              return;
            }
          }else{
            Swal.fire({
                title: "ตรวจสอบข้อมูล !!",
                text: `กรุณาบันทึกข้อมูล Number of Shares Held !!`,
                icon: "warning",
            });
            return;
          }
          //Get Date Value TH
          values.th_issued_on = valueDTP_TH_Issued_On?.toISOString().substring(0, 10);
          values.th_statutory_meet_date = valueDTP_TH_Statutory_Meet_Date?.toISOString().substring(0, 10);
          values.th_income_tax_return_filing_date = valueDTP_TH_Income_Tax_Return_Filing_Date
            ?.toISOString()
            .substring(0, 10);
          values.th_vat_issued_on = valueDTP_TH_Vat_Issued_On?.toISOString().substring(0, 10);
          values.th_tax_issued_on = valueDTP_TH_Tax_Issued_On?.toISOString().substring(0, 10);
          values.th_publish_notice_in_newspaper_date = valueDTP_TH_Publish_Notice_In_Newspaper_Date
            ?.toISOString()
            .substring(0, 10);
          values.th_social_security_date = valueDTP_TH_Social_Security_Date?.toISOString().substring(0, 10);
          values.th_date_last_revised = valueDTP_TH_Last_Revised_Date?.toISOString().substring(0, 10);
          //Get Date Value TH
          //Get Date Value EN
          values.en_issued_on = valueDTP_EN_Issued_On?.toISOString().substring(0, 10);
          values.en_statutory_meet_date = valueDTP_EN_Statutory_Meet_Date?.toISOString().substring(0, 10);
          values.en_income_tax_return_filing_date = valueDTP_EN_Income_Tax_Return_Filing_Date
            ?.toISOString()
            .substring(0, 10);
          values.en_vat_issued_on = valueDTP_EN_Vat_Issued_On?.toISOString().substring(0, 10);
          values.en_tax_issued_on = valueDTP_EN_Tax_Issued_On?.toISOString().substring(0, 10);
          values.en_publish_notice_in_newspaper_date = valueDTP_EN_Publish_Notice_In_Newspaper_Date
            ?.toISOString()
            .substring(0, 10);
          values.en_social_security_date = valueDTP_EN_Social_Security_Date?.toISOString().substring(0, 10);
          values.en_date_last_revised = valueDTP_EN_Last_Revised_Date?.toISOString().substring(0, 10);
          //Get Date Value EN

          // dispatch(loginActions.login(values, navigate));

          // values.member_id = localStorage.getItem(MEMBER_ID);
          // values.datasheet_number = localStorage.getItem(DATASHEET_NUMBER);

          dispatch(
            dataSheetCreateActions.updateDataSheet(
              Number(id),
              values,
              dataSheetCreateReducer.result_share_holder_th,
              dataSheetCreateReducer.result_share_holder_en,
              dataSheetCreateReducer.result_director_th,
              dataSheetCreateReducer.result_director_en,
              navigate
            )
          );

          // console.log("onSubmit date_value:", valueDTP_TH_Issued_On);
          // console.log("onSubmit:", JSON.stringify(values));
          // console.log(
          //   "onSubmit result_share_holder_th:",
          //   JSON.stringify(dataSheetCreateReducer.result_share_holder_th)
          // );
          // console.log(
          //   "onSubmit result_share_holder_en:",
          //   JSON.stringify(dataSheetCreateReducer.result_share_holder_en)
          // );
          // console.log("onSubmit result_director_th:", JSON.stringify(dataSheetCreateReducer.result_director_th));
        }}
        initialValues={defaultvalue}
        enableReinitialize
      >
        {(props) => showForm(props)}
      </Formik>
    </Box>
  );
};

export default DataSheetEditPage;
