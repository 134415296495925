import {
    MESSAGE_FETCHING,
    MESSAGE_SUCCESS,
    MESSAGE_ADD_SUCCESS,
    MESSAGE_ADD_FAILED,
    MESSAGE_EDIT_SUCCESS,
    MESSAGE_EDIT_FAILED,
    MESSAGE_DELETE_SUCCESS,
    MESSAGE_DELETE_FAILED,
    MESSAGE_FAILED,
    USER_CLEAR,
  } from "../services/Constants";
    import { Massage } from "../types/massage.type";
        
    export interface  MassageState {
      result: Massage[];
      isFetching: boolean;
      isError: boolean;
      isAdddata: boolean,
      isEditdata: boolean,
      isAddError: boolean;
      isEditError: boolean;
      isDeletedata: boolean;
      isDeleteError: boolean;
      isCheckUserName: boolean;
    }
        
        const initialState: MassageState = {
            result: [],
            isFetching: false,
            isError: false,
            isAdddata: false,
            isEditdata: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false,
            isCheckUserName: false,
        };
        
        export default (state = initialState, { type, payload}: any) => {
          switch (type) {
            case MESSAGE_FETCHING:
              return { 
                ...state, 
                result: [], 
                isFetching: true,
                isAdddata: false,
                isEditdata: false, 
                isError: false,
                isAddError: false,
                isEditError: false,
                isDeletedata: false,
                isDeleteError: false,
                isCheckUserName: false
                
              };
            case MESSAGE_SUCCESS:
              return { 
                ...state, 
                result: payload,
                isFetching: false,
                isAdddata: false,
                isEditdata: false,
                isError: false,
                isAddError: false,
                isEditError: false,
                isDeletedata: false,
                isDeleteError: false,
                isCheckUserName: false
              };
            case MESSAGE_ADD_SUCCESS:
              return { 
                ...state, 
                isFetching: false,
                isAdddata: true,
                isEditdata: false, 
                isError: false,
                isAddError: false,
                isEditError: false,
                isDeletedata: false,
                isDeleteError: false,
                isCheckUserName: false
              };
              case MESSAGE_EDIT_SUCCESS:
              return { 
                ...state, 
                isFetching: false, 
                isAdddata: false,
                isEditdata: true, 
                isError: false,
                isAddError: false,
                isEditError: false,
                isDeletedata: false,
                isDeleteError: false,
                isCheckUserName: false
              };
            case MESSAGE_FAILED:
              return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false,
                 isError: true,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: false,isCheckUserName: false};
            case MESSAGE_ADD_FAILED:
              return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false,isCheckUserName: false,
                 isError: false,isAddError: true,isEditError: false,isDeletedata: false,isDeleteError: false};
            case MESSAGE_EDIT_FAILED:
              return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, isCheckUserName: false,
                isError: false,isAddError: false,isEditError: true,isDeletedata: false,isDeleteError: false};
            case MESSAGE_DELETE_SUCCESS:
              return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, isCheckUserName: false,
                isError: false,isAddError: false,isEditError: false,isDeletedata: true,isDeleteError: false};
            case MESSAGE_DELETE_FAILED:
              return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, isCheckUserName: false,
                isError: false,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: true};
            default:
              return state;
          }
          
        };
        