import {
  LOGIN_LOGS_FETCHING,
  LOGIN_LOGS_SUCCESS,
  LOGIN_LOGS_FAILED,
  } from "../services/Constants";
    
    export interface LoginlogsState {
        result: [];
        isFetching: boolean;
        isError: boolean;
    }
    
    const initialState: LoginlogsState = {
        result: [],
        isFetching: false,
        isError: false,
    };
    
    export default (state = initialState, { type, payload}: any) => {
      switch (type) {
        case LOGIN_LOGS_FETCHING:
          return { ...state, result: [], isFetching: true, isError: false};
        case LOGIN_LOGS_SUCCESS:
          return { 
            ...state, 
            result: payload,
            isFetching: false,
          };
        case LOGIN_LOGS_FAILED:
          return { ...state, result: [], isFetching: false,isError: true};
       
        default:
          return state;
      }
      
    };
    