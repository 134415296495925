import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../../services/Constants";
import * as servicetypeActions from "../../../../actions/service_type.action";
import * as requestDeleteActions from "../../../../actions/request.delete.actions";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../../reducers";
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN } from "../../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./ServiceType.css";
import { Search as SearchIcon } from "@mui/icons-material";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import * as loginActions from "../../../../actions/login.action";
// import SearchBar from "material-ui-search-bar";
// const CustomToolbar = (props:any) => {
//     return (
//         <Box>
//             <Grid>
//                 <SearchBar {...props} />
//             </Grid>
//         </Box>
//     )
// }



interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}


type AgreementGroupManagementProps = {
  //
};

const AgreementGroupManagement: React.FC<any> = () => {
  const servicetypeStateReducer = useSelector((state: RootReducers) => state.serviceTypeStateReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedAgreement, setSelectedAgreement] = React.useState<any>({});
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dataAddAgreement, setdataAddAgreement] = React.useState({name:"",name_th:"",status:"1"});
  const [dataEditAgreement, setdataEditAgreement] = React.useState({id:0,name:"",name_th:"",status:""});
  
  const [openaddagreementgroup, setOpenaddagreementgroup] = React.useState(false);
  const [openeditagreementgroup, setOpeneditagreementgroup] = React.useState(false);

  const AllStatus = [
    {
      value: 1,
      name: 'Active'
    },
    {
      value: 0,
      name: 'Inactive'
    }
  ];

  const handleAddAgreementGroup = () => {
    setdataAddAgreement({name:"",name_th:"",status:"1"})
    // console.log("add");
    setOpenaddagreementgroup(true);
};

const handleEditAgreementGroup = (data:any) => {
  setdataEditAgreement({id:data.id,name:data.name,name_th:data.name_th,status:data.status});
    // console.log("edit");
    setOpeneditagreementgroup(true);
};


  const handleCloseaddagreementgroup = () => {
    setOpenaddagreementgroup(false);
  };

  const handleCloseeditagreementgroup = () => {
    setOpeneditagreementgroup(false);
  };

  const handleAddAgreementGroupSubmit = (event:any) => {
    event.preventDefault();
    // console.log("SubmitAdd");
    dispatch(
        servicetypeActions.addAgreement(
            dataAddAgreement,
          navigate
        )
      );
  };
  const handleEditAgreementGroupSubmit = (event:any) => {
    event.preventDefault();
    // console.log("SubmitEdit");
    dispatch(
        servicetypeActions.editAgreement(
            dataEditAgreement,
          navigate
        )
      );
  };

  React.useEffect(() => {
    if (servicetypeStateReducer.isAdddata) {
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated successfully.")}`,
            icon: "success",
          });
          setOpenaddagreementgroup(false);
          dispatch(servicetypeActions.setSERVICE_TYPEFetchingToState());
          dispatch(servicetypeActions.loadSERVICE_TYPE());
      return;
    } else if(servicetypeStateReducer.isAddError) {
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated unsuccessfully.")}`,
            icon: "error",
          });
          setOpenaddagreementgroup(false);
        dispatch(servicetypeActions.setSERVICE_TYPEFetchingToState());
        dispatch(servicetypeActions.loadSERVICE_TYPE());
     
    }else if(servicetypeStateReducer.isEditdata) {
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated successfully.")}`,
            icon: "success",
          });
          setOpeneditagreementgroup(false);
        dispatch(servicetypeActions.setSERVICE_TYPEFetchingToState());
        dispatch(servicetypeActions.loadSERVICE_TYPE());
     
    }else if(servicetypeStateReducer.isEditError) {
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated unsuccessfully.")}`,
            icon: "error",
          });
          setOpeneditagreementgroup(false);
        dispatch(servicetypeActions.setSERVICE_TYPEFetchingToState());
        dispatch(servicetypeActions.loadSERVICE_TYPE());
     
    }
    
    else if(servicetypeStateReducer.isDeletedata) {
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated successfully.")}`,
            icon: "success",
          });
        dispatch(servicetypeActions.setSERVICE_TYPEFetchingToState());
        dispatch(servicetypeActions.loadSERVICE_TYPE());
     
    }else if(servicetypeStateReducer.isDeleteError) {
        Swal.fire({
          title: `${t("Information Saved")}`,
          text: `${t("The information has been saved and updated unsuccessfully.")}`,
            icon: "error",
          });
        dispatch(servicetypeActions.setSERVICE_TYPEFetchingToState());
        dispatch(servicetypeActions.loadSERVICE_TYPE());
     
    }else {
    }
  }, [servicetypeStateReducer]);
  


  //load data table
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      // navigate("/login");
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
      dispatch(servicetypeActions.loadSERVICE_TYPE());

  }, []);
  React.useEffect(() => {

  }, [servicetypeStateReducer]);

  const requestColumns: GridColDef[] = [
      {
          headerName: "ID",
          field: "id",
          maxWidth: 120,
          minWidth: 50
      },
      {
          headerName: `${t('Service Type Name')}(EN)`,
          field: "name",
          flex: 1,
          minWidth: 200
      },
      {
        headerName: `${t('Service Type Name')}(TH)`,
        field: "name_th",
        flex: 1,
        minWidth: 200 
    },
      {
        headerName: `${t('Status')}`,
        field: "status_name",
        flex: 1,
        minWidth: 100
    },
      
      {
          headerName: "",
          field: ".",
          maxWidth: 150,
          align: "center",
          renderCell: ({ row }: GridRenderCellParams<string>) => (
              <Stack direction="row">
                  <IconButton
                      aria-label="edit"
                      size="large"
                      onClick={() => {
                        handleEditAgreementGroup(row);
                      }}
              >
                      <EditIcon 
                          fontSize="inherit" 
                          sx={{ color: blue[700] }}  
                      />
                  </IconButton>
                  <IconButton
                      disabled={row.status == "Close" || row.status == "Cancel" }
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                          // console.log(row)
                          setSelectedAgreement(row);
                          setOpenDialog(true);
                      }}
                  >
                      <DeleteIcon 
                          fontSize="inherit" 
                          sx={{ color: red[500] }}
                      />
                  </IconButton>
              </Stack>
            ),
      },
  ];

  //search
  const [searchText, setSearchText] = React.useState("");
  const [searchText1, setSearchText1] = React.useState("");
  const [tableData, setTableData] = React.useState<any[]>([]);

  React.useEffect(() => {
      let  dataList:any[] = [];
      let index = 0;
      servicetypeStateReducer.result.forEach((item) =>{
          dataList[index] = item;
          index += 1;
      })
      setTableData(dataList)
  }, [servicetypeStateReducer.result]);

  const requestSearch = (searchValue: string) => {
      // const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
      // const filteredRows = servicetypeStateReducer.result.filter((o: any) => {
      //   return Object.keys(o).some((k: any) => {
      //     return searchRegex.test(o[k].toString());
      //   });
      // });
      const filteredRows = servicetypeStateReducer.result.filter((row) => {
        return row.name?.toString().toLowerCase().includes(searchValue.toLowerCase());
      });
      setTableData(filteredRows);
  };

  // const cancelSearch = () => {
  //     setSearchText("");
  //     requestSearch(searchText);
  // };
  const handleDeleteConfirm = (SERVICE_TYPE_id:number) => {
      dispatch(servicetypeActions.deleteAgreementGroup(SERVICE_TYPE_id,navigate));
      setOpenDialog(false);
  };



  const showDialog = () => {
      if (selectedAgreement === null) {
          return "";
      }
  return (
      <Dialog
      fullWidth
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">
            {t("Delete Data")}
             
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">  {t("Confirm Delete Data")} : {selectedAgreement.group}</DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="info">
              ยกเลิก
          </Button>
          <Button onClick={() => handleDeleteConfirm(selectedAgreement.id)} color="primary">
              ยืนยัน
          </Button>
          </DialogActions>
      </Dialog>
      );
  };

  const showDialogAddAgreementGroup = () => {
   
return (
  <Dialog
  disableScrollLock
  fullWidth
  open={openaddagreementgroup}
  onClose={handleCloseaddagreementgroup}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
<form onSubmit={handleAddAgreementGroupSubmit}>
  <DialogTitle id="alert-dialog-title" >
  {t("Add Service Type")} 
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
    </DialogContentText>
    <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Service Type')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          size="small"
          onChange={(e) => {
                setdataAddAgreement({...dataAddAgreement,name:e.target.value});
          
           
          }}
          value={dataAddAgreement.name}
        />
         </Grid>
       </Grid>

       <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center"}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
    {t('Service Type')}(TH) :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          size="small"
          onChange={(e) => {
                setdataAddAgreement({...dataAddAgreement,name_th:e.target.value});
          
           
          }}
          value={dataAddAgreement.name_th}
        />
         </Grid>
       </Grid>
       
       <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Status')} :
</Typography>
</Grid>
<Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
       <Select
          fullWidth
          size="small"
          MenuProps={{ disableScrollLock: true }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dataAddAgreement.status}
          onChange={(e) => {
            setdataAddAgreement({...dataAddAgreement,status:e.target.value});
      }}
          variant="outlined"
          sx={{mt:2}}
        >
          {AllStatus.map(({value,name}) => (
                    <MenuItem key={value}  value={value} >
                      {t(name)}
                    </MenuItem>
                    )
                  )}
        
        </Select>
         </Grid>
       </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseaddagreementgroup}> {t("Cancel")} </Button>
    <Button type="submit" autoFocus>
    {t("Save")} 
    </Button>
  </DialogActions>
  </form>
</Dialog>
    );
};

const showDialogEditAgreementGroup = () => {
   
    return (
      <Dialog
      disableScrollLock
      fullWidth
      open={openeditagreementgroup}
      onClose={handleCloseeditagreementgroup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <form onSubmit={handleEditAgreementGroupSubmit}>
      <DialogTitle id="alert-dialog-title">
      {t("Edit Service Type")} 
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        </DialogContentText>
        <Grid container  >
                        <Grid 
                        sx={{alignSelf:"center"}}
                        item xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}>
        <Typography  variant="inherit"  component="h6">
    {t('Service Type')} :
    </Typography>
    </Grid>
    
    <Grid item xs={12}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email_address"
              size="small"
              onChange={(e) => {
                    setdataEditAgreement({...dataEditAgreement,name:e.target.value});
              
               
              }}
              value={dataEditAgreement.name}
            />
             </Grid>
             </Grid>

             <Grid container  >
                        <Grid 
                        sx={{alignSelf:"center"}}
                        item xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}>
        <Typography  variant="inherit"  component="h6">
        {t('Service Type')}(TH) :
    </Typography>
    </Grid>
    
    <Grid item xs={12}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email_address"
              size="small"
              onChange={(e) => {
                    setdataEditAgreement({...dataEditAgreement,name_th:e.target.value});
              
               
              }}
              value={dataEditAgreement.name_th}
            />
             </Grid>
             </Grid>

             <Grid container  >
                    <Grid 
                    sx={{alignSelf:"center",mt:2}}
                    item xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}>
    <Typography  variant="inherit"  component="h6">
{t('Status')} :
</Typography>
</Grid>
             <Grid item xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}>
       <Select
          fullWidth
          size="small"
          MenuProps={{ disableScrollLock: true }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dataEditAgreement.status}
          onChange={(e) => {
            setdataEditAgreement({...dataEditAgreement,status:e.target.value});
      }}
          variant="outlined"
          sx={{mt:2}}
        >
          {AllStatus.map(({value,name}) => (
                    <MenuItem key={value}  value={value} >
                      {t(name)}
                    </MenuItem>
                    )
                  )}
        
        </Select>
         </Grid>
           </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseeditagreementgroup}> {t("Cancel")} </Button>
        <Button type="submit" autoFocus>
        {t("Save")} 
        </Button>
      </DialogActions>
      </form>
    </Dialog>
        );
    };

  return (
    <Container fixed sx={{ mt: 3}} >
      <Box 
      sx={{}}
     >
          {/* Summary Icons */}
          <Grid 
          container item
          justifyContent="center"
          alignItems="center"
          sx={{
              margin: 'auto',
              width: '100%',
              maxWidth: '60rem'
          }}
          ><Typography variant="body1">{t('Service Type Management Page')}</Typography>

          </Grid>

          <Grid container justifyContent="flex-end">
              <Button 
                  size="medium"
                  variant="contained" 
                  sx={{
                      marginTop: 2,
                      marginLeft:2,
                      marginRight: 2,
                      minWidth: 200,
                      backgroundColor: "#c79059"
                  }}
                  className="requestButton"
                  onClick={() => { handleAddAgreementGroup()}}
              >
                  {t("Add Service Type")} 
              </Button>
          </Grid>
          <br />
          <Grid>
          <Grid container spacing={2}>
 
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
   <TextField 
   fullWidth 
                  placeholder="ค้นหา..."
                  variant="outlined"
                  size="small"
                  InputProps={{
                      endAdornment: (<IconButton><SearchIcon /></IconButton>)
                  }}
                  onChange={(e)=>{
                      requestSearch(e.target.value)
                      setSearchText(e.target.value)
                  }} 
                  sx={{
                      //  width:500,
                      "& .MuiInput-underline:before": {
                          borderBottom: 1,
                          borderColor: "divider",
                      },
                      marginBottom:2
                  }}
              />
  </Grid>
  <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
  </Grid>
</Grid>
              
              <DataGrid
                  // components={{ Toolbar: CustomToolbar }}
                  sx={{ backgroundColor: "white"}}  
                  rows={tableData}
                  columns={requestColumns}
                  // componentsProps={{
                  //     toolbar: {
                  //       value: searchText,
                  //       onChange: (searchVal: string) => requestSearch(searchVal),
                  //       onCancelSearch: () => cancelSearch()
                  //     }
                  // }}
                  autoHeight
                  pageSize={10}
                  rowsPerPageOptions={[10]}
              />
          </Grid>
          <br />
          <br />
          {showDialog()}
          {showDialogAddAgreementGroup()}
          {showDialogEditAgreementGroup()}
      </Box>
      </Container>
);
                }
export default AgreementGroupManagement;
