import {
    STRIPE_PAYMENT_FETCHING,
    STRIPE_PAYMENT_SUCCESS,
    STRIPE_PAYMENT_ADD_SUCCESS,
    STRIPE_PAYMENT_ADD_FAILED,
    STRIPE_PAYMENT_EDIT_SUCCESS,
    STRIPE_PAYMENT_EDIT_FAILED,
    STRIPE_PAYMENT_DELETE_SUCCESS,
    STRIPE_PAYMENT_DELETE_FAILED,
    STRIPE_PAYMENT_FAILED,
    STRIPE_PAYMENT_CLEAR,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
const Swal = require('sweetalert2')

export const setSTRIPE_PAYMENTFetchingToState = () => ({
    type: STRIPE_PAYMENT_FETCHING,
});
  
export const setSTRIPE_PAYMENTSuccessToState = (
    payload: any[],) => (
        {
            type: STRIPE_PAYMENT_SUCCESS,
            payload,
        }
);

export const setSTRIPE_PAYMENTAddSuccessToState = (payload: any) => ({
            type: STRIPE_PAYMENT_ADD_SUCCESS,
            payload,
});

export const setSTRIPE_PAYMENTAddFailedToState = () => ({
    type: STRIPE_PAYMENT_ADD_FAILED,
});

export const setSTRIPE_PAYMENTEditSuccessToState = (payload: any) => ({
    type: STRIPE_PAYMENT_EDIT_SUCCESS,
    payload,
});

export const setSTRIPE_PAYMENTEditFailedToState = () => ({
    type: STRIPE_PAYMENT_EDIT_FAILED,
});

export const setSTRIPE_PAYMENTDeleteSuccessToState = (payload: any) => ({
    type: STRIPE_PAYMENT_DELETE_SUCCESS,
    payload,
});

export const setSTRIPE_PAYMENTDeleteFailedToState = () => ({
    type: STRIPE_PAYMENT_DELETE_FAILED,
});
  
export const setSTRIPE_PAYMENTFailedToState = () => ({
    type: STRIPE_PAYMENT_FAILED,
});

export const setSTRIPE_PAYMENTClearToState = () => ({
    type: STRIPE_PAYMENT_CLEAR,
});
  

export const loadSTRIPE_PAYMENT = () => {
    return async(dispatch: any) => {
        // dispatch(setSTRIPE_PAYMENTFetchingToState());
        // sendDataSTRIPE_PAYMENT(dispatch);
    };
};


export const sendDataSTRIPE_PAYMENT = (data: any, navigate: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(setAGREEMENT_GROUPFetchingToState());
      const result = await httpClient.post(server.STRIPE_PAYMENT+"/create-checkout-session", data);
      if (result.status == 200 || result.status == 201) {
         dispatch(setSTRIPE_PAYMENTSuccessToState([result.data]));
         
      } else {
        dispatch(setSTRIPE_PAYMENTFailedToState());
      }
    } catch (error) {
      dispatch(setSTRIPE_PAYMENTFailedToState());
    }
  };
};

export const sendchecksession_statusSTRIPE_PAYMENT = (dataclientSecret: any, navigate: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(setAGREEMENT_GROUPFetchingToState());
      const result = await httpClient.get(`${server.STRIPE_PAYMENT}/session_status?session_id=${dataclientSecret}`);
      // console.log(result)
      // return;
      if (result.data.status == "complete" || result.data.payment_status == "paid") {
        const resultinvoice = await httpClient.get(`${server.STRIPE_PAYMENT}/getinvoice_number?session_id=${dataclientSecret}`);
        if(resultinvoice.data){
          navigate('/success-payment',{ state: resultinvoice.data });
        }else{
          navigate('/success-payment',{ state: resultinvoice.data });
        }
        
         
      } else {
        navigate('/cancel-payment',{ state: result.data });
      }
    } catch (error) {
      dispatch(setSTRIPE_PAYMENTFailedToState());
    }
  };
};


export const loadAGREEMENTGROUPByservice_type_id = (id:any) => {
  return async(dispatch: any) => {
    dispatch(setSTRIPE_PAYMENTFetchingToState());
    getDataSTRIPE_PAYMENTByservice_type_id(id,dispatch)
  };
};

const getDataSTRIPE_PAYMENTByservice_type_id = async (id:any,dispatch: any) => {
  try {
      const result = await httpClient.get<any[]>(`${server.STRIPE_PAYMENT}/getAllByservice_type_id/${id}`);
      result.data.forEach((item)=>{
          if(item.status == 1){
              item.status = "Active"
          }
          else if(item.status == 0){
              item.status = "Inactive"
          }else{
              item.status = ""
          }
          
      })
      dispatch(setSTRIPE_PAYMENTSuccessToState(
          result.data 
      ));
  }   catch (error) {
      dispatch(setSTRIPE_PAYMENTFailedToState());
  }
}
  
  
export const addAgreement = (data: any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setSTRIPE_PAYMENTFetchingToState());
        // connect
        const result = await httpClient.post(server.STRIPE_PAYMENT+"/add", data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setSTRIPE_PAYMENTAddSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setSTRIPE_PAYMENTAddFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setSTRIPE_PAYMENTAddFailedToState());
      }
    };
  };

  export const editAgreement = (data: any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setSTRIPE_PAYMENTFetchingToState());
        // connect
        const result = await httpClient.put(`${server.STRIPE_PAYMENT}/${data.id}`, data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setSTRIPE_PAYMENTEditSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setSTRIPE_PAYMENTEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setSTRIPE_PAYMENTEditFailedToState());
      }
    };
  };


  export const deleteAgreementGroup = (STRIPE_PAYMENT_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.STRIPE_PAYMENT + `/${STRIPE_PAYMENT_id}`);
            if (result.status == 204) {
               dispatch(setSTRIPE_PAYMENTDeleteSuccessToState(result.data));
             } else {
               dispatch(setSTRIPE_PAYMENTDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setSTRIPE_PAYMENTDeleteFailedToState());
        }
    };
};
  