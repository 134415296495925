import {
    VERIFYEMAIL_FETCHING,
    VERIFYEMAIL_SUCCESS,
    VERIFYEMAIL_FAILED,
    VERIFY_CHECK_EMAIL_SUCCESS,
    VERIFY_CHECK_EMAIL_FAIL,
  } from "../services/Constants";
  
  export interface VerifyEmailState {
    isFetching: boolean;
    isError: boolean;
    isEmailError: boolean;
    result: any;
    resultEmail: any;
  }
  
  const initialState: VerifyEmailState = {
    isFetching: false,
    isError: false,
    isEmailError: false,
    result: null,
    resultEmail: null,
  };
  
  export default (
    state = initialState,
    { type, payload }: any
  ): VerifyEmailState => {
    switch (type) {
      case VERIFYEMAIL_FETCHING:
        return { ...state, isFetching: true, isError: false,isEmailError: false, result: null, resultEmail: null };
      case VERIFYEMAIL_SUCCESS:
        return { ...state, isFetching: false, isError: false,isEmailError: false, result: payload, resultEmail: null };
      case VERIFYEMAIL_FAILED:
        return { ...state, isFetching: false, isError: true,isEmailError: false, result: null, resultEmail: null };
        case VERIFY_CHECK_EMAIL_SUCCESS:
        return { ...state, isFetching: false, isError: false,isEmailError: false, result: null, resultEmail: payload };
        case VERIFY_CHECK_EMAIL_FAIL:
        return { ...state, isFetching: false, isError: false,isEmailError: true, result: null, resultEmail: null };
      default:
        return state;
    }
  };
  