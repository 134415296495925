import {
    PROVINCES_FETCHING,
    PROVINCES_SUCCESS,
    PROVINCES_FAILED,
  } from "../services/Constants";
  import { Provinces } from "../types/provinces.type";
  
  export interface ProvincesState {
    isFetching: boolean;
    isError: boolean;
    result: Provinces[];
  }
  
  const initialState: ProvincesState = {
    isFetching: false,
    isError: false,
    result: [],
  };
  
  export default (
    state = initialState,
    { type, payload }: any
  ): ProvincesState => {
    switch (type) {
      case PROVINCES_FETCHING:
        return { ...state, isFetching: true, isError: false, result: [] };
      case PROVINCES_SUCCESS:
        return { ...state, isFetching: false, isError: false, result: payload };
      case PROVINCES_FAILED:
        return { ...state, isFetching: false, isError: true, result: [] };
      default:
        return state;
    }
  };
  