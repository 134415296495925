// import { history } from "../index";
import {
    SHARE_HOLDER_FETCHING,
    SHARE_HOLDER_LIST,
    server,
  } from "../services/Constants";
  import { httpClient } from "../services/httpclient";

  export const setShare_HolderrFetchingToState = () => ({
    type: SHARE_HOLDER_FETCHING,
  });
  
  export const setGetShare_HolderrListToState = (payload: Boolean) => ({
    type: SHARE_HOLDER_LIST,
    payload
  });

  export const getShare_HolderrList = (id:number) => {
    return async (dispatch: any) => {
        try {
            // begin connecting...
            dispatch(setShare_HolderrFetchingToState());
            // connect
            const result = await httpClient.get(server.SHARE_HOLDER+`/getby_data_sheet_id/${id}`);
            console.log(result);
            if (result.status === 200 && result.data.length > 0) {
                dispatch(setGetShare_HolderrListToState(result.data));
            } else {
              dispatch(setShare_HolderrFetchingToState());
            }
          } catch (error) {
            // error
            dispatch(setShare_HolderrFetchingToState());
          }
    };
  };
  