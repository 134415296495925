import * as React from "react";
import "./ServicesPage.css";
import { Paper, Grid ,Button, ListItemText, ListItem, List, Divider} from "@mui/material";
import Iframe from "react-iframe";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import getLanguage from '../../Translation/Translation';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "../../..";
import * as registerActions from "../../../actions/register.action";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import { RootReducers } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import Typography from "@mui/material/Typography";
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { fontSize } from "@mui/system";
import CardMedia from '@mui/material/CardMedia'
import { highlightTrailingWhitespace } from "jest-matcher-utils";
import { useNavigate, Link } from "react-router-dom";
import qcimage from "../../../components/layouts/assets/quick_access.PNG";
import rqimage from "../../../components/layouts/assets/request.PNG";
import osimage from "../../../components/layouts/assets/online_support.PNG";
import atimage from "../../../components/layouts/assets/assistance.PNG";
import about1 from "../../../components/layouts/assets/about1.png";
import sub1 from "../../../components/layouts/assets/a-sub1.png";
import sub2 from "../../../components/layouts/assets/sub2.png";
import sub3 from "../../../components/layouts/assets/sub3.png";

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "blanchedalmond",
  width: 1,
  paddingBottom: 1,
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center"
}));

const ServicesPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const registerReducer = useSelector((state: RootReducers) => state.registerReducer);


  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
  };
  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'ไทย'
    }
  ];
 

  return (
    <Container fixed maxWidth="lg" className="about-container">

      <Grid
        container
        justifyContent="start"
        className="why-container"
      >
        <Grid className='container-text' xs={12} sm={12} md={7} lg={7} xl={7}>
          <Typography className="about-text-heading" >
            {t(`About SME-Mate`)}
          </Typography>
          <Typography className="about-text-descritons" >
            {t("SME Mate is a leading provider of business solutions for small and medium-sized enterprises (SMEs) in Thailand. Founded in 2022, the company's mission is to empower SMEs with innovative technologies and expert services to help them grow and succeed.")}
          </Typography>
          <Typography className="about-text-descritons" >
            {t("Headquartered in Bangkok, SME-Mate has a team of dedicated professionals who are passionate about supporting the Thai business community. The company offers a wide range of services, including business document request and business consulting, to help SMEs navigate the challenges of the modern business landscape.")}
          </Typography>
        </Grid>
        <Grid className=' container-img' xs={12} sm={12} md={5} lg={5} xl={5} >
          <img src={about1} className="img-about" />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="start"
        className="why-container"
      >
        <Typography className="about-text-heading-title" sx={{paddingBottom: "20px"}} >
          {t(`A Service for Every SME Business Need`)}
        </Typography>
        <Grid  className="col-grap" xs={12} sm={12} md={4} lg={4} xl={4}>
            <img src={sub1} className="img-sub" />
            <Typography className="about-text-sub-heading" >
              {t("Business Consulting")}
            </Typography>
            <Typography className="about-text-descritons" >
              {t("Our expert team provides tailored guidance to help SMEs navigate challenges and find innovative solutions to grow their businesses.")}
            </Typography>
        </Grid>
        <Grid className="col-grap" xs={12} sm={12} md={4} lg={4} xl={4}>
            <img src={sub2} className="img-sub" />
            <Typography className="about-text-sub-heading" >
              {t("Paperwork Made Easy")}
            </Typography>
            <Typography className="about-text-descritons" >
              {t("We handle the forms, documents, and administrative tasks, allowing you to focus on your core business operations.")}
            </Typography>
        </Grid>
        <Grid className="col-grap" xs={12} sm={12} md={4} lg={4} xl={4}>
          <img src={sub3} className="img-sub" />

            <Typography className="about-text-sub-heading" >
              {t("24/7 Support")}
            </Typography>
            <Typography className="about-text-descritons" >
            {t("Our responsive support team is available around the clock to assist you. Submit requests anytime and track progress in real-time.")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="start"
        className="why-container"
      >
        <Typography className="about-text-heading" sx={{paddingBottom: "20px"}} >
          {t(`Organized Business, Seamless Support`)}
        </Typography>
        <Grid  xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="about-text-descritons" >
              {t("As a business owner, it's crucial to have your important documents organized and prepped. ")} <b>{t("SME Mate")}</b> {t("ensures this process is quick and smooth, delivering everything you need to run your business efficiently. With our expert support, you can focus on what truly matters - growing your company.")}
            </Typography>
            <Typography className="about-text-descritons" >
              {t("Don't hesitate, ")}  <Link  to="/register" className="link-url"><b>{t("join us today")}</b></Link > {t("and experience the difference our seamless document management and dedicated assistance can make for your SME.")}
            </Typography>
        </Grid>

      </Grid>
    </Container>

  );

};


export default ServicesPage;
