import {
    NOTIC_FETCHING,
    NOTIC_SUCCESS,
    NOTIC_FAILED
} from "../services/Constants";

export interface NoticState {
    result: any[];
    isFetching: boolean;
    isError: boolean;
}

const initialState: NoticState = {
    result: [],
    isFetching: false,
    isError: false,
};

export default (state = initialState, { type, payload }: any) => {
    switch (type) {
        case NOTIC_FETCHING:
            return { ...state, result: [], isFetching: true, isError: false };
        case NOTIC_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false };
        case NOTIC_FAILED:
            return { ...state, result: [], isFetching: false, isError: true };
        default:
            return state; 
    }
};