// import { history } from "../index";
import {
    SERVICE_TYPE_FETCHING,
    SERVICE_TYPE_SUCCESS,
    SERVICE_TYPE_ADD_SUCCESS,
    SERVICE_TYPE_ADD_FAILED,
    SERVICE_TYPE_EDIT_SUCCESS,
    SERVICE_TYPE_EDIT_FAILED,
    SERVICE_TYPE_DELETE_SUCCESS,
    SERVICE_TYPE_DELETE_FAILED,
    SERVICE_TYPE_FAILED,
    SERVICE_TYPE_CLEAR,
    SERVICE_TYPE_SUCCESS_All_SERVICE,
    server,
    MEMBER_ID,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { ServiceType } from "../types/service_type.type";
const Swal = require('sweetalert2')

export const setSERVICE_TYPEFetchingToState = () => ({
    type: SERVICE_TYPE_FETCHING,
});
  
export const setSERVICE_TYPESuccessToState = (
    payload: ServiceType[],) => (
        {
            type: SERVICE_TYPE_SUCCESS,
            payload,
        }
);

export const setSERVICE_TYPEAddSuccessToState = (payload: any) => ({
            type: SERVICE_TYPE_ADD_SUCCESS,
            payload,
});

export const setSERVICE_TYPEAddSuccessAllserviceToState = (payload: any) => ({
  type: SERVICE_TYPE_SUCCESS_All_SERVICE,
  payload,
});

export const setSERVICE_TYPEAddFailedToState = () => ({
    type: SERVICE_TYPE_ADD_FAILED,
});

export const setSERVICE_TYPEEditSuccessToState = (payload: any) => ({
    type: SERVICE_TYPE_EDIT_SUCCESS,
    payload,
});

export const setSERVICE_TYPEEditFailedToState = () => ({
    type: SERVICE_TYPE_EDIT_FAILED,
});

export const setSERVICE_TYPEDeleteSuccessToState = (payload: any) => ({
    type: SERVICE_TYPE_DELETE_SUCCESS,
    payload,
});

export const setSERVICE_TYPEDeleteFailedToState = () => ({
    type: SERVICE_TYPE_DELETE_FAILED,
});
  
export const setSERVICE_TYPEFailedToState = () => ({
    type: SERVICE_TYPE_FAILED,
});

export const setSERVICE_TYPEClearToState = () => ({
    type: SERVICE_TYPE_CLEAR,
});
  

export const loadSERVICE_TYPE = () => {
    return async(dispatch: any) => {
        dispatch(setSERVICE_TYPEFetchingToState());
        getDataSERVICE_TYPE(dispatch)
    };
};

const getDataSERVICE_TYPE = async (dispatch: any) => {
    try {
        const result = await httpClient.get<ServiceType[]>(`${server.SERVICE_TYPE}/getAll`);
        dispatch(setSERVICE_TYPESuccessToState(
            result.data 
        ));
    }   catch (error) {
        dispatch(setSERVICE_TYPEFailedToState());
    }
}

export const loadSERVICE_TYPEActive = () => {
  return async(dispatch: any) => {
      dispatch(setSERVICE_TYPEFetchingToState());
      getDataSERVICE_TYPEActive(dispatch)
  };
};

const getDataSERVICE_TYPEActive = async (dispatch: any) => {
  try {
      const result = await httpClient.get<ServiceType[]>(`${server.SERVICE_TYPE}/getAll`);
      dispatch(setSERVICE_TYPESuccessToState(
          result.data 
      ));
  }   catch (error) {
      dispatch(setSERVICE_TYPEFailedToState());
  }
}

export const loadAGREEMENTGROUPByservice_type_id = (id:any) => {
  return async(dispatch: any) => {
    dispatch(setSERVICE_TYPEFetchingToState());
    getDataSERVICE_TYPEByservice_type_id(id,dispatch)
  };
};

const getDataSERVICE_TYPEByservice_type_id = async (id:any,dispatch: any) => {
  try {
      const result = await httpClient.get<ServiceType[]>(`${server.SERVICE_TYPE}/getAllByservice_type_id/${id}`);
      result.data.forEach((item)=>{
          if(item.status == 1){
              item.status = "Active"
          }
          else if(item.status == 0){
              item.status = "Inactive"
          }else{
              item.status = ""
          }
          
      })
      dispatch(setSERVICE_TYPESuccessToState(
          result.data 
      ));
  }   catch (error) {
      dispatch(setSERVICE_TYPEFailedToState());
  }
}
  
  
export const addAgreement = (data: any, navigate: any) => {
    console.log(data)
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setSERVICE_TYPEFetchingToState());
        // connect
        const result = await httpClient.post(server.SERVICE_TYPE+"/add", data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setSERVICE_TYPEAddSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setSERVICE_TYPEAddFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setSERVICE_TYPEAddFailedToState());
      }
    };
  };

  export const editAgreement = (data: any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setSERVICE_TYPEFetchingToState());
        // connect
        const result = await httpClient.put(`${server.SERVICE_TYPE}/${data.id}`, data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setSERVICE_TYPEEditSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setSERVICE_TYPEEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setSERVICE_TYPEEditFailedToState());
      }
    };
  };


  export const deleteAgreementGroup = (SERVICE_TYPE_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.SERVICE_TYPE + `/${SERVICE_TYPE_id}`);
            console.log(result);
            if (result.status == 204) {
               dispatch(setSERVICE_TYPEDeleteSuccessToState(result.data));
             } else {
               dispatch(setSERVICE_TYPEDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setSERVICE_TYPEDeleteFailedToState());
        }
    };
};
  
export const getDataAllSERVICE_TYPE = (navigate:any) => {
  return async(dispatch: any) => {
    try {
      const result = await httpClient.get<ServiceType[]>(`${server.SERVICE_TYPE}/getallservices`);
      dispatch(setSERVICE_TYPEAddSuccessAllserviceToState(
          result.data 
      ));
  }   catch (error) {
      dispatch(setSERVICE_TYPEFailedToState());
  }
  };
};

