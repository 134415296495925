import * as React from "react";
import "./SuccessPayment.css";
import { Paper, Grid ,Button} from "@mui/material";
import Iframe from "react-iframe";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import getLanguage from '../../Translation/Translation';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "../../..";
import * as registerActions from "../../../actions/register.action";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import * as agreementActions from "../../../actions/agreement.action";
import * as stripe_paymentActions from "../../../actions/stripe_payment.action";
import { RootReducers } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import Typography from "@mui/material/Typography";
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { fontSize } from "@mui/system";
import CardMedia from '@mui/material/CardMedia'
import { highlightTrailingWhitespace } from "jest-matcher-utils";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import {encode,decode} from "../../layouts/assets/calculator/decode_encode";

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "blanchedalmond",
  width: 1,
  paddingBottom: 1,
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center"
}));

const SuccessPayment: React.FC<any> = () => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const registerReducer = useSelector((state: RootReducers) => state.registerReducer);

  React.useEffect(() => {
    if(!location || !data){
      navigate('/home');
      return;
    }
    //   const callsession_status = async () =>{
    //   await dispatch(stripe_paymentActions.sendchecksession_statusSTRIPE_PAYMENT("",navigate));
    // }
    // callsession_status();
   
    // localStorage.removeItem("YourCart");
    localStorage.setItem("YourCart",encode(JSON.stringify([])));
    dispatch(agreementActions.setAGREEMENTFetchingAddtoCartToState());
  }, []);


  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
  };
  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'ไทย'
    }
  ];
 

  return (
    <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  sx={{ height: '100%' }}
>
  <Grid item xs={3}   alignItems="center"  alignContent="center" textAlign="center"
  justifyContent="center"
  >
    <CreditScoreIcon sx={{fontSize:"70px"}} ></CreditScoreIcon>
  <Typography className='quote' sx={{color:'#4F4A41',marginBottom: '1rem',textAlign:'center'}} variant="h6">
              <b>{t(`Payment Successful`)} {data && data.invoice_number?`#${data.invoice_number}`:""}</b>
            </Typography>
            <Typography className='quote' sx={{color:'#4F4A41',marginBottom: '1rem',textAlign:'center'}} variant="h6">
              {t(`Thank you for your payment. An automated receipt will be sent to your registered email.`)}
            </Typography>
     
            <Button
                onClick={() => navigate("/home")}
                variant="outlined"
                sx={{ 
                  textDecoration: 'underline',
                  borderColor:'#ffffff',
                  // backgroundColor: '#CA965C', 
                  color:'#CA965C',
                  marginTop: '1rem',
                  marginBottom: '2rem',
                  '&:hover': {
                    backgroundColor: '#E8630A',
                    color:'#ffffff',
                    borderColor:'#ffffff',
                  }, 
                  // width:'20%',
                }}
                
              >
              {t('Back to Home')}
            </Button>
  </Grid>
</Grid>

  );

};


export default SuccessPayment;
