import {
  PERONAL_INFOMATION_FAILED,
  PERONAL_INFOMATION_FETCHING,
  PERONAL_INFOMATION_SUCCESS,
  LOGOUT,
} from "../services/Constants";

export interface Personal_informationStat {
  isFetching: boolean;
  isError: boolean;
  result: any;
}

const initialState: Personal_informationStat = {
  isFetching: false,
  isError: false,
  result: null,
};

export default (state = initialState, { type, payload,tokenpayload }: any): Personal_informationStat => {
  switch (type) {
    case PERONAL_INFOMATION_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
    case PERONAL_INFOMATION_SUCCESS:
      return { ...state, isFetching: false, isError: false, result: payload };
    case PERONAL_INFOMATION_FAILED:
      return { ...state, isFetching: false, isError: true, result: null };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
