import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../../services/Constants";
import * as datasheetActions from "../../../../actions/datasheet.create.action";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import * as requestDeleteActions from "../../../../actions/request.delete.actions";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../../reducers";
import Autocomplete from '@mui/material/Autocomplete';
import * as userActions from "../../../../actions/user.action";
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { server, TOKEN } from "../../../../services/Constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./BusinessInformationlogs.css";
import { Search as SearchIcon } from "@mui/icons-material";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import * as loginActions from "../../../../actions/login.action";
import html2pdf from "html2pdf.js";
// import SearchBar from "material-ui-search-bar";
// const CustomToolbar = (props:any) => {
//     return (
//         <Box>
//             <Grid>
//                 <SearchBar {...props} />
//             </Grid>
//         </Box>
//     )
// }



interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}


type BusinessInformationlogsProps = {
  //
};

const BusinessInformationlogs: React.FC<any> = () => {
  const dataSheetCreateReducer = useSelector((state: RootReducers) => state.dataSheetCreateReducer);
  const menuconfigReducer = useSelector((state: RootReducers) => state.menuconfigStateReducer);
  const UserStateReducer = useSelector((state: RootReducers) => state.userStateReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedBusinessInformationlogs, setSelectedBusinessInformationlogs] = React.useState<any>({});
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dataAddBusinessInformationlogs, setdataAddBusinessInformationlogs] = React.useState({menu_name:"",menu_route:""});
  const [dataEditBusinessInformationlogs, setdataEditBusinessInformationlogs] = React.useState({id:0,menu_name:"",menu_route:""});
  
  const [openaddBusinessInformationlogs, setOpenaddBusinessInformationlogs] = React.useState(false);
  const [openeditBusinessInformationlogs, setOpeneditBusinessInformationlogs] = React.useState(false);

  const statuslist =  [{status:"A",label: "Active"},{status:"I",label: "Inactive"}];
  const [dataSearch, setdataSearch] = React.useState({
    user_id:0,
    status:""
  });

  const [dataAlluserandmember, setdataAlluserandmember] = React.useState<any[]>([]);

  const Alllanguages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'Thai'
    }
  ];




  const SearchDataAll = () => {
    setTableData([]);
    dispatch(datasheetActions.getDataBUSINESS_INFORMATION_LOGS_Search({
      params: dataSearch,
    },navigate) 
  );
};

  //load data table
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      dispatch(loginActions.logouttologinpage(navigate));
    }
    dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
        dispatch(userActions.loadUser());
        dispatch(datasheetActions.setDataSheetCreateFetchingToState());
  }, []);


  React.useEffect(() => {
    setTableData([]);
    let  dataList:any[] = [];
    let index = 0;
    if(dataSheetCreateReducer.result_data_list && dataSheetCreateReducer.result_data_list.length > 0 ){
      dataSheetCreateReducer.result_data_list.map((item:any) =>{
        dataList[index] = item;
        index += 1;
    })
    setTableData(dataList)
    }
   
}, [dataSheetCreateReducer.result_data_list]);

React.useEffect(() => {
  setdataAlluserandmember(UserStateReducer.result);
}, [UserStateReducer.result]);





  const requestColumns: GridColDef[] = [
      {
          headerName: `${t('ID')}`,
          field: "id",
          flex: 1,
          minWidth: 30 
      },
      {
        headerName: `${t("Datasheet Number")}`,
        field: `datasheet_number`,
        flex: 1,
        minWidth: 150
    },
      {
          headerName: `${t("Name")}`,
          field: `.`,
          flex: 1,
          minWidth: 250,
          renderCell: ({ row }) => (
            <>
            {row.tbl_user?.first_name} {row.tbl_user?.last_name} {row.tbl_member?.contact_name}
            </>
          ), 
      },
    {
      headerName: `${t("DateTime Create")}`,
      field: `created_at_date`,
      flex: 1,
      minWidth: 150
  },
      {
        headerName: `${t("DateTime Update")}`,
        field: "updated_at_date",
        flex: 1,
        minWidth: 150 
    },
    {
      headerName: `${t("Status")}`,
      field: "status_name",
      flex: 1,
      minWidth: 150 
  },
  ];

  //search
  const [searchText, setSearchText] = React.useState("");
  const [searchText1, setSearchText1] = React.useState("");
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [setdatatoshow, setsetdatatoshow] = React.useState<any[]>([]);
  const [pageAll, setpageAll] = React.useState(0);
  const [datashowAll, setdatashowAll] = React.useState<any[]>([]);
  const [lengthAlldatasearch, setlengthAlldatasearch] = React.useState(0);
 

  const requestSearch = (searchValue: string) => {
      const filteredRows = dataSheetCreateReducer.result_data_list.filter((row:any) => {
        return row.user_id?.toString().toLowerCase().includes(searchValue.toLowerCase());
      });
      setTableData(filteredRows);
  };


  const exportToPDF = async() => {
    let element = document.getElementById("mydivhtmltobase");

    const opt = {
      margin: [0, 0, 0, 0],
      image: {
        type: "jpeg",
        quality: 0.98,
      },
      // enableLinks: false,
      html2canvas: {
        scale: 2,
        dpi: 192,
        letterRendering: true,
        useCORS: true,
        // ignoreElements: (e) => {
        //   return e.classList.contains("cke_pagebreak") ||
        //     e.classList.contains("html2pdf__page-break")
        //     ? true
        //     : false;
        // },
      },
      jsPDF: {
        unit: "cm",
        format: "A4",
        orientation: "portrait",
        putOnlyUsedFonts: true,
        pagesplit: true,
      },
      // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
    };

    let abcd = await html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function(pdf:any) {
        // console.log(pdf);
        const totalPages = pdf.internal.getNumberOfPages();
        pdf.deletePage(totalPages);
        return pdf.output("bloburl");
      });
    window.open(abcd);
    let blob = await fetch(abcd).then((r) => r.blob());
};

  const checkcontent = async() => {
    let setcountpage = 0;
  let totalclient = 0;
  setsetdatatoshow([]);
  setdatashowAll([]);
  // setlengthAlldatasearch(0);
  let linedetailprpo = 35;
  let datainlineprpo = 27;
  let getdata = [];
  let getnewdata = [];
  let hllinedetails = 0;
  let pageAll = 1;
  let newhllinedetails = 0;

  for (let i = 0; i < tableData.length; i++) {



    let maiek = "่"; //ไม้เอก
    let maito = "้"; //ไม้โท
    let maitree = "๊"; //ไม้ตรี
    let maijattawa = "๋"; //ไม้จัตวา
    let vowelei = "ิ"; //สระอิ
    let vowelee = "ี"; //สระอี
    let voweleue = "ึ"; //สระอึ
    let voweleu = "ื"; //สระอือ
    let vowelu = "ุ"; //สระอุ
    let voweluu = "ู"; //สระอู
    let maihun = "ั"; //ไม้หันอากาศ
    let maiyamok = "็"; //ไม้เอก

    newhllinedetails = 0;
    getnewdata = [];

    tableData[i].no = i + 1;

    let stringchecklength = "";

    if(tableData[i].tbl_user){
      tableData[i].name = `${tableData[i].tbl_user?.first_name} ${tableData[i].tbl_user?.last_name}`
    }else{
      tableData[i].name = `${tableData[i].tbl_member?.contact_name}`
    }
   

    if(tableData[i].name != null){
      let lengthoftableData = tableData[i].name.length;
      stringchecklength = tableData[i].name;

      for (let j = 0; j < tableData[i].name.length; j++) {
        if (
          stringchecklength[j] == maiek ||
          stringchecklength[j] == maito ||
          stringchecklength[j] == maitree ||
          stringchecklength[j] == maijattawa ||
          stringchecklength[j] == vowelei ||
          stringchecklength[j] == vowelee ||
          stringchecklength[j] == voweleue ||
          stringchecklength[j] == voweleu ||
          stringchecklength[j] == vowelu ||
          stringchecklength[j] == voweluu ||
          stringchecklength[j] == maihun ||
          stringchecklength[j] == maiyamok
        ) {
          lengthoftableData--;
        }
      }

      newhllinedetails = Math.ceil(lengthoftableData / datainlineprpo);
      getnewdata.push(tableData[i]);

      if (hllinedetails + newhllinedetails > linedetailprpo || tableData[i].changedepartment == true) {
        setdatatoshow[pageAll - 1] = getdata;
        setcountpage += setcountpage+1
        pageAll++;

        hllinedetails = 0;
        getdata = [];
        getdata.push(tableData[i]);
        hllinedetails += newhllinedetails;
        if (i == tableData.length - 1) {
          tableData[i].totalclient = totalclient.toFixed(2);
          setdatatoshow[pageAll - 1] = getdata;
          getdata = [];
        }
      } else {
        hllinedetails += newhllinedetails;

        getdata.push(tableData[i]);

        if (i == tableData.length - 1) {
          tableData[i].totalclient = totalclient.toFixed(2);
          setdatatoshow[pageAll - 1] = getdata;
          getdata = [];
        }
      }
    }else{
      if(tableData[i].date_in == null){
        tableData[i].date_in = "-";
      }
      let lengthoftableData = tableData[i].date_in.length;
      stringchecklength = tableData[i].date_in;

      for (let j = 0; j < tableData[i].date_in.length; j++) {
        if (
          stringchecklength[j] == maiek ||
          stringchecklength[j] == maito ||
          stringchecklength[j] == maitree ||
          stringchecklength[j] == maijattawa ||
          stringchecklength[j] == vowelei ||
          stringchecklength[j] == vowelee ||
          stringchecklength[j] == voweleue ||
          stringchecklength[j] == voweleu ||
          stringchecklength[j] == vowelu ||
          stringchecklength[j] == voweluu ||
          stringchecklength[j] == maihun ||
          stringchecklength[j] == maiyamok
        ) {
          lengthoftableData--;
        }
      }

      newhllinedetails = Math.ceil(lengthoftableData / datainlineprpo);

      getnewdata.push(tableData[i]);

      if (hllinedetails + newhllinedetails > linedetailprpo || tableData[i].changedepartment == true) {
        setdatatoshow[pageAll - 1] = getdata;
        pageAll++

        hllinedetails = 0;
        getdata = [];
        getdata.push(tableData[i]);
        hllinedetails += newhllinedetails;
        if (i == tableData.length - 1) {
          tableData[i].totalclient = totalclient.toFixed(2);
          setdatatoshow[pageAll - 1] = getdata;
          getdata = [];
        }
      } else {
        hllinedetails += newhllinedetails;

        getdata.push(tableData[i]);

        if (i == tableData.length - 1) {
          tableData[i].totalclient = totalclient.toFixed(2);
          setdatatoshow[pageAll - 1] = getdata;
          getdata = [];
        }
      }
    }
  }
  // setcountpage += setcountpage + (lengthAlldatasearch - 1);
  // setpageAll(setcountpage);
  // opensummarize = true;

  setdatatoshow.forEach((item, iitem) => {

    if (iitem == setdatatoshow.length - 1) {
      item[setdatatoshow[iitem].length - 1].lastdata = "lastindex";
    }
    setdatashowAll(datashowAll => [...datashowAll, item]);
    // datashowAll.push(item);
  });
  // console.log("datashowAll")
  // console.log(datashowAll)
  // console.log(datashowAll.length)
  // console.log(pageAll)
};


  return (
    <Container fixed sx={{ mt: 3}} >
      <Box 
      sx={{}}
     >
          {/* Summary Icons */}
          <Grid 
          container item
          justifyContent="center"
          alignItems="center"
          sx={{
              margin: 'auto',
              width: '100%',
              maxWidth: '60rem'
          }}
          ><Typography variant="body1">{t(`Business Information Logs Page`)}</Typography>
          </Grid>

          <Grid 
          container item
          justifyContent="right"
          alignItems="right"
          sx={{
              margin: 'auto',
              width: '100%'
          }}
          >
            {/* {tableData.length > 0 &&  */}
            <Button
            disabled={tableData.length <= 0}
                     onClick={() => {
                      checkcontent();
                    }}
              variant="contained"
              sx={{
                miaxWidth: '200px',
                "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                },
                backgroundColor: "#254E58",
                "&:hover": { backgroundColor: "#162E34" },
            }}
              className="requestButton"
            >
              {t(`Show Print Details`)}
            </Button>
            {/* } */}
          </Grid>
          <br />
          <Grid>
          <Grid container spacing={2}>
 
  {/* <Grid item xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}>
   <TextField 
   fullWidth 
                  placeholder="ค้นหา..."
                  variant="outlined"
                  size="small"
                  InputProps={{
                      endAdornment: (<IconButton><SearchIcon /></IconButton>)
                  }}
                  onChange={(e)=>{
                      requestSearch(e.target.value)
                      setSearchText(e.target.value)
                  }} 
                  sx={{
                      //  width:500,
                      "& .MuiInput-underline:before": {
                          borderBottom: 1,
                          borderColor: "divider",
                      },
                      marginBottom:2
                  }}
              />
  </Grid> */}
  <Grid item xs={12}
                sm={5}
                md={5}
                lg={5}
                xl={5}>
  <Autocomplete
                onChange={(e:any,value: any) => {
                  if(!value){
                    setdataSearch({
                      ...dataSearch,
                      user_id:0,
                    });
                  }else{
                  setdataSearch({
                    ...dataSearch,
                    user_id: value?.id,
                  });
                }
                }}
                // inputValue={dataSearch.user_id}
                disableClearable={dataSearch.user_id == 0}
                size="small"
                options={dataAlluserandmember}
                // defaultValue={{ contact_name:""}}
                getOptionLabel={(options:any) => options.contact_name}
                sx={{
                  minWidth: '100%',
                  "& .MuiInput-underline:before": {
                      borderBottom: 1,
                      borderColor: "divider",
                  },
                  marginBottom:2
              }}
                renderInput={(params:any) => <TextField {...params} value={dataSearch.user_id} variant="outlined" label={t(`User`)} />}
              />
  </Grid>
  <Grid item xs={12}
                sm={5}
                md={5}
                lg={5}
                xl={5}>
                  <Autocomplete
                onChange={(e:any,value: any) => {
                  if(!value){
                    setdataSearch({
                      ...dataSearch,
                      status:"",
                    });
                  }else{
                    setdataSearch({
                      ...dataSearch,
                      status: value?.status,
                    });
                }

                 
                }}
                disableClearable={dataSearch.status == ""}
                size="small"
                disablePortal
                options={statuslist}
                sx={{
                  minWidth: '100%',
                  "& .MuiInput-underline:before": {
                      borderBottom: 1,
                      borderColor: "divider",
                  },
                  marginBottom:2
              }}
                renderInput={(params:any) => <TextField {...params} value={dataSearch.status}    variant="outlined" label={t(`Status`)}  />}
              />
  </Grid>
  <Grid item xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}>
                  <Button
                     onClick={() => {
                      SearchDataAll();
                    }}
              variant="contained"
              sx={{
                
                minWidth: '100%',
                "& .MuiInput-underline:before": {
                    borderBottom: 1,
                    borderColor: "divider",
                },
                backgroundColor: "#c79059",
                "&:hover": { backgroundColor: "#c18346" },
                marginBottom:2
            }}
              className="requestButton"
            >
              {t(`Search`)}
            </Button>
  </Grid>
</Grid>
              
              <DataGrid
                  // components={{ Toolbar: CustomToolbar }}
                  sx={{ backgroundColor: "white"}}  
                  rows={tableData}
                  columns={requestColumns}
                  // componentsProps={{
                  //     toolbar: {
                  //       value: searchText,
                  //       onChange: (searchVal: string) => requestSearch(searchVal),
                  //       onCancelSearch: () => cancelSearch()
                  //     }
                  // }}
                  autoHeight
                  pageSize={10}
                  rowsPerPageOptions={[10]}
              />
          </Grid>
          <br />

          <div id="mydivhtmltobase">
         
         {datashowAll.length > 0 &&  datashowAll.map((item:any,index:any) => (
          <>
         <div> 
        <div className="aligncenter" id="foo">
          <div  className="page aligncenter A4">
          <br />
            <div className="aligncenter">
              <div className="rowprpo ">
                <div className="col-md-6report"></div>
                <div className="col-md-6report">
                  <p className="alignright  colprpo mr30prpo mt10 mb-5">
                    Page : {index+1}
                  </p>
                </div>
              </div>
              <br />
              <div className="rowprpo aligncenter mb-5" >
                <div className="col-md-12report mt-30prpo">
                  <h2>
                  Business Information Logs Report
                  </h2>
                </div>
              </div>
              {/* <div className="rowprpo  aligncenter" >
                <div className="col-md-12report">
                  <h3>
                  Business Information Logs Report
                  </h3>
                </div>
              </div> */}
            </div>
            <br />
            <div
              className="rowprpo caption  ml30prpo mr30prpo mb-2 mt10"
            >
              <table className="captionhtable ">
                <tr>
                  <th
                    
                    scope="colgroup"
                    className=" prborderbottom2pxhtable prbordertop2pxhtable width10 "
                  >
                    <span>ID</span>
                  </th>
                  <th
                    scope="colgroup"
                    className=" prborderbottom2pxhtable prbordertop2pxhtable width20"
                  >
                    <span className="aligncenter">Datasheet No </span>
                  </th>
                  <th
                    scope="colgroup"
                    className=" prborderbottom2pxhtable prbordertop2pxhtable width30"
                  >
                    <span className="aligncenter">Name</span>
                  </th>
                  <th
                    scope="colgroup"
                    className=" prborderbottom2pxhtable prbordertop2pxhtable width20"
                  >
                    <span className="aligncenter">Date Create</span>
                  </th>
                  <th
                    scope="colgroup"
                    className=" prborderbottom2pxhtable prbordertop2pxhtable width20"
                  >
                    <span className="aligncenter">Date Update</span>
                  </th>
                </tr>
              
              </table>
            </div>
            {datashowAll[index] && datashowAll[index].map((data:any,i:any) => (
            <div
              id="content"
              className="rowprpo caption  ml30prpo mr30prpo"
            >
                <table className="">
                  <tr>
                    <th
                      className="width10"
                    >
                      <span>{data.id}</span>
                    </th>

                    <th
                      className="width20"
                    >
                      <span>{data.datasheet_number?data.datasheet_number:"-"}</span>
                    </th>
                    <th
                      className="width30"
                    >
                      <span>{data.name?data.name:"-"}</span>
                    </th>
                    <th
                      className="width20"
                    >
                      <span>{data.created_at_date?data.created_at_date:"-"}</span>
                    </th>
                    <th
                      className="width20"
                    >
                      <span>{data.updated_at_date?data.updated_at_date:"-"}</span>
                    </th>
                  </tr>
                </table>  
            </div>
              ))}
          </div>
        </div>
        
      </div>
      </>
    ))}
    </div>

    {datashowAll.length > 0 &&
      <Grid 
      container item
      justifyContent="center"
      alignItems="center"
      sx={{
          margin: 'auto',
          width: '100%',
          maxWidth: '60rem'
      }}
      >
<Button
                 onClick={() => {
                  exportToPDF();
                }}
          variant="contained"
          sx={{
            
            minWidth:'150px',
            "& .MuiInput-underline:before": {
                borderBottom: 1,
                borderColor: "divider",
            },
            backgroundColor: "#1976D2",
            marginTop:2,
            marginBottom:2
        }}
          className="requestButton"
        >
          {t(`Print`)}
        </Button>
      </Grid>

        
}
      </Box>
      </Container>
);
                }
export default BusinessInformationlogs;
