// import { history } from "../index";
import {
    server,
    REQUEST_EDIT_SUB_TYPE_LIST_FETCHING,
    REQUEST_EDIT_SUB_TYPE_LIST_SUCCESS,
    REQUEST_EDIT_SUB_TYPE_LIST_FAILED,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { RequesEditSubTypeList } from "../types/requet_edit_sub_type_list.type";
const Swal = require('sweetalert2')

export const setRequestEditSubTypeListFetchingToState = () => ({
    type: REQUEST_EDIT_SUB_TYPE_LIST_FETCHING,
});
  
export const setRequestEditSubTypeListSuccessToState = (payload: RequesEditSubTypeList[]) => ({
    type: REQUEST_EDIT_SUB_TYPE_LIST_SUCCESS,
    payload,
});
  
export const setRequestEditSubTypeListFailedToState = () => ({
    type: REQUEST_EDIT_SUB_TYPE_LIST_FAILED,
});

export const loadRequestEditSubTypeList = (req_id:number,req_sub_id:number) => {
    return async(dispatch: any) => {
        dispatch(setRequestEditSubTypeListFetchingToState());
        try {
            let result = await httpClient.get<any>(`${server.REQUEST_DETAIL}/get-request-select/${req_id}/${req_sub_id}`);
            dispatch(setRequestEditSubTypeListSuccessToState(result.data));
        } catch (error) {
            dispatch(setRequestEditSubTypeListFailedToState());
        }
    };
};
  