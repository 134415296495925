import {
  AGREEMENT_FETCHING,
  AGREEMENT_SUCCESS,
  AGREEMENT_BYLANG_SUCCESS,
  AGREEMENT_ADD_SUCCESS,
  AGREEMENT_ADD_FAILED,
  AGREEMENT_EDIT_SUCCESS,
  AGREEMENT_EDIT_FAILED,
  AGREEMENT_DELETE_SUCCESS,
  AGREEMENT_DELETE_FAILED,
  AGREEMENT_FAILED,
  AGREEMENT_CLEAR,
  AGREEMENT_FETCHING_ADDTOCART,
  } from "../services/Constants";
  import { Agreement } from "../types/agreement.type";
    
    export interface AgreementState {
        result: Agreement[];
        resultbylang: Agreement[];
        isFetching: boolean;
        isError: boolean;
        isAdddata: boolean,
        isEditdata: boolean,
        isAddError: boolean;
        isEditError: boolean;
        isDeletedata: boolean;
        isDeleteError: boolean;
        isFetchingAddtoCart: boolean;
    }
    
    const initialState: AgreementState = {
        result: [],
        resultbylang:[],
        isFetching: false,
        isError: false,
        isAdddata: false,
        isEditdata: false,
        isAddError: false,
        isEditError: false,
        isDeletedata: false,
        isDeleteError: false,
        isFetchingAddtoCart: false,
    };
    
    export default (state = initialState, { type, payload}: any) => {
      switch (type) {
        case AGREEMENT_FETCHING:
          return { ...state, result: [],  resultbylang:[], isFetching: true,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: false};
        case AGREEMENT_SUCCESS:
          return { 
            ...state, 
            result: payload,
            resultbylang:[],
            isFetching: false,
            isAdddata: false,
            isEditdata: false,
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
          case AGREEMENT_BYLANG_SUCCESS:
          return { 
            ...state, 
            result: [],
            resultbylang:payload,
            isFetching: false,
            isAdddata: false,
            isEditdata: false,
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
          case AGREEMENT_ADD_SUCCESS:
          return { 
            ...state, 
            isFetching: false,
            isAdddata: true,
            isEditdata: false, 
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
          case AGREEMENT_EDIT_SUCCESS:
          return { 
            ...state, 
            isFetching: false, 
            isAdddata: false,
            isEditdata: true, 
            isError: false,
            isAddError: false,
            isEditError: false,
            isDeletedata: false,
            isDeleteError: false
          };
        case AGREEMENT_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false,
             isError: true,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: false};
        case AGREEMENT_ADD_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false,
             isError: false,isAddError: true,isEditError: false,isDeletedata: false,isDeleteError: false};
          case AGREEMENT_EDIT_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: true,isDeletedata: false,isDeleteError: false};
            case AGREEMENT_DELETE_SUCCESS:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: true,isDeleteError: false};
            case AGREEMENT_DELETE_FAILED:
          return { ...state, result: [], isFetching: false,isAdddata: false,isEditdata: false, 
            isError: false,isAddError: false,isEditError: false,isDeletedata: false,isDeleteError: true};
            case AGREEMENT_FETCHING_ADDTOCART:
          return { isFetchingAddtoCart: true};
        default:
          return state;
      }
      
    };
    