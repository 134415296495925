import * as React from "react";
import "./AgreementPage.css";
import { Paper, Grid } from "@mui/material";
import Iframe from "react-iframe";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import getLanguage from "../../Translation/Translation";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "../../..";
import * as registerActions from "../../../actions/register.action";
import * as agreementActions from "../../../actions/agreement.action";
import { RootReducers } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Typography from "@mui/material/Typography";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { fontSize } from "@mui/system";
import CardMedia from "@mui/material/CardMedia";
import { highlightTrailingWhitespace } from "jest-matcher-utils";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DataSheetCreate } from "../../../types/data_sheet.type";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from "@mui/icons-material/Circle";
import Link from "@mui/material/Link";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Stack,
  SxProps,
  Theme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ReactPDF from "@react-pdf/renderer";
import Addvertising_Agreement from "../../layouts/assets/agreement/Addvertising Agreement.pdf";
import { imageUrl, agreementfileUrl } from "../../../services/Constants";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {encode,decode} from "../../layouts/assets/calculator/decode_encode"

type AgreementPageProps = {
  //
};

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "blanchedalmond",
  width: 1,
  paddingBottom: 1,
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const AgreementPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const agreementStateReducer = useSelector(
    (state: RootReducers) => state.agreementStateReducer
  );
  const servicetypeStateReducer = useSelector((state: RootReducers) => state.serviceTypeStateReducer);
  const [rows, setrows] = React.useState<any[]>([]);
  const [searched, setSearched] = React.useState<string>("");
  const [searchrows, setsearchrows] = React.useState(rows);
  const [showcloseicon, setshowcloseicon] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const [nameform, setnameform] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [addtocartname, setaddtocartname] = React.useState('');
  const setdataagreementA = [
    { showgroup: true, group: "A" },
    {
      group: "A",
      name: "Addvertising Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "A",
      name: "Agency Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "A",
      name: "Assets Purchase Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "A",
      name: "Assignment Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];
  const setdataagreementB = [
    { showgroup: true, group: "B" },
    {
      group: "B",
      name: "Brokerage Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "B",
      name: "Buying Agency Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementC = [
    { showgroup: true, group: "C" },
    {
      group: "C",
      name: "Collateral Agreement Commission Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Compromise Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Condominium Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Condominium Purchase Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Confidential Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Consignment Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Consortium Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Construction Engineering Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Consultancy Advisory Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Consulting Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Contract Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Cooperation Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Copyright Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Corporate Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Cost Sharing Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Credit Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Currency Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Custodian Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "C",
      name: "Customs Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementD = [
    { showgroup: true, group: "D" },
    {
      group: "D",
      name: "Dealer Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "D",
      name: "Debt Restructuring Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "D",
      name: "Deposit Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "D",
      name: "Disclosure Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "D",
      name: "Discounting Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "D",
      name: "Disengagement Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "D",
      name: "Dissolution Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "D",
      name: "Distribution Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "D",
      name: "Distributorship Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "D",
      name: "Divorce Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "D",
      name: "Donation Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementE = [
    { showgroup: true, group: "E" },
    {
      group: "E",
      name: "Employment Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "E",
      name: "Employment Termination Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "E",
      name: "Escrow Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "E",
      name: "Exchange Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementF = [
    { showgroup: true, group: "F" },
    {
      group: "F",
      name: "Facility Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "F",
      name: "Factoring Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "F",
      name: "Foreign Exchange Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "F",
      name: "Franchise Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementG = [
    { showgroup: true, group: "G" },
    {
      group: "G",
      name: "Granting of Khow-How Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "G",
      name: "Guarantee Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementH = [
    { showgroup: true, group: "H" },
    {
      group: "H",
      name: "Heads Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "H",
      name: "Hire Purchase Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "H",
      name: "Hire of Work Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementI = [
    { showgroup: true, group: "I" },
    {
      group: "I",
      name: "Import Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "I",
      name: "Indemnity Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "I",
      name: "Inter Account Transfer Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "I",
      name: "Interior Design Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "I",
      name: "Investment Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementJ = [
    { showgroup: true, group: "J" },
    {
      group: "J",
      name: "Joint Venture Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementL = [
    { showgroup: true, group: "L" },
    {
      group: "L",
      name: "Land Lease Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "L",
      name: "Land Purchase Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "L",
      name: "Lease Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "L",
      name: "License Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "L",
      name: "Licensing Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "L",
      name: "Loan Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "L",
      name: "Loss Sharing Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementM = [
    { showgroup: true, group: "M" },
    {
      group: "M",
      name: "Management Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "M",
      name: "Manufacturing Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "M",
      name: "Marketing Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "M",
      name: "Master Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "M",
      name: "Merger Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "M",
      name: "Mortgage Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "M",
      name: "Mutual Release Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementN = [
    { showgroup: true, group: "N" },
    {
      group: "N",
      name: "Netting Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "N",
      name: "Novation Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementO = [
    { showgroup: true, group: "O" },
    {
      group: "O",
      name: "Operation Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "O",
      name: "Opinion Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "O",
      name: "Ownership Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementP = [
    { showgroup: true, group: "P" },
    {
      group: "P",
      name: "Participation Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "P",
      name: "Partner Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "P",
      name: "Partnership Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "P",
      name: "Payment Factory Agreement ",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "P",
      name: "Performance Guarantee Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "P",
      name: "Pledge Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "P",
      name: "Prenuptial Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "P",
      name: "Processing Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "P",
      name: "Purchase Sale Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementR = [
    { showgroup: true, group: "R" },
    {
      group: "R",
      name: "Rahabilitation Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "R",
      name: "Release Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "R",
      name: "Retainer Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "R",
      name: "Royalty Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementS = [
    { showgroup: true, group: "S" },
    {
      group: "S",
      name: "Sale Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Sale Purchase Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Scholarship Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Seller Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Separation Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Service Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Servitude Agreement ",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Set-Off Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Settlement Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Severance Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Share Pledges Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Share Purchase Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Shared Resource Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Shareholders Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Software Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Sponsorship Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Subscription Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Sub-Lease Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Superintendent Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Supplier Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Supply Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "S",
      name: "Suretyship Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementT = [
    { showgroup: true, group: "T" },
    {
      group: "T",
      name: "Technical Assistance Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "T",
      name: "Trademark Security Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
    {
      group: "T",
      name: "Transfer of Employment Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementU = [
    { showgroup: true, group: "U" },
    {
      group: "U",
      name: "Underwriting Agreement",
      preview: "Preview",
      addtocard: "Add to cart",
    },
  ];

  const setdataagreementLease = [
    { showgroup: true, group: "สัญญาเช่า" },
    {
      group: "สัญญาเช่า",
      name: "สัญญาเช่าอาคารสำนักงาน",
      preview: "Preview",
      addtocard: "Add to card",
    },
  ];
  const setdataagreementService = [
    { showgroup: true, group: "สัญญาบริการ" },
    {
      group: "สัญญาบริการ",
      name: "สัญญาการใช้บริการระบบสื่อสารข้อมูลระหว่างประเทศ",
      preview: "Preview",
      addtocard: "Add to card",
    },
    {
      group: "สัญญาบริการ",
      name: "สัญญาขนส่ง",
      preview: "Preview",
      addtocard: "Add to card",
    },
  ];
  let AlldataAgreement: any = [];
  //   const AlldataAgreement = [...setdataagreementA,...setdataagreementB,...setdataagreementC,...setdataagreementD,...setdataagreementE,...setdataagreementF,...setdataagreementG
  //   ,...setdataagreementH,...setdataagreementI,...setdataagreementJ,...setdataagreementL,...setdataagreementM,...setdataagreementN,...setdataagreementO,...setdataagreementP
  // ,...setdataagreementR,...setdataagreementS,...setdataagreementT,...setdataagreementU,...setdataagreementLease,...setdataagreementService]
  const { id } = useParams();
  React.useEffect(() => {
    dispatch(
      agreementActions.getDataAGREEMENTBylang(
        {
          params: {
            lang: localStorage.getItem("i18nextLng")
              ? localStorage.getItem("i18nextLng")
              : "en",
            service_type_id: id,
          },
        },
        navigate
      )
    );
  }, []);

  React.useEffect(() => {
    console.log(agreementStateReducer)
    if(agreementStateReducer.resultbylang){
    AlldataAgreement = agreementStateReducer.resultbylang;
    setrows(AlldataAgreement);
    setsearchrows(AlldataAgreement);
    // if(AlldataAgreement[1]){
    //   setnameform(AlldataAgreement[1].service_type_name);
    // }
  }
  }, [agreementStateReducer.resultbylang]);

  React.useEffect(() => {
    dispatch(
      agreementActions.getDataAGREEMENTBylang(
        {
          params: {
            lang: localStorage.getItem("i18nextLng")
              ? localStorage.getItem("i18nextLng")
              : "en",
            service_type_id: id,
          },
        },
        navigate
      )
    );
  }, [i18next.language, id]);

  React.useEffect(() => {
    console.log(servicetypeStateReducer.result)
    if (servicetypeStateReducer.result) {
      servicetypeStateReducer.result.forEach((x,i)=>{
          if(id == x.id){
            if(i18n.language === "en"){
              setnameform(x.name);
            }else{
              setnameform(x.name_th);
            }
      }
      })
     
    }
  }, [servicetypeStateReducer.result,i18next.language, id]);


  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const filteredRows = rows.filter((row) => {
      // console.log(row)
      return row.name
        ?.toString()
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    let calldatagroup = "";
    let count = 0;
    const resultArray = filteredRows.reduce((dataAll, databyRow, dataIndex) => {
      dataAll.push(databyRow);
      if (dataIndex == 0) {
        dataAll.splice(dataIndex, 0, {
          showgroup: true,
          group: databyRow.group,
        });
        count++;
        calldatagroup = databyRow.group;
      } else if (calldatagroup !== databyRow.group) {
        dataAll.splice(dataIndex + count, 0, {
          showgroup: true,
          group: databyRow.group,
        });
        count++;
        calldatagroup = databyRow.group;
      }
      return dataAll;
    }, []);

    // console.log(resultArray);

    setsearchrows(resultArray);
    // console.log(searchrows)
    if (searchedVal === "") {
      setshowcloseicon(false);
    } else {
      setshowcloseicon(true);
    }
    setPage(0);
  };

  const handleClickClearSearch = () => {
    setSearched("");
    setsearchrows(rows);
    setshowcloseicon(false);
  };

  const handlePreview = (value: any) => {
    let pdf_newTab = window.open("");

    if (!pdf_newTab) {
    } else {
      pdf_newTab.document.write(
        `<html><head><title>${value.pdf_file}</title></head><body><iframe title='MY title'  width='100%' height='100%' src='` +
          `${agreementfileUrl}/${value.pdf_file}#toolbar=0&navpanes=0&scrollbar=0` +
          `'></iframe></body></html>`
      );
    }

    return;
  };

  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
  };
  const languages = [
    {
      code: "en",
      name: "English",
    },
    {
      code: "th",
      name: "ไทย",
    },
  ];

  return (
    <Grid>
      <Grid
        container
        sx={{ ...gridStyles }}
        justifyContent="center"
        className="ag-box"
      >
        <Grid className="contact-col" sx={{ textAlign: "center", mb: 10 }}>
          <Typography
            sx={{
              color: "#4F4A41",
              marginBottom: "1rem",
              marginTop: "4rem",
              textAlign: "center",
            }}
            variant="h5"
            component="h5"
          >
            {t(`${nameform}`)}
          </Typography>
        </Grid>

        <Grid
          className="contact-row"
          item
          xs={12}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          sx={{ textAlign: "center", mt: -10 }}
        >
          <InsertDriveFile
            sx={{ color: "#876445", width: "100%", height: "80px" }}
          />
          <Typography
            sx={{ color: "#876445", marginBottom: "4rem", fontSize: "14px" }}
          >
            {t("Choose Form(s)")}
          </Typography>
        </Grid>
        <Grid
          className="contact-row"
          item
          xs={12}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          sx={{ textAlign: "center", mt: -10, mb: 10 }}
        >
          <HorizontalRuleIcon
            sx={{ color: "#876445", width: "100%", height: "80px" }}
          />
        </Grid>

        <Grid
          className="contact-row"
          item
          xs={12}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          sx={{ textAlign: "center", mt: -10 }}
        >
          <ShoppingCartIcon
            sx={{ color: "#876445", width: "100%", height: "80px" }}
          />
          <Typography
            sx={{ color: "#876445", marginBottom: "4rem", fontSize: "14px" }}
          >
            {t("Checkout and Pay")}
          </Typography>
        </Grid>
        <Grid
          className="contact-row"
          item
          xs={12}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          sx={{ textAlign: "center", mt: -10, mb: 10 }}
        >
          <HorizontalRuleIcon
            sx={{ color: "#876445", width: "100%", height: "80px" }}
          />
        </Grid>

        <Grid
          className="contact-row"
          item
          xs={12}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          sx={{ textAlign: "center", mt: -10 }}
        >
          <LocalPrintshopIcon
            sx={{
              color: "#876445",
              width: "100%",
              height: "80px",
            }}
          />
          <Typography
            sx={{ color: "#876445", marginBottom: "4rem", fontSize: "14px" }}
          >
            {t("Download and Print")}
          </Typography>
        </Grid>
      </Grid>

      <Container maxWidth="md" sx={{ mt: 3 }}>
        <Grid container justifyContent="center">
          <Grid className="contact-col">
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <TextField
                sx={{ width: "60%" }}
                value={searched}
                onChange={(e) => {
                  requestSearch(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickClearSearch}
                        edge="end"
                      >
                        {showcloseicon ? <CloseIcon /> : ""}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="input-with-icon-textfield"
                label="Search"
                size="small"
                variant="outlined"
              />
            </Box>
          </Grid>
          {/* <Box sx={{ width: "100%",textAlign:"left", mt: 3 }}>
        <Grid >
          <Typography sx={{textDecoration: 'underline',fontWeight: 'bold',fontSize: 18}}>
           A
          </Typography>
        </Grid>
        </Box> */}

          {React.Children.toArray(
            searchrows.length > 0 &&
              searchrows.map((value, index) => (
                <Box sx={{ width: "100%", textAlign: "left" }}>
                  {value.showgroup == true ? (
                    <Grid container spacing={0} sx={{ mb: 2 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography
                          sx={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                            fontSize: 18,
                            mt: 3,
                          }}
                        >
                          {value.group}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={0} sx={{ mt: 2 }}>
                      <Grid item xs={7}>
                        {/* <Typography sx={{display: 'inline-block'}}> */}
                        {/* <ul> */}
                        <li className="form-list">{value.name}</li>
                        {/* </ul> */}
                        {/* <ListItem className="form-box">
                        <CircleIcon sx={{fontSize:"8px",mr:1}} /> {value.name}
                        </ListItem> */}
                        {/* </Typography> */}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        sx={{ textAlign: "right" }}
                      >
                        <Typography sx={{ display: "inline-block",mr:2 }}>
                            {" "}
                            {value.price}฿
                          
                        </Typography>
                        <Typography sx={{ display: "inline-block" }}>
                          <Button
                            onClick={() => handlePreview(value)}
                            variant="text"
                            sx={{
                              color: "#CA965C",
                              textDecoration: "underline",
                            }}
                          >
                            {" "}
                            preview{" "}
                          </Button>
                        </Typography>
                         <Typography sx={{display: 'inline-block',ml:2}}>
                          <Button variant="text" sx={{textDecoration:"underline"}}
                           onClick={() => {
                           
                            if(localStorage.getItem("YourCart")){
                              let dataduplicate = false;
                              let oldresult:any;
                              try{
                                oldresult  = JSON.parse(decode(localStorage.getItem("YourCart")));
                                console.log(oldresult);
                              }catch (error) {
                                dispatch(agreementActions.setAGREEMENTFetchingAddtoCartToState());
                                return;
                            }
                            if(oldresult.length > 0){
                              oldresult.forEach((item:any,index:any) => {
                                  if(item.id == value.id){
                                  dataduplicate = true;
                                  item.count = item.count+1;
                                }
                                if(index == oldresult.length-1){
                                  if(!dataduplicate){
                                    oldresult.push({id:value.id,count:1});
                                    localStorage.setItem("YourCart",encode(JSON.stringify(oldresult)));
                                    dispatch(agreementActions.setAGREEMENTFetchingAddtoCartToState());
                                  }else{
                                    localStorage.setItem("YourCart",encode(JSON.stringify(oldresult)));
                                    dispatch(agreementActions.setAGREEMENTFetchingAddtoCartToState());
                                  }
                                 
                                }
                              });
                            }else{
                              localStorage.setItem("YourCart",encode(JSON.stringify([{id:value.id,count:1}])));
                              dispatch(agreementActions.setAGREEMENTFetchingAddtoCartToState());
                            }
                            }else{
                          try{   
                              localStorage.setItem("YourCart",encode(JSON.stringify([{id:value.id,count:1}])));
                              dispatch(agreementActions.setAGREEMENTFetchingAddtoCartToState());
                            }catch (error) {
                              dispatch(agreementActions.setAGREEMENTFetchingAddtoCartToState());
                              
                          }

                            }
                            setOpenDialog(true);
                            setaddtocartname(value.name);
                            
                           }} > add to cart</Button>
                          </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              ))
          )}
        </Grid>
        <Dialog
                            fullWidth
                                open={openDialog}
                                onClose={() => setOpenDialog(false)}
                                keepMounted
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle sx={{alignSelf:'center'}} id="alert-dialog-slide-title">
                                {t("Added")} {addtocartname}  {t("To Cart")}
                                </DialogTitle>
                                <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
                                <DialogContent sx={{textAlign:'center'}}>
                                <DialogContentText id="alert-dialog-slide-description"> </DialogContentText>
                                <Button
                onClick={() => navigate('/your-cart')}
                variant="outlined" 
                sx={{ 
                  backgroundColor: '#876445', 
                  color:'#ffffff',
                  borderColor:'#876445',
                  marginBottom: '1rem',
                  '&:hover': {backgroundColor: '#E8630A', borderColor:'#876445',}, 
                  width:'80%',
                }}
                
              >
              {t('PROCEED TO CART')}
            </Button>
            <Button
                onClick={() => setOpenDialog(false)}
                variant="outlined" 
                sx={{ 
                  backgroundColor: '#ffffff', 
                  color:'#876445',
                  borderColor:'#876445',
                  marginTop: '1rem',
                  '&:hover': {backgroundColor: '#E8630A', borderColor:'#876445',}, 
                  width:'80%',
                }}
                
              >
              {t('CONTINUE SHOPPING')}
            </Button>
                                </DialogContent>
                                <DialogActions>
                                </DialogActions>
                            </Dialog>
                          
      </Container>
    </Grid>
  );
};

export default AgreementPage;
