import {
  COMPANY_LIST_FETCHING,
  COMPANY_LIST_SUCCESS,
  COMPANY_LIST_FAILED,
} from "../services/Constants";
import { CompanyList } from "../types/conpany_list.type";
    
    export interface CompanyListState {
        result: CompanyList[];
        isFetching: boolean;
        isError: boolean;
    }
    
    const initialState: CompanyListState = {
        result: [],
        isFetching: false,
        isError: false,
    };
    
    export default (state = initialState, { type, payload}: any) => {
      switch (type) {
        case COMPANY_LIST_FETCHING:
          return { ...state, result: [], isFetching: true, isError: true };
        case COMPANY_LIST_SUCCESS:
          return { ...state, result: payload, isFetching: false, isError: false };
        case COMPANY_LIST_FAILED:
          return { ...state, result: [], isFetching: false, isError: true };
        default:
          return state;
      }
    };
    