// import { history } from "../index";
import {
    AGREEMENT_FETCHING,
    AGREEMENT_SUCCESS,
    AGREEMENT_BYLANG_SUCCESS,
    AGREEMENT_ADD_SUCCESS,
    AGREEMENT_ADD_FAILED,
    AGREEMENT_EDIT_SUCCESS,
    AGREEMENT_EDIT_FAILED,
    AGREEMENT_DELETE_SUCCESS,
    AGREEMENT_DELETE_FAILED,
    AGREEMENT_FAILED,
    AGREEMENT_CLEAR,
    AGREEMENT_FETCHING_ADDTOCART,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { Agreement } from "../types/agreement.type";
const Swal = require('sweetalert2')

export const setAGREEMENTFetchingToState = () => ({
    type: AGREEMENT_FETCHING,
});
  
export const setAGREEMENTSuccessToState = (
    payload: Agreement[],) => (
        {
            type: AGREEMENT_SUCCESS,
            payload,
        }
);

export const setAGREEMENTBylangSuccessToState = (
  payload: Agreement[],) => (
      {
          type: AGREEMENT_BYLANG_SUCCESS,
          payload,
      }
);

export const setAGREEMENTAddSuccessToState = (payload: any) => ({
            type: AGREEMENT_ADD_SUCCESS,
            payload,
});

export const setAGREEMENTAddFailedToState = () => ({
    type: AGREEMENT_ADD_FAILED,
});

export const setAGREEMENTEditSuccessToState = (payload: any) => ({
    type: AGREEMENT_EDIT_SUCCESS,
    payload,
});

export const setAGREEMENTEditFailedToState = () => ({
    type: AGREEMENT_EDIT_FAILED,
});

export const setAGREEMENTDeleteSuccessToState = (payload: any) => ({
    type: AGREEMENT_DELETE_SUCCESS,
    payload,
});

export const setAGREEMENTDeleteFailedToState = () => ({
    type: AGREEMENT_DELETE_FAILED,
});
  
export const setAGREEMENTFailedToState = () => ({
    type: AGREEMENT_FAILED,
});

export const setAGREEMENTClearToState = () => ({
    type: AGREEMENT_CLEAR,
});

export const setAGREEMENTFetchingAddtoCartToState = () => ({
  type: AGREEMENT_FETCHING_ADDTOCART,
});


export const loadAGREEMENT = () => {
    return async(dispatch: any) => {
        dispatch(setAGREEMENTFetchingToState());
        getDataAGREEMENT(dispatch)
    };
};

export const loadAGREEMENTACTIVE = () => {
  return async(dispatch: any) => {
      // dispatch(setAGREEMENTFetchingToState());
      getDataAGREEMENTACTIVE(dispatch)
  };
};

const getDataAGREEMENT = async (dispatch: any) => {
    try {
        const result = await httpClient.get<Agreement[]>(`${server.AGREEMENT}/getAll`);
        console.log(result);
        result.data.forEach((item)=>{
            if(item.status == 1){
                item.status = "Active"
            }
            else if(item.status == 0){
                item.status = "Inactive"
            }else{
                item.status = ""
            }
            
        })
        dispatch(setAGREEMENTSuccessToState(
            result.data 
        ));
    }   catch (error) {
        dispatch(setAGREEMENTFailedToState());
    }
}

const getDataAGREEMENTACTIVE = async (dispatch: any) => {
  try {
      const result = await httpClient.get<Agreement[]>(`${server.AGREEMENT}/getAllActive`);
      result.data.forEach((item)=>{
          if(item.status == 1){
              item.status = "Active"
          }
          else if(item.status == 0){
              item.status = "Inactive"
          }else{
              item.status = ""
          }
          
      })
      dispatch(setAGREEMENTSuccessToState(
          result.data 
      ));
  }   catch (error) {
      dispatch(setAGREEMENTFailedToState());
  }
}

export const getDataAGREEMENTBylang = (query: any, navigate: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(setAGREEMENTFetchingToState());
      const result = await httpClient.get<Agreement[]>(`${server.AGREEMENT}/getBylang`,query);
         dispatch(setAGREEMENTBylangSuccessToState(result.data));
         
    } catch (error) {
      dispatch(setAGREEMENTAddFailedToState());
    }
  };
};
  
export const addAgreement = (data: any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setAGREEMENTFetchingToState());
        // connect
        const result = await httpClient.post(server.AGREEMENT+"/add", data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setAGREEMENTAddSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setAGREEMENTAddFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setAGREEMENTAddFailedToState());
      }
    };
  };

  export const editAgreement = (id: any,data: any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setAGREEMENTFetchingToState());
        // connect
        const result = await httpClient.put(`${server.AGREEMENT}/${id}`, data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setAGREEMENTEditSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setAGREEMENTEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setAGREEMENTEditFailedToState());
      }
    };
  };


  export const deleteAgreementGroup = (AGREEMENT_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.AGREEMENT + `/${AGREEMENT_id}`);
            console.log(result);
            if (result.status == 204) {
               dispatch(setAGREEMENTDeleteSuccessToState(result.data));
             } else {
               dispatch(setAGREEMENTDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setAGREEMENTDeleteFailedToState());
        }
    };
};
  