// import { history } from "../index";
import {
    AGREEMENT_GROUP_FETCHING,
    AGREEMENT_GROUP_SUCCESS,
    AGREEMENT_GROUP_ADD_SUCCESS,
    AGREEMENT_GROUP_ADD_FAILED,
    AGREEMENT_GROUP_EDIT_SUCCESS,
    AGREEMENT_GROUP_EDIT_FAILED,
    AGREEMENT_GROUP_DELETE_SUCCESS,
    AGREEMENT_GROUP_DELETE_FAILED,
    AGREEMENT_GROUP_FAILED,
    AGREEMENT_GROUP_CLEAR,
    server,
    MEMBER_ID
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { AgreementGroup } from "../types/agreement_group.type";
const Swal = require('sweetalert2')

export const setAGREEMENT_GROUPFetchingToState = () => ({
    type: AGREEMENT_GROUP_FETCHING,
});
  
export const setAGREEMENT_GROUPSuccessToState = (
    payload: AgreementGroup[],) => (
        {
            type: AGREEMENT_GROUP_SUCCESS,
            payload,
        }
);

export const setAGREEMENT_GROUPAddSuccessToState = (payload: any) => ({
            type: AGREEMENT_GROUP_ADD_SUCCESS,
            payload,
});

export const setAGREEMENT_GROUPAddFailedToState = () => ({
    type: AGREEMENT_GROUP_ADD_FAILED,
});

export const setAGREEMENT_GROUPEditSuccessToState = (payload: any) => ({
    type: AGREEMENT_GROUP_EDIT_SUCCESS,
    payload,
});

export const setAGREEMENT_GROUPEditFailedToState = () => ({
    type: AGREEMENT_GROUP_EDIT_FAILED,
});

export const setAGREEMENT_GROUPDeleteSuccessToState = (payload: any) => ({
    type: AGREEMENT_GROUP_DELETE_SUCCESS,
    payload,
});

export const setAGREEMENT_GROUPDeleteFailedToState = () => ({
    type: AGREEMENT_GROUP_DELETE_FAILED,
});
  
export const setAGREEMENT_GROUPFailedToState = () => ({
    type: AGREEMENT_GROUP_FAILED,
});

export const setAGREEMENT_GROUPClearToState = () => ({
    type: AGREEMENT_GROUP_CLEAR,
});
  

export const loadAGREEMENT_GROUP = () => {
    return async(dispatch: any) => {
        dispatch(setAGREEMENT_GROUPFetchingToState());
        getDataAGREEMENT_GROUP(dispatch)
    };
};

const getDataAGREEMENT_GROUP = async (dispatch: any) => {
    try {
        const result = await httpClient.get<AgreementGroup[]>(`${server.AGREEMENT_GROUP}/getAll`);
        result.data.forEach((item)=>{
            if(item.status == 1){
                item.status = "Active"
            }
            else if(item.status == 0){
                item.status = "Inactive"
            }else{
                item.status = ""
            }
            
        })
        dispatch(setAGREEMENT_GROUPSuccessToState(
            result.data 
        ));
    }   catch (error) {
        dispatch(setAGREEMENT_GROUPFailedToState());
    }
}

export const loadAGREEMENTGROUPByservice_type_id = (id:any) => {
  return async(dispatch: any) => {
    dispatch(setAGREEMENT_GROUPFetchingToState());
    getDataAGREEMENT_GROUPByservice_type_id(id,dispatch)
  };
};

const getDataAGREEMENT_GROUPByservice_type_id = async (id:any,dispatch: any) => {
  try {
      const result = await httpClient.get<AgreementGroup[]>(`${server.AGREEMENT_GROUP}/getAllByservice_type_id/${id}`);
      result.data.forEach((item)=>{
          if(item.status == 1){
              item.status = "Active"
          }
          else if(item.status == 0){
              item.status = "Inactive"
          }else{
              item.status = ""
          }
          
      })
      dispatch(setAGREEMENT_GROUPSuccessToState(
          result.data 
      ));
  }   catch (error) {
      dispatch(setAGREEMENT_GROUPFailedToState());
  }
}
  
  
export const addAgreement = (data: any, navigate: any) => {
    console.log(data)
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setAGREEMENT_GROUPFetchingToState());
        // connect
        const result = await httpClient.post(server.AGREEMENT_GROUP+"/add", data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setAGREEMENT_GROUPAddSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setAGREEMENT_GROUPAddFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setAGREEMENT_GROUPAddFailedToState());
      }
    };
  };

  export const editAgreement = (data: any, navigate: any) => {
    return async (dispatch: any) => {
      try {
        // begin connecting...
        dispatch(setAGREEMENT_GROUPFetchingToState());
        // connect
        const result = await httpClient.put(`${server.AGREEMENT_GROUP}/${data.id}`, data);
        if (result.status == 200 || result.status == 201) {
           dispatch(setAGREEMENT_GROUPEditSuccessToState(result.data));
           
          // setTimeout(() => {
            // navigate("/home");
          // }, 2000);
        } else {
          dispatch(setAGREEMENT_GROUPEditFailedToState());
        }
      } catch (error) {
        // error
        dispatch(setAGREEMENT_GROUPEditFailedToState());
      }
    };
  };


  export const deleteAgreementGroup = (agreement_group_id:number,navigate:any) => {
    return async(dispatch: any) => {
        try {
            const result = await httpClient.delete(server.AGREEMENT_GROUP + `/${agreement_group_id}`);
            console.log(result);
            if (result.status == 204) {
               dispatch(setAGREEMENT_GROUPDeleteSuccessToState(result.data));
             } else {
               dispatch(setAGREEMENT_GROUPDeleteFailedToState());
             }
            
        } catch (error) {
            dispatch(setAGREEMENT_GROUPDeleteFailedToState());
        }
    };
};
  