// import { history } from "../index";
import {
    server,
    REQUEST_TYPE_LIST_FETCHING,
    REQUEST_TYPE_LIST_SUCCESS,
    REQUEST_TYPE_LIST_FAILED,
    REQUEST_TYPE_ADD_SUCCESS,
    REQUEST_TYPE_ADD_FAILED,
    REQUEST_TYPE_EDIT_SUCCESS,
    REQUEST_TYPE_EDIT_FAILED,
    REQUEST_TYPE_DELETE_SUCCESS,
    REQUEST_TYPE_DELETE_FAILED,
} from "../services/Constants";
import { httpClient } from "../services/httpclient";
import { RequesTypeList } from "../types/requet_type_list.type";
import i18n from 'i18next';
const Swal = require('sweetalert2');

export const setRequestTypeListFetchingToState = () => ({
    type: REQUEST_TYPE_LIST_FETCHING,
});
  
export const setRequestTypeListSuccessToState = (payload: RequesTypeList[]) => ({
    type: REQUEST_TYPE_LIST_SUCCESS,
    payload,
});
  
export const setRequestTypeListFailedToState = () => ({
    type: REQUEST_TYPE_LIST_FAILED,
});

export const setRequestTypeAddSuccessToState = (payload: any) => ({
    type: REQUEST_TYPE_ADD_SUCCESS,
    payload,
});

export const setRequestTypeAddFailedToState = () => ({
    type: REQUEST_TYPE_ADD_FAILED,
});
export const setRequestTypeEditSuccessToState = (payload: any) => ({
    type: REQUEST_TYPE_EDIT_SUCCESS,
    payload,
});

export const setRequestTypeEditFailedToState = () => ({
    type: REQUEST_TYPE_EDIT_FAILED,
});
export const setRequestTypeDeleteSuccessToState = (payload: any) => ({
    type: REQUEST_TYPE_DELETE_SUCCESS,
    payload,
});

export const setRequestTypeDeleteFailedToState = () => ({
    type: REQUEST_TYPE_DELETE_FAILED,
});

export const loadRequestTypeList = () => {
    return async(dispatch: any) => {
        // dispatch(setRequestTypeListFetchingToState());
        try {
            let result = await httpClient.get<any>(`${server.REQUEST_TYPE}`);
            // console.log("result.data")
            // console.log(result.data)
            dispatch(setRequestTypeListSuccessToState(result.data));
        } catch (error) {
            dispatch(setRequestTypeListFailedToState());
        }
    };
};
export const loadRequestTypeListData = () => {
    return async(dispatch: any) => {
        // dispatch(setRequestTypeListFetchingToState());
        try {
            let result = await httpClient.get<any>(`${server.REQUEST_TYPE}/load-request`);
            // console.log("result.data")
            // console.log(result.data)
            dispatch(setRequestTypeListSuccessToState(result.data));
        } catch (error) {
            dispatch(setRequestTypeListFailedToState());
        }
    };
};

export const addRequestType = (data: any, navigate: any) => {
    console.log(data)
    return async (dispatch: any) => {
      try {
        dispatch( setRequestTypeListFetchingToState())
        const result = await httpClient.post(server.REQUEST_TYPE+"/add", data);
        if (result.status == 200 || result.status == 201) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: `${i18n.t(`Information Saved`)}`,
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(setRequestTypeAddSuccessToState(result.data))
        } 
      } catch (error) {
        dispatch(setRequestTypeAddFailedToState())
      }
    };
};
export const editRequestType = (id:number,data: any, navigate: any) => {
    console.log(data)
    return async (dispatch: any) => {
      try {
        dispatch( setRequestTypeListFetchingToState())
        const result = await httpClient.put(server.REQUEST_TYPE+`/edit/${id}`, data);
        if (result.status == 200 || result.status == 201) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: `${i18n.t(`Information Saved`)}`,
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(setRequestTypeEditSuccessToState(result.data))
        } 
      } catch (error) {
        dispatch(setRequestTypeEditFailedToState())
      }
    };
};
export const deleteRequestType = (id:number, navigate: any) => {
    return async (dispatch: any) => {
      try {
        dispatch( setRequestTypeListFetchingToState())
        const result = await httpClient.delete(server.REQUEST_TYPE+`/${id}`);
        if (result.status == 200 || result.status == 201) {
            dispatch(setRequestTypeDeleteSuccessToState(result.data))
        } 
      } catch (error) {
        dispatch(setRequestTypeDeleteFailedToState())
      }
    };
};
  